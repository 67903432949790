import classNames from "classnames";
import { Field } from "formik";
import Box from "pages/_components/Box";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import FormTransition from "pages/forms/_components/FormTransition";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { bool, func, node, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { push, routerActions } from "react-router-redux";
import { selectors as depositSelectors, actions as depositsActions } from "reducers/deposits";
import {
    actions as fixedTermDepositActions,
    selectors as fixedTermDepositSelectors,
} from "reducers/fixedTermDeposit/fixedTermDeposit.reducer";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as templateSelectors } from "reducers/template";
import { compose } from "redux";
import * as i18n from "util/i18n";
import { numberFormat } from "util/number";

import Button from "pages/_components/Button";
import Notification from "pages/_components/Notification";
import PercentageBeneficiaryField from "pages/forms/_components/_fields/_percentageBeneficiary/PercentageBeneficiaryField";
import { PDFAmountField, PDFAmountFieldError, PDFText } from "pages/forms/customForms/PDFTicket";
import { actions as notificationActions } from "reducers/notification";
import { calculateShortLabel } from "util/account";
import { DEFAULT_REQUIRED } from "util/validationSchemaUtil";

import { formatNumber } from "util/format";
import { statusMap } from "util/general";
import { PDFTextField } from "../PDFTicket";
import useFixedTermDepositPreData from "./useFixedTermDepositPreData";

export const ID_FORM = "fixed.term.deposit.create";
const ID_ACTIVITY = `${ID_FORM}.send`;
const ID_ACTIVITY_PRE = `${ID_FORM}.pre`;

export const EDIT_MODE = "edit";
export const PREVIEW_MODE = "preview";
export const VIEW_MODE = "view";

const MINUMUM_FRACTION_DIGITS = 2;
const MAXIMUM_FRACTION_DIGITS = 2;

const CreateFixedTermDepositForm = (props) => {
    const {
        preDataForm,
        mode,
        currentLang,
        idTransaction,
        fromBackoffice,
        isDesktop,
        dispatch,
        location,
        activeEnvironment,
        isFetchingRate,
        parentTransaction,
        fetchingDownloadCertified,
    } = props;

    const { decimalSeparator, thousandSeparator } = numberFormat();

    const [
        selectorDebitAccountList,
        selectorPaymentAccountList,
        depositTypeOptionList,
        dataAmount,
        expirationActionOptionList,
        interestPaymentFrequencyList,
        termList,
        depositTypeMetadata,
        setDepositTypeMetadata,
        documentData,
        beneficiaryRelationshipList,
        updateInterestPaymentFrequencyByTerm,
    ] = useFixedTermDepositPreData(preDataForm, activeEnvironment);

    useEffect(() => {
        if (mode === EDIT_MODE) {
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, formData: {} }));
        }
    }, [dispatch, location]);

    const validateCostCenter = () => {
        if (!preDataForm || preDataForm.isValidCostCenter === undefined) {
            return;
        }

        const { isValidCostCenter } = preDataForm;
        if (isValidCostCenter !== false) {
            return;
        }

        setTimeout(
            () =>
                dispatch(
                    notificationActions.showNotification(i18n.get(`${ID_FORM}.validation.costcenter.error`), "error", [
                        "desktop",
                    ]),
                ),
            0,
        );

        dispatch(routerActions.replace({ pathname: "/desktop", state: { transition: "transition-flow-close" } }));
    };

    const validatePendingProcess = () => {
        if (!preDataForm || preDataForm.hasPendingPfProcess === undefined) {
            return;
        }

        const { hasPendingPfProcess } = preDataForm;
        if (hasPendingPfProcess === "true" || hasPendingPfProcess === true) {
            setTimeout(
                () =>
                    dispatch(
                        notificationActions.showNotification(
                            i18n.get(`${ID_FORM}.validation.pending.process.error`),
                            "error",
                            ["desktop"],
                        ),
                    ),
                0,
            );

            dispatch(routerActions.replace({ pathname: "/desktop", state: { transition: "transition-flow-close" } }));
        }
    };

    useEffect(() => {
        validateCostCenter();
        validatePendingProcess();
    }, [preDataForm]);

    const isCorporate = activeEnvironment.type === "corporate";

    const calculateRate = (setFieldValue, { depositType, amount, term }) => {
        if (!depositType || depositType.length === 0 || !amount || !term || term.length === 0) {
            return;
        }
        const productName = depositType[0]?.id;
        const termId = term[0]?.id;
        dispatch(
            fixedTermDepositActions.calculateRateRequest({
                amount,
                productName,
                term: termId,
                setFieldValue,
                keyRateForm: "rate",
            }),
        );
    };

    const getSignerName = (document) => {
        dispatch(
            fixedTermDepositActions.getBeneficiaryByDocumentTypeRequest({
                documentType: document?.documentType,
                documentCountry: document?.documentCountry,
                documentNumber1: document?.documentNumber1,
                documentNumber2: document?.documentNumber2,
                idNumber2: document?.idNumber2,
                idNumber1: document?.idNumber1,
            }),
        );
    };

    const getDepositTypeMetadata = (depositType, currency) => {
        if (!depositType || depositType.length === 0 || !depositType[0].id || !currency) {
            return;
        }

        dispatch(
            fixedTermDepositActions.depositTypeMetadataRequest({
                currency,
                productName: depositType[0].id,
                setDepositTypeMetadata,
            }),
        );
    };

    const onChangeDepositType = (depositType, values, setFieldValue) => {
        calculateRate(setFieldValue, { depositType, amount: values?.amount, term: values?.term });
        getDepositTypeMetadata(depositType, values?.amount?.currency);
    };

    const onChangeAmount = (amount, values, setFieldValue) => {
        calculateRate(setFieldValue, { depositType: values?.depositType, amount, term: values?.term });
    };

    const onChangeTerm = (term, values, setFieldValue) => {
        calculateRate(setFieldValue, { depositType: values?.depositType, amount: values?.amount, term });
        updateInterestPaymentFrequencyByTerm(term, setFieldValue);
    };

    const getDataAmount = (amount) => ({
        decimalSeparator,
        precision: 2,
        thousandsSeparator: thousandSeparator,
        options: [
            {
                id: amount?.currency,
                label: amount?.currency ? i18n.get(`currency.label.${amount?.currency}`) : "",
            },
        ],
    });

    const handlerCancelAction = () => {
        dispatch(push("/desktop"));
    };

    const getTxCreatorName = () => {
        let txCreatorName = "";
        if (parentTransaction !== null && parentTransaction) {
            txCreatorName = parentTransaction.userCreatorFirstName + parentTransaction.userCreatorLastName;
        } else if (activeEnvironment && activeEnvironment.clients && activeEnvironment.clients.length > 0) {
            txCreatorName = activeEnvironment.clients[0]?.name || "";
        }

        return txCreatorName;
    };

    const renderResumeField = (values, genericProps) => (
        <>
            <Box display="flex" alignX="center" fullWidth className="amount-wrapper">
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Amount}
                    data={getDataAmount(values?.amount)}
                    key="amount"
                    name="amount"
                    value={values?.amount}
                    idField="amount"
                    bigDataAmount
                />
            </Box>

            <Box className={classNames("mt-3", { "mt-9 mx-7": mode === "view" })}>
                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    value={values?.depositDateField || ""}
                    label={`${ID_FORM}.fixedTermDate.label`}
                />

                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    value={getTxCreatorName()}
                    label={`${ID_FORM}.clientName.label`}
                />

                {mode === VIEW_MODE && (
                    <FormFieldsComponents.ReadTextCustom
                        {...genericProps}
                        value={i18n.get(`${ID_FORM}.operationType.fixedTermDeposit.label`)}
                        label={`${ID_FORM}.operationType.label`}
                    />
                )}

                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    value={(values?.depositType && values.depositType.length > 0 && values.depositType[0]?.label) || ""}
                    label={`${ID_FORM}.depositType.label_preview`}
                />

                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    value={
                        values?.debitAccountData?.otherLabel ||
                        calculateShortLabel(values?.debitAccountData?.productType, values?.debitAccountData?.number)
                    }
                    label={`${ID_FORM}.debitAccount.label_preview`}
                />

                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    value={(values?.term && values.term.length > 0 && values.term[0]?.label) || ""}
                    label={`${ID_FORM}.term.label_preview`}
                />

                {values?.rate && (
                    <FormFieldsComponents.ReadTextCustom
                        {...genericProps}
                        value={values.rate}
                        label={`${ID_FORM}.rate.label_preview`}
                    />
                )}

                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    value={
                        (values?.expirationAction &&
                            values.expirationAction.length > 0 &&
                            values.expirationAction[0]?.label) ||
                        ""
                    }
                    label={`${ID_FORM}.expirationAction.label_preview`}
                />

                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    value={
                        (values?.interestPaymentFrequency &&
                            values.interestPaymentFrequency.length > 0 &&
                            values.interestPaymentFrequency[0]?.label) ||
                        ""
                    }
                    label={`${ID_FORM}.interestPaymentFrequency.label_preview`}
                />

                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    value={
                        values?.paymentAccountData?.otherLabel ||
                        calculateShortLabel(values?.paymentAccountData?.productType, values?.paymentAccountData?.number)
                    }
                    label={`${ID_FORM}.paymentAccount.label_preview`}
                />
                {values?.beneficiaryPercentageList && values?.beneficiaryPercentageList.length > 0 && (
                    <Field
                        {...genericProps}
                        component={PercentageBeneficiaryField}
                        data={[]}
                        labelKey={
                            isCorporate
                                ? `${ID_FORM}.corporate.beneficiarySelector.title`
                                : `${ID_FORM}.beneficiarySelector.title`
                        }
                        key="beneficiaryPercentageList"
                        name="beneficiaryPercentageList"
                        idField="beneficiaryPercentageList"
                        isDesktop={isDesktop}
                        documentData={documentData}
                        defaultValue={values?.beneficiaryPercentageList || []}
                        activeEnvironment={activeEnvironment}
                        getSignerName={getSignerName}
                    />
                )}
            </Box>
        </>
    );

    const renderViewMode = (values, genericProps) => renderResumeField(values, genericProps);

    const renderPreviewMode = (values, genericProps) => renderResumeField(values, genericProps);

    const renderEditMode = (setFieldValue, values, genericProps) => {
        const deftCurrency =
            preDataForm?.currencyList && preDataForm.currencyList.length > 0
                ? { ...preDataForm.currencyList[0], currency: preDataForm.currencyList[0].backendId }
                : {};

        return (
            <>
                <Row {...(isDesktop ? { gapY: "5" } : { gapY: "3", className: "mt-3" })}>
                    <Col xs={12} md={6}>
                        <Box
                            background="white"
                            display="flex"
                            className="p-5 py-md-8 px-md-9"
                            {...(!isDesktop && { column: true })}
                            borderRadius="default"
                            toBorderInMobile>
                            <Box
                                display="flex"
                                gapX="3"
                                alignX="between"
                                alignY="center"
                                fullWidth
                                {...(!isDesktop && { className: "mb-2" })}>
                                <Text size="5" color="heading">
                                    {i18n.get(`${ID_FORM}.clientName.label`)}
                                </Text>

                                <Text size="5" color="heading" wrap align="right" ellipsis bold>
                                    {activeEnvironment?.name}
                                </Text>
                            </Box>
                            {!isDesktop && (
                                <Box display="flex" gapX="3" alignX="between" alignY="center" fullWidth>
                                    <Text size="5" color="heading" labelKey={`${ID_FORM}.fixedTermDate.label`} />

                                    <Text size="5" color="heading" wrap align="right" ellipsis bold>
                                        {values?.depositDateField || ""}
                                    </Text>
                                </Box>
                            )}
                        </Box>
                    </Col>
                    {isDesktop && (
                        <Col xs={12} md={6}>
                            <Box background="white" display="flex" className="py-md-8 px-md-9" borderRadius="default">
                                <Box display="flex" gapX="3" alignX="between" alignY="center" fullWidth>
                                    <Text size="5" color="heading" labelKey={`${ID_FORM}.fixedTermDate.label`} />

                                    <Text size="5" color="heading" wrap align="right" ellipsis bold>
                                        {values?.depositDateField || ""}
                                    </Text>
                                </Box>
                            </Box>
                        </Col>
                    )}

                    <Col xs={12} md={6}>
                        <Box
                            background="white"
                            display="flex"
                            className="pt-5 pb-8 px-5 pt-md-7 pb-md-10 px-md-9"
                            borderRadius="default"
                            toBorderInMobile>
                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Selector}
                                key="depositType"
                                name="depositType"
                                idField="depositType"
                                labelIdField="react-select-depositType-input"
                                optionList={depositTypeOptionList}
                                customPlaceholder={i18n.get("forms.placeholder.select")}
                                renderAs="combo"
                                labelNoMarginTop
                                isFocused={false}
                                onChange={(depositType) => {
                                    onChangeDepositType(depositType, values, setFieldValue);
                                }}
                            />
                        </Box>
                    </Col>
                    <Col xs={12} md={6}>
                        <Box
                            background="white"
                            display="flex"
                            className="pt-5 pb-8 px-5 pt-md-7 pb-md-10 px-md-9"
                            borderRadius="default"
                            toBorderInMobile>
                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.ProductselectorCustom}
                                data={selectorDebitAccountList || []}
                                key="debitAccount"
                                name="debitAccount"
                                renderAs="combo"
                                labelNoMarginTop
                                customPlaceholder={i18n.get("forms.placeholder.select")}
                                idField="debitAccount"
                            />
                        </Box>
                    </Col>
                    <Col xs={12} md={6}>
                        <Row {...(isDesktop ? { gapY: "5" } : { gapY: "3" })}>
                            <Col xs={12}>
                                <Box
                                    background="white"
                                    display="flex"
                                    className="pt-5 pb-8 px-5 pt-md-7 pb-md-10 px-md-9"
                                    borderRadius="default"
                                    toBorderInMobile>
                                    <Field
                                        {...genericProps}
                                        component={FormFieldsComponents.Amount}
                                        data={dataAmount}
                                        key="amount"
                                        name="amount"
                                        value={values?.amount || deftCurrency}
                                        idField="amount"
                                        customPlaceholderCurrency=""
                                        customPlaceholderQuantity="0.00"
                                        quantityTextAlign="text-left"
                                        boldAmount
                                        sizeAmount="3"
                                        disabledCurrency
                                        onChangeDelay={(amount) => {
                                            onChangeAmount(amount, values, setFieldValue);
                                        }}
                                    />
                                </Box>
                            </Col>
                            {isDesktop && (
                                <Col xs={12}>
                                    <Box
                                        background="white"
                                        display="flex"
                                        className="pt-5 pb-8 px-5 pt-md-9 pb-md-10 px-md-9"
                                        borderRadius="default"
                                        toBorderInMobile>
                                        <Field
                                            {...genericProps}
                                            component={FormFieldsComponents.ProductselectorCustom}
                                            data={selectorPaymentAccountList}
                                            key="paymentAccount"
                                            name="paymentAccount"
                                            renderAs="combo"
                                            labelNoMarginTop
                                            customPlaceholder={i18n.get("forms.placeholder.select")}
                                            idField="paymentAccount"
                                            tooltipText={`${ID_FORM}.paymentAccount.tooltip`}
                                            tooltipPosition="bottom-centered"
                                        />
                                    </Box>
                                </Col>
                            )}
                        </Row>
                    </Col>
                    <Col xs={12} md={6}>
                        <Box
                            background="white"
                            display="flex"
                            className="pt-5 pb-9 px-5 pt-md-7 pb-md-10 px-md-9"
                            borderRadius="default"
                            toBorderInMobile
                            fullHeight>
                            <Row {...(isDesktop && { gapY: "6" })}>
                                <Col xs={6}>
                                    <Field
                                        {...genericProps}
                                        component={FormFieldsComponents.Selector}
                                        key="term"
                                        name="term"
                                        idField="term"
                                        labelIdField="react-select-term-input"
                                        optionList={termList || []}
                                        customPlaceholder={i18n.get("forms.placeholder.select")}
                                        renderAs="combo"
                                        labelNoMarginTop
                                        isFocused={false}
                                        onChange={(term) => {
                                            onChangeTerm(term, values, setFieldValue);
                                        }}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <Box position="relative">
                                        <Field
                                            {...genericProps}
                                            component={FormFieldsComponents.Text}
                                            key="rate"
                                            name="rate"
                                            idField="rate"
                                            validationRegularExpresion="^[a-zA-Z0-9 áéíóúñ]*$"
                                            optional={i18n.get("form.field.optional")}
                                            labelNoMarginTop
                                            disabled
                                        />
                                        {isFetchingRate && (
                                            <Box
                                                display="flex"
                                                alignY="center"
                                                alignX="center"
                                                className="pt-7"
                                                fullHeight
                                                position="absolute"
                                                left="0"
                                                top="0">
                                                <Button loading bsStyle="link" className="min-width-12" />
                                                <Box
                                                    display="flex"
                                                    alignY="center"
                                                    alignX="center"
                                                    className="min-height-7 min-width-12"
                                                    position="absolute"
                                                    borderRadius="form"
                                                    background="background-secondary"
                                                    top="8"
                                                    left="1"
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                </Col>
                                <Col xs={12}>
                                    <Field
                                        {...genericProps}
                                        component={FormFieldsComponents.Selector}
                                        key="expirationAction"
                                        name="expirationAction"
                                        idField="expirationAction"
                                        labelIdField="react-select-expirationAction-input"
                                        optionList={expirationActionOptionList || []}
                                        renderAs="combo"
                                        labelNoMarginTop
                                        customPlaceholder={i18n.get("forms.placeholder.select")}
                                        isFocused={false}
                                        tooltipText={`${ID_FORM}.expirationAction.tooltip`}
                                        tooltipPosition="bottom-centered"
                                    />
                                </Col>
                                <Col xs={12}>
                                    <Field
                                        {...genericProps}
                                        component={FormFieldsComponents.Selector}
                                        key="interestPaymentFrequency"
                                        name="interestPaymentFrequency"
                                        idField="interestPaymentFrequency"
                                        labelIdField="react-select-interestPaymentFrequency-input"
                                        optionList={interestPaymentFrequencyList || []}
                                        renderAs="combo"
                                        labelNoMarginTop
                                        customPlaceholder={i18n.get("forms.placeholder.select")}
                                        isFocused={false}
                                        tooltipText={`${ID_FORM}.interestPaymentFrequency.tooltip`}
                                        tooltipPosition={isDesktop ? "bottom-centered" : "bottom-right"}
                                    />
                                </Col>
                            </Row>
                        </Box>
                    </Col>
                    {!isDesktop && (
                        <Col xs={12}>
                            <Box
                                background="white"
                                display="flex"
                                className="pt-5 pb-8 px-5 pt-md-9 pb-md-10 px-md-9"
                                borderRadius="default"
                                toBorderInMobile>
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.ProductselectorCustom}
                                    data={selectorPaymentAccountList}
                                    key="paymentAccount"
                                    name="paymentAccount"
                                    renderAs="combo"
                                    labelNoMarginTop
                                    customPlaceholder={i18n.get("forms.placeholder.select")}
                                    idField="paymentAccount"
                                    tooltipText={`${ID_FORM}.paymentAccount.tooltip`}
                                    tooltipPosition="bottom-right"
                                />
                            </Box>
                        </Col>
                    )}
                    <Col xs={12} md={12}>
                        <Box
                            {...(isDesktop && { background: "white" })}
                            display="flex"
                            className="pt-0 pb-3 px-5 pt-md-7 pb-md-9 px-md-9"
                            borderRadius="default"
                            toBorderInMobile
                            fullHeight>
                            <Field
                                {...genericProps}
                                component={PercentageBeneficiaryField}
                                data={[]}
                                labelKey={
                                    isCorporate
                                        ? `${ID_FORM}.corporate.beneficiarySelector.title`
                                        : `${ID_FORM}.beneficiarySelector.title`
                                }
                                key="beneficiaryPercentageList"
                                name="beneficiaryPercentageList"
                                idField="beneficiaryPercentageList"
                                isDesktop={isDesktop}
                                infoLabel={
                                    isCorporate
                                        ? i18n.get(`${ID_FORM}.signers.info.label`)
                                        : i18n.get(`${ID_FORM}.beneficiaryPercentageList.info.label`)
                                }
                                documentData={documentData}
                                defaultValue={values?.beneficiaryPercentageList || []}
                                currentLang={currentLang}
                                beneficiaryRelationshipList={beneficiaryRelationshipList || []}
                                activeEnvironment={activeEnvironment}
                                getSignerName={getSignerName}
                            />
                        </Box>
                    </Col>
                </Row>
            </>
        );
    };

    const [depositDateField, setDepositDateField] = useState();
    const renderFields = (setFieldValue, values) => {
        if (!mode) {
            return <div />;
        }
        if (!depositDateField && mode === EDIT_MODE) {
            setFieldValue("depositDateField", preDataForm?.depositDateField);
            setDepositDateField(preDataForm?.depositDateField);
        }

        const genericProps = {
            mode,
            lang: currentLang,
            idTransactionTicket: idTransaction,
            fromBackoffice,
            isRequired: true,
            idActivity: ID_ACTIVITY,
        };

        return (
            <>
                {mode === EDIT_MODE && renderEditMode(setFieldValue, values, genericProps)}
                {mode === PREVIEW_MODE && renderPreviewMode(values, genericProps)}
                {mode === VIEW_MODE && renderViewMode(values, genericProps)}
            </>
        );
    };

    const renderTicket = (values) => {
        const { idTransactionStatus } = props.transaction;
        const idStatus = statusMap.get(idTransactionStatus) ?? idTransactionStatus;
        return (
            <>
                {idStatus === "FINISHED" ? (
                    <PDFAmountField
                        idForm={ID_FORM}
                        name="amount"
                        values={values}
                        label={i18n.get(`forms.${ID_FORM}.amount.label`)}
                    />
                ) : (
                    <PDFAmountFieldError
                        idForm={ID_FORM}
                        name="amount"
                        values={values}
                        label={i18n.get(`forms.${ID_FORM}.amount.label`)}
                    />
                )}
                <PDFTextField
                    idForm={ID_FORM}
                    value={values?.depositDateField || ""}
                    label={i18n.get(`${ID_FORM}.fixedTermDate.label`)}
                />
                <PDFTextField
                    idForm={ID_FORM}
                    value={
                        activeEnvironment?.clients && activeEnvironment.clients.length > 0
                            ? activeEnvironment.clients[0]?.name || ""
                            : ""
                    }
                    label={i18n.get(`${ID_FORM}.clientName.label`)}
                />
                <PDFTextField
                    idForm={ID_FORM}
                    value={i18n.get(`${ID_FORM}.operationType.fixedTermDeposit.label`)}
                    label={i18n.get(`${ID_FORM}.operationType.label`)}
                />
                <PDFTextField
                    idForm={ID_FORM}
                    value={(values?.depositType && values.depositType.length > 0 && values.depositType[0]?.label) || ""}
                    label={i18n.get(`${ID_FORM}.depositType.label_preview`)}
                />
                <PDFTextField
                    idForm={ID_FORM}
                    value={calculateShortLabel(values?.debitAccountData?.productType, values?.debitAccountData?.number)}
                    label={i18n.get(`${ID_FORM}.debitAccount.label_preview`)}
                />
                <PDFTextField
                    idForm={ID_FORM}
                    value={(values?.term && values.term.length > 0 && values.term[0]?.label) || ""}
                    label={i18n.get(`${ID_FORM}.term.label_preview`)}
                />
                <PDFTextField idForm={ID_FORM} value={values.rate} label={i18n.get(`${ID_FORM}.rate.label_preview`)} />
                <PDFTextField
                    idForm={ID_FORM}
                    value={
                        (values?.expirationAction &&
                            values.expirationAction.length > 0 &&
                            values.expirationAction[0]?.label) ||
                        ""
                    }
                    label={i18n.get(`${ID_FORM}.expirationAction.label_preview`)}
                />
                <PDFTextField
                    idForm={ID_FORM}
                    value={
                        (values?.interestPaymentFrequency &&
                            values.interestPaymentFrequency.length > 0 &&
                            values.interestPaymentFrequency[0]?.label) ||
                        ""
                    }
                    label={i18n.get(`${ID_FORM}.interestPaymentFrequency.label_preview`)}
                />
                <PDFTextField
                    idForm={ID_FORM}
                    value={calculateShortLabel(
                        values?.paymentAccountData?.productType,
                        values?.paymentAccountData?.number,
                    )}
                    label={i18n.get(`${ID_FORM}.paymentAccount.label_preview`)}
                />
                {!isCorporate && <PDFText text={i18n.get(`${ID_FORM}.beneficiarySelector.title`)} />}
                {isCorporate && <PDFText text={i18n.get(`beneficiaryPercentageList.signer.new.title`)} />}

                {values?.beneficiaryPercentageList?.map((beneficiary) => (
                    <>
                        {!isCorporate && (
                            <>
                                <PDFTextField
                                    idForm={ID_FORM}
                                    value={beneficiary?.name || ""}
                                    label={i18n.get("beneficiaryPercentageList.beneficiary.name.label")}
                                />
                                <PDFTextField
                                    idForm={ID_FORM}
                                    value={`${beneficiary?.percentage || ""}%`}
                                    label={i18n.get("beneficiaryPercentageList.beneficiary.percentage.label")}
                                />
                            </>
                        )}
                        {isCorporate && (
                            <>
                                <PDFTextField
                                    idForm={ID_FORM}
                                    value={beneficiary?.name || ""}
                                    label={i18n.get("forms.transaction.joint.ticket.authorizedBy")}
                                />
                            </>
                        )}
                    </>
                ))}
            </>
        );
    };

    const validateAmount = (values, errors, messageRequired) => {
        if (!values?.amount?.currency || !values?.amount?.quantity) {
            // eslint-disable-next-line no-param-reassign
            errors.amount = messageRequired;
            return errors;
        }

        /**
         * do we have to validate if metada is empty?
         */

        if (!depositTypeMetadata?.maxAmount && !depositTypeMetadata?.minAmount) {
            return errors;
        }

        const defAmount = values.amount.quantity;
        if (+defAmount < +depositTypeMetadata.minAmount || +defAmount > +depositTypeMetadata.maxAmount) {
            const minAmountFormatted = formatNumber(
                depositTypeMetadata?.minAmount,
                MAXIMUM_FRACTION_DIGITS,
                MINUMUM_FRACTION_DIGITS,
            );
            const maxAmountFormatted = formatNumber(
                depositTypeMetadata?.maxAmount,
                MAXIMUM_FRACTION_DIGITS,
                MINUMUM_FRACTION_DIGITS,
            );
            const dataMessage = i18n.get(`${ID_FORM}.amount.quantity.label`);
            // eslint-disable-next-line no-param-reassign
            errors.amount = (dataMessage || "")
                .replace("#min", minAmountFormatted || "")
                .replace("#max", maxAmountFormatted || "");
        }

        return errors;
    };

    const validateBeneficiaryList = (values, errors, messageRequired) => {
        if (!values?.beneficiaryPercentageList || values?.beneficiaryPercentageList.length === 0) {
            // eslint-disable-next-line no-param-reassign
            errors.beneficiaryPercentageList = messageRequired;
            return;
        }
        const percentage = values.beneficiaryPercentageList?.reduce(
            (acc, item) => acc + (item?.percentage ? +item.percentage : 0),
            0,
        );
        if (percentage !== 100 && !isCorporate) {
            // eslint-disable-next-line no-param-reassign
            errors.beneficiaryPercentageList = i18n.get(`${ID_FORM}.beneficiary.percentage.invalid`);
        }
    };

    const validateForm = (values) => {
        const messageRequired = i18n.get(DEFAULT_REQUIRED);
        const errors = {};
        if (!values?.depositType || values.depositType.length === 0 || !values.depositType[0]?.id) {
            errors.depositType = messageRequired;
        }

        if (!values?.debitAccount) {
            errors.debitAccount = messageRequired;
        }

        if (!values?.paymentAccount) {
            errors.paymentAccount = messageRequired;
        }

        if (!values?.amount?.currency || !values?.amount?.quantity) {
            errors.amount = messageRequired;
        }

        validateAmount(values, errors, messageRequired);

        if (!values?.term || values.term.length === 0 || !values?.term[0]?.id) {
            errors.term = messageRequired;
        }

        if (
            !values?.interestPaymentFrequency ||
            values.interestPaymentFrequency.length === 0 ||
            !values?.interestPaymentFrequency[0]?.id
        ) {
            errors.interestPaymentFrequency = messageRequired;
        }

        if (!values?.expirationAction || values.expirationAction.length === 0 || !values?.expirationAction[0]?.id) {
            errors.expirationAction = messageRequired;
        }
        validateBeneficiaryList(values, errors, messageRequired);

        return errors;
    };

    // eslint-disable-next-line no-confusing-arrow
    const renderExtraContent = (transactionData) =>
        transactionData?.idTransactionStatus && transactionData?.idTransactionStatus === "FINISHED" ? (
            <Box display="flex" alignX="center" className="mt-auto pb-6">
                <Button
                    loading={fetchingDownloadCertified}
                    className="px-5"
                    bsStyle="link"
                    image="images/icons/download.svg"
                    label={`${ID_FORM}.certificate.download.label`}
                    onClick={() => {
                        dispatch(depositsActions.downloadCertified(transactionData));
                    }}
                />
            </Box>
        ) : null;

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
    } = preDataForm || {};
    const formProps = {
        title: "forms.fixed.term.deposit.create",
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: true,
            programable: true,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },

        renderFields,
        renderTicket,
        cancelAction: handlerCancelAction,
        useDefaultSubmit: true,
        isCancellingTransaction: true,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        titleFormConfirmation: "OTP CODE",
        validate: validateForm,
        topDisclaimer: null,
        showFilterChips: false,
        extraContent: renderExtraContent,
    };
    return (
        <>
            <Notification scopeToShow={ID_FORM} />
            <FormTransition {...props} {...formProps} />
        </>
    );
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    isFetchingRate: fixedTermDepositSelectors.isFetchingRate(state),
    usesJointAccount: formSelectors.getUsesJointAccount(state),
    fetchingDownloadCertified: depositSelectors.getFetchingDownloadCertified(state),
});

CreateFixedTermDepositForm.propTypes = {
    preDataForm: shape({}).isRequired,
    mode: string,
    currentLang: string,
    idTransaction: string,
    fromBackoffice: string,
    isDesktop: bool,
    dispatch: func,
    location: shape({}),
    activeEnvironment: shape({}),
    children: node,
    classNameCustom: string,
    column: bool,
    isFetchingRate: bool,
    usesJointAccount: bool,
    parentTransaction: shape({}),
    fetchingDownloadCertified: bool,
    transaction: shape({}),
};

CreateFixedTermDepositForm.defaultProps = {
    mode: undefined,
    currentLang: undefined,
    idTransaction: undefined,
    fromBackoffice: undefined,
    isDesktop: true,
    dispatch: () => {},
    location: undefined,
    activeEnvironment: undefined,
    children: undefined,
    classNameCustom: undefined,
    column: false,
    isFetchingRate: false,
    usesJointAccount: false,
    parentTransaction: null,
    fetchingDownloadCertified: false,
    transaction: null,
};

export default compose(connect(mapStateToProps), withRouter)(CreateFixedTermDepositForm);
