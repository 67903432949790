import { types, actions } from "reducers/requestAndComplaints";
import * as requestsAndClaimsMiddleware from "middleware/requestAndComplaints";
import { call, fork, put, takeLatest } from "redux-saga/effects";
import { DATA_LAYER_FILTER_REQUEST_CLAIMS, setDataLayer } from "util/tagManager/tagManager.util";

const sagas = [takeLatest(types.LIST_REQUESTS_AND_CLAIMS_REQUEST, requestAndClaimListRequest)];

export default sagas;

function* requestAndClaimListRequest({ filters, onFinish }) {
    yield fork(setDataLayer, { ...filters, event: DATA_LAYER_FILTER_REQUEST_CLAIMS });
    const response = yield call(requestsAndClaimsMiddleware.listRequestsAndClaims, filters);
    if (response && response.status === 200) {
        yield put(actions.listRequestsAndClaimsSuccess(response.data.data));
    }

    if (typeof onFinish === "function") {
        onFinish();
    }
}
