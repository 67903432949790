import EmptyTextBox from "pages/_components/EmptyTextBox";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import LoansList from "pages/loans/List";
import { array, bool, func, oneOfType, shape, string } from "prop-types";
import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { routerActions } from "react-router-redux/actions";
import { isFromNotificationRedirection } from "util/general";
import { actions as loansActions, selectors as loansSelectors } from "reducers/loans";
import { actions as productsActions } from "reducers/products";
import { actions as productRequestActions } from "reducers/productRequest";

class ProductList extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: oneOfType([string, bool]),
        fetching: oneOfType([string, bool]),
        loans: oneOfType([array]),
        showProductIcon: bool,
        completeFavorites: bool,
        location: shape({}),
    };

    static defaultProps = {
        isDesktop: false,
        fetching: false,
        loans: [],
        showProductIcon: true,
        completeFavorites: false,
        location: {},
    };

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(loansActions.listLoans());
    }

    handleRequestLoanClick = () => {
        const { dispatch } = this.props;
        dispatch(push("/loansPayment/request/"));
    };

    handleBack = () => {
        const { dispatch, location } = this.props;
        if (isFromNotificationRedirection(location)) {
            dispatch(routerActions.push("/desktop"));
        } else {
            dispatch(routerActions.goBack());
        }
    };

    displayProductRequestModal = () => {
        const { dispatch } = this.props;
        dispatch(productRequestActions.modalShow("LO"));
    };

    renderHeader = () => (
        <Head
            onBack={this.handleBack}
            title="menu.loans"
            onAdd={this.displayProductRequestModal}
            addLinkToLabel="loan.new"
        />
    );

    btnHandlerOnClick = () => {
        const { dispatch } = this.props;
        dispatch(push("/loansPayment/request/"));
    };

    setFavoriteLoan = (idProduct, productType, favorite) => {
        const { dispatch } = this.props;
        dispatch(productsActions.toggleFavorite(idProduct, productType, favorite, null, true));
    };

    render() {
        const { loans, fetching, isDesktop, showProductIcon, completeFavorites } = this.props;
        const isLoading = fetching && !loans.length;
        return (
            <Fragment>
                <Notification scopeToShow="loans" />
                {!isLoading && this.renderHeader()}
                <MainContainer showLoader={isLoading}>
                    <Row className="mt-5 mt-md-0">
                        <Col xs={12} md={8} mdOffset={2}>
                            {loans.length ? (
                                <>
                                    <LoansList
                                        loans={loans}
                                        setFavoriteLoan={this.setFavoriteLoan}
                                        showProductIcon={showProductIcon}
                                        completeFavorites={completeFavorites}
                                        isDesktop={isDesktop}
                                    />
                                </>
                            ) : (
                                <EmptyTextBox text="product.empty.text.loans" footerLink />
                            )}
                        </Col>
                    </Row>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    loans: loansSelectors.getLoans(state),
    fetching: loansSelectors.getFetching(state),
    completeFavorites: loansSelectors.getCompleteFavorites(state),
});

export default connect(mapStateToProps)(ProductList);
