import { parse } from "query-string";
import { useEffect, useState } from "react";
import { numberFormat } from "util/number";

const { decimalSeparator, thousandSeparator } = numberFormat();

const ID_FORM = "transfers.internal";
const ID_ACTIVITY = `${ID_FORM}.send`;

export const useMetaData = (preDataForm, idActivity) => {
    const [metadata, setMetadata] = useState({
        draftsEnabled: false,
        templatesEnabled: false,
        schedulable: true,
        programable: true,
        idActivity: idActivity || ID_ACTIVITY,
    });
    useEffect(() => {
        const { nonWorkingDays, enabledWeekDays, firstWorkingDate = new Date(), maxDaysToSchedule } = preDataForm;
        setMetadata((state) => ({
            ...state,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
        }));
    }, [preDataForm]);
    return [metadata];
};

export const useGenericProps = (props, idActivity) => {
    const { mode, currentLang, fromBackoffice, transaction } = props;
    const [genericProps, setGenericProps] = useState({
        isRequired: true,
        idActivity: idActivity || ID_ACTIVITY,
    });
    useEffect(() => {
        setGenericProps((state) => ({
            ...state,
            mode,
            fromBackoffice,
            lang: currentLang,
            idTransactionTicket: transaction?.idTransaction,
        }));
    }, [mode, fromBackoffice, currentLang, transaction]);
    return [genericProps];
};

export const useDebitAccountOrigin = (location) => {
    const [debitAccountOrigin, setDebitAccountOrigin] = useState("");
    useEffect(() => {
        setDebitAccountOrigin(parse(location.search)?.debitAccount);
    }, [location]);
    return [debitAccountOrigin];
};

export const useCurrencyOrigin = (location) => {
    const [currencyOrigin, setCurrencyOrigin] = useState("");
    useEffect(() => {
        setCurrencyOrigin(parse(location.search)?.currency);
    }, [location]);
    return [currencyOrigin];
};

export const useCreditAccountDestination = (location) => {
    const [creditAccountDestination, setCreditAccountDestination] = useState("");
    useEffect(() => {
        setCreditAccountDestination(parse(location.search)?.creditAccount);
    }, [location]);
    return [creditAccountDestination];
};

export const useIsFromWally = (location) => {
    const [isFromWally, setIsFromWally] = useState("");
    useEffect(() => {
        setIsFromWally(parse(location.search)?.from === "wally");
    }, [location]);
    return [isFromWally];
};

export const useSelectorOptions = (preDataForm) => {
    const [originAccountList, setOriginAccountList] = useState([]);
    const [destinationAccountList, setDestinationAccountList] = useState([]);
    const createAccountList = (acc, isOrigin) => ({
        ...acc,
        balance: { currency: acc.currency, quantity: acc.balance },
        id: acc.idProduct,
        disabled: acc.isDisabled && (isOrigin || acc.statusDesc !== "ASD"),
    });
    useEffect(() => {
        setOriginAccountList({
            options: preDataForm?.originAccountList?.map((acc) => createAccountList(acc, true)),
        });
        setDestinationAccountList({
            options: preDataForm?.destinationAccountList?.map((acc) => createAccountList(acc, false)),
        });
    }, [preDataForm]);
    return [originAccountList, destinationAccountList];
};

export const useDataAmount = (preDataForm) => {
    const [dataAmount, setDataAmount] = useState({
        decimalSeparator,
        precision: 2,
        thousandsSeparator: thousandSeparator,
        options: [],
    });

    useEffect(() => {
        const options =
            preDataForm?.currencyList?.map((c) => ({
                id: c.backendId,
                label: c.code,
            })) || [];
        setDataAmount({
            decimalSeparator,
            precision: 2,
            thousandsSeparator: thousandSeparator,
            options,
        });
    }, [preDataForm]);
    return [dataAmount];
};

export const useExchangeRate = (preDataForm) => {
    const [labelAmountEstimate, setLabelAmountEstimate] = useState("");
    const [amountEstimate, setAmountEstimate] = useState(0);
    const [exchangeRateEstimate, setExchangeRateEstimate] = useState("");
    const [currencyEstimate, setCurrencyEstimate] = useState("");

    const setChangeForm = (values, setFieldValue, isSameCurrency) => {
        const idDebitAccount = values?.debitAccount;
        const idCreditAccount = values?.creditAccount;
        if (idDebitAccount && idCreditAccount && !isSameCurrency) {
            const currencyOrigin = preDataForm?.originAccountList?.find((acc) => acc.idProduct === idDebitAccount)
                ?.currency;
            const currencyDestination = preDataForm?.destinationAccountList?.find(
                (acc) => acc.idProduct === idCreditAccount,
            )?.currency;
            const currencyAmount = values?.amount?.currency;
            const quantityAmount = values?.amount?.quantity || 0;

            const selectExchangeRate = preDataForm?.ExchangeRateList?.find(
                (exRate) =>
                    (exRate.baseCurrencyCode === currencyOrigin && exRate.targetCurrencyCode === currencyDestination) ||
                    (exRate.baseCurrencyCode === currencyDestination && exRate.targetCurrencyCode === currencyOrigin),
            );
            const sell = selectExchangeRate?.sale || 0;
            const buy = selectExchangeRate?.purchase || 0;

            const rate = currencyOrigin === "USD" ? sell : buy;
            const targetCurrency = currencyOrigin !== "USD" ? currencyOrigin : currencyDestination;

            setExchangeRateEstimate(`${targetCurrency} 1.00 = USD ${rate}`);

            if (currencyAmount === currencyOrigin) {
                setLabelAmountEstimate("forms.transfers.internal.amount.credit_estimate.label");
                const quiantityA = currencyOrigin === "USD" ? quantityAmount / rate : quantityAmount * rate;
                setAmountEstimate(quiantityA);
                setCurrencyEstimate(currencyDestination);
            }
            if (currencyAmount === currencyDestination) {
                setLabelAmountEstimate("forms.transfers.internal.amount.debit_estimate.label");
                const quiantityA = currencyOrigin !== "USD" ? quantityAmount / rate : quantityAmount * rate;
                setAmountEstimate(quiantityA);
                setCurrencyEstimate(currencyOrigin);
            }
        } else {
            setLabelAmountEstimate(null);
            setAmountEstimate(null);
            setExchangeRateEstimate(null);
            setCurrencyEstimate(null);
        }
        const propertiesInfoExchange = { labelAmountEstimate, amountEstimate, exchangeRateEstimate, currencyEstimate };
        if (JSON.stringify(values?.propertiesInfoExchange) !== JSON.stringify(propertiesInfoExchange)) {
            setFieldValue("propertiesInfoExchange", propertiesInfoExchange);
        }
    };
    return [{ labelAmountEstimate, amountEstimate, exchangeRateEstimate, currencyEstimate }, setChangeForm];
};

export const useCreditCardOrigin = (location) => {
    const [creditCardOrigin, setCreditCardOrigin] = useState("");
    useEffect(() => {
        setCreditCardOrigin(parse(location.search)?.creditCard);
    }, [location]);
    return [creditCardOrigin];
};
