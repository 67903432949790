import React, { useEffect, useState } from "react";
import { Field } from "formik";
import BeneficiaryField from "pages/forms/customForms/_customFields/BeneficiaryField";
import { selectors as i18nSelectors } from "reducers/i18n";
import {
    selectors as beneficiarySelectors,
    actions as beneficiaryActions,
} from "reducers/frequentDestination/frequentDestination";
import { connect } from "react-redux";
import useGenericProps from "pages/forms/customForms/hooks/BeneficiaryHooks";
import { compose } from "redux";
import * as i18n from "util/i18n";
import PageLoading from "pages/_components/PageLoading";
import { bool, func, shape, string } from "prop-types";
import { BANESCO_BANK_CODE, PAY_2X3 } from "../validation/BeneficiaryFormValidation";

export const ID_FORM = "beneficiary.create";
const ID_ACTIVITY_PRE = "beneficiary.pay.2x3.pre";

const BeneficiaryPay2x3Form = ({
    values,
    setFieldValue,
    dispatch,
    preDataForm,
    currentLang,
    handleSubmit,
    beneficiaryId,
    isFetching,
    beneficiaryType,
    directoryStyle,
    errors,
}) => {
    const getProductTypeList = () => [
        { id: "CA", label: i18n.get("forms.pay.2x3.productType.other.CA") },
        { id: "CC", label: i18n.get("forms.pay.2x3.productType.other.CC") },
        { id: "TC", label: i18n.get("forms.pay.2x3.productType.other.TC") },
        { id: "PR", label: i18n.get("forms.pay.2x3.productType.other.PR") },
    ];

    const [genericProps] = useGenericProps(currentLang);
    const [bankList, setBankList] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [disabledBankSelector, setDisabledBankSelector] = useState(false);

    useEffect(() => {
        dispatch(beneficiaryActions.getPreDataForm(beneficiaryId, ID_ACTIVITY_PRE));
    }, []);

    const validateBeneficiaryType = (benefType, bankListResponse) => {
        if (!benefType || !bankListResponse) {
            return;
        }
        if (benefType === PAY_2X3) {
            const filterBankList = [...bankListResponse].filter((bank) => bank && bank.id !== BANESCO_BANK_CODE);
            setBankList(filterBankList);
        }
    };

    const preloadData = () => {
        const bankListResponse = preDataForm?.bankList?.map((item) => ({ id: item.id || "", label: item.label || "" }));
        setBankList(bankListResponse);

        validateBeneficiaryType(beneficiaryType, bankListResponse);

        const { beneficiary } = preDataForm;
        if (beneficiary) {
            const extraData = JSON.parse(beneficiary.extraData);
            Object.keys(extraData).forEach((item) => {
                setFieldValue(`beneficiary.${item}`, extraData[item]);
            });
        }
    };

    useEffect(() => {
        if (preDataForm) {
            preloadData();
        }
    }, [preDataForm]);

    const onBeneficiaryChange = (beneficiary) => {
        const { amount } = values;
        if (beneficiary?.bank?.id !== BANESCO_BANK_CODE) {
            setFieldValue("amount", { ...(amount || {}), currency: "USD" });
        } else if (!amount) {
            setFieldValue("amount", { currency: "USD", quantity: "" });
        }
    };

    return (
        <PageLoading loading={isFetching} borderRadius="default" zIndex="9">
            <form className="full-width" onSubmit={handleSubmit}>
                <Field
                    {...genericProps}
                    isLocalForm
                    component={BeneficiaryField}
                    key="beneficiary"
                    name="beneficiary"
                    idField="beneificary"
                    bankList={bankList || []}
                    productTypeList={getProductTypeList()}
                    value={values?.beneficiary}
                    showHeaderComponent={false}
                    showSaveBeneficiary={false}
                    disabledBankSelector={disabledBankSelector}
                    directoryStyle={directoryStyle}
                    onChange={onBeneficiaryChange}
                    errors={errors}
                />
                {/* <BeneficiaryButtons /> */}
            </form>
        </PageLoading>
    );
};

BeneficiaryPay2x3Form.propTypes = {
    values: shape({}).isRequired,
    setFieldValue: func.isRequired,
    dispatch: func.isRequired,
    preDataForm: shape({}),
    currentLang: string.isRequired,
    handleSubmit: func.isRequired,
    beneficiaryId: string,
    isFetching: bool,
    beneficiaryType: string,
    directoryStyle: bool,
    errors: shape({}),
};

BeneficiaryPay2x3Form.defaultProps = {
    preDataForm: undefined,
    beneficiaryId: undefined,
    isFetching: false,
    beneficiaryType: undefined,
    directoryStyle: false,
    errors: {},
};

const mapStateToProps = (state) => ({
    preDataForm: beneficiarySelectors.getPreDataForm(state),
    currentLang: i18nSelectors.getLang(state),
    isFetching: beneficiarySelectors.isFetching(state),
});

export default compose(connect(mapStateToProps))(BeneficiaryPay2x3Form);
