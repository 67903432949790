import { replace, routerActions } from "react-router-redux";
import { call, fork, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import { AVAILABLE_REGIONS, REGION_REST_OF_LATAM, REGION_USA } from "constants.js";
import * as creditCardsMiddleware from "middleware/creditCards";
import { listCreditLinesRequest } from "middleware/creditLines";
import * as session from "middleware/session";
import * as softtokenApi from "middleware/softToken/softToken";
import { actions as assistantActions, selectors as assistantSelectors } from "reducers/assistant";
import { actions as creditCardActions } from "reducers/creditCard";
import { actions as updateCompanyDataActions } from "reducers/updateCompanyData/updateCompanyData.reducer";
import { actions as actionsCreditLines } from "reducers/creditLines";
import { actions as desktopActions } from "reducers/desktop";
import { types as enrollmentTypes } from "reducers/enrollment";
import { actions as i18nActions } from "reducers/i18n";
import { actions as updateUserDataActions } from "reducers/updateUserData/updateUserData.reducer";
import { actions as loginActions, selectors, types } from "reducers/login";
import { actions as notificationActions } from "reducers/notification";
import { actions as productActions, types as productTypes } from "reducers/products";
import { types as tourTypes } from "reducers/tour";
import { actions as sessionActions, types as sessionTypes, selectors as sessionSelectors } from "reducers/session";
import { actions as statusActions } from "reducers/status";
import { actions as revelockActions } from "reducers/revelock/revelock.reducer";
import { types as transactionTypes } from "reducers/transactions";
import formTypes from "reducers/types/form";
import globalTypes from "reducers/types/global";
import { BIOMETRIC_CALLBACK_SAVE_TOKEN } from "util/biometric.util";
import * as configUtils from "util/config";
import * as deviceUtils from "util/device";
import * as fingerprintUtils from "util/fingerprint";
import { adjustIdFieldErrors } from "util/form";
import * as i18n from "util/i18n";
import { generateUserNameSession } from "util/revelock";
import { setPositionRevelock, setSessionIdRevelock, setUserIdRevelock } from "util/revelockMobile/revelockMobile.util";
import * as secureStorageUtils from "util/secureStorage";
import {
    USER_TOKEN_STATUS_AUTHENTICATE,
    USER_TOKEN_STATUS_INACTIVE,
    USER_TOKEN_STATUS_MIGRATE_DIFERENT_UUID,
    USER_TOKEN_STATUS_MIGRATE_ENTRUST,
    USER_TOKEN_STATUS_MIGRATE_LOCAL,
} from "util/userToken.util";
import { ACCOUNT_ID_SESSION, setItemKeychainShared } from "util/initializationDevice.util";
import axios from "axios";
import { getFpTokenbyDevice } from "util/monitor/monitorDbf.utils";
import { setSession } from "util/notification/notificationLocal.util";
import kuaraVersionNumber from "util/kuaraVersionNumber";
import isLocalPhoneNumber from "util/mobileNumber";
import { types as fingerprintTypes } from "reducers/fingerprint";

let sourceLoginStep1 = null;
let sourceLoginStep3 = null;

const sagas = [
    takeEvery(types.RESET, reset),

    takeLatest(types.LOGIN_STEP_1_REQUEST, handleLoginStep1Request),
    takeLatest(types.LOGIN_OAUTH_REQUEST, handleOauthRequest),
    takeLatest(types.LOGIN_STEP_3_REQUEST, handleLoginStep3Request),
    takeLatest(types.LOGIN_STEP_4_REQUEST, handleLoginStep4Request),
    takeLatest(types.FINALIZE_LOGIN, handleFinalizeLogin),
    takeLatest(types.FINGERPRINT_LOGIN_PRE, handleFingerprintLoginPre),
    takeLatest(types.GO_BACK_LOGIN_SHOW_MESSAGE, goBackToLoginAndShowMessage),
    takeLatest(types.GET_CLIENT_COUNTRY_REQ, getClientCountry),
    takeLatest(types.LOGIN_FINGERPRINTER_STEP_4_REQUEST, fingerPrinterFourthStepRequest),
    takeLatest(types.CANCEL_LOGIN_STEP_1_REQUEST, cancelLoginStep1Request),
    takeLatest(types.CANCEL_LOGIN_STEP_3_REQUEST, cancelLoginStep3Request),
];

export default sagas;

function* getClientCountry() {
    const activeRegion = yield select(selectors.getRegion);
    if (!activeRegion) {
        try {
            const { type, data } = yield call(session.getClientCountry);
            if (type !== "W") {
                const { country } = data.data;
                if (country) {
                    yield put({ type: types.GET_CLIENT_COUNTRY_RES, clientCountry: country.iso_code });

                    // Verify if the client country is in our supported countries
                    if (AVAILABLE_REGIONS.indexOf(country.iso_code) !== -1) {
                        yield put({ type: types.SET_REGION, region: country.iso_code });
                    } else {
                        yield put({ type: types.SET_REGION, region: REGION_REST_OF_LATAM });
                    }
                }
            } else {
                yield put({ type: types.SET_REGION, region: REGION_REST_OF_LATAM });
            }
        } catch (e) {
            yield put({ type: types.SET_REGION, region: REGION_REST_OF_LATAM });
        }
    }
}

function* goBackToLoginAndShowMessage({ message }) {
    yield put(notificationActions.showNotification(message, "error", ["externalLayout"]));
    yield put({ type: globalTypes.BACK_TO_STEP_0 });
    yield put(replace("/"));
}

function* reset() {
    yield put(routerActions.replace({ pathname: "/loginStep1" }));
}

function* showErrorUserToken() {
    yield put(
        notificationActions.showNotification(
            i18n.get("token.entrust.validateStatus.error.message"),
            "error",
            ["menu"],
            false,
        ),
    );
    yield put(replace("/desktop"));
    yield put(statusActions.resetComeFromLogin());
}

function* redirectBiometricValidation(pathRedirect) {
    const callbackDataSelfie = {
        type: BIOMETRIC_CALLBACK_SAVE_TOKEN,
        data: {
            deviceUuid: window?.app?.getDeviceUUID() || "",
            deviceModel: window?.app?.getDeviceModel() || "",
            deviceBrand: window?.app?.getDeviceManufacturer() || "",
        },
        redirectSuccess: "/auth/tokenActivationSuccess",
        redirectError: "/auth/tokenEntrustActivationFailed",
        scopeError: [],
        scopeSuccess: [],
        redirectDocument: "/auth/tokenActivationPending",
        redirectAbort: "/desktop",
    };

    yield put({ type: enrollmentTypes.SET_CALLBACK_DATA_SELFIE, callbackDataSelfie });
    yield put(
        routerActions.push({
            pathname: pathRedirect || "/auth/tokenActivationStep1",
        }),
    );

    yield put(statusActions.deleteLastHref());
}

function* setFetchingToken(fetching) {
    yield put({
        type: enrollmentTypes.SET_FETCHING,
        fetching,
    });
}

function* validateUserToken() {
    const deviceUuid = window?.app?.getDeviceUUID() || "";
    if (!deviceUuid) {
        yield call(setFetchingToken, false);
        yield call(showErrorUserToken);
        yield;
        return;
    }

    const validateStatusTokenResponse = yield call(softtokenApi.validateStatusToken, deviceUuid);
    if (!validateStatusTokenResponse) {
        yield call(setFetchingToken, false);
        yield call(showErrorUserToken);
        yield;
        return;
    }
    const { data, type } = validateStatusTokenResponse;
    if (!type || type === "W" || !data) {
        yield call(setFetchingToken, false);
        yield call(showErrorUserToken);
        yield;
        return;
    }
    const { tokenStatus } = data?.data;
    if (!tokenStatus) {
        yield call(setFetchingToken, false);
        yield call(showErrorUserToken);
        yield;
        return;
    }

    yield call(setFetchingToken, false);
    if (tokenStatus === USER_TOKEN_STATUS_INACTIVE) {
        yield call(redirectBiometricValidation);
    } else if (tokenStatus === USER_TOKEN_STATUS_MIGRATE_DIFERENT_UUID) {
        yield put(
            notificationActions.showNotification(
                i18n.get("token.entrust.migrateEntrust.validate.error.message"),
                "error",
                ["tokenActivation"],
                false,
            ),
        );
        yield call(redirectBiometricValidation, "/auth/tokenActivationStep1RedirectSession");
    } else if (tokenStatus === USER_TOKEN_STATUS_AUTHENTICATE) {
        yield put(replace("/authenticateSofttoken"));
        yield put(statusActions.deleteLastHref());
    } else if (tokenStatus === USER_TOKEN_STATUS_MIGRATE_LOCAL) {
        yield call(redirectBiometricValidation);
        yield put(statusActions.deleteLastHref());
    } else if (tokenStatus === USER_TOKEN_STATUS_MIGRATE_ENTRUST) {
        yield put(replace("/migrateSoftTokenEntrust"));
        yield put(statusActions.deleteLastHref());
    } else {
        yield call(showErrorUserToken);
    }
}

function* setUserRevelock(userId, sessionId) {
    if (!deviceUtils.isMobileNativeFunc()) {
        yield;
        return;
    }

    yield call(setSessionIdRevelock, sessionId || "");
    yield call(setUserIdRevelock, generateUserNameSession(userId));
}

function* setSessionKeystoreGroup(accessToken) {
    const response = yield setItemKeychainShared(ACCOUNT_ID_SESSION, accessToken);
    // eslint-disable-next-line no-console
    console.log("response session group", response);
}

function* setSessionNotification(sessionRef) {
    yield call(setSession, sessionRef);
}

function* handleFinalizeLogin({ response }) {
    const activeRegion = yield select(selectors.getRegion);
    const { isAdministrator, generalConditions, _accessToken: accessToken } = response.data.data;
    const exchangeToken = yield select(selectors.getExchangeToken);
    const username = yield select(selectors.getUsername);
    const isAssistantLogin = yield select(assistantSelectors.isAssistantLogin);
    if (generalConditions) {
        const {
            generalConditionsShowExpiration,
            generalConditionsText,
            generalConditionsExpirationDate,
        } = response.data.data;
        const termsAndConditions = {
            // just joining the long named variables into a single object
            showExpiration: generalConditionsShowExpiration,
            generalConditions,
            body: generalConditionsText,
            expirationDate: generalConditionsExpirationDate,
        };

        yield put({ type: types.LOGIN_STEP_3_SUCCESS, termsAndConditions });
        if (activeRegion === REGION_USA) {
            const firstName = yield select(selectors.getUserFirstName);
            const email = yield select(selectors.getUsername);
            yield put({
                type: enrollmentTypes.SET_INVITATION,
                invitation: {
                    email,
                    firstName,
                    lastName: "",
                },
            });
            yield put(replace("/enrollment/Step3Part4"));
        } else {
            yield put(replace("/loginStep4"));
        }
    } else if (isAssistantLogin) {
        yield put(assistantActions.setAccessToken(accessToken));
        yield put(replace("/loginStep5"));
    } else {
        const loginData = yield call(processLoginSuccess, response);
        if (loginData.user.isNeedingUpdate) {
            yield put(desktopActions.updateModalShow(loginData.user.daysSinceLastUpdt));
        }
        const {
            isNeedingUpdateCorporate,
            yearsSinceLastUpdateCorporate,
            dateLastUpdateCorporate,
            stateUpdateCorporate,
        } = loginData.environment || {};
        if (isNeedingUpdateCorporate) {
            yield put(desktopActions.updateCorporateModalShow(yearsSinceLastUpdateCorporate));
        }
        yield put(updateCompanyDataActions.updateCorporateInfo(dateLastUpdateCorporate, stateUpdateCorporate));

        yield put(
            updateUserDataActions.getUpdateInformation(false, loginData.user.isNeedingUpdate, loginData.user.lastUpdDt),
        );

        const { environments, lang, isFirstLogin } = response.data.data;
        const { lastHref } = yield select((state) => state.status);

        configUtils.setRecaptchaLang(lang);
        yield put(i18nActions.setLang(lang));

        const responseOAuth = yield call(session.oauth, username, exchangeToken);
        if (responseOAuth.type === "W") {
            const errorMsg = i18n.get(
                `returnCode.${responseOAuth.data.error_description}`,
                responseOAuth.data.error_description,
            );
            yield put({ type: types.LOGIN_OAUTH_FAILURE });
            // exchangeToken expired, restart flow
            yield put(notificationActions.showNotification(errorMsg, "error", ["externalLayout"]));
            yield put({ type: globalTypes.BACK_TO_STEP_0 });
            yield put(replace("/"));
        } else {
            const { access_token, refresh_token } = responseOAuth.data;
            session.setAuthToken(access_token);
            yield put(sessionActions.setTokens(access_token, refresh_token));
            /**
             * Get session data
             */
            const { data: sessionData } = yield call(session.check);
            yield put({
                type: types.LOGIN_SUCCESS,
                environment: loginData.environment,
                user: { ...loginData.user, sessionId: sessionData?.data?.sessionId || "" },
                environments,
                isAdministrator,
            });

            yield fork(setSessionNotification, "1");
        }

        /**
         * Set user id revelock
         */
        const { data: sessionData } = yield call(session.check);
        yield call(setUserRevelock, loginData?.user?.username, sessionData?.data?.sessionId);
        yield fork(setSessionKeystoreGroup, responseOAuth?.data?.access_token || "");

        /**
         * Update mask amount
         */
        const { maskAmount } = loginData.user;
        if (maskAmount) {
            yield put(sessionActions.maskAmountUpdate(maskAmount === "true"));
        }

        if (!isFirstLogin) {
            yield put(productActions.syncEnviromentProduct(true));
        }

        yield fork(readCreditCardList);
        yield fork(readCreditLineList);
        yield fork(validateAccess);

        const { pepCompleted, irsCompleted } = loginData.user;
        if ((!pepCompleted || !irsCompleted) && activeRegion === REGION_USA) {
            yield put(replace("/pendingActions"));
        } else if (lastHref) {
            if (lastHref === "/tokenActivationStep1") {
                yield call(validateUserToken);
            } else if (lastHref === "/kuara" || lastHref === "/wally") {
                let route = "";
                if (kuaraVersionNumber(1)) {
                    route = isLocalPhoneNumber() ? "/kuara" : "/wally";
                } else {
                    route = "/kuara";
                }
                yield put(
                    replace({
                        pathname: editLastHrefByConditions(route, loginData.environment),
                        state: { isFromPublicPath: true },
                    }),
                );
            } else {
                if (typeof lastHref === "string" && lastHref.includes("desktop") && lastHref.includes("show")) {
                    yield put(
                        replace({
                            pathname: "/desktop",
                            state: { isFromPublicPath: true },
                            search: `?${lastHref}`,
                        }),
                    );
                } else {
                    yield put(
                        replace({
                            pathname: editLastHrefByConditions(lastHref, loginData.environment),
                            state: { isFromPublicPath: true },
                        }),
                    );
                }

                yield put(statusActions.deleteLastHref());
            }
        } else {
            yield put(replace("/desktop"));
            yield put(statusActions.resetComeFromLogin());
        }
    }
}

function editLastHrefByConditions(lastHref, environment) {
    const { permissions } = environment;

    // card payment
    if (
        lastHref === "/formCustom/payCreditCardLocal" &&
        (permissions.payCreditCard || permissions.payCreditCardThird)
    ) {
        if (permissions.payCreditCard) {
            return lastHref;
        }
        if (permissions.payCreditCardThird) {
            return "/formCustom/payCreditCardThird";
        }
    }

    // card recharge
    if (
        lastHref === "/formCustom/rechargeCreditCardLocal" &&
        (permissions.rechargeCreditCardLocal || permissions.rechargeCreditCardThird)
    ) {
        if (permissions.rechargeCreditCardLocal) {
            return lastHref;
        }
        if (permissions.rechargeCreditCardThird) {
            return "/formCustom/rechargeCreditCardThird";
        }
    }

    return lastHref;
}

export function* readCreditCardList(idCreditCard) {
    yield put(creditCardActions.listFetching());
    const responseCreditCard = yield call(creditCardsMiddleware.listRequest);
    if (responseCreditCard.type === "W") {
        yield put(creditCardActions.listFailure());
    } else {
        const { creditCards } = responseCreditCard.data.data;
        yield put(creditCardActions.listSuccess(creditCards));
        if (idCreditCard) {
            yield put(creditCardActions.detailEveryRequest(idCreditCard));
        } else {
            for (let i = 0; i < creditCards.length; i++) {
                const hasActiveSession = yield select(sessionSelectors.isLoggedIn);
                if (hasActiveSession) {
                    yield put(creditCardActions.detailEveryRequest(creditCards[i].idProduct));
                }
            }
        }
    }
}

export function* readCreditLineList() {
    const response = yield call(listCreditLinesRequest);
    if (response.type === "W") {
        yield put(actionsCreditLines.listFailure());
    } else {
        const { creditLines: responseCreditLines, total } = response.data.data;
        yield put(actionsCreditLines.listSuccess(responseCreditLines, total));
        if (responseCreditLines.length === 0) {
            yield put(desktopActions.loadLayoutRemoveWidget("creditLines"));
        }
    }
}

function* handleLoginStep1Request({
    email,
    shouldRememberEmail,
    password,
    recaptchaResponse,
    credentialType,
    otp,
    formikBag,
    isActiveCorporate,
}) {
    sourceLoginStep1 = axios.CancelToken.source();
    const cancelToken = sourceLoginStep1.token;
    const { fingerPrintToken } = yield call(getFpTokenbyDevice, "session.login.legacy.step1");
    const response = yield call(
        session.loginStep1,
        email,
        password,
        recaptchaResponse,
        credentialType,
        otp,
        cancelToken,
        fingerPrintToken,
    );
    if (response.data.code === "COR020W") {
        if (formikBag) {
            formikBag.setSubmitting(false);
        }

        yield put({ type: types.LOGIN_FAILURE });
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));

        if ("captcha" in response.data.data) {
            // Capcha required || capcha invalid
            yield put({ type: types.LOGIN_FAILURE_REQUIRE_CAPTCHA });
        }
    } else if (
        response.data.code === "API519W" ||
        response.data.code === "API517W" ||
        response.data.code === "API516W" ||
        response.data.code === "API016W" ||
        response.data.code === "API021W" ||
        response.data.code === "COR050W" ||
        response.data.code === "COR029E" ||
        response.data.code === "COR047E" ||
        response.data.code === "API582W" ||
        response.data.code === "API605W"
    ) {
        // Wrong credentials || capcha required || capcha invalid
        if (formikBag) {
            formikBag.setSubmitting(false);
        }

        if (response.data.code === "API605W") {
            // user not migrated
            yield put({
                type: types.SHOW_MODAL_INFO,
                active: true,
            });
        } else {
            yield put(notificationActions.showNotification(response.data.message, "error", ["externalLayout"]));
            yield put({ type: types.LOGIN_FAILURE });
        }

        if (response.data.code === "API021W" || response.data.code === "COR050W" || response.data.code === "API516W") {
            // Capcha required || capcha invalid
            yield put({ type: types.LOGIN_FAILURE_REQUIRE_CAPTCHA });
        }

        yield put({
            type: formTypes.SEND_FORM_DATA_FAILURE,
            idForm: "loginStep1",
            code: response.data.code,
            errors: response.data.data,
        });
    } else {
        const {
            environments,
            selectEnvironment,
            lang,
            _userFirstName,
            _userFullName,
            defEnvironmentEnabled,
            _exchangeToken,
        } = response.data.data;

        if (!defEnvironmentEnabled) {
            yield put(
                notificationActions.showNotification(i18n.get("settings.defaultEnvironment.blockedMessage"), "error", [
                    "loginStep3",
                ]),
            );
        }

        // Remember user
        if (shouldRememberEmail) {
            yield put(
                loginActions.setRememberedUser({
                    username: email,
                    userFirstName: _userFirstName,
                    userFullName: _userFullName,
                    isUserActiveCorporate: isActiveCorporate,
                }),
            );
        }

        yield put({
            type: types.LOGIN_STEP_1_SUCCESS,
            exchangeToken: _exchangeToken,
            username: email,
            lang,
            _userFirstName,
            _userFullName,
            environments,
            shouldRememberEmail,
        });

        if (selectEnvironment && Object.keys(environments).length > 1) {
            if (formikBag) {
                formikBag.setSubmitting(false);
            }

            yield put(replace("/loginStep3"));
        } else {
            yield put({
                type: types.LOGIN_STEP_3_REQUEST,
                formikBag,
            });
        }
    }
}

function cancelLoginStep1Request() {
    if (sourceLoginStep1) {
        sourceLoginStep1.cancel("LoginStep1Request canceled by the user.");
    }
}

function* handleOauthRequest({ password, recaptchaResponse, formikBag }) {
    const exchangeToken = yield select(selectors.getExchangeToken);
    const username = yield select(selectors.getUsername);
    const isFromMessenger = yield select(assistantSelectors.isFromMessenger);
    // eslint-disable-next-line camelcase
    const { redirect_uri } = yield select(assistantSelectors.getParams);
    const isFromWhatsapp = yield select(assistantSelectors.isFromWhatsapp);

    let response;
    if (isFromMessenger || isFromWhatsapp) {
        const thirdPartyToken = yield select(assistantSelectors.getThirdPartyToken);
        response = yield call(
            session.thirdPartyOauth,
            username,
            password,
            thirdPartyToken,
            redirect_uri,
            exchangeToken,
            recaptchaResponse,
        );
        if (response.data) {
            response.data.access_token = thirdPartyToken;
        } else {
            response.data = {
                access_token: thirdPartyToken,
            };
        }
    } else {
        response = yield call(session.oauth, username, password, exchangeToken);
    }

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors({}));
        formikBag.setSubmitting(false);
        const errorMsg = i18n.get(`returnCode.${response.data.error_description}`, response.data.error_description);
        if (
            response.data.error_description === "COR020W" ||
            response.data.error_description === "API019W" ||
            response.data.error_description === "API020W" ||
            response.data.error_description === "COR050W" ||
            response.data.error_description === "COR029E" ||
            response.data.error_description === "COR047E"
        ) {
            // Wrong credentials || captcha required || captcha invalid
            yield put(notificationActions.showNotification(errorMsg, "error", ["externalLayout"]));

            if (response.data.error_description === "API020W" || response.data.error_description === "COR050W") {
                // Captcha required || captcha invalid
                yield put({ type: types.LOGIN_FAILURE_REQUIRE_CAPTCHA });
            }

            yield put({
                type: formTypes.SEND_FORM_DATA_FAILURE,
                code: response.data.error_description,
            });
            yield put({ type: types.LOGIN_OAUTH_FAILURE });
        } else {
            // exchangeToken expired, restart flow
            yield put(notificationActions.showNotification(errorMsg, "error", ["externalLayout"]));
            yield put({ type: globalTypes.BACK_TO_STEP_0 });
            yield put(replace("/"));
        }
    } else {
        // Ignored because it's the OAUTH standard
        // eslint-disable-next-line camelcase
        const { access_token, refresh_token } = response.data;
        if (!isFromMessenger && !isFromWhatsapp) {
            session.setAuthToken(access_token);
            yield put(sessionActions.setTokens(access_token, refresh_token));
        } else {
            yield put(assistantActions.setAccessToken(access_token));
            yield put(replace("/loginStep5"));
            return;
        }
        const environmentsResponse = yield call(session.listEnvironments);
        const {
            environments,
            selectEnvironment,
            lang,
            _userFirstName,
            _userFullName,
            defEnvironmentEnabled,
        } = environmentsResponse.data.data;

        if (!defEnvironmentEnabled) {
            yield put(
                notificationActions.showNotification(i18n.get("settings.defaultEnvironment.blockedMessage"), "error", [
                    "loginStep3",
                ]),
            );
        }

        const rememberUser = yield select(selectors.getShouldRememberEmail);
        const rememberedUser = yield select(selectors.getRememberedUser);

        // Remember user
        if (rememberUser) {
            const userName = yield select(selectors.getUsername);
            yield put(
                loginActions.setRememberedUser({
                    username: userName,
                    userFirstName: _userFirstName,
                    userFullName: _userFullName,
                    isUserActiveCorporate: rememberedUser.isUserActiveCorporate,
                }),
            );
        }

        yield put({
            type: types.LOGIN_OAUTH_SUCCESS,
            lang,
            _userFirstName,
            _userFullName,
            environments,
        });

        if (selectEnvironment && Object.keys(environments).length > 1) {
            yield put(replace("/loginStep3"));
        } else {
            yield put({
                type: types.LOGIN_STEP_3_REQUEST,
                formikBag,
            });
        }
    }
}

function* handleLoginStep3Request({ idEnvironment, rememberEnvironment, formikBag, isUserActiveCorporate }) {
    const exchangeToken = yield select(selectors.getExchangeToken);
    const isFromMessenger = yield select(assistantSelectors.isFromMessenger);
    const isFromGoogle = yield select(assistantSelectors.isFromGoogle);
    const isFromAmazon = yield select(assistantSelectors.isFromAmazon);
    const isFromWhatsapp = yield select(assistantSelectors.isFromWhatsapp);
    const clientNumber = yield select(assistantSelectors.getClientNumber);
    const accountLinkingToken = yield select(assistantSelectors.getAccountLinkingToken);

    const location = {
        latitude: 0.0,
        longitude: 0.0,
    };

    let extraInfo = {};
    let idDevice = null;
    let registrationId = null;

    if (window.app) {
        const { registrationId: registrationIdResult } = yield call(window.pushNotifications.isEnabled);
        extraInfo = window.device;
        extraInfo.isTablet = false;
        extraInfo.isIOS = deviceUtils.getMobileOS(deviceUtils.getDisplay()) === deviceUtils.IOS_DEVICE;
        extraInfo.isAndroid = deviceUtils.getMobileOS(deviceUtils.getDisplay()) === deviceUtils.ANDROID_DEVICE;
        extraInfo.uuid = window.app.getDeviceUUID();
        registrationId = registrationIdResult;
        idDevice = window.app.getDeviceUUID();
    } else {
        idDevice = yield deviceUtils.getDeviceFingerprint();
        extraInfo = yield deviceUtils.getExtraInfo();
    }
    yield call(session.registerUserDevice, exchangeToken, idDevice, registrationId, JSON.stringify(extraInfo));
    sourceLoginStep3 = axios.CancelToken.source();
    const cancelToken = sourceLoginStep3.token;
    const { fingerPrintToken } = yield call(getFpTokenbyDevice, "session.login.legacy.step3");
    const response = yield call(
        session.loginStep3,
        exchangeToken,
        idEnvironment,
        rememberEnvironment,
        location,
        idDevice,
        {
            isFromMessenger,
            isFromGoogle,
            isFromAmazon,
            isFromWhatsapp,
            accountLinkingToken,
            clientNumber,
        },
        cancelToken,
        fingerPrintToken,
    );

    if (response.type === "W") {
        const { code, data } = response.data;
        if (code === "COR020W" && data.environment) {
            // mark the environment as disabled and redirecting the user to the step 3 again
            const environments = yield select(selectors.getEnvironments);
            if (environments[idEnvironment]) {
                environments[idEnvironment].allowedToAccess = false;
                yield put({ type: types.MARK_ENVIRONMENTS_DISABLED, environments });
            }

            formikBag.setSubmitting(false);
            yield put(replace("/loginStep3"));
        } else {
            // en este paso lo unico que podria suceder es que se venciera el exchange token
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
            yield put(notificationActions.showNotification(response.data.message, "error", ["externalLayout"]));
            yield put({ type: globalTypes.BACK_TO_STEP_0 }); // por lo que borro lo que tengo de sesion y voy al step0

            formikBag.setSubmitting(false);
            yield put(replace("/"));
        }
    } else {
        const {
            data: {
                data: { enabledAssistant, tourSkipsCounter, becameAdult, scheduledMigrationFinished, signatureLevel },
            },
        } = response;

        yield put({ type: types.FINALIZE_LOGIN, response, isUserActiveCorporate });
        if (becameAdult) {
            yield put({
                type: productTypes.OPEN_ADULTHOOD_MODAL,
                show: true,
            });
        }
        yield put({ type: tourTypes.ADD_SKIP_SUCCESS, skipsCounter: tourSkipsCounter });
        yield put({ type: sessionTypes.SET_ENABLED_ASSISTANT, enabledAssistant });
        yield put({ type: sessionTypes.SET_SIGNATURE_LEVEL, signatureLevel });
        if (scheduledMigrationFinished) {
            yield put({ type: transactionTypes.SET_LOAD_MIGTRATION_EXECUTION_COUNT, loadMigrationExecutionCount: 3 });
        }
    }
}

function cancelLoginStep3Request() {
    if (sourceLoginStep3) {
        sourceLoginStep3.cancel("LoginStep3Request canceled by the user");
    }
}

function* handleLoginStep4Request({ acceptConditions, formikBag }) {
    const activeRegion = yield select(selectors.getRegion);
    const exchangeToken = yield select(selectors.getExchangeToken);
    const username = yield select(selectors.getUsername);
    const idEnvironmentToAcceptConditions = yield select(selectors.getIdEnvironmentToAcceptConditions);
    const { fingerPrintToken } = yield call(getFpTokenbyDevice, "session.login.legacy.step4");
    const response = yield call(
        session.loginStep4,
        exchangeToken,
        acceptConditions,
        fingerPrintToken,
        idEnvironmentToAcceptConditions,
    );

    if (response.type === "W") {
        if (formikBag) {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        }
        if (formikBag) {
            formikBag.setSubmitting(false);
        }
        yield put(notificationActions.showNotification(response.data.message, "error", ["loginStep4"]));
        yield put({ type: types.LOGIN_FAILURE, errors: response.data.data });
    } else {
        const loginData = yield call(processLoginSuccess, response);
        if (loginData.user.isNeedingUpdate) {
            yield put(desktopActions.updateModalShow(loginData.user.daysSinceLastUpdt));
        }
        const {
            isNeedingUpdateCorporate,
            yearsSinceLastUpdateCorporate,
            dateLastUpdateCorporate,
            stateUpdateCorporate,
        } = loginData.environment || {};
        if (isNeedingUpdateCorporate) {
            yield put(desktopActions.updateCorporateModalShow(yearsSinceLastUpdateCorporate));
        }
        yield put(updateCompanyDataActions.updateCorporateInfo(dateLastUpdateCorporate, stateUpdateCorporate));
        yield put(
            updateUserDataActions.getUpdateInformation(false, loginData.user.isNeedingUpdate, loginData.user.lastUpdDt),
        );

        const { data } = response.data;
        const { lastHref } = yield select((state) => state.status);

        configUtils.setRecaptchaLang(data.lang);
        yield put(i18nActions.setLang(data.lang));

        if (
            configUtils.get("core.sessionHandler.componentFQN") ===
            "com.technisys.omnichannel.core.session.DbSessionHandler"
        ) {
            yield call(session.setAuthToken, data._accessToken);
        } else {
            const responseOAuth = yield call(session.oauth, username, exchangeToken);

            if (responseOAuth.type === "W") {
                const errorMsg = i18n.get(
                    `returnCode.${responseOAuth.data.error_description}`,
                    responseOAuth.data.error_description,
                );
                yield put({ type: types.LOGIN_OAUTH_FAILURE });
                // exchangeToken expired, restart flow
                yield put(notificationActions.showNotification(errorMsg, "error", ["externalLayout"]));
                yield put({ type: globalTypes.BACK_TO_STEP_0 });
                yield put(replace("/"));
            } else {
                const { access_token, refresh_token } = responseOAuth.data;

                session.setAuthToken(access_token);
                yield put(sessionActions.setTokens(access_token, refresh_token));
            }
        }

        yield put({
            type: types.LOGIN_SUCCESS,
            environment: loginData.environment,
            user: loginData.user,
            environments: data.environments,
            isAdministrator: data.isAdministrator,
        });

        yield fork(validateAccess);
        yield fork(setSessionNotification, "1");

        const { pepCompleted, irsCompleted } = loginData.user;

        if ((!pepCompleted || !irsCompleted) && activeRegion === REGION_USA) {
            yield put(replace("/pendingActions"));
        } else if (lastHref) {
            if (lastHref === "/tokenActivationStep1") {
                yield put({
                    type: enrollmentTypes.SET_FETCHING,
                    fetching: true,
                });
                yield call(validateUserToken);
            } else {
                // si la sesion expiró y se guardó la url en la que se estaba voy para ahi
                yield put(replace(editLastHrefByConditions(lastHref, loginData.environment)));
                yield put(statusActions.deleteLastHref());
            }
        } else {
            yield put(statusActions.resetComeFromLogin());
            // si no, voy a desktop
            yield put(replace("/desktop"));
        }
    }
}

function processLoginSuccess(response) {
    const {
        _accessToken,
        _securitySeal,
        username,
        userFirstName,
        userFullName,
        userId,
        previousLoginInfo,
        defaultAvatarId,
        email,
        clients,
        signedTAndCDate,
        irsDate,
        pepCompleted,
        irsCompleted,
        ssnid,
        extendedData,
        isNeedingUpdate,
        lastUpdDt,
        daysSinceLastUpdt,
        isNeedingUpdateCorporate,
        yearsSinceLastUpdateCorporate,
        dateLastUpdateCorporate,
        stateUpdateCorporate,
        bankType,
        isLocalNumber,
        ...data
    } = response.data.data;

    const user = {
        defaultAvatarId,
        previousLoginInfo,
        userFirstName,
        userFullName,
        username,
        userId,
        email,
        accessToken: _accessToken,
        securitySeal: _securitySeal,
        signedTAndCDate,
        irsDate,
        pepCompleted,
        irsCompleted,
        ssnid,
        idDefaultEnvironment:
            data.idDefaultEnvironment && data.idDefaultEnvironment > 0 ? data.idDefaultEnvironment : null,
        isNeedingUpdate,
        lastUpdDt,
        daysSinceLastUpdt,
        bankType,
        isLocalNumber,
    };

    /**
     * Add mask amount user
     */
    if (extendedData) {
        const { maskAmount } = extendedData;
        user.maskAmount = maskAmount;
        if (extendedData?.isSecondary && extendedData?.isSecondary === "true") {
            user.isSecondary = true;
        }
    }
    let forms = null;
    if (data.forms) {
        forms = {};

        for (let i = 0; i < data.forms.length; i++) {
            let category = forms[data.forms[i].category];
            if (!category) {
                category = [];
                forms[data.forms[i].category] = category;
            }
            category.push(data.forms[i]);
        }
    }

    const environment = {
        permissions: data.permissions,
        forms,
        name: data.activeEnvironmentName,
        type: data.activeEnvironmentType,
        id: data.activeIdEnvironment,
        administrationScheme: data.administrationScheme,
        isNeedingUpdateCorporate,
        yearsSinceLastUpdateCorporate,
        dateLastUpdateCorporate,
        stateUpdateCorporate,
        clients,
        creationDate: data.creationDate,
    };

    return { user, environment };
}

function* getDeviceInfo() {
    if (!deviceUtils.isMobileNativeFunc()) {
        const idDevice = yield deviceUtils.getDeviceFingerprint();
        const extraInfo = yield deviceUtils.getExtraInfo();
        yield;
        return { idDevice, extraInfo: JSON.stringify(extraInfo) };
    }

    const { registrationId: registrationIdResult } = yield call(window.pushNotifications.isEnabled);
    const extraInfo = window.device;
    extraInfo.isTablet = false;
    extraInfo.isIOS = deviceUtils.getMobileOS(deviceUtils.getDisplay()) === deviceUtils.IOS_DEVICE;
    extraInfo.isAndroid = deviceUtils.getMobileOS(deviceUtils.getDisplay()) === deviceUtils.ANDROID_DEVICE;
    extraInfo.uuid = window.app.getDeviceUUID();
    const idDevice = window.app.getDeviceUUID();
    yield;
    return { idDevice, extraInfo: JSON.stringify(extraInfo), registrationId: registrationIdResult };
}

function* redirectTermConditionStep(response) {
    const {
        generalConditionsShowExpiration,
        generalConditionsText,
        generalConditionsExpirationDate,
        generalConditions,
    } = response?.data?.data;

    const activeRegion = yield select(selectors.getRegion);
    const termsAndConditions = {
        // just joining the long named variables into a single object
        showExpiration: generalConditionsShowExpiration,
        generalConditions,
        body: generalConditionsText,
        expirationDate: generalConditionsExpirationDate,
    };

    yield put({ type: types.LOGIN_STEP_3_SUCCESS, termsAndConditions });
    if (activeRegion && activeRegion === REGION_USA) {
        const firstName = yield select(selectors.getUserFirstName);
        const email = yield select(selectors.getUsername);
        yield put({
            type: enrollmentTypes.SET_INVITATION,
            invitation: {
                email,
                firstName,
                lastName: "",
            },
        });
        yield put(replace("/enrollment/Step3Part4"));
        yield;
        return;
    }

    yield put(replace("/loginStep4"));
}

function* redirectGeneralLoginError(errorMsg) {
    yield put({ type: types.LOGIN_OAUTH_FAILURE });
    // exchangeToken expired, restart flow
    yield put(notificationActions.showNotification(errorMsg || "", "error", ["externalLayout"]));
    yield put({ type: globalTypes.BACK_TO_STEP_0 });
    yield put(replace("/"));
}

function* handleFingerprintLoginPre() {
    let fingerprintAuthToken = null;
    let fingerprintAuthTokenExists = true;

    const location = {
        latitude: 0.0,
        longitude: 0.0,
    };

    try {
        fingerprintAuthToken = yield call(secureStorageUtils.get, "fingerprintAuthToken");
    } catch (error) {
        fingerprintAuthTokenExists = false;
    }

    if (fingerprintAuthTokenExists) {
        const fingerPrintLoginFail = yield select((state) => state.session.fingerprintLoginFail);
        if (!fingerPrintLoginFail) {
            try {
                /**
                 * Set revelock position
                 */
                yield call(setPositionRevelock, "/login.biometric");
                yield call(fingerprintUtils.verify);

                const username = yield call(secureStorageUtils.get, "fingerprintUsername");
                const response = yield call(session.fingerprintOauth, username, fingerprintAuthToken);

                if (response.status === 200) {
                    yield put({ type: types.FINGERPRINT_LOGIN_SUBMIT });

                    // eslint-disable-next-line camelcase
                    if (response.type === "W") {
                        const errorMsg = i18n.get(
                            `returnCode.${response?.data?.error_description || ""}`,
                            "login.error.default.message",
                        );
                        yield call(redirectGeneralLoginError, errorMsg);
                    } else {
                        const { access_token, refresh_token } = response.data;

                        session.setAuthToken(access_token);
                        yield put(sessionActions.setTokens(access_token, refresh_token));

                        const deviceInfo = yield call(getDeviceInfo);
                        const { fingerPrintToken } = yield call(getFpTokenbyDevice, "login.fingerprint");
                        const responseFinger = yield call(
                            session.fingerprintLogin,
                            location,
                            deviceInfo,
                            fingerPrintToken,
                        );
                        if (!responseFinger?.type || !responseFinger?.data?.data) {
                            yield call(redirectGeneralLoginError, i18n.get("login.error.default.message"));
                            yield;
                            return;
                        }

                        if (responseFinger.type === "W") {
                            yield call(redirectGeneralLoginError, i18n.get("login.error.default.message"));
                            yield;
                            return;
                        }
                        const { generalConditions } = responseFinger.data.data;

                        if (generalConditions) {
                            yield call(redirectTermConditionStep, responseFinger);
                            yield;
                            return;
                        }

                        yield call(fingerPrinterLoginSuccess, { ...responseFinger, accessTokenOauth: access_token });
                    }
                } else if (response.status === 400 && response.data?.error_description === "COR097E") {
                    yield put(
                        routerActions.push({
                            pathname: "serverError",
                        }),
                    );
                } else if (response.status === 400 && response.data?.error_description === "COR999E") {
                    yield call(secureStorageUtils.remove, "fingerprintAuthToken");
                    yield put({ type: fingerprintTypes.CONFIGURATION_DEVICE_HAS_NOT_FINGERPRINTER });
                    yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });
                    yield put(
                        notificationActions.showNotification(
                            i18n.get("settings.fingerprintConfiguration.dialog.error_2"),
                            "error",
                            ["externalLayout"],
                        ),
                    );
                    yield;
                    return;
                }
            } catch (error) {
                if (error === fingerprintUtils.fingerprintErrors.FINGERPRINT_ERROR) {
                    yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });
                    const mess = `${i18n.get("settings.fingerprintConfiguration.dialog.error_1")}\n${i18n.get(
                        "settings.fingerprintConfiguration.dialog.error_2",
                    )}`;
                    yield put(notificationActions.showNotification(mess, "error", ["externalLayout"]));
                    yield;
                    return;
                }

                if (error === fingerprintUtils.fingerprintErrors.FINGERPRINT_CANCELLED) {
                    yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });
                    yield;
                    return;
                }

                if (error.status && error.status === 200) {
                    yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });
                    yield put(
                        notificationActions.showNotification(
                            i18n.get("login.fingerprint.session.wrongAccess"),
                            "error",
                            ["externalLayout"],
                        ),
                    );
                    yield;
                    return;
                }

                if (error.response && error.response.status && error.response.status === 401) {
                    yield put(
                        notificationActions.showNotification(i18n.get("login.fingerprint.session.expired"), "error", [
                            "externalLayout",
                        ]),
                    );
                    yield put({ type: globalTypes.BACK_TO_STEP_0 });
                    yield put(replace("/"));
                }

                yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });
            }
        } else {
            yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });
        }
    } else {
        yield put({ type: types.LOGIN_FINGERPRINT_FAILURE_PROCESS });
    }
}

function* showGenericErrorFingerPrinter(message, errors) {
    yield put(
        notificationActions.showNotification(message || i18n.get("login.error.default.message"), "error", [
            "loginStep4",
        ]),
    );
    yield put({ type: types.LOGIN_FAILURE, errors: errors || {} });
}

function* fingerPrinterLoginSuccess(responseFinger) {
    const {
        environments,
        isAdministrator,
        tourSkipsCounter,
        enabledAssistant,
        lang,
        isFirstLogin,
    } = responseFinger.data.data;

    const { lastHref } = yield select((state) => state.status);

    yield put({ type: tourTypes.ADD_SKIP_SUCCESS, skipsCounter: tourSkipsCounter });
    yield put({ type: sessionTypes.SET_ENABLED_ASSISTANT, enabledAssistant });

    const loginData = yield call(processLoginSuccess, responseFinger);
    if (loginData.user.isNeedingUpdate) {
        yield put(desktopActions.updateModalShow(loginData.user.daysSinceLastUpdt));
    }
    const { isNeedingUpdateCorporate, yearsSinceLastUpdateCorporate, dateLastUpdateCorporate, stateUpdateCorporate } =
        loginData.environment || {};
    if (isNeedingUpdateCorporate) {
        yield put(desktopActions.updateCorporateModalShow(yearsSinceLastUpdateCorporate));
    }
    yield put(updateCompanyDataActions.updateCorporateInfo(dateLastUpdateCorporate, stateUpdateCorporate));
    yield put(
        updateUserDataActions.getUpdateInformation(false, loginData.user.isNeedingUpdate, loginData.user.lastUpdDt),
    );

    configUtils.setRecaptchaLang(lang);
    yield put(i18nActions.setLang(lang));

    /**
     * Get session data
     */
    const { data: sessionData } = yield call(session.check);
    yield put({
        type: types.LOGIN_SUCCESS,
        environment: loginData.environment,
        user: { ...loginData.user, sessionId: sessionData?.data?.sessionId || "" },
        environments,
        isAdministrator,
    });
    yield fork(setSessionNotification, "1");

    /**
     * Set user id revelock
     */
    yield call(setUserRevelock, loginData?.user?.username, sessionData?.data?.sessionId);
    yield fork(setSessionKeystoreGroup, responseFinger?.accessTokenOauth || "");
    /**
     * Update mask amount
     */
    const { maskAmount } = loginData.user;
    if (maskAmount) {
        yield put(sessionActions.maskAmountUpdate(maskAmount === "true"));
    }

    if (!isFirstLogin) {
        yield put(productActions.syncEnviromentProduct(true));
    }

    yield fork(readCreditCardList);
    yield fork(readCreditLineList);
    yield fork(validateAccess);

    if (lastHref) {
        if (lastHref === "/tokenActivationStep1") {
            yield call(validateUserToken);
        } else if (lastHref === "/kuara" || lastHref === "/wally") {
            let route = "";
            if (kuaraVersionNumber(1)) {
                route = isLocalPhoneNumber() ? "/kuara" : "/wally";
            } else {
                route = "/kuara";
            }
            yield put(
                replace({
                    pathname: editLastHrefByConditions(route, loginData.environment),
                    state: { isFromPublicPath: true },
                }),
            );
        } else {
            if (typeof lastHref === "string" && lastHref.includes("desktop") && lastHref.includes("show")) {
                yield put(
                    replace({
                        pathname: "/desktop",
                        state: { isFromPublicPath: true },
                        search: `?${lastHref}`,
                    }),
                );
            } else {
                yield put(
                    replace({
                        pathname: editLastHrefByConditions(lastHref, loginData.environment),
                        state: { isFromPublicPath: true },
                    }),
                );
            }
            yield put(statusActions.deleteLastHref());
        }
    } else {
        yield put(replace("/desktop"));
        yield put(statusActions.resetComeFromLogin());
    }
}

function* fingerPrinterFourthStepRequest({ acceptConditions, formikBag }) {
    const { fingerPrintToken } = yield call(getFpTokenbyDevice, "login.fingerprint");
    const response = yield call(session.fingerPrinterFourthStepRequest, acceptConditions, fingerPrintToken);
    if (!response?.type) {
        yield call(showGenericErrorFingerPrinter);
        yield;
        return;
    }

    if (!response?.data?.data) {
        yield call(showGenericErrorFingerPrinter);
        yield;
        return;
    }

    if (response.type === "W") {
        if (formikBag) {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
            formikBag.setSubmitting(false);
        }
        yield call(showGenericErrorFingerPrinter, response?.data?.message, response?.data?.data);
        yield;
        return;
    }
    yield call(fingerPrinterLoginSuccess, response);
}

export function* validateAccess() {
    try {
        const { fingerPrintToken } = yield call(getFpTokenbyDevice, "monitor.session.validate");
        const response = yield call(session.validateAccess, fingerPrintToken);
        if (response.type === "W") {
            yield put(sessionActions.logout());
            yield put(revelockActions.showSuspiciousActivityModal());
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }
}
