import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import Text from "pages/_components/Text";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import { shape, bool, func } from "prop-types";
import React, { useEffect } from "react";
import withRouter from "react-router-dom/withRouter";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { connect } from "react-redux";
import { compose } from "redux";
import { routerActions } from "react-router-redux/actions";
import { isFromNotificationRedirection } from "util/general";
import { resizableRoute } from "pages/_components/Resizable";
import { actions as financesActions, selectors as financesSelectors } from "reducers/finances";

export const ID_ACTIVITY = `finances.report`;

const Finances = (props) => {
    const { dispatch, fetching, history, report, isDesktop, location } = props;
    useEffect(() => {
        dispatch(financesActions.financeReadRequest());
    }, []);

    const handleBack = () => {
        if (isFromNotificationRedirection(location)) {
            dispatch(routerActions.push("/desktop"));
        } else {
            history.goBack();
        }
    };

    return (
        <>
            <Notification scopeToShow="finances" />
            <Head title={`${ID_ACTIVITY}.title`} onBack={handleBack} />
            <MainContainer showLoader={fetching} {...(!isDesktop && { className: "pb-0 overflow-hidden" })}>
                <Box
                    component="section"
                    background="white"
                    {...(isDesktop && { borderRadius: "default" })}
                    className="px-5 px-md-7 py-md-3 mx-n-5">
                    {report ? (
                        <PowerBIEmbed
                            embedConfig={{
                                type: "report",
                                id: report?.id,
                                embedUrl: report?.url,
                                accessToken: report?.token,
                                tokenType: models.TokenType.Embed,
                                settings: {
                                    layoutType: isDesktop ? models.LayoutType.Master : models.LayoutType.MobilePortrait,
                                    panes: {
                                        filters: {
                                            expanded: false,
                                            visible: false,
                                        },
                                    },
                                    background: models.BackgroundType.Transparent,
                                    navContentPaneEnabled: false,
                                },
                            }}
                            cssClassName="report-style-class"
                        />
                    ) : (
                        <Box component="section" className="mt-6 mt-md-0 px-7 py-3 mb-6 mx-n-5">
                            <Text labelKey="finances.without.data" />
                        </Box>
                    )}
                </Box>
            </MainContainer>
        </>
    );
};

const mapStateToProps = (state) => ({
    fetching: financesSelectors.isFetching(state),
    report: financesSelectors.getReport(state),
});

Finances.propTypes = {
    dispatch: func.isRequired,
    fetching: bool,
    history: shape({}).isRequired,
    report: shape({}),
    isDesktop: bool,
    location: shape({}),
};

Finances.defaultProps = {
    fetching: false,
    report: null,
    isDesktop: false,
    location: {},
};

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(Finances));
