import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import GridTable from "pages/_components/GridTable/GridTable";
import ChevromRight from "pages/_components/listItem/ChevromRight";
import Sticker from "pages/_components/Sticker";
import Text from "pages/_components/Text";
import { bool, shape } from "prop-types";
import React, { Component } from "react";

class Statement extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        fee: shape({}).isRequired,
        paymentPlan: bool.isRequired,
        isFeePaid: bool.isRequired,
    };

    render() {
        const { isDesktop, paymentPlan, fee, isFeePaid } = this.props;

        let lastColumnNumber = "9";
        if (!isDesktop) {
            lastColumnNumber = "3";
        } else if (isFeePaid) {
            lastColumnNumber = "8";
        }

        return (
            <>
                <GridTable.Data overflow={null} columnStart={1} alignX="flex-Start">
                    <Text {...(!isDesktop && { bold: true, size: "5" })}>{fee.idFee}</Text>
                    {fee.expired && paymentPlan && (
                        <Sticker status="error" labelKey="loan.detail.statement.field.expired" className="ml-3" />
                    )}
                    {!fee.expired && paymentPlan && (
                        <Sticker status="success" labelKey="loan.detail.statement.field.valid" className="ml-3" />
                    )}
                </GridTable.Data>
                <GridTable.Data overflow={null} columnStart={2} alignX={isDesktop ? "center" : "flex-start"}>
                    <FormattedDate
                        date={fee.dueDate}
                        size={isDesktop ? "6" : "5"}
                        {...(!isDesktop && { color: "text" })}
                    />
                </GridTable.Data>
                {isDesktop && (
                    <>
                        <GridTable.Data overflow={null} columnStart={3} alignX="flex-end">
                            <FormattedAmount
                                size="6"
                                className="data-amount justify-content-end"
                                quantity={fee.principalBalance}
                                noCurrency
                            />
                        </GridTable.Data>
                        <GridTable.Data overflow={null} columnStart={4} alignX="flex-end">
                            <FormattedAmount
                                size="6"
                                className="data-amount justify-content-end"
                                quantity={fee.interest}
                                noCurrency
                            />
                        </GridTable.Data>
                        <GridTable.Data overflow={null} columnStart={5} alignX="flex-end">
                            <FormattedAmount
                                size="6"
                                className="data-amount justify-content-end"
                                quantity={fee.penaltyInterest}
                                noCurrency
                            />
                        </GridTable.Data>
                        <GridTable.Data overflow={null} columnStart={6} alignX="flex-end">
                            <FormattedAmount
                                size="6"
                                className="data-amount justify-content-end"
                                quantity={fee.feci}
                                noCurrency
                            />
                        </GridTable.Data>
                        <GridTable.Data
                            overflow={null}
                            columnStart={isDesktop ? 7 : 3}
                            alignX="flex-end"
                            {...(!isDesktop && { rowStart: 1, rowWidth: 2 })}>
                            <FormattedAmount
                                size="6"
                                className="data-amount justify-content-end"
                                quantity={fee.overdraft}
                                noCurrency
                            />
                        </GridTable.Data>
                        {!isFeePaid && (
                            <GridTable.Data
                                overflow={null}
                                columnStart={isDesktop ? 8 : 4}
                                alignX="flex-end"
                                {...(!isDesktop && { rowStart: 1, rowWidth: 2 })}>
                                <FormattedAmount
                                    size="6"
                                    className="data-amount justify-content-end"
                                    quantity={fee.amount}
                                    noCurrency
                                />
                            </GridTable.Data>
                        )}
                    </>
                )}
                <GridTable.Data
                    overflow={null}
                    columnStart={lastColumnNumber}
                    alignX="flex-end"
                    {...(!isDesktop && { rowStart: 1, rowWidth: 2 })}>
                    <FormattedAmount
                        size="6"
                        className="data-amount justify-content-end"
                        quantity={isFeePaid ? fee.dueBalancePaid ?? 0 : fee.dueBalance}
                        noCurrency
                        {...(!isDesktop && { bold: true, size: "5", color: "text" })}
                    />
                </GridTable.Data>
                {!isDesktop && (
                    <GridTable.Data overflow={null} columnStart={4} alignX="flex-end">
                        <ChevromRight />
                    </GridTable.Data>
                )}
            </>
        );
    }
}

export default Statement;
