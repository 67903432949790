import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image/Image";
import MainContainer from "pages/_components/MainContainer";
import PageLoading from "pages/_components/PageLoading";
import Text from "pages/_components/Text";
import SelectAccount from "pages/_components/fields/SelectAccount/SelectAccount";
import { arrayOf, bool, func, number, shape } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { actions as kuaraActions, selectors as kuaraSelectors } from "reducers/kuara";
import { actions as wallyActions } from "reducers/wally";
import * as i18n from "util/i18n";
import Notification from "pages/_components/Notification";
import { actions as notificationActions } from "reducers/notification";
import * as config from "util/config";
import kuaraVersionNumber from "util/kuaraVersionNumber";

const KuaraChangeAccountStep1 = (props) => {
    const { dispatch, history, fetching, accounts, accountKuara } = props;

    useEffect(() => {
        dispatch(kuaraActions.listAccountRequest());
    }, []);

    useEffect(() => {
        if (accounts.length === 0 && fetching === false) {
            dispatch(push("/settingsKuara"));
        }
    }, [accounts, fetching]);

    const [fetchingSubmit, setFetchingSubmit] = useState(false);

    const handleBack = () => {
        history.goBack();
    };

    const handleCancel = () => {
        dispatch(push("/kuara"));
    };

    const onFinish = () => {
        if (kuaraVersionNumber(1)) {
            dispatch(wallyActions.userWallyRequest());
        }
        setFetchingSubmit(false);
    };

    const accountStatus = {
        Actived: "ACTIVA",
        Inactived: "INACTIVA",
        NoActived: "NOACTIVA",
        NoAccount: "NOACCOUNT",
        IsMancomunate: "MANCOMUNADA",
        IsPhoneValid: "PHONE",
    };
    const showInvalidSubProductStatus = () => {
        dispatch(
            notificationActions.showNotification(
                <div>
                    {i18n.get("kuara.validate.invalid.status")}
                    <Button
                        label="kuara.validate.invalid.status.link"
                        externalHref={config.get("accessBar.requests.url")}
                        bsStyle="link"
                        className="p-0 btn-link"
                    />
                    .
                </div>,
                "error",
                ["kuaraChangeAccountStep1"],
                true,
            ),
        );
    };

    const isValidAccounts = (account) => {
        /**
         * validate status sub product account
         */
        const invalidAccountStatus = config.getArray("kuara.account.subproduct.status.invalid", []);
        if (!account?.subProduct || invalidAccountStatus.some((item) => item === account.subProduct)) {
            showInvalidSubProductStatus();
            return false;
        }

        let valid = true;
        let validateAccount = null;
        const accountsValidate = [account];

        validateAccount = accountsValidate.some((accountItem) => accountItem.status === accountStatus.Inactived)
            ? accountStatus.Inactived
            : validateAccount;

        validateAccount = accountsValidate.some((accountItem) => accountItem.status === accountStatus.NoActived)
            ? accountStatus.NoActived
            : validateAccount;

        if (validateAccount) {
            valid = false;
        }

        switch (validateAccount) {
            case accountStatus.NoAccount:
                dispatch(
                    notificationActions.showNotification(
                        <div>
                            {i18n.get("kuara.validate.NoAccount")}
                            <Button
                                label="kuara.validate.NoAccount.link"
                                externalHref={config.get("accessBar.requests.url")}
                                bsStyle="link"
                                className="p-0 btn-link"
                            />
                            .
                        </div>,
                        "error",
                        ["kuaraChangeAccountStep1"],
                        true,
                    ),
                );
                break;
            case accountStatus.Inactived:
                dispatch(
                    notificationActions.showNotification(
                        <div>
                            <div dangerouslySetInnerHTML={{ __html: i18n.get("kuara.validate.Inactived") }} />
                            <Button
                                label="kuara.validate.Inactived.link"
                                externalHref={config.get("accessBar.chat.url")}
                                bsStyle="link"
                                className="p-0 btn-link"
                            />
                        </div>,
                        "error",
                        ["kuaraChangeAccountStep1"],
                        true,
                    ),
                );
                break;
            case accountStatus.NoActived:
                dispatch(
                    notificationActions.showNotification(
                        i18n.get("kuara.validate.NoActived"),
                        "error",
                        ["kuaraChangeAccountStep1"],
                        true,
                    ),
                );
                break;
            case accountStatus.IsMancomunate:
                dispatch(
                    notificationActions.showNotification(
                        <div>
                            {i18n.get("kuara.validate.IsMancomunate")}
                            <Button
                                label="kuara.validate.NoAccount.link"
                                externalHref={config.get("accessBar.requests.url")}
                                bsStyle="link"
                                className="p-0 btn-link"
                            />
                        </div>,
                        "error",
                        ["kuaraChangeAccountStep1"],
                        true,
                    ),
                );
                break;

            case accountStatus.IsPhoneValid:
                dispatch(
                    notificationActions.showNotification(
                        i18n.get("kuara.validate.IsPhoneValid"),
                        "error",
                        ["kuaraChangeAccountStep1"],
                        true,
                    ),
                );
                break;

            default:
                break;
        }
        return valid;
    };

    const handleSubmit = (values) => {
        const accountSelected = accounts?.find((item) => item.idProduct === values.account);
        if (isValidAccounts(accountSelected) && accountSelected && !fetchingSubmit) {
            setFetchingSubmit(true);
            dispatch(kuaraActions.updateRequest(accountSelected, onFinish));
        }
    };

    return (
        <PageLoading loading={fetching} classicStyle={false}>
            <Head onBack={handleBack} title="kuara.header.activation" additionalClassName="kuara-header" />
            <Notification scopeToShow="kuaraChangeAccountStep1" />
            <MainContainer>
                <Box display="flex" column className="pt-8">
                    <Box display="flex" alignX="center" fullWidth className="mb-7">
                        <Image src="images/phone-cash.svg" isMobileNative color="kuara-secondary" />
                    </Box>
                    <Text
                        labelKey="kuara.change.account.title"
                        bold
                        size="1"
                        align="center"
                        color="heading"
                        className="mb-3"
                    />

                    <Box display="flex" className="px-7 mb-5">
                        <Text size="4" color="heading-color" align="center">
                            {i18n.get("kuara.change.account.subtitle")}
                            <Box display="inline-flex" className="ml-2 min-width-5rem" position="relative">
                                <Box position="absolute" top="n-4">
                                    <Image
                                        src="images/logos/kuaraHorizontalSmall.svg"
                                        fullWidth={false}
                                        isMobileNative
                                        height="5"
                                    />
                                </Box>
                            </Box>
                        </Text>
                    </Box>
                </Box>
                <Formik onSubmit={handleSubmit} initialValues={{ account: "" }} enableReinitialize>
                    {(form) => (
                        <Form className="full-height">
                            <Box display="flex" column fullHeight>
                                <Field
                                    name="account"
                                    component={SelectAccount}
                                    options={accounts}
                                    initialChecked={accountKuara}
                                    kuaraSecondaryColor
                                />
                                <Box display="flex" column fullWidth className="mt-auto">
                                    <Button
                                        className="kuara-color"
                                        label="kuara.change.button.confirm"
                                        bsStyle="primary"
                                        block
                                        disabled={accountKuara?.idProduct === form?.values?.account}
                                        loading={fetchingSubmit}
                                        type="submit"
                                    />
                                    <Button
                                        label="global.cancel"
                                        bsStyle="outline"
                                        disabled={fetchingSubmit}
                                        block
                                        onClick={handleCancel}
                                    />
                                </Box>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </MainContainer>
        </PageLoading>
    );
};

KuaraChangeAccountStep1.propTypes = {
    dispatch: func.isRequired,
    history: shape({}).isRequired,
    fetching: bool,
    accounts: arrayOf(shape({ length: number })),
    accountKuara: shape({}).isRequired,
};

KuaraChangeAccountStep1.defaultProps = { fetching: true, accounts: null };

const mapStateToProps = (state) => ({
    accounts: kuaraSelectors.getAccountListKuara(state),
    fetching: kuaraSelectors.isFetching(state),
    accountKuara: kuaraSelectors.getAccountKuara(state),
});

export default connect(mapStateToProps)(KuaraChangeAccountStep1);
