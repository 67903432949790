import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    ADMINISTRATION_USER_INVITE_PRE_REQUEST: "administrationUsersInvite/ADMINISTRATION_USER_INVITE_PRE",
    ADMINISTRATION_USER_INVITE_PRE_REQUEST_SUCCESS:
        "administrationUsersInvite/ADMINISTRATION_USER_INVITE_PRE_REQUEST_SUCCESS",
    ADMINISTRATION_USER_INVITE_PRE_FAILURE: "administrationUsersInvite/ADMINISTRATION_USER_INVITE_PRE_FAILURE",
    ADMINISTRATION_USER_INVITE_VERIFY: "administrationUsersInvite/ADMINISTRATION_USER_INVITE_VERIFY",
    ADMINISTRATION_USER_INVITE_VERIFY_FAILED: "administrationUsersInvite/ADMINISTRATION_USER_INVITE_VERIFY_FAILED",
    ADMINISTRATION_USER_INVITE_VERIFY_SUCCESS: "administrationUsersInvite/ADMINISTRATION_USER_INVITE_VERIFY_SUCCESS",
    ADMINISTRATION_SET_USER_INVITE: "administrationUsersInvite/ADMINISTRATION_SET_USER_INVITE",
    ADMINISTRATION_USER_INVITE_PREVIEW: "administrationUsersInvite/ADMINISTRATION_USER_INVITE_PREVIEW",
    ADMINISTRATION_USER_INVITE_PREVIEW_FAILED: "administrationUsersInvite/ADMINISTRATION_USER_INVITE_PREVIEW_FAILED",
    ADMINISTRATION_USER_INVITE_PREVIEW_SUCCESS: "administrationUsersInvite/ADMINISTRATION_USER_INVITE_PREVIEW_SUCCESS",
    ADMINISTRATION_USER_INVITE_CONFIRM: "administrationUsersInvite/ADMINISTRATION_USER_INVITE_CONFIRM",
    ADMINISTRATION_USER_INVITE_FINISH: "administrationUsersInvite/ADMINISTRATION_USER_INVITE_FINISH",

    SET_USER_INVITATION_TO_EDIT: "administrationUsersInvite/SET_USER_INVITATION_TO_EDIT",
    SAVE_CUSTOMER_NAMES: "administrationUsersInvite/SAVE_CUSTOMER_NAMES",
};

export const INITIAL_STATE = {
    countryList: [],
    documentTypeList: [],
    idNumberMap1: [],
    idNumberMap2: null,
    defaultCountry: "",
    selectedDocument: null,
    isInvitingNewUser: false,
    existentUserInfo: null,
    groupList: [],
    roleList: [],
    encryptedDocument: null,
    confirmationParams: null,
    idTransaction: null,
    idActivity: null,
    credentialGroups: [],
    fetching: false,
    invitationData: null,
    customerNames: [],
};

export default createReducer(INITIAL_STATE, {
    [types.ADMINISTRATION_USER_INVITE_PRE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [types.ADMINISTRATION_USER_INVITE_PRE_REQUEST_SUCCESS]: (state, { data }) => ({
        ...state,
        defaultCountry: data.defaultCountry,
        countryList: data.countryList,
        documentTypeList: data.documentTypeList,
        idNumberMap1: data.idNumberMap1,
        idNumberMap2: data.idNumberMap2,
        fetching: false,
    }),
    [types.ADMINISTRATION_USER_INVITE_VERIFY]: (state, { document }) => ({
        ...state,
        selectedDocument: document,
        fetching: true,
    }),
    [types.ADMINISTRATION_USER_INVITE_VERIFY_FAILED]: (state) => ({
        ...state,
        fetching: false,
    }),
    [types.ADMINISTRATION_USER_INVITE_VERIFY_SUCCESS]: (state, { data }) => ({
        ...state,
        isInvitingNewUser: data.newUser,
        existentUserInfo: data.firstName
            ? {
                  firstName: data.firstName,
                  lastName: data.lastName,
                  mobilePhone: data.mobilePhone,
                  email: data.email,
                  userName: data.userName,
                  role: data.role,
              }
            : null,
        invitationData: data.invitationData,
        selectedDocument: data.invitationData
            ? {
                  documentCountry: data.invitationData.documentCountry,
                  documentType: data.invitationData.documentType,
              }
            : state.selectedDocument,
        environmentList: data.environmentList,
        roleList: data.roleList,
        encryptedDocument: data.document,
        fetching: false,
    }),
    [types.ADMINISTRATION_SET_USER_INVITE]: (state, { data }) => ({
        ...state,
        isInvitingNewUser: data.newUser,
        existentUserInfo: data,
    }),
    [types.ADMINISTRATION_USER_INVITE_PREVIEW_FAILED]: () => ({
        ...INITIAL_STATE,
    }),
    [types.ADMINISTRATION_USER_INVITE_PREVIEW_SUCCESS]: (
        state,
        { params, idTransaction, idActivity, credentialGroups },
    ) => ({
        ...state,
        confirmationParams: params,
        idTransaction,
        idActivity,
        credentialGroups,
    }),
    [types.ADMINISTRATION_USER_INVITE_FINISH]: () => ({
        ...INITIAL_STATE,
    }),

    [types.SET_USER_INVITATION_TO_EDIT]: (state) => ({
        ...state,
    }),
    [types.SAVE_CUSTOMER_NAMES]: (state, { names }) => ({
        ...state,
        customerNames: names,
    }),
});

export const actions = {
    userInvitePre: makeActionCreator(types.ADMINISTRATION_USER_INVITE_PRE_REQUEST),
    userInvitePreFailure: makeActionCreator(types.ADMINISTRATION_USER_INVITE_PRE_FAILURE),
    userInviteVerify: makeActionCreator(types.ADMINISTRATION_USER_INVITE_VERIFY, "document"),
    userInvitePreview: makeActionCreator(types.ADMINISTRATION_USER_INVITE_PREVIEW, "params", "formikBag"),
    userInvitePreviewFailed: makeActionCreator(types.ADMINISTRATION_USER_INVITE_PREVIEW_FAILED),
    userInviteFinish: makeActionCreator(types.ADMINISTRATION_USER_INVITE_FINISH),
    userInviteConfirm: makeActionCreator(
        types.ADMINISTRATION_USER_INVITE_CONFIRM,
        "confirmationParams",
        "credentials",
        "formikBag",
    ),
    userSetUserInfo: makeActionCreator(types.ADMINISTRATION_SET_USER_INVITE, "data"),
    setUserInvitationToEdit: makeActionCreator(types.SET_USER_INVITATION_TO_EDIT, "invitation"),
};

export const selectors = {
    getCountryList: ({ administrationUsersInvite }) => administrationUsersInvite.countryList,
    getDocumentTypeList: ({ administrationUsersInvite }) => administrationUsersInvite.documentTypeList,
    getDefaultCountry: ({ administrationUsersInvite }) => administrationUsersInvite.defaultCountry,
    getIdNumberMap1: ({ administrationUsersInvite }) => administrationUsersInvite.idNumberMap1,
    getIdNumberMap2: ({ administrationUsersInvite }) => administrationUsersInvite.idNumberMap2,
    getRoleList: ({ administrationUsersInvite }) => administrationUsersInvite.roleList,
    getEnvironmentList: ({ administrationUsersInvite }) => administrationUsersInvite.environmentList,
    getEncryptedDocument: ({ administrationUsersInvite }) => administrationUsersInvite.encryptedDocument,
    getSelectedDocument: ({ administrationUsersInvite }) => administrationUsersInvite.selectedDocument,
    getConfirmationParams: ({ administrationUsersInvite }) => administrationUsersInvite.confirmationParams,
    isInvitingNewUser: ({ administrationUsersInvite }) => administrationUsersInvite.isInvitingNewUser,
    getExistentUserInfo: ({ administrationUsersInvite }) => administrationUsersInvite.existentUserInfo,
    getIdTransaction: ({ administrationUsersInvite }) => administrationUsersInvite.idTransaction,
    getIdActivity: ({ administrationUsersInvite }) => administrationUsersInvite.idActivity,
    getCredentialGroups: ({ administrationUsersInvite }) => administrationUsersInvite.credentialGroups,
    fetching: ({ administrationUsersInvite }) => administrationUsersInvite.fetching,
    getInvitationData: ({ administrationUsersInvite }) => administrationUsersInvite.invitationData,
    getCustomerNames: ({ administrationUsersInvite }) => administrationUsersInvite.customerNames,
};
