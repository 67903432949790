import SideBarModal from "pages/_components/modal/SideBarModal";
import UpdateUserDataStepZero from "pages/updateUserData/UpdateUserDataStepZero";
import { bool, func, shape } from "prop-types";
import React, { useMemo } from "react";
import { connect } from "react-redux";
import {
    actions as updateUserDataActions,
    selectors as updateUserDataSelectors,
} from "reducers/updateUserData/updateUserData.reducer";
import * as configUtil from "util/config";
import { ENABLED_CONFIG_MONITOR_DBF } from "util/monitor/monitorDbf.utils";

const PAGE_ID = "settings.personalDataUpdateStep1";

const PersonalDataUpdateStepZeroModal = ({ dispatch, isShowUpdateUserDataStepZero, location }) => {
    const hidePersonalDataUpdateStepZero = () => {
        dispatch(updateUserDataActions.hideUpdateUserDataStepZero());
    };

    const showUpdateDataUser = useMemo(() => {
        const enabledMonitorDbf = configUtil.get(ENABLED_CONFIG_MONITOR_DBF, "0");
        return enabledMonitorDbf === "1" && isShowUpdateUserDataStepZero;
    }, [isShowUpdateUserDataStepZero]);

    return (
        <SideBarModal
            show={showUpdateDataUser}
            onClose={hidePersonalDataUpdateStepZero}
            modalId={PAGE_ID}
            title="settings.userData.update.pre.stepZero.title">
            <UpdateUserDataStepZero location={location} />
        </SideBarModal>
    );
};

PersonalDataUpdateStepZeroModal.propTypes = {
    dispatch: func,
    isShowUpdateUserDataStepZero: bool,
    location: shape({}),
};

PersonalDataUpdateStepZeroModal.defaultProps = {
    dispatch: () => {},
    isShowUpdateUserDataStepZero: false,
    location: {},
};

const mapStateToProps = (state) => ({
    isShowUpdateUserDataStepZero: updateUserDataSelectors.isShowUpdateUserDataStepZero(state),
});

export default connect(mapStateToProps)(PersonalDataUpdateStepZeroModal);
