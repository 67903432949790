import I18n from "pages/_components/I18n";
import Row from "pages/_components/Row";
import CreditCardsListItem from "pages/creditCards/ListItem";
import { arrayOf, func, shape } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actions as productActions } from "reducers/products";

class CreditCardsList extends Component {
    static propTypes = {
        creditCards: arrayOf(shape({})).isRequired,
        history: shape({}),
        dispatch: func,
    };

    static defaultProps = {
        history: {},
        dispatch: () => {},
    };

    onClickItem = (creditCard) => {
        const { history, dispatch } = this.props;
        const { idProduct, description, statusCode, number } = creditCard;
        dispatch(productActions.setSelectProduct({ idProduct, description, statusCode, number }));
        history.push(`/creditCards/${idProduct}`);
    };

    render() {
        const { creditCards } = this.props;
        return (
            <Row>
                {!creditCards.length ? (
                    <I18n id="creditCards.list.empty" />
                ) : (
                    creditCards.map((creditCard) => (
                        <Col xs={12} md={creditCards.length === 1 ? 12 : 6}>
                            <CreditCardsListItem
                                {...this.props}
                                minimumPayment={creditCard.setMinimumPayment}
                                minimumPaymentCurrency={creditCard.minimumPaymentCurrency}
                                availableBalance={creditCard.availableBalance}
                                currency={creditCard.availableBalanceCurrency}
                                cardStyle={creditCard.cardStyle}
                                icon={creditCard.franchise.toLowerCase()}
                                id={creditCard.idProduct}
                                number={creditCard.number}
                                statusCode={creditCard.statusCode}
                                shortLabel={creditCard.shortLabel}
                                blocked={creditCard.blocked}
                                isSwitchVisible={creditCard.isSwitchVisible}
                                productTypeTitle={creditCard.productType}
                                reference={`**** ${creditCard.number.slice(-4)}`}
                                hasCashBackService={creditCard.hasCashBackService}
                                hasPointsService={creditCard.hasPointsService}
                                isPrepaid={creditCard.isPrepaid}
                                franchise={creditCard.franchise.toLowerCase()}
                                {...creditCard}
                                idProduct={creditCard.idProduct}
                                payButton
                                isList
                                onClick={() => {
                                    this.onClickItem(creditCard);
                                }}
                                creditCardData={creditCard}
                                isIssuerAvaibleForPoints={creditCard.isIssuerAvaibleForPoints}
                                isIssuerAvaibleForCashback={creditCard.isIssuerAvaibleForCashback}
                                isFetchingPoints={creditCard.isFetchingPoints || false}
                            />
                        </Col>
                    ))
                )}
            </Row>
        );
    }
}

export default withRouter(connect()(CreditCardsList));
