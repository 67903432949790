import classNames from "classnames";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import FieldError from "pages/_components/fields/FieldError";
import { bool, func, number, oneOfType, string } from "prop-types";
import React, { useEffect, useState } from "react";

const BanescoCheckbox = ({
    name,
    defaultChecked = false,
    onChange,
    error = "",
    labelKey = null,
    readOnly = false,
    block = true,
    formGroup = true,
    noMarginBottom = false,
    className,
    ...props
}) => {
    const [isChecked, setIsChecked] = useState(defaultChecked);

    useEffect(() => {
        setIsChecked(defaultChecked);
    }, [defaultChecked]);

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
        if (onChange) {
            onChange(e);
        }
    };

    return (
        <Box onClick={(event) => event.stopPropagation()}>
            <Box
                className={classNames(
                    "c-control c-control--has-icon c-control--checkbox display-flex gap-3 align-items-center",
                    className,
                    {
                        "has-error": error,
                        "c-control-block": block,
                        "form-group": formGroup,
                        "form-group-margin-bottom": !noMarginBottom,
                    },
                )}>
                <input
                    id={name}
                    className="c-control-input"
                    type="checkbox"
                    checked={isChecked}
                    readOnly={readOnly}
                    onChange={handleCheckboxChange}
                    {...props}
                />
                <label className="c-control-label m-0" htmlFor={name}>
                    <Box className="c-control-icons">
                        <Box className="c-control-mark">
                            <Image src="images/icons/checkBox.svg" className="svg-icon svg-caret" />
                        </Box>
                    </Box>
                    {labelKey && (
                        <Box className="form-group-text">
                            <Text labelKey={labelKey} />
                        </Box>
                    )}
                </label>
                {error && <FieldError error={error} />}
            </Box>
        </Box>
    );
};

BanescoCheckbox.propTypes = {
    name: oneOfType([number, string]).isRequired,
    defaultChecked: bool,
    onChange: func,
    error: string,
    labelKey: string,
    readOnly: bool,
    block: bool,
    formGroup: bool,
    noMarginBottom: bool,
    className: string,
};

BanescoCheckbox.defaultProps = {
    defaultChecked: false,
    onChange: () => {},
    error: "",
    labelKey: null,
    readOnly: false,
    block: true,
    formGroup: true,
    noMarginBottom: false,
    className: null,
};

export default BanescoCheckbox;
