/* eslint-disable*/
import { useEffect, useState } from "react";
import moment from "moment";
import { filterData } from "util/array";
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const PANAMA_COUNTRY_CODE = "507";
export const useMetaData = (preDataForm, idActivity) => {
    const [metadata, setMetadata] = useState({
        draftsEnabled: false,
        templatesEnabled: false,
        schedulable: true,
        programable: true,
        idActivity,
    });
    useEffect(() => {
        const { nonWorkingDays, enabledWeekDays, firstWorkingDate = new Date(), maxDaysToSchedule } = preDataForm;
        setMetadata((state) => ({
            ...state,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
        }));
    }, [preDataForm]);
    return [metadata];
};

export const formatAccount = (accountWally) => (accountWally?.number ? `****${accountWally.number.slice(-4)}` : "");

export const getDefaultValueDate = (metadata) => moment(metadata.firstWorkingDate, "YYYY-MM-DDTHH:mm:ss.000Z");

export const formatPhone = (phone) => {
    const value = phone
        .replaceAll("+", "")
        .replaceAll(/ /g, "")
        .replaceAll("-", "")
        .replaceAll("(", "")
        .replaceAll(")", "");

    if (!isNaN(parseFloat(value)) && isFinite(value)) {
        return value;
    } else {
        return "";
    }
};

export const useFilterBeneficiaries = (beneficiariesPeople) => {
    const [listPeople, setListPeople] = useState();
    useEffect(() => {
        setListPeople(beneficiariesPeople);
    }, [beneficiariesPeople]);
    const filterBeneficiaries = (value, beneficiaries, keys) => {
        if (value !== "") {
            const data = filterData(beneficiaries, keys, value);
            setListPeople(data);
        } else {
            setListPeople(beneficiaries);
        }
    };
    return [listPeople, filterBeneficiaries];
};

export const extractCountryAndNumber = (phoneNumber) => {
    const parsedNumber = parsePhoneNumberFromString(phoneNumber);
    return { countryCode: parsedNumber?.countryCallingCode, phoneNumber: parsedNumber?.nationalNumber};
};

export const extractCountryCodePanama = (phoneNumber) => {

    const phoneNumberClean = formatPhone(phoneNumber)
    if(!phoneNumberClean || phoneNumberClean.length < 11) {
        return undefined
    }
    const countryCode = phoneNumberClean.substring(0, 3);
    const phoneRef = phoneNumberClean.substring(3);

    if(countryCode !== PANAMA_COUNTRY_CODE) {
        return undefined
    }

    return {countryCode: countryCode, phoneNumber: phoneRef}

}



export const secondCleanPhoneNumberKuara = (phone,countryCodeToValidate) => {
    const value = formatPhone(phone);
    if (!value) {
      return undefined;
    }
    if (value.length !== 11) {
      return undefined;
    }
    const countryCode = value.substring(0, 3);
    if(countryCode!==countryCodeToValidate){
        return undefined;
    }
    const phoneNumber = value.substring(4);
    return {
      countryCode,
      phoneNumber,
    };
  };
