import { Form, withFormik } from "formik";
import Box from "pages/_components/Box/Box";
import Button from "pages/_components/Button/Button";
import Image from "pages/_components/Image";
import PageLoading from "pages/_components/PageLoading";
import Sticker from "pages/_components/Sticker";
import Text from "pages/_components/Text";
import CredentialTokenComponent from "pages/forms/_components/credential/CredentialTokenComponent";
import { bool, func, string } from "prop-types";
import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import {
    selectors as updateUserDataSelectors,
    actions as updateUserDataActions,
} from "reducers/updateUserData/updateUserData.reducer";
import { compose } from "redux";
import { flattenArray, removeDuplicateItems } from "util/array";
import * as i18n from "util/i18n";
import * as Yup from "yup";

const ACTIVITY_ID = "settings.userData.update.send";
const UpdateUserDataStepZero = ({
    dispatch,
    setFieldValue,
    isSubmitting,
    hasUpdatesProcessing,
    isNeedingUpdate,
    lastUpdDt,
    isFetchingData,
}) => {
    useEffect(() => {
        dispatch(updateUserDataActions.getCredentialGroupsRequest({ activityId: ACTIVITY_ID }));
    }, []);

    const hideUpdateUserDataStepZero = () => {
        dispatch(updateUserDataActions.hideUpdateUserDataStepZero());
    };

    const getStatus = (state) => {
        let statusClass = "";
        switch (state) {
            case "updated":
                statusClass = "success";
                break;
            case "inReview":
                statusClass = "warning";
                break;
            case "outdated":
                statusClass = "error";
                break;
            default:
                break;
        }
        return statusClass;
    };

    const calculateStatus = useMemo(() => {
        if (hasUpdatesProcessing) {
            return "inReview";
        }
        return isNeedingUpdate ? "outdated" : "updated";
    }, [hasUpdatesProcessing, isNeedingUpdate]);

    return (
        <PageLoading loading={isSubmitting || isFetchingData}>
            <Box display="flex" column alignX="between" className="scrollable-content px-0" fullWidth fullHeight>
                <Form className="full-height">
                    <Box display="flex" column alignX="between" alignY="center" fullWidth fullHeight>
                        <Text
                            key="text"
                            component="p"
                            className="m-0"
                            color="heading"
                            align="center"
                            labelKey="token.drawer.text"
                        />
                        <Box>
                            <Box display="flex" column className="mb-8" gap="3">
                                <Box display="flex">
                                    <Image key="src" src="images/icons/userEditPencil.svg" />
                                </Box>
                            </Box>

                            <Box display="flex">
                                <Text component="label" className="m-0" regular addColon>
                                    {i18n.get("global.status.label")}
                                </Text>
                                <Sticker
                                    labelKey={`global.status.${calculateStatus}.label`}
                                    uppercase={false}
                                    bold
                                    textSize="7"
                                    status={getStatus(calculateStatus)}
                                />
                            </Box>
                            <Box display="flex">
                                <Text component="label" className="m-0" regular addColon>
                                    {i18n.get("global.lastUpdate.label")}
                                </Text>
                                <Text size="5" bold color="heading-color">
                                    {lastUpdDt}
                                </Text>
                            </Box>
                        </Box>

                        {!hasUpdatesProcessing && (
                            <Box display="flex" column alignX="flex-end" fullWidth>
                                <CredentialTokenComponent
                                    credentials={["otp"]}
                                    onChangeToken={(tokenCode) => {
                                        if (setFieldValue) {
                                            setFieldValue("otp", tokenCode);
                                        }
                                    }}
                                    relativeStyleError
                                />
                            </Box>
                        )}
                        <Box className="pb-9 pb-md-12" fullWidth>
                            <Text
                                key="text"
                                component="p"
                                size="6"
                                className="mb-5"
                                labelKey="token.drawer.protection.text"
                            />

                            {!hasUpdatesProcessing && (
                                <Button
                                    block
                                    type="submit"
                                    label="global.confirm"
                                    bsStyle="primary"
                                    loading={isSubmitting || isFetchingData}
                                    disabled={isSubmitting || isFetchingData}
                                />
                            )}
                            <Button
                                block
                                type="button"
                                bsStyle="outline"
                                onClick={hideUpdateUserDataStepZero}
                                disabled={isSubmitting || isFetchingData}
                                label="global.cancel"
                            />
                        </Box>
                    </Box>
                </Form>
            </Box>
        </PageLoading>
    );
};

UpdateUserDataStepZero.propTypes = {
    dispatch: func,
    setFieldValue: func.isRequired,
    isFetchingData: bool,
    isSubmitting: bool,
    lastUpdDt: string.isRequired,
    isNeedingUpdate: bool.isRequired,
    hasUpdatesProcessing: bool.isRequired,
};

UpdateUserDataStepZero.defaultProps = {
    dispatch: () => {},
    isSubmitting: false,
    isFetchingData: false,
};

const mapStateToProps = (state) => ({
    isFetchingData: updateUserDataSelectors.isFetchingData(state),
    isSubmitting: updateUserDataSelectors.isSubmitting(state),
    credentials: compose(
        (array) => array.filter((item) => item !== "accessToken"),
        removeDuplicateItems,
        flattenArray,
        (array) => array.map(({ credentials }) => credentials),
    )(updateUserDataSelectors.getCredentialGroups(state)),
    lastUpdDt: updateUserDataSelectors.getLastUpdateDate(state),
    isNeedingUpdate: updateUserDataSelectors.isNeedingUpdate(state),
    hasUpdatesProcessing: updateUserDataSelectors.hasUpdatesProcessing(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            otp: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18n.get(`${ACTIVITY_ID}.otp.required`)),
            }),
        handleSubmit: ({ otp }, formikBag) => {
            const { location } = formikBag.props;
            formikBag.props.dispatch(updateUserDataActions.updateUserDataRequest(otp, formikBag, location));
        },
    }),
)(UpdateUserDataStepZero);
