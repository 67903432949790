import { call, put, select, takeLatest } from "redux-saga/effects";
import { replace } from "react-router-redux";
import queryString from "query-string";

import * as creditCards from "middleware/creditCards";
import * as i18n from "util/i18n";
import { types, actions, selectors } from "reducers/creditCard";
import { actions as notificationActions } from "reducers/notification";
import * as creditCardsCache from "util/creditCardsCache";

const sagas = [takeLatest(types.LIST_REQUEST, listRequest)];

export default sagas;

function* listRequest({ path }) {
    // valido si la data del cache es valida
    let invalidateCache = false;
    const responseCache = yield call(creditCards.creditCardCacheIsInvalidatedRequest);
    if (responseCache.type !== "W") {
        invalidateCache = responseCache.data.data.invalidateCreditCardCache;
        if (invalidateCache) {
            yield put(actions.invalidateCache());
        }
    }

    if (!invalidateCache && creditCardsCache.isCreditCardListCacheStatusOk()) {
        const responseCards = yield select(selectors.getList);
        yield call(redirectFlow, responseCards, path);
    } else {
        const response = yield call(creditCards.listRequest);

        if (response.type === "W") {
            yield put(actions.listFailure());
            yield put(
                notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                    "creditCardsPayment",
                    "creditCardsList",
                ]),
            );
        } else {
            yield call(creditCards.revalidateCreditCardCacheRequest);
            const { creditCards: responseCards } = response.data.data;
            yield call(redirectFlow, responseCards, path);
        }
    }
}

function* redirectFlow(responseCards, path) {
    yield put(actions.hideOptions());

    yield put(actions.listSuccess(responseCards));

    const visibleCards = responseCards.filter((card) => card.isVisibleInWidget);

    if (visibleCards.length === 1 && path) {
        const { idProduct } = visibleCards[0];

        // listRequest method is reused on credit cards payment which should redirect to a dynamic
        // form instead of credit card detail
        if (path.startsWith("/form")) {
            yield put(
                replace({
                    pathname: path,
                    search: queryString.stringify({ creditCard: idProduct }),
                }),
            );
        }
    }
}
