import { Field, Form, withFormik } from "formik";
import moment from "moment";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import DateField from "pages/_components/fields/DateField";
import Document from "pages/_components/fields/Document";
import Selector from "pages/_components/fields/formik/Selector";
import SwitchField from "pages/_components/fields/formik/SwitchField";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import {
    actions as updateUserDataActions,
    selectors as updateUserDataSelectors,
} from "reducers/updateUserData/updateUserData.reducer";
import { compose } from "redux";
import * as dateUtils from "util/date";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import { useLoadingGlobalProvider } from "providers/LoadingGlobalProvider";
import useCustomState from "./hooks/PersonalDataUpdateStep1Hook";

const FORM_ID = "settings.personalDataUpdateStep1";

const PersonalDataUpdateStep1 = ({
    isSubmitting,
    isDesktop,
    exchangeToken,
    preDataStep1,
    dispatch,
    isFetching,
    currentUserData,
    isDisabledStep1,
    setFieldValue,
}) => {
    const { customState, setCustomState } = useCustomState();
    const { section1, section2, section3, selectors, userNationality } = customState;
    // Section 1
    const { userFullName, userNationalityId } = section1;

    // Section 2
    const { birthDate, birthDateAsString, educationLevelId, maritalStatusId, professionOccupationId } = section2;

    // Section 3
    const {
        documentUserValue,
        issuedDate,
        issuedDateAsString,
        expirationDate,
        expirationDateAsString,
        minExpirationDate,
    } = section3;

    // Selectors
    const {
        maritalStatusList,
        educationLevelList,
        professionOccupationList,
        countryList,
        documentTypeList,
        idNumberMap1,
        idNumberMap2,
    } = selectors;

    const { setLoading } = useLoadingGlobalProvider();
    const [updatedMinExpirationDate, setUpdatedMinExpirationDate] = useState(minExpirationDate);

    useEffect(() => {
        setLoading(isFetching);
    }, [isFetching]);

    useEffect(() => {
        dispatch(updateUserDataActions.preFormStep1(exchangeToken));
    }, [dispatch]);

    useEffect(() => {
        if (currentUserData) {
            setCustomState("init", currentUserData);
        }
    }, [currentUserData]);

    useEffect(() => {
        if (preDataStep1) {
            setCustomState("preDataStep1", preDataStep1);
        }
    }, [preDataStep1]);

    useEffect(() => {
        if (customState) {
            setFieldValue("userFullName", userFullName);
            setFieldValue("birthDate", birthDate);
            setFieldValue("educationLevel", educationLevelId || "");
            setFieldValue("maritalStatus", maritalStatusId);
            setFieldValue("professionOccupation", professionOccupationId);
            setFieldValue("documentUser", documentUserValue);
            setFieldValue("issuedDate", issuedDate);
            if (expirationDate) {
                setFieldValue("expirationDate", expirationDate);
            }
        }
    }, [customState]);

    useEffect(() => {
        if (userNationalityId && countryList && countryList.length > 0) {
            const country = countryList.find(({ id }) => userNationalityId === id) || {};
            const countryName = country?.name;
            setCustomState("user_nationality", countryName);
            setFieldValue("userNationality", countryName);
        }
    }, [userNationalityId, countryList]);

    const enableStep1 = () => {
        dispatch(updateUserDataActions.enableStep1(!isDisabledStep1));
    };

    const handleChangeMaritalStatus = (maritalStId) => {
        setCustomState("marital_status", maritalStId);
        setFieldValue("maritalStatus", maritalStId);
    };

    const handleChangeEducationLevel = (edLevelId) => {
        setCustomState("education_level", edLevelId);
        setFieldValue("educationLevel", edLevelId);
    };

    const handleChangeProfession = (professionId) => {
        setCustomState("profession_occupation", professionId);
        setFieldValue("professionOccupation", professionId);
    };

    const handleChangeIssuedDate = (selectedIssuedDate) => {
        setCustomState("issued_date", selectedIssuedDate);
        setFieldValue("issuedDate", selectedIssuedDate.toDate());

        const newExpirationDate = moment(new Date());
        setCustomState("expiration_date", newExpirationDate);
        setFieldValue("expirationDate", newExpirationDate);
        setUpdatedMinExpirationDate(newExpirationDate);
    };

    const handleChangeExpirationDate = (selectedExpirationDate) => {
        setCustomState("expiration_date", selectedExpirationDate);
        setFieldValue("expirationDate", selectedExpirationDate.toDate());
    };

    const handleCancel = () => {
        dispatch(updateUserDataActions.enableStep1(false));
        dispatch(push("/desktop"));
    };

    const setDocumentUserValue = (e) => {
        setCustomState("document_user", e);
    };

    return (
        <>
            <Notification scopeToShow="personalDataUpdate" />
            <Form className="mx-n-5">
                <Box
                    display="flex"
                    column
                    alignY="flex-start"
                    alignX="center"
                    background="component-background"
                    borderRadius="default"
                    className="px-5 px-md-12 py-7 py-md-9 mb-3 mb-md-7">
                    <Box display="inline-block" className="mb-2 mb-md-4">
                        <Text labelKey="settings.personalDataUpdateStep1.name" color="heading" bold addColon />
                        <Text color="heading" uppercase name="userFullName">
                            {userFullName}
                        </Text>
                    </Box>
                    <Box display="inline-block" className="mb-2 mb-md-4">
                        <Text labelKey="settings.personalDataUpdateStep1.nationality" color="heading" bold addColon />
                        <Text color="heading" name="userNationality">
                            {userNationality}
                        </Text>
                    </Box>

                    <Box display="inline-block">
                        <Text
                            labelKey="settings.personalDataUpdateStep1.birthDate.label"
                            color="heading"
                            bold
                            addColon
                        />
                        <Text color="heading" name="birthDate">
                            {birthDateAsString}
                        </Text>
                    </Box>
                </Box>

                <Box
                    display="flex"
                    column
                    fullWidth
                    background="component-background"
                    className="px-5 px-md-12 pt-7 pb-10 pb-md-11 mb-3 mb-md-7"
                    borderRadius="default">
                    <Box className="mb-7 mb-md-8">
                        <Field
                            component={SwitchField}
                            idForm={FORM_ID}
                            name="modifyDataControl"
                            onChange={enableStep1}
                        />
                    </Box>

                    <Row>
                        <Col xs={6}>
                            <Field
                                idForm={FORM_ID}
                                name="maritalStatus"
                                component={Selector}
                                options={maritalStatusList}
                                value={maritalStatusId}
                                valueKey="value"
                                labelKey="label"
                                labelNoMarginTop
                                disabled={isDisabledStep1}
                                handleChange={handleChangeMaritalStatus}
                            />
                        </Col>
                        <Col xs={6}>
                            <Field
                                idForm={FORM_ID}
                                name="educationLevel"
                                component={Selector}
                                options={educationLevelList}
                                value={educationLevelId || ""}
                                labelNoMarginTop
                                disabled={isDisabledStep1}
                                valueKey="value"
                                labelKey="label"
                                handleChange={handleChangeEducationLevel}
                            />
                        </Col>
                        <Col xs={12}>
                            <Field
                                idForm={FORM_ID}
                                name="professionOccupation"
                                component={Selector}
                                options={professionOccupationList}
                                value={professionOccupationId}
                                labelNoMarginTop
                                disabled={isDisabledStep1}
                                valueKey="value"
                                labelKey="label"
                                handleChange={handleChangeProfession}
                            />
                        </Col>
                    </Row>
                </Box>
                <Box
                    display="flex"
                    column
                    fullWidth
                    background="component-background"
                    className="px-5 px-md-12 pt-9 pb-10 pb-md-11 mb-5 mb-md-8"
                    borderRadius="default">
                    <Row {...(!isDesktop && { gapY: "0" })}>
                        <Col xs={12} lg={6}>
                            <Field
                                countries={countryList}
                                documentCountryMap={documentTypeList}
                                idNumberMap={idNumberMap1}
                                idNumber2Map={idNumberMap2}
                                value={documentUserValue}
                                component={Document}
                                name="documentUser"
                                reducedColumnGap
                                {...(!isDesktop && { idNumberStyle2: true })}
                                gapX="7"
                                spacingInputs="mb-5 mb-md-7"
                                labelNoMarginTop
                                disabled={isDisabledStep1}
                                disabledAll={isDisabledStep1}
                                setDocumentUserValue={setDocumentUserValue}
                            />
                        </Col>
                        <Col xs={12} lg={6}>
                            <Row gapX="0">
                                <Field
                                    component={DateField}
                                    idForm={FORM_ID}
                                    name="issuedDate"
                                    minDate={moment().add(-30, "years")}
                                    maxDate={moment(new Date())}
                                    dateFormat={dateUtils.FRIENDY_DATE_FORMAT(i18n.getLang())}
                                    idField="issuedDate.date"
                                    value={issuedDateAsString}
                                    {...(isDesktop && { labelNoMarginTop: true })}
                                    disabled={isDisabledStep1}
                                    onChange={handleChangeIssuedDate}
                                />
                                <Field
                                    component={DateField}
                                    idForm={FORM_ID}
                                    name="expirationDate"
                                    minDate={updatedMinExpirationDate}
                                    maxDate={moment().add(30, "years")}
                                    dateFormat={dateUtils.FRIENDY_DATE_FORMAT(i18n.getLang())}
                                    idField="expirationDate.date"
                                    value={expirationDateAsString}
                                    labelNoMarginTop
                                    disabled={isDisabledStep1}
                                    onChange={handleChangeExpirationDate}
                                />
                            </Row>
                        </Col>
                    </Row>
                </Box>
                <Row className="px-5 px-md-0" {...(!isDesktop && { gapY: "3" })}>
                    <Col xs={12} md={4} mdOffset={2}>
                        <Button label="global.cancel" bsStyle="outline" onClick={handleCancel} block />
                    </Col>
                    <Col xs={12} md={4} {...(!isDesktop && { className: "grid-reversed" })}>
                        <Button
                            bsStyle="primary"
                            label="global.continue"
                            loading={isSubmitting || isFetching}
                            type="submit"
                            block
                        />
                    </Col>
                </Row>
            </Form>
        </>
    );
};

PersonalDataUpdateStep1.propTypes = {
    isFetching: bool,
    isSubmitting: bool.isRequired,
    isDesktop: bool.isRequired,
    dispatch: func.isRequired,
    exchangeToken: string.isRequired,
    preDataStep1: shape({}),
    currentUserData: shape({}).isRequired,
    isDisabledStep1: bool,
    values: shape({}).isRequired,
    setFieldValue: func.isRequired,
};

PersonalDataUpdateStep1.defaultProps = {
    preDataStep1: null,
    isFetching: true,
    isDisabledStep1: true,
};

const mapStateToProps = (state) => ({
    preDataStep1: updateUserDataSelectors.getPreDataStep1(state),
    exchangeToken: updateUserDataSelectors.getExchangeToken(state),
    isFetching: updateUserDataSelectors.isFetching(state),
    currentUserData: updateUserDataSelectors.getCurrentUserData(state),
    isDisabledStep1: updateUserDataSelectors.isDisabledStep1(state),
});

export default compose(
    connect(mapStateToProps),
    resizableRoute,
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: () => ({
            birthDate: "",
            maritalStatus: "",
            educationLevel: "",
            professionOccupation: "",
            documentUser: "",
            issuedDate: "",
            expirationDate: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                birthDate: Yup.string().required(i18n.get(`${FORM_ID}.birthDate.required`)),
                maritalStatus: Yup.string().required(i18n.get(`${FORM_ID}.maritalStatus.required`)),
                educationLevel: Yup.string().required(i18n.get(`${FORM_ID}.educationLevel.required`)),
                professionOccupation: Yup.string().required(i18n.get(`${FORM_ID}.professionOccupation.required`)),
                issuedDate: Yup.string().required(i18n.get(`${FORM_ID}.issuedDate.required`)),
                expirationDate: Yup.string()
                    .typeError(i18n.get(`${FORM_ID}.expirationDate.required`))
                    .required(i18n.get(`${FORM_ID}.expirationDate.required`)),
                documentUser: Yup.object().shape({
                    documentCountry: Yup.string().required(i18n.get(`${FORM_ID}.documentUser.required`)),
                    documentType: Yup.string().required(i18n.get(`${FORM_ID}.documentUser.required`)),
                    documentNumber1: Yup.string().required(i18n.get(`${FORM_ID}.documentUser.required`)),
                    documentNumber2: Yup.string().when(["documentCountry", "documentType"], {
                        is: (documentCountry, documentType) => documentCountry === "PA" && documentType === "CIP",
                        then: Yup.string().required(i18n.get(`${FORM_ID}.documentUser.required`)),
                    }),
                    idNumber1: Yup.string().when(["documentCountry", "documentType"], {
                        is: (documentCountry, documentType) =>
                            documentCountry === "PA" || (documentCountry === "VE" && documentType === "CED"),
                        then: Yup.string().required(i18n.get(`${FORM_ID}.documentUser.required`)),
                    }),
                    idNumber2: Yup.string().when("documentCountry", {
                        is: "PA",
                        then: Yup.string().required(i18n.get(`${FORM_ID}.documentUser.required`)),
                    }),
                }),
            }),
        handleSubmit: (values, formikBag) => {
            formikBag.props.dispatch(updateUserDataActions.saveForm(values, 1, formikBag));
        },
    }),
)(PersonalDataUpdateStep1);
