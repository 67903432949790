import classNames from "classnames";
import Statement from "pages/loans/_components/Statement";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Container from "pages/_components/Container";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import GridTable from "pages/_components/GridTable/GridTable";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import ProductList from "pages/_components/product/List";
import Sticker from "pages/_components/Sticker";
import Text from "pages/_components/Text";
import Tooltip from "pages/_components/Tooltip";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { selectors as loansSelectors } from "reducers/loans";
import { selectors as loginSelectors } from "reducers/login";
import * as configUtil from "util/config";
import * as i18nUtils from "util/i18n";

class Statements extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        fees: arrayOf(shape({})).isRequired,
        isDesktop: bool.isRequired,
        isFeePaid: bool.isRequired,
        isFetchingFees: bool.isRequired,
        isTablet: bool.isRequired,
        loan: shape({}),
        paymentPlan: bool,
        noFiltersDataText: string,
    };

    static defaultProps = {
        loan: null,
        paymentPlan: false,
        noFiltersDataText: "loan.detail.statement.noFees",
    };

    state = {
        data: [],
        feeDetail: null,
        pageNumber: 1,
        showFeeDetail: false,
    };

    getFeesByType = (isFeePaid) => {
        const { fees } = this.props;

        return fees.filter((f) => f.paid === isFeePaid);
    };

    fetchMoreFees = () => {
        const { pageNumber } = this.state;
        const { isFeePaid } = this.props;
        const feesPerPage = configUtil.getInteger("loan.detail.statements.feesPerPage");
        this.setState({
            data: this.getFeesByType(isFeePaid).slice(0, (pageNumber + 1) * feesPerPage),
            pageNumber: pageNumber + 1,
        });
    };

    handleClick = () => {
        this.fetchMoreFees();
    };

    handleFeeClick = (fee) => {
        this.setState({ feeDetail: fee });
        this.setState({ showFeeDetail: true });
    };

    handleCloseModal = () => {
        this.setState({ showFeeDetail: false });
    };

    renderItem = (fee, index) => {
        const { isDesktop, isTablet, paymentPlan, isFeePaid } = this.props;
        return (
            <GridTable.Container
                className={classNames("product-data", { "gapX-3": isDesktop, "min-height-10-i": !isDesktop })}
                columnsTemplate={isDesktop ? `repeat(${this.getLastColumnNumber()}, 1fr)` : "auto 1fr 1fr 1.5rem"}
                rows={1}
                key={fee.idStatement}
                {...(!isDesktop &&
                    !isTablet && {
                        onClick: () => this.handleFeeClick(fee),
                    })}>
                <Statement
                    isDesktop={isDesktop}
                    isTablet={isTablet}
                    fee={fee}
                    isFeePaid={isFeePaid}
                    index={index + 1}
                    paymentPlan={paymentPlan}
                />
            </GridTable.Container>
        );
    };

    getLastColumnNumber = () => {
        const { isDesktop, isFeePaid } = this.props;
        if (!isDesktop) {
            return "3";
        }
        if (isFeePaid) {
            return "8";
        }
        return "9";
    };

    renderList = (list, renderLoadMore) => {
        const { isDesktop, paymentPlan, isFeePaid } = this.props;

        const isEmpty = list.length === 0;

        const feeLabel = paymentPlan
            ? "loan.detail.statement.header.feeByDueDate"
            : "loan.detail.statement.header.feeByPaidDate";

        const lastColumnNumber = this.getLastColumnNumber();
        return (
            <Container className="container--layout scrollable movements-container">
                <GridTable>
                    <GridTable.Header>
                        <GridTable.Container
                            columnsTemplate={isDesktop ? `repeat(${lastColumnNumber}, 1fr)` : "auto .5fr 1fr 1.5rem"}>
                            <GridTable.Data overflow={null} columnStart="1" alignX="flex-Start" inHeader>
                                <Text labelKey={isDesktop ? "loan.detail.statement.header.fee" : feeLabel} />
                            </GridTable.Data>
                            {isDesktop && (
                                <>
                                    <GridTable.Data overflow={null} columnStart="2" alignX="center" inHeader>
                                        <Text
                                            labelKey={
                                                isFeePaid
                                                    ? "loan.detail.statement.header.paidDate"
                                                    : "loan.detail.statement.header.dueDate"
                                            }
                                            align="center"
                                        />
                                    </GridTable.Data>
                                    <GridTable.Data overflow={null} columnStart="3" alignX="flex-end" inHeader>
                                        <Text labelKey="loan.detail.statement.header.capital" />
                                    </GridTable.Data>
                                    <GridTable.Data overflow={null} columnStart="4" alignX="flex-end" inHeader>
                                        <Text labelKey="loan.detail.statement.header.interest" />
                                    </GridTable.Data>
                                    <GridTable.Data overflow={null} columnStart="5" alignX="flex-end" inHeader>
                                        <Box display="flex" alignX="flex-end" wrap>
                                            <Text labelKey="loan.detail.statement.header.mora.part1" />
                                            <Text labelKey="loan.detail.statement.header.mora.part2" />
                                        </Box>
                                    </GridTable.Data>
                                    <GridTable.Data overflow={null} columnStart="6" alignX="flex-end" inHeader>
                                        <Text labelKey="loan.detail.statement.header.feci" />
                                    </GridTable.Data>
                                    <GridTable.Data overflow={null} columnStart="7" alignX="flex-end" inHeader>
                                        <Text labelKey="loan.detail.statement.header.otherCharges" />
                                        <Tooltip
                                            position="bottom-right"
                                            text="loan.detail.statement.header.otherCharges.info"
                                            className="mr-0"
                                        />
                                    </GridTable.Data>
                                    {!isFeePaid && (
                                        <GridTable.Data overflow={null} columnStart="8" alignX="flex-end" inHeader>
                                            <Text labelKey="loan.detail.statement.header.initialAmount" />
                                        </GridTable.Data>
                                    )}
                                </>
                            )}
                            <GridTable.Data overflow={null} columnStart={lastColumnNumber} alignX="flex-end" inHeader>
                                <Text
                                    labelKey={
                                        !isFeePaid
                                            ? "loan.detail.statement.header.owedAmount"
                                            : "loan.detail.statement.header.amount"
                                    }
                                />
                                {!isFeePaid && (
                                    <Tooltip
                                        position="bottom-right"
                                        text="loan.detail.statement.header.owedAmount.info"
                                        className="mr-0"
                                    />
                                )}
                            </GridTable.Data>
                        </GridTable.Container>
                    </GridTable.Header>
                    <GridTable.Body>
                        {list}
                        {!isEmpty && renderLoadMore()}
                    </GridTable.Body>
                </GridTable>
            </Container>
        );
    };

    renderModal = () => {
        const { isFeePaid, paymentPlan } = this.props;
        const { feeDetail, showFeeDetail } = this.state;
        return (
            <Modal aria-labelledby="modalTitleID" aria-modal="true" show={showFeeDetail} className="drawer">
                <div className="modal-container remove-padding">
                    <Head
                        onBack={this.handleCloseModal}
                        title={isFeePaid ? "loans.statements.details.paid" : "loans.statements.details.to.pay"}
                        hideNavbarInMobile
                    />
                    <MainContainer background="menu-background">
                        <Box
                            background="component-background"
                            borderRadius="default"
                            className="mt-5 pt-0 pb-7 px-5 mb-9">
                            <Box
                                display="flex"
                                alignY="center"
                                className="py-6 mb-8"
                                border="bottom-background-divider-1">
                                <Text size="3" color="heading" bold>
                                    {i18nUtils.get("loan.detail.statement.header.fee")}: {feeDetail?.idFee}
                                </Text>
                                {feeDetail?.expired && paymentPlan && (
                                    <Sticker
                                        status="error"
                                        labelKey="loan.detail.statement.field.expired"
                                        className="ml-3"
                                    />
                                )}
                                {!feeDetail?.expired && paymentPlan && (
                                    <Sticker
                                        status="success"
                                        labelKey="loan.detail.statement.field.valid"
                                        className="ml-3"
                                    />
                                )}
                            </Box>
                            <Box display="flex" alignY="center" alignX="between" className="mb-3">
                                <Text
                                    size="3"
                                    color="heading"
                                    labelKey="loan.detail.statement.header.paidDate"
                                    addColon
                                    bold
                                />

                                <FormattedDate size="3" color="heading" date={feeDetail?.dueDate} />
                            </Box>
                            <Box display="flex" alignY="center" alignX="between" className="mb-3">
                                <Text
                                    size="3"
                                    color="heading"
                                    labelKey="loan.detail.statement.header.capital"
                                    addColon
                                    bold
                                />

                                <FormattedAmount
                                    size="3"
                                    className="data-amount justify-content-end"
                                    quantity={feeDetail?.principalBalance}
                                    noCurrency
                                />
                            </Box>
                            <Box display="flex" alignY="center" alignX="between" className="mb-3">
                                <Text
                                    size="3"
                                    color="heading"
                                    labelKey="loan.detail.statement.header.interest"
                                    addColon
                                    bold
                                />
                                <FormattedAmount
                                    size="3"
                                    className="data-amount justify-content-end"
                                    quantity={feeDetail?.interest}
                                    noCurrency
                                />
                            </Box>
                            <Box display="flex" alignY="center" alignX="between" className="mb-3">
                                <Text
                                    size="3"
                                    color="heading"
                                    labelKey="loan.detail.statement.header.mora"
                                    addColon
                                    bold
                                />
                                <FormattedAmount
                                    size="3"
                                    className="data-amount justify-content-end"
                                    quantity={feeDetail?.penaltyInterest}
                                    noCurrency
                                />
                            </Box>
                            <Box display="flex" alignY="center" alignX="between" className="mb-3">
                                <Text
                                    size="3"
                                    color="heading"
                                    labelKey="loan.detail.statement.header.feci"
                                    addColon
                                    uppercase
                                    bold
                                />
                                <FormattedAmount
                                    size="3"
                                    className="data-amount justify-content-end"
                                    quantity={feeDetail?.feci}
                                    noCurrency
                                />
                            </Box>
                            <Box display="flex" alignY="center" alignX="between" className="mb-3">
                                <Box>
                                    <Text
                                        size="3"
                                        color="heading"
                                        labelKey="loan.detail.statement.header.otherCharges"
                                        addColon
                                        uppercase
                                        bold
                                    />
                                    <Tooltip
                                        position="bottom-centered"
                                        text="loan.detail.statement.header.otherCharges.info"
                                    />
                                </Box>
                                <FormattedAmount
                                    size="3"
                                    className="data-amount justify-content-end"
                                    quantity={feeDetail?.overdraft}
                                    noCurrency
                                />
                            </Box>
                            <Box display="flex" alignY="center" alignX="between" className="mb-3">
                                <Text
                                    size="3"
                                    color="heading"
                                    labelKey="loan.detail.statement.header.amount"
                                    addColon
                                    bold
                                />
                                <FormattedAmount
                                    size="3"
                                    className="data-amount justify-content-end"
                                    quantity={feeDetail?.amount}
                                    noCurrency
                                />
                            </Box>
                            <Box display="flex" alignY="center" alignX="between" className="mb-3">
                                <Text
                                    size="3"
                                    color="heading"
                                    labelKey={
                                        isFeePaid
                                            ? "loan.detail.statement.header.totalPaid"
                                            : "loan.detail.statement.header.owedAmount"
                                    }
                                    addColon
                                    bold
                                />
                                <FormattedAmount
                                    size="3"
                                    className="data-amount justify-content-end"
                                    quantity={(isFeePaid ? feeDetail.dueBalancePaid : feeDetail.dueBalance) ?? 0}
                                    noCurrency
                                />
                            </Box>
                        </Box>
                        <Button
                            bsStyle="outline"
                            label="global.back"
                            onClick={this.handleCloseModal}
                            block
                            className="mt-auto"
                        />
                    </MainContainer>
                </div>
            </Modal>
        );
    };

    getFees = () => {
        const { data, pageNumber } = this.state;
        const { isFeePaid, fees } = this.props;

        let newData = data;
        if (pageNumber === 1 && fees && fees.length !== 0) {
            const feesFirstPage = configUtil.getInteger("loan.detail.statements.feesFirstPage");
            const filterData = this.getFeesByType(isFeePaid).slice(0, feesFirstPage);
            if (data.toString() !== filterData.toString()) {
                this.setState({ data: filterData, pageNumber });
                newData = filterData;
            }
        }

        return newData;
    };

    hasMoreFees = () => {
        const { isFeePaid } = this.props;
        const { data } = this.state;

        return data.length < this.getFeesByType(isFeePaid).length;
    };

    render() {
        const { isDesktop, isFetchingFees, noFiltersDataText } = this.props;
        const { feeDetail } = this.state;

        return (
            <>
                <ProductList
                    fetching={isFetchingFees}
                    items={this.getFees()}
                    renderItem={this.renderItem}
                    lastPage={!this.hasMoreFees()}
                    onLoadMoreClick={this.handleClick}
                    noMoreDataText="loan.detail.statement.noMoreFees"
                    loadMoreText="loan.detail.statement.moreFees"
                    noDataText="loan.detail.statement.noFees"
                    noFiltersDataText={noFiltersDataText}>
                    {this.renderList}
                </ProductList>
                {feeDetail && !isDesktop && this.renderModal()}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    activeRegion: loginSelectors.getRegion(state),
    fees: loansSelectors.getFees(state),
    isFetchingFees: loansSelectors.getFetchingFees(state),
    loan: loansSelectors.getSelectedLoan(state),
});

export default connect(mapStateToProps)(Statements);
