// eslint-disable-next-line no-unused-vars
import { call, put, takeLatest, select } from "redux-saga/effects";
import { replace, goBack } from "react-router-redux";

import * as settings from "middleware/settings";

import { types as fingerprintTypes, actions as fingerprintActions } from "reducers/fingerprint";
import { actions as notificationActions } from "reducers/notification";
import { selectors as sessionSelectors } from "reducers/session";

import * as deviceUtils from "util/device";
import * as fingerprintUtils from "util/fingerprint";
import * as i18nUtils from "util/i18n";
import * as secureStorageUtils from "util/secureStorage";
import { CORPORATE_ENVIRONMENT_TYPE, RETAIL_ENVIRONMENT_TYPE } from "constants.js";

import { store } from "../store";

const sagas = [
    takeLatest(fingerprintTypes.FINGERPRINT_AVAILABILITY, isAvailable),
    takeLatest(fingerprintTypes.FINGERPRINT_CONFIGURATION_PRE, fingerprintConfigurationPre),
    takeLatest(fingerprintTypes.FINGERPRINT_VERIFICATION_REQUEST, verify),
    takeLatest(fingerprintTypes.FINGERPRINT_DELETE_DEVICE, deleteDevice),
    takeLatest(fingerprintTypes.ENROLL_FINGERPRINT_ON_DEVICE, enrollFingerprintOnDevice),
    takeLatest(fingerprintTypes.CLEAN_UP, handleFingerprintCleanUp),
    takeLatest(fingerprintTypes.FINGERPRINT_CONFIGURATION_MODAL_VALIDATE, validateFingerPrinterModal),
    takeLatest(fingerprintTypes.CONFIGURATION_DEVICE_HAS_FINGERPRINTER_REQUEST, hasFingerPrinter),
];

export default sagas;

function* isAvailable() {
    if (window.cordova) {
        try {
            const availability = yield call(fingerprintUtils.isAvailable);

            yield put({ type: fingerprintTypes.FINGERPRINT_AVAILABILITY_SUCCESS, availability });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log("ERROR!!! - ", error);
        }
    }
}

function* fingerprintConfigurationPre() {
    const deviceId = deviceUtils.isMobileNative ? deviceUtils.getDeviceId() : "";
    const deviceModel = deviceUtils.isMobileNative ? deviceUtils.getDeviceModel() : "";

    const response = yield call(settings.listFingerprintConfiguredUserDevices, deviceId, deviceModel);

    if (response && response.status === 200) {
        yield put({
            type: fingerprintTypes.FINGERPRINT_CONFIGURATION_PRE_SUCCESS,
            isDeviceWithFingerprint: response.data.data.isThisDeviceWithFingerPrint,
            fingerprintConfiguredUserDevices: response.data.data.devices,
            // eslint-disable-next-line no-underscore-dangle
            exchangeToken: response.data.data._exchangeToken,
        });
    }
}

function* verify() {
    try {
        let result = yield call(fingerprintUtils.verify);
        yield put({ type: fingerprintTypes.CONFIGURE_NOW, isConfigured: false });

        if (result && result.withFingerprint) {
            const response = yield call(
                settings.saveFingerprint,
                deviceUtils.getDeviceId(),
                deviceUtils.getDeviceModel(),
            );

            if (response && response.status === 200) {
                result = yield call(
                    secureStorageUtils.set,
                    "fingerprintAuthToken",
                    sessionSelectors.getAccessToken(store.getState()),
                );
                result = yield call(
                    secureStorageUtils.set,
                    "fingerprintUsername",
                    sessionSelectors.getUsername(store.getState()),
                );
                const { isActiveCorporate } = store.getState().session;
                result = yield call(
                    secureStorageUtils.set,
                    "fingerprintEnvironmentType",
                    isActiveCorporate ? CORPORATE_ENVIRONMENT_TYPE : RETAIL_ENVIRONMENT_TYPE,
                );

                if (result) {
                    const confirmationMessage = i18nUtils.get("settings.fingerprintConfiguration.register.success");
                    yield put(
                        notificationActions.showNotification(confirmationMessage, "success", ["settings", "desktop"]),
                    );

                    yield put(goBack());
                }
            } else {
                const errorMessage = i18nUtils.get("settings.fingerprintConfiguration.register.error");
                yield put(notificationActions.showNotification(errorMessage, "error", ["settings"]));
            }
        }
    } catch (error) {
        if (
            deviceUtils.getDevicePlatform() === "Android" &&
            fingerprintUtils.getError(error) === fingerprintUtils.fingerprintErrors.FINGERPRINT_ERROR
        ) {
            const mess = `${i18nUtils.get("settings.fingerprintConfiguration.dialog.error_1")}\n${i18nUtils.get(
                "settings.fingerprintConfiguration.dialog.error_2",
            )}`;
            yield put(notificationActions.showNotification(mess, "error", ["fingerprintConfiguration"]));
        } else if (
            deviceUtils.getDevicePlatform() === "Android" &&
            fingerprintUtils.getError(error) !== fingerprintUtils.fingerprintErrors.FINGERPRINT_CANCELLED
        ) {
            yield put(
                notificationActions.showNotification(fingerprintUtils.getError(error), "error", [
                    "fingerprintConfiguration",
                ]),
            );
        } else if (deviceUtils.getDevicePlatform() === "iOS" && error.code !== -128) {
            yield put(
                notificationActions.showNotification(error.localizedDescription, "error", ["fingerprintConfiguration"]),
            );
        }
    }
}

function* deleteDevice({ idSession }) {
    const response = yield call(settings.deleteFingerprint, idSession);

    if (response && response.status === 200) {
        yield put({ type: fingerprintTypes.CLEAN_UP });

        const confirmationMessage = i18nUtils.get("settings.fingerprintConfiguration.device.remove.success");
        yield put(notificationActions.showNotification(confirmationMessage, "success", ["fingerprintConfiguration"]));
    } else {
        const errorMessage = i18nUtils.get("settings.fingerprintConfiguration.device.remove.error");
        yield put(notificationActions.showNotification(errorMessage, "error", ["fingerprintConfiguration"]));
    }

    yield put(fingerprintActions.fingerprintConfigurationPre());
}

function* enrollFingerprintOnDevice() {
    const response = yield call(fingerprintUtils.enrollOnDevice);

    if (!response) {
        const errorMessage = i18nUtils.get(
            "settings.fingerprintConfiguration.device.enrolledFingerprints.enroll.error",
        );
        yield put(notificationActions.showNotification(errorMessage, "error", ["fingerprintConfiguration"]));
    } else {
        yield put({ type: fingerprintTypes.CONFIGURE_NOW, isConfigured: true });
        yield put(replace("/settings/fingerprintConfiguration"));
    }
}

function* handleFingerprintCleanUp() {
    if (window.cordova) {
        try {
            yield call(secureStorageUtils.remove, "fingerprintAuthToken");
            yield call(secureStorageUtils.remove, "fingerprintUsername");
            yield call(secureStorageUtils.remove, "fingerprintEnvironmentType");
        } catch (error) {
            /* do something with the error */
        }
    }
}

function* validateFingerPrinterModal() {
    const deviceId = deviceUtils.isMobileNative ? deviceUtils.getDeviceId() : "";
    const deviceModel = deviceUtils.isMobileNative ? deviceUtils.getDeviceModel() : "";

    const response = yield call(settings.listFingerprintConfiguredUserDevices, deviceId, deviceModel);

    if (response && response.status === 200 && response.data && response.data.data) {
        const { isThisDeviceWithFingerPrint } = response.data.data;
        const availability = yield call(fingerprintUtils.isAvailable);
        const hardwareAvailability =
            deviceUtils.isMobileNativeFunc() &&
            availability &&
            availability.isAvailable &&
            availability.isHardwareDetected;
        const shouldShowModal = hardwareAvailability && !isThisDeviceWithFingerPrint;
        yield put({
            type: shouldShowModal
                ? fingerprintTypes.FINGERPRINT_CONFIGURATION_MODAL_SHOW
                : fingerprintTypes.FINGERPRINT_CONFIGURATION_MODAL_HIDE,
        });
    } else {
        yield put({
            type: fingerprintTypes.FINGERPRINT_CONFIGURATION_MODAL_HIDE,
        });
    }
}

function* hasFingerPrinter() {
    const availability = yield call(fingerprintUtils.isAvailable);
    if (!availability) {
        yield put({
            type: fingerprintTypes.CONFIGURATION_DEVICE_HAS_NOT_FINGERPRINTER,
        });
    } else {
        const fingerPrintEnvironmentRestricted = yield select(({ login }) => login.fingerPrintEnvironmentRestricted);
        const { isHardwareDetected, hasEnrolledFingerprints } = availability;

        let fingerprintAuthTokenExists = true;
        try {
            yield call(secureStorageUtils.get, "fingerprintAuthToken");
        } catch (error) {
            fingerprintAuthTokenExists = false;
        }

        const hasFingerPrinterFlag =
            isHardwareDetected &&
            hasEnrolledFingerprints &&
            availability.isAvailable &&
            !fingerPrintEnvironmentRestricted &&
            fingerprintAuthTokenExists;

        if (hasFingerPrinterFlag) {
            const { isActiveCorporate } = store.getState().session;
            let fingerprintEnvironmentType;
            try {
                fingerprintEnvironmentType = yield call(secureStorageUtils.get, "fingerprintEnvironmentType");
            } catch (err) {
                fingerprintEnvironmentType = "retail";
            }
            const environmentType = isActiveCorporate ? CORPORATE_ENVIRONMENT_TYPE : RETAIL_ENVIRONMENT_TYPE;
            if (environmentType !== fingerprintEnvironmentType) {
                yield put(
                    notificationActions.showNotification(
                        i18nUtils.get(`fingerprint.error.environmentType.${environmentType}`),
                        "error",
                        ["externalLayout"],
                    ),
                );
            } else {
                yield put({ type: fingerprintTypes.CONFIGURATION_DEVICE_HAS_FINGERPRINTER });
            }
        } else {
            yield put({ type: fingerprintTypes.CONFIGURATION_DEVICE_HAS_NOT_FINGERPRINTER });
        }
    }
}
