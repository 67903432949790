import * as configUtils from "util/config";

const kuaraVersionNumber = (neededVersionNumber) => {
    try {
        const shortDateFormat = configUtils.get("kuara.features.version", "1");
        const version = parseInt(shortDateFormat, 10);
        return version === neededVersionNumber;
    } catch (error) {
        return 1;
    }
};

export default kuaraVersionNumber;
