import { TIME_CHANGE_OTP } from "constants.js";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import TextAndButtonsModal from "pages/_components/modal/TextAndButtonsModal";
import TokenAndButtonsModal from "pages/_components/modal/TokenAndButtonsModal/TokenAndButtonsModal";
import { bool, func, number, string } from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { push } from "react-router-redux";
import {
    actions as authenticateHandlerActions,
    selectors as authenticateHandlerSelector,
} from "reducers/authenticateHandler";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as statusActions } from "reducers/status";
import { compose } from "redux";
import { isMobileNativeFunc } from "util/device";
import * as i18n from "util/i18n";
import { ENT000, generateOTP, hasRegisteredIdentity } from "util/softToken.util";
import { maskString2 } from "util/string";

const TokenButton = ({
    entrustKey,
    isDesktop,
    onChange,
    isAlwaysUnlock,
    dispatch,
    pinTries,
    userNameToken,
    isPinProtectionValidated,
    pinCode,
    isPinProtectionValidatedCount,
    isStep0,
    hasActiveSession,
    isFetching,
    loadingPrimaryBackground,
    environmentType,
    isDetails,
}) => {
    const circleAnimation = useRef();
    const [isPinProtectionActive, setisPinProtectionActive] = useState(pinCode);
    const [showModalPinBlocked, setshowModalPinBlocked] = useState(false);
    const [showModalProtectToken, setShowModalProtectToken] = useState(false);
    const [showPinValidationModal, setShowPinValidationModal] = useState(false);
    const [currentOtp, setCurrentOtp] = useState("");

    const showLock = isPinProtectionActive && !isAlwaysUnlock ? "images/icons/lock.svg" : "images/icons/unlock.svg";
    const strokeWidth = 3.5;

    const showPinProtection = () =>
        isPinProtectionActive &&
        (isAlwaysUnlock === undefined || isAlwaysUnlock === false) &&
        (hasActiveSession === undefined || hasActiveSession === false);

    const handleOpenModal = () => {
        if(!isMobileNativeFunc()) {
            return;
        }
        if(hasActiveSession !== undefined && hasActiveSession === true) {
            return;
        }
        if (pinTries >= 3) {
            setshowModalPinBlocked(true);
            return;
        }
        setShowPinValidationModal(true);
    };

    const handleCloseModal = () => {
        setShowPinValidationModal(false);
    };

    const handleShowTimer = (pinValue) => {
        dispatch(
            authenticateHandlerActions.validateTokenPinRequest(pinValue, environmentType, entrustKey, handleCloseModal),
        );
    };

    const handleAcceptModalPinBlocked = () => {
        setshowModalPinBlocked(false);
    };

    const showModalProtectTokenFunction = () => {
        setShowModalProtectToken(true);
    };

    const handleCancelModalPinBlocked = () => {
        setShowModalProtectToken(false);
    };

    const handleAcceptModalProtectToken = () => {
        dispatch(statusActions.saveLastHref("/settings/authenticatorHandler"));
        dispatch(push("/loginStep1"));
        setShowModalProtectToken(false);
    };

    useEffect(() => {
        setisPinProtectionActive(isPinProtectionValidated);
    }, [isPinProtectionValidated, isPinProtectionValidatedCount]);

    useEffect(() => {
        setisPinProtectionActive(pinCode);
    }, [pinCode]);

    const getOtpCode = async () => {
        const responseHasRegisterIdentity = await hasRegisteredIdentity(entrustKey, environmentType);
        if (!responseHasRegisterIdentity) {
            return;
        }

        const { code: codeIdentity, data: dataIdentity } = JSON.parse(responseHasRegisterIdentity);
        if (!codeIdentity || !dataIdentity || codeIdentity !== ENT000 || dataIdentity !== "true") {
            return;
        }

        const currentOtpResponse = await generateOTP(entrustKey, environmentType);
        if (!currentOtpResponse) {
            return;
        }

        const { code, data } = JSON.parse(currentOtpResponse);
        if (!code || !data || code !== ENT000) {
            return;
        }

        if (data === currentOtp) {
            return;
        }

        setCurrentOtp(data);

        if (onChange) {
            onChange(data);
        }
    };

    const [countTimer, setCountTimer] = useState(1);
    useEffect(() => {
        const intervalCodeOtp = setTimeout(() => {
            if (countTimer === 1) {
                getOtpCode();

                setCountTimer(1);
            } else {
                getOtpCode();
            }
            setCountTimer(countTimer + 1);
        }, TIME_CHANGE_OTP * 1000);

        if (circleAnimation?.current) {
            circleAnimation.current.style.animation = `timer ${TIME_CHANGE_OTP}s linear infinite`;
        }

        return () => {
            clearTimeout(intervalCodeOtp);
        };
    }, [countTimer, isFetching]);

    useEffect(() => {
        getOtpCode();
        dispatch(authenticateHandlerActions.getTokenInfoRequest(environmentType, entrustKey || ""));
    }, []);

    let button;
    if (!isPinProtectionActive || isAlwaysUnlock) {
        button = (
            <Button
                borderRadius="circle"
                bsStyle="only-icon"
                className="primary-icon"
                image={showLock}
                imageColor="primary"
                onClick={() => {
                    if (pinCode) {
                        setisPinProtectionActive(true);
                    } else if (isStep0 || isDetails) {
                        showModalProtectTokenFunction();
                    }
                }}
            />
        );
    } else {
        button = (
            <Button
                borderRadius="circle"
                bsStyle="only-icon"
                image={showLock}
                imageColor="primary"
                onClick={handleOpenModal}
            />
        );
    }

    const labelName = () => (!showPinProtection() ? userNameToken : maskString2(userNameToken, 1, 2));

    const renderContent = () => (
        <>
            {showModalProtectToken ? (
                <TextAndButtonsModal
                    modalShow
                    acceptFunction={handleAcceptModalProtectToken}
                    confirmLabel="token.protect.token.modal.accept.button"
                    headingText={i18n.get(`token.protect.token.modal.tittle`)}
                    headingSize="1"
                    text={i18n.get(`token.protect.token.modal.text`)}
                    cancelFunction={handleCancelModalPinBlocked}
                />
            ) : null}
            {showModalPinBlocked ? (
                <TextAndButtonsModal
                    modalShow
                    acceptFunction={handleAcceptModalPinBlocked}
                    headingText={i18n.get(`token.pin.validation.pin.tries.tittle`)}
                    text={i18n.get(`token.pin.validation.pin.tries.text`)}
                />
            ) : null}

            {showPinValidationModal ? (
                <TokenAndButtonsModal
                    modalShow={showPinValidationModal}
                    acceptFunction={(pinValue) => handleShowTimer(pinValue)}
                    cancelFunction={handleCloseModal}
                    headingText={i18n.get(`token.pin.validation.pin.tittle`)}
                />
            ) : null}

            <Box
                background="white"
                borderRadius="extreme"
                border="background-divider"
                display="flex"
                alignX="center"
                alignY="center"
                fitWidth
                className={`py-2 pl-2 pr-4 mx-auto ${isDetails && "token-details-btn"}`}>
                <Box display="flex" alignX="center" alignY="center" position="relative">
                    <Box className="svg-item">
                        <svg width="100%" height="100%" viewBox="0 0 36 36">
                            <linearGradient id="linear-gradient">
                                <stop className="stop1" offset="0%" />
                                <stop className="stop2" offset="100%" />
                            </linearGradient>
                            <circle
                                className="circle-ring"
                                cx="50%"
                                cy="50%"
                                r="15.91549430918954"
                                fill="transparent"
                                strokeWidth={strokeWidth}
                                shapeRendering="geometricPrecision"
                            />
                            {!showPinProtection() && (
                                <circle
                                    ref={circleAnimation}
                                    className="circle-progress"
                                    cx="50%"
                                    cy="50%"
                                    r="15.91549430918954"
                                    fill="transparent"
                                    strokeWidth={strokeWidth}
                                    strokeDasharray="100 0"
                                    strokeDashoffset="25"
                                    shapeRendering="geometricPrecision"
                                    style={{ "--time": `${TIME_CHANGE_OTP}s` }}
                                />
                            )}
                        </svg>
                    </Box>
                    <Box
                        display="flex"
                        alignX="center"
                        alignY="center"
                        top="50"
                        left="50"
                        absoluteCentered
                        position="absolute">
                        {button}
                    </Box>
                </Box>
                <Box display="flex" column alignX="center" className="pl-3">
                    <Text size={isDesktop ? "7" : "6"}>{isDetails ? labelName() : "TOKEN"}</Text>
                    <Text color="heading" bold size={isDesktop ? "4" : "2"} className="token-code">
                        {!showPinProtection()
                            ? // tokenCode
                              currentOtp
                            : "--- ---"}
                    </Text>
                </Box>
            </Box>
        </>
    );

    const renderLoading = () => (
        <Box display="flex" alignX="center" alignY="center" fullWidth>
            <Button
                className={loadingPrimaryBackground ? "btn-login-action btn-login-action-dashed" : "btn-token-loading"}
                loading
                {...(loadingPrimaryBackground && { block: true })}
            />
        </Box>
    );

    return isFetching ? renderLoading() : renderContent();
};

const mapStateToProps = (state, ownProps) => ({
    pinCode: authenticateHandlerSelector.getPinCodeByList(state, ownProps.entrustKey),
    pinTries: authenticateHandlerSelector.getPinTriesByList(state, ownProps.entrustKey),
    userNameToken: authenticateHandlerSelector.getUserNameByList(state, ownProps.entrustKey),
    isFetching: authenticateHandlerSelector.isFetchingByList(state, ownProps.entrustKey),

    isPinProtectionValidated: authenticateHandlerSelector.isPinProtectionValidated(state, ownProps.entrustKey),
    isPinProtectionValidatedCount: authenticateHandlerSelector.isPinProtectionValidatedCount(
        state,
        ownProps.entrustKey,
    ),
    hasActiveSession: sessionSelectors.isLoggedIn(state),

    isActiveCorporate: sessionSelectors.isActiveCorporate(state),
});

TokenButton.propTypes = {
    entrustKey: string.isRequired,
    dispatch: func.isRequired,
    isAlwaysUnlock: bool,
    isDesktop: bool.isRequired,
    isPinProtectionValidated: bool.isRequired,
    isPinProtectionValidatedCount: number.isRequired,
    isStep0: bool,
    onChange: func,
    pinCode: bool,
    pinTries: number.isRequired,
    userNameToken: string,
    hasActiveSession: bool,
    isFetching: bool,
    loadingPrimaryBackground: bool,
    isDetails: bool,
};

TokenButton.defaultProps = {
    isAlwaysUnlock: false,
    isStep0: false,
    onChange: () => {},
    pinCode: false,
    hasActiveSession: false,
    isFetching: true,
    loadingPrimaryBackground: false,
    userNameToken: "",
    isDetails: false,
};

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(TokenButton));
