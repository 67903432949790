/* eslint-disable no-console */
/* eslint-disable prefer-const */
/* eslint-disable import/prefer-default-export */
const isAvailablePlugin = () => window?.cordova?.plugins?.LocalNotificationPlugin;

export const setSession = (session) =>
    new Promise((resolve) => {
        if (!isAvailablePlugin()) {
            console.log("LocalNotificationPlugin is unavailable");
            return;
        }

        window.cordova.plugins.LocalNotificationPlugin.setSession(session ?? "0");
        resolve(true);
    });
