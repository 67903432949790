import classNames from "classnames";
import LanguageSelectionLink from "pages/login/_components/LanguageSelectionLink";
import AccessBar from "pages/_components/AccessBar";
import Box from "pages/_components/Box";
import ErrorBoundary from "pages/_components/ErrorBoundary";
import FooterDesktop from "pages/_components/FooterDesktop";
import MomentDay from "pages/_components/MomentDay";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import { bool, func, node, oneOfType, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect, Route } from "react-router-dom";
import PageTransition from "react-router-page-transition";
import { actions as fingerprintActions, selectors as fingerprintSelectors } from "reducers/fingerprint";
import { actions as notificationActions } from "reducers/notification";
import * as config from "util/config";
import { getTransition } from "util/transition";
import { actions as statusActions } from "reducers/status";
import { selectors as sessionSelectors } from "reducers/session";
import { push } from "react-router-redux";
import RevelockPositionProvider from "providers/RevelockPositionProvider";
import Text from "pages/_components/Text";
import Button from "pages/_components/Button";
import isLocalPhoneNumber from "util/mobileNumber";

class ExternalLayout extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        history: shape({}).isRequired,
        location: shape({}).isRequired,
        transition: string,
        component: oneOfType([node, func]).isRequired,
        isDesktop: bool,
        isMobile: bool,
        isMobileNative: bool,
        loginBar: bool,
        pageStep0: bool,
        hideNotification: bool,
        fingerprintAvailable: shape({}),
        fingerPrintEnvironmentRestricted: bool,
        withoutBackgroundMobile: bool,
        landingPage: string,
        hasActiveSession: bool,
        aditionalClassName: string,
        background: string,
        componentProps: shape({}),
        redirect: bool,
        delayAccessBarAppear: bool,
        isActiveCorporate: bool.isRequired,
        path: string.isRequired,
        noImageBG: bool,
    };

    static defaultProps = {
        isDesktop: false,
        isMobile: false,
        isMobileNative: false,
        transition: "",
        loginBar: false,
        pageStep0: false,
        hideNotification: false,
        fingerPrintEnvironmentRestricted: false,
        fingerprintAvailable: undefined,
        withoutBackgroundMobile: false,
        landingPage: null,
        hasActiveSession: false,
        aditionalClassName: null,
        background: null,
        componentProps: undefined,
        redirect: false,
        delayAccessBarAppear: false,
        noImageBG: false,
    };

    state = {
        transition: "",
        styles: {},
    };

    componentDidMount() {
        const { isMobileNative } = this.props;

        if (isMobileNative) {
            window.common.hideSplashScreen();
        }
    }

    shouldComponentUpdate(nextProps) {
        return !(nextProps.path === "/loginStep3" && this.props.path === "/loginStep3");
    }

    componentDidUpdate() {
        const { dispatch, location } = this.props;
        const isInitialStep = location.pathname === "/" || location.pathname === "/loginStep1";
        if (!isInitialStep) {
            dispatch(notificationActions.removeNotification());
        }
    }

    /* eslint-disable-next-line react/sort-comp, camelcase */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { location } = this.props;

        if (location.pathname !== nextProps.location.pathname) {
            this.setState({
                transition: nextProps.transition || getTransition(this.props, nextProps),
            });
        }
    }

    getMobileLayout = (matchProps) => {
        const {
            component: ReceivedComponent,
            isMobile,
            isDesktop,
            isMobileNative,
            loginBar,
            hideNotification,
            dispatch,
            pageStep0,
            landingPage,
            hasActiveSession,
            aditionalClassName,
            withoutBackgroundMobile,
            background,
            delayAccessBarAppear,
            componentProps,
            isActiveCorporate,
            noImageBG,
        } = this.props;

        const extras = {
            isMobile,
            isDesktop,
            isMobileNative,
            landingPage,
            hasActiveSession,
            dispatch,
            ...componentProps,
        };
        const { transition, styles } = this.state;
        const accessBarBtnsStep0 = [
            {
                image: "images/icons/onlineShift.svg",
                label: "login.access.bar.online",
                externalHref: config.get("accessBar.onlineShift.url"),
                className: "secondary",
            },
            {
                image: "images/icons/chatWithVane.svg",
                label: isActiveCorporate ? "access.bar.chat.vane.corporate" : "login.access.bar.chat.vane",
                externalHref: config.get("accessBar.chat.url"),
                className: "secondary",
            },
            {
                image: "images/icons/requests.svg",
                label: "login.access.bar.product.request",
                externalHref: config.get("accessBar.requests.url"),
                className: "secondary",
            },
            {
                image: "images/icons/userPlus.svg",
                label: "login.step0.new.user",
                href: "/enrollment",
                className: "secondary",
            },
        ];
        const accessBarBtnsStep1 = [
            {
                image: "images/icons/onlineShift.svg",
                label: "login.access.bar.online",
                externalHref: config.get("accessBar.onlineShift.url"),
                className: "secondary",
            },
            {
                image: "images/icons/chatWithVane.svg",
                label: isActiveCorporate ? "access.bar.chat.vane.corporate" : "login.access.bar.chat.vane",
                externalHref: config.get("accessBar.chat.url"),
                className: "secondary",
            },
            {
                image: "images/icons/requests.svg",
                label: "login.access.bar.product.request",
                externalHref: config.get("accessBar.requests.url"),
                className: "secondary",
            },
            {
                image: "images/icons/fingerprintLogin.svg",
                label: "login.access.bar.fingerPrintLogin",
                className: "secondary",
                onClick: () => {
                    dispatch(fingerprintActions.configurationDeviceHasFingerPrinterRequest());
                },
            },
        ];
        const kuara = {
            image: "images/logos/kuaraAccessBarLogo.svg",
            label: "kuara",
            className: "",
            onClick: () => {
                dispatch(statusActions.saveLastHref("/kuara"));
                dispatch(push("/loginStep1"));
            },
        };

        const home = {
            image: "images/banesco-icon.svg",
            className: "banesco-btn my-auto",
            onClick: () => {
                dispatch(statusActions.saveLastHref("/desktop"));
                dispatch(push("/loginStep1"));
            },
        };

        const wally = {
            image: "images/wally.svg",
            label: "wally",
            className: "",
            onClick: () => {
                dispatch(statusActions.saveLastHref(isActiveCorporate ? "/pendingTransaction/list" : "/wally"));
                dispatch(push("/loginStep1"));
            },
        };

        return (
            <Box
                display="flex"
                column
                position="relative"
                fullHeight
                fullWidth
                className={classNames({ "delay-access-bar-appear": delayAccessBarAppear })}>
                {!hideNotification ? <Notification scopeToShow="externalLayout" /> : <></>}
                <MomentDay
                    pageStep0={pageStep0}
                    withoutBackgroundMobile={withoutBackgroundMobile}
                    className={classNames("login-background", transition)}
                    style={styles}
                    isActiveCorporate={isActiveCorporate}
                    noImageBG={noImageBG}>
                    <PageTransition timeout={transition ? 600 : 0}>
                        <div
                            className={classNames("view-app transition-item full-height", aditionalClassName, {
                                "padding-for-access-bar": loginBar && isMobile,
                                [`background-${background}`]: background,
                            })}>
                            <ErrorBoundary>
                                <ReceivedComponent {...matchProps} {...extras} />
                            </ErrorBoundary>
                        </div>
                    </PageTransition>
                </MomentDay>
                {loginBar && (
                    <AccessBar
                        {...(!isMobileNative && { defaultAccesBar: false })}
                        options={pageStep0 ? accessBarBtnsStep0 : accessBarBtnsStep1}
                        kuara={kuara}
                        wally={wally}
                        isLocal={isLocalPhoneNumber()}
                        isExternalLayout
                        home={home}
                    />
                )}
            </Box>
        );
    };

    getDesktopLayout = (matchProps) => {
        const {
            component: ReceivedComponent,
            isMobile,
            isDesktop,
            isMobileNative,
            location: { pathname },
            hideNotification,
            isActiveCorporate,
        } = this.props;
        const extras = { isMobile, isDesktop, isMobileNative };
        const { transition } = this.state;
        return (
            <MomentDay className={`${transition} login-background`} isActiveCorporate={isActiveCorporate}>
                {!hideNotification ? <Notification scopeToShow="externalLayout" /> : <></>}
                <PageTransition
                    timeout={transition ? 600 : 0}
                    style={{
                        background: "linear-gradient(transparent, transparent 67.5%, black)",
                    }}>
                    <div className="app transition-item">
                        <ErrorBoundary>
                            <section className="container--layout items-center">
                                <Box className="login-desktop-wrapper panel">
                                    {/* <Logo className="svg-image logo" /> */}
                                    <main className="app-default-main" role="main">
                                        <ReceivedComponent {...matchProps} {...extras} />
                                    </main>

                                    <FooterDesktop
                                        moreOptions={[
                                            <Button
                                                externalHref={config.get("accessBar.privacyProtection.url", "/")}
                                                key="privacy">
                                                <Text
                                                    className="legal-policy-text"
                                                    labelKey="footer.privacyProtection.url"
                                                />
                                            </Button>,
                                            <LanguageSelectionLink
                                                key="lng-sec"
                                                disabled={pathname === "/languageSelection"}
                                            />,
                                        ]}
                                    />
                                </Box>
                            </section>
                        </ErrorBoundary>
                    </div>
                </PageTransition>
            </MomentDay>
        );
    };

    render() {
        const { redirect, component: ReceivedComponent, ...rest } = this.props;

        return (
            <RevelockPositionProvider {...this.props}>
                <Route
                    {...rest}
                    render={(matchProps) => {
                        const { isMobile } = this.props;

                        if (redirect) {
                            return <Redirect to="/desktop" />;
                        }

                        if (isMobile) {
                            return <>{this.getMobileLayout(matchProps)}</>;
                        }

                        return <>{this.getDesktopLayout(matchProps)}</>;
                    }}
                />
            </RevelockPositionProvider>
        );
    }
}

const mapStateToProps = (state) => ({
    fingerprintAvailable: fingerprintSelectors.getAvailability(state),
    isActiveCorporate: sessionSelectors.isActiveCorporate(state),
});

export default connect(mapStateToProps)(resizableRoute(withRouter(ExternalLayout)));
