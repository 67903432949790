import { types as productsTypes } from "reducers/products";

export const types = {
    ACCOUNT_DETAILS_REQUEST: "accounts/ACCOUNT_DETAILS_REQUEST",
    ACCOUNT_DETAILS_SUCCESS: "accounts/ACCOUNT_DETAILS_SUCCESS",
    ACCOUNT_DETAILS_ERROR: "accounts/ACCOUNT_DETAILS_ERROR",
    ACCOUNT_DOWNLOAD_STATEMENT_REQUEST: "accounts/ACCOUNT_DOWNLOAD_STATEMENT_REQUEST",
    ACCOUNT_DOWNLOAD_STATEMENT_SUCCESS: "accounts/ACCOUNT_DOWNLOAD_STATEMENT_SUCCESS",
    ACCOUNT_FETCH_MORE_MOVEMENTS_REQUEST: "accounts/ACCOUNT_FETCH_MORE_MOVEMENTS_REQUEST",
    ACCOUNT_FETCH_MORE_MOVEMENTS_SUCCESS: "accounts/ACCOUNT_FETCH_MORE_MOVEMENTS_SUCCESS",
    ACCOUNT_MOVEMENT_DETAILS_REQUEST: "accounts/ACCOUNT_MOVEMENT_DETAILS_REQUEST",
    ACCOUNT_MOVEMENT_DETAILS_SUCCESS: "accounts/ACCOUNT_MOVEMENT_DETAILS_SUCCESS",
    ACCOUNT_READ_REQUEST: "accounts/ACCOUNT_READ_REQUEST",
    ACCOUNT_READ_SUCCESS: "accounts/ACCOUNT_READ_SUCCESS",
    CLOSE_OPTIONS: "accounts/CLOSE_OPTIONS",
    DOWNLOAD_MOVEMENTS_REQUEST: "accounts/DOWNLOAD_MOVEMENTS_REQUEST",
    DOWNLOAD_MOVEMENTS_FAILURE: "accounts/DOWNLOAD_MOVEMENTS_FAILURE",
    DOWNLOAD_MOVEMENTS_SUCCESS: "accounts/DOWNLOAD_MOVEMENTS_SUCCESS",
    DOWNLOAD_MOVEMENTS_BLOCKED_REQUEST: "accounts/DOWNLOAD_MOVEMENTS_BLOCKED_REQUEST",
    DOWNLOAD_MOVEMENTS_BLOCKED_FAILURE: "accounts/DOWNLOAD_MOVEMENTS_BLOCKED_FAILURE",
    DOWNLOAD_MOVEMENTS_BLOCKED_SUCCESS: "accounts/DOWNLOAD_MOVEMENTS_BLOCKED_SUCCESS",
    EDIT_MOVEMENT_NOTE_REQUEST: "accounts/EDIT_MOVEMENT_NOTE_REQUEST",
    EDIT_MOVEMENT_NOTE_SUCCESS: "accounts/EDIT_MOVEMENT_NOTE_SUCCESS",
    LIST_ACCOUNT_STATEMENTS_REQUEST: "accounts/LIST_ACCOUNT_STATEMENTS_REQUEST",
    LIST_ACCOUNT_STATEMENTS_SUCCESS: "accounts/LIST_ACCOUNT_STATEMENTS_SUCCESS",
    LIST_ACCOUNT_MOVEMENTS_BLOCKED_REQUEST: "accounts/LIST_ACCOUNT_MOVEMENTS_BLOCKED_REQUEST",
    LIST_ACCOUNT_MOVEMENTS_BLOCKED_SUCCESS: "accounts/LIST_ACCOUNT_MOVEMENTS_BLOCKED_SUCCESS",
    LIST_ACCOUNT_MOVEMENTS_BLOCKED_FAILURE: "accounts/LIST_ACCOUNT_MOVEMENTS_BLOCKED_FAILURE",
    LIST_ACCOUNTS_REQUEST: "accounts/LIST_ACCOUNTS_REQUEST",
    LIST_ACCOUNTS_SUCCESS: "accounts/LIST_ACCOUNTS_SUCCESS",
    LIST_ACCOUNTS_WALLY_REQUEST: "accounts/LIST_ACCOUNTS_WALLY_REQUEST",
    LIST_ACCOUNTS_WALLY_SUCCESS: "accounts/LIST_ACCOUNTS_WALLY_SUCCESS",
    LIST_ACCOUNTS_WALLY_FAILURE: "accounts/LIST_ACCOUNTS_WALLY_FAILURE",
    RESET_FILTERS: "accounts/RESET_FILTERS",
    SET_MOBILE_FILTER: "accounts/SET_MOBILE_FILTER",
    SET_SELECTED_ACCOUNT: "accounts/SET_SELECTED_ACCOUNT",
    SET_SELECTED_MOVEMENT: "accounts/SET_SELECTED_MOVEMENT",
    SET_UNIQUE_ACCOUNT: "accounts/SET_UNIQUE_ACCOUNT",
    TOGGLE_OPTIONS: "accounts/TOGGLE_OPTIONS",
    ACCOUNT_FETCH_MOVEMENTS_REQUEST: "accounts/ACCOUNT_FETCH_MOVEMENTS_REQUEST",
    ACCOUNT_FETCH_MOVEMENTS_SUCCESS: "accounts/ACCOUNT_FETCH_MOVEMENTS_SUCCESS",

    ACCOUNT_NO_FETCH_MOVEMENTS: "accounts/ACCOUNT_NO_FETCH_MOVEMENTS",
    GET_TRACKING_TRANSFER_URL_REQUEST: "accounts/movements/GET_TRACKING_TRANSFER_URL_REQUEST",
    GET_TRACKING_TRANSFER_URL_SUCCESS: "accounts/movements/GET_TRACKING_TRANSFER_URL_SUCCESS",
    GET_TRACKING_TRANSFER_URL_FAILURE: "accounts/movements/GET_TRACKING_TRANSFER_URL_FAILURE",
    GET_TRACKING_TRANSFER_URL_CLEAR: "accounts/movements/GET_TRACKING_TRANSFER_URL_CLEAR",
    SET_IS_ADVANCED: "accounts/SET_IS_ADVANCED",

    MODAL_SHOW: "accounts/MODAL_SHOW",
    MODAL_CLOSE: "accounts/MODAL_CLOSE",
};

export const INITIAL_STATE = {
    account: {}, // No encuentro que se utilice, dado que el detalle utiliza selectedAccount
    accounts: [],
    accountsForWally: [],
    equivalentTotalBalance: null,
    fetching: false,
    fetchingDetail: true,
    fetchingMovements: false,
    fetchingAccountsWally: true,
    firstFetched: true,
    idSelectedAccount: null,
    mobileFilter: null,
    movements: [],
    movementsBlocked: [],
    moreMovements: false,
    pageNumber: 1,
    selectedAccount: null,
    selectedFilter: null,
    selectedMovement: null,
    statements: null,
    voucher: null,
    isOptionsVisible: false,
    filters: {
        channels: [],
        check: null,
        dateFrom: null,
        dateTo: null,
        minAmount: null,
        maxAmount: null,
        reference: null,
        advanced: false,
    },
    totalCount: 0,
    totalFavorites: 0,
    completeFavorites: false,
    offset: 0,
    quantity: 0,
    fetchTrackingUrl: false,
    isAdvanced: false,
    showModal: false,
    helpMessage: "",
};

export default (state = INITIAL_STATE, action = {}) => {
    const {
        account,
        accountsForWally,
        accounts,
        alias,
        equivalentTotalBalance,
        filters,
        idSelectedAccount,
        mobileFilter,
        moreStatements,
        pageNumber,
        selectedMovement,
        statement,
        statements,
        statementLines,
        totalCount,
        totalFavorites,
        favorite,
        offset,
        quantity,
        helpMessage,
    } = action;

    switch (action.type) {
        case types.CLOSE_OPTIONS:
            return {
                ...state,
                isOptionsVisible: false,
            };
        case types.ACCOUNT_DETAILS_REQUEST:
            return {
                ...state,
                fetchingMovements: true,
                filters: filters || state.filters,
                movements: [],
                pageNumber: 1,
                offset: 0,
                quantity: 0,
                helpMessage: "",
            };
        case types.ACCOUNT_DETAILS_SUCCESS:
            return {
                ...state,
                fetchingMovements: false,
                firstFetched: false,
                moreMovements: moreStatements,
                movements: statements,
                offset,
                totalCount,
                helpMessage: "",
            };
        case types.ACCOUNT_DETAILS_ERROR:
            return {
                ...state,
                fetchingMovements: false,
                filters: INITIAL_STATE.filters,
                movements: [],
                pageNumber: 1,
                offset: 0,
                quantity: 0,
                helpMessage,
            };
        case types.ACCOUNT_READ_REQUEST:
            return {
                ...state,
                isOptionsVisible: false,
                fetchingDetail: true,
                idSelectedAccount,
            };
        case types.ACCOUNT_READ_SUCCESS:
            return {
                ...state,
                fetchingDetail: false,
                selectedAccount: account,
                totalFavorites,
            };
        case types.ACCOUNT_DOWNLOAD_STATEMENT_REQUEST:
            return { ...state, fetching: true };
        case types.ACCOUNT_DOWNLOAD_STATEMENT_SUCCESS:
            return { ...state, fetching: false };
        case types.ACCOUNT_FETCH_MORE_MOVEMENTS_REQUEST:
            return {
                ...state,
                fetchingMovements: true,
            };
        case types.ACCOUNT_FETCH_MORE_MOVEMENTS_SUCCESS:
            return {
                ...state,
                fetchingMovements: false,
                movements: state.movements ? [...state.movements, ...statements] : statements,
                moreMovements: moreStatements,
                pageNumber: state.pageNumber + 1,
                offset,
                quantity,
            };
        case types.ACCOUNT_NO_FETCH_MOVEMENTS:
            return {
                ...state,
                fetchingMovements: false,
                moreMovements: moreStatements,
                pageNumber,
                offset,
                quantity,
            };
        case types.ACCOUNT_MOVEMENT_DETAILS_REQUEST:
            return { ...state, fetching: true };
        case types.ACCOUNT_MOVEMENT_DETAILS_SUCCESS:
            return { ...state, fetching: false, voucher: statement.voucher };
        case types.LIST_ACCOUNT_STATEMENTS_REQUEST:
            return {
                ...state,
                fetchingMovements: true,
            };
        case types.LIST_ACCOUNT_STATEMENTS_SUCCESS:
            return {
                ...state,
                fetchingMovements: false,
                statements: statementLines,
            };
        case types.LIST_ACCOUNTS_REQUEST:
            return {
                ...state,
                fetching: true,
                movements: [],
                moreMovements: false,
                selectedMovement: null,
                pageNumber: 1,
                offset: 0,
                quantity: 0,
            };
        case types.LIST_ACCOUNTS_SUCCESS: {
            const { completeFavorites } = action;
            return {
                ...state,
                accounts,
                equivalentTotalBalance,
                fetching: false,
                isOptionsVisible: false,
                completeFavorites,
            };
        }
        case types.LIST_ACCOUNTS_WALLY_REQUEST:
            return {
                ...state,
                fetchingAccountsWally: true,
            };
        case types.LIST_ACCOUNTS_WALLY_SUCCESS:
            return {
                ...state,
                accountsForWally,
                fetchingAccountsWally: false,
            };
        case types.LIST_ACCOUNTS_WALLY_FAILURE:
            return {
                ...state,
                fetchingAccountsWally: false,
            };
        case types.RESET_FILTERS:
            return { ...state, filters: INITIAL_STATE.filters };
        case types.SET_MOBILE_FILTER:
            return {
                ...state,
                mobileFilter,
            };
        case types.SET_SELECTED_MOVEMENT:
            return {
                ...state,
                selectedMovement,
            };
        case types.SET_UNIQUE_ACCOUNT:
            return {
                ...state,
                accounts,
                equivalentTotalBalance,
                selectedAccount: accounts[0],
            };

        case types.TOGGLE_OPTIONS: {
            return {
                ...state,
                isOptionsVisible: !state.isOptionsVisible,
            };
        }

        case productsTypes.CHANGE_PRODUCT_ALIAS_SUCCESS: {
            if (state.selectedAccount) {
                return {
                    ...state,
                    selectedAccount: {
                        ...state.selectedAccount,
                        productAlias: alias,
                    },
                };
            }
            return {
                ...state,
            };
        }

        case types.DOWNLOAD_MOVEMENTS_REQUEST:
            return {
                ...state,
            };
        case types.DOWNLOAD_MOVEMENTS_FAILURE:
        case types.DOWNLOAD_MOVEMENTS_SUCCESS:
            return {
                ...state,
            };

        case types.ACCOUNT_FETCH_MOVEMENTS_REQUEST:
            return {
                ...state,
                pageNumber: 1,
                offset: 0,
                limit: 0,
                filters: action.filters,
                fetchingMovements: true,
                movements: [],
            };
        case types.ACCOUNT_FETCH_MOVEMENTS_SUCCESS:
            return {
                ...state,
                fetchingMovements: false,
                movements: action.statements,
                moreMovements: action.moreStatements,
                totalCount: action.totalCount,
            };
        case types.LIST_ACCOUNT_MOVEMENTS_BLOCKED_REQUEST:
            return {
                ...state,
                fetchingMovements: true,
                movementsBlocked: [],
            };
        case types.LIST_ACCOUNT_MOVEMENTS_BLOCKED_FAILURE:
            return {
                ...state,
                fetchingMovements: false,
                movementsBlocked: [],
            };
        case types.LIST_ACCOUNT_MOVEMENTS_BLOCKED_SUCCESS:
            return {
                ...state,
                fetchingMovements: false,
                movementsBlocked: action.movementBlockedList,
            };
        case productsTypes.TOGGLE_FAVORITE_SUCCESS: {
            const { fromList, idProduct, completeFavorites } = action;
            if (fromList) {
                // eslint-disable-next-line no-shadow
                const { accounts } = state;
                if (!accounts || !accounts.some((accItem) => accItem.idProduct === idProduct)) {
                    return state;
                }
                const updateAccountArray = accounts.map((accItem) => {
                    if (accItem.idProduct === idProduct) {
                        return { ...accItem, favorite };
                    }
                    return accItem;
                });
                return { ...state, accounts: updateAccountArray, completeFavorites };
            }
            return {
                ...state,
                selectedAccount: {
                    ...state.selectedAccount,
                    favorite,
                },
                totalFavorites,
            };
        }
        case types.GET_TRACKING_TRANSFER_URL_REQUEST:
            return {
                ...state,
                fetchTrackingUrl: true,
            };
        case types.GET_TRACKING_TRANSFER_URL_SUCCESS:
            return {
                ...state,
                fetchTrackingUrl: false,
            };
        case types.GET_TRACKING_TRANSFER_URL_FAILURE:
            return {
                ...state,
                fetchTrackingUrl: false,
            };
        case types.GET_TRACKING_TRANSFER_URL_CLEAR:
            return {
                ...state,
                fetchTrackingUrl: false,
            };
        case types.SET_IS_ADVANCED:
            return {
                ...state,
                isAdvanced: action.isAdvanced,
            };
        case types.MODAL_SHOW:
            return {
                ...state,
                showModal: true,
            };
        case types.MODAL_CLOSE:
            return {
                ...state,
                showModal: false,
            };
        case types.EDIT_MOVEMENT_NOTE_SUCCESS:
            return {
                ...state,
                selectedMovement: { ...state.selectedMovement, note: action.note },
            };
        default:
            return state;
    }
};

export const actions = {
    closeOptions: () => ({
        type: types.CLOSE_OPTIONS,
    }),
    details: (idSelectedAccount, filters, formikBag) => ({
        type: types.ACCOUNT_DETAILS_REQUEST,
        idSelectedAccount,
        filters,
        formikBag,
    }),
    downloadMovements: (idAccount, format, onFinish) => ({
        type: types.DOWNLOAD_MOVEMENTS_REQUEST,
        idAccount,
        format,
        onFinish,
    }),
    downloadMovementsBlocked: (idAccount, format, onFinishDownload) => ({
        type: types.DOWNLOAD_MOVEMENTS_BLOCKED_REQUEST,
        idAccount,
        format,
        onFinishDownload,
    }),
    downloadStatement: (idAccount, idStatement) => ({
        type: types.ACCOUNT_DOWNLOAD_STATEMENT_REQUEST,
        idAccount,
        idStatement,
    }),
    editMovementNote: (accountId, movementId, note, onFinish) => ({
        type: types.EDIT_MOVEMENT_NOTE_REQUEST,
        accountId,
        movementId,
        note,
        onFinish,
    }),
    editMovementNoteSuccess: (note) => ({
        type: types.EDIT_MOVEMENT_NOTE_SUCCESS,
        note,
    }),
    fetchMoreMovements: (accountId, filters) => ({
        type: types.ACCOUNT_FETCH_MORE_MOVEMENTS_REQUEST,
        accountId,
        filters,
    }),
    noFetchMovements: (accountId, pageNumber, moreStatements) => ({
        type: types.ACCOUNT_NO_FETCH_MOVEMENTS,
        accountId,
        pageNumber,
        moreStatements,
    }),
    listAccounts: (currencyFilterList) => ({
        type: types.LIST_ACCOUNTS_REQUEST,
        currencyFilterList,
    }),
    listAccountsForWally: () => ({
        type: types.LIST_ACCOUNTS_WALLY_REQUEST,
    }),
    listStatements: (idAccount) => ({
        type: types.LIST_ACCOUNT_STATEMENTS_REQUEST,
        idAccount,
    }),
    listMovementBlocked: (idAccount) => ({
        type: types.LIST_ACCOUNT_MOVEMENTS_BLOCKED_REQUEST,
        idAccount,
    }),
    movementDetails: (idAccount, idStatement) => ({
        type: types.ACCOUNT_MOVEMENT_DETAILS_REQUEST,
        idAccount,
        idStatement,
    }),
    readAccount: (idAccount) => ({
        type: types.ACCOUNT_READ_REQUEST,
        idAccount,
    }),
    resetFilters: () => ({
        type: types.RESET_FILTERS,
    }),
    setMobileFilter: (mobileFilter) => ({
        type: types.SET_MOBILE_FILTER,
        mobileFilter,
    }),
    setSelectedMovement: (selectedMovement) => ({
        type: types.SET_SELECTED_MOVEMENT,
        selectedMovement,
    }),
    toggleOptions: () => ({
        type: types.TOGGLE_OPTIONS,
    }),
    fetchMovements: (accountId, filters) => ({
        type: types.ACCOUNT_FETCH_MOVEMENTS_REQUEST,
        accountId,
        filters,
    }),
    getTrackingTransferUrlRequest: (accountId, transactionRef) => ({
        type: types.GET_TRACKING_TRANSFER_URL_REQUEST,
        accountId,
        transactionRef,
    }),
    clearTrackingTransferUrlRequest: () => ({
        type: types.GET_TRACKING_TRANSFER_URL_CLEAR,
    }),
    showModal: () => ({
        type: types.MODAL_SHOW,
    }),
    modalHide: () => ({
        type: types.MODAL_CLOSE,
    }),
};

export const selectors = {
    getAccount: ({ accounts }) => accounts.account,
    getAccounts: ({ accounts }) => accounts.accounts,
    getAccountsForWally: ({ accounts }) => accounts.accountsForWally,
    getTotalAccounts: ({ accounts }) => accounts.accounts.length,
    getEquivalentTotalBalance: ({ accounts }) => accounts.equivalentTotalBalance,
    getFetching: ({ accounts }) => accounts.fetching,
    getFetchingAccountsWally: ({ accounts }) => accounts.fetchingAccountsWally,
    getFetchingDetail: ({ accounts }) => accounts.fetchingDetail,
    getFetchingMovements: ({ accounts }) => accounts.fetchingMovements,
    getFilters: ({ accounts }) => accounts.filters,
    getFirstFetched: ({ accounts }) => accounts.firstFetched,
    getIdSelectedAccount: ({ accounts }) => accounts.idSelectedAccount,
    getIsOptionsVisible: ({ accounts }) => accounts.isOptionsVisible,
    getSelectedAccount: ({ accounts }) => accounts.selectedAccount,
    getSelectedFilter: ({ accounts }) => accounts.selectedFilter,
    getSelectedMovement: ({ accounts }) => accounts.selectedMovement,
    getStatements: ({ accounts }) => accounts.statements,
    getPageNumber: ({ accounts }) => accounts.pageNumber,
    getMobileFilter: ({ accounts }) => accounts.mobileFilter,
    getMoreMovements: ({ accounts }) => accounts.moreMovements,
    getMovements: ({ accounts }) => accounts.movements,
    getMovementsBlocked: ({ accounts }) => accounts.movementsBlocked,
    getVoucher: ({ accounts }) => accounts.voucher,
    getTotalCount: ({ accounts }) => accounts.totalCount,
    getTotalFavorites: ({ accounts }) => accounts.totalFavorites,
    getCompleteFavorites: ({ accounts }) => accounts.completeFavorites,

    getOffset: ({ accounts }) => accounts.offset,
    getQuantity: ({ accounts }) => accounts.quantity,
    isTrackingUrlFetch: ({ accounts }) => accounts?.fetchTrackingUrl || false,
    isAdvanced: ({ accounts }) => accounts.isAdvanced,
    getShowModal: ({ accounts }) => accounts.showModal,
    getHelpMessage: ({ accounts }) => accounts.helpMessage,
};
