import classNames from "classnames";
import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import Button from "pages/_components/Button";
import Checkbox from "pages/_components/Checkbox";
import GridTable from "pages/_components/GridTable/GridTable";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import CredentialTokenComponent from "pages/forms/_components/credential/CredentialTokenComponent";
import { useSelectorOptionsAccounts } from "pages/forms/customForms/hooks/TransferCustomHooks";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import {
    selectors as transactionalProfileSelector,
    actions as transactionalProfileActions,
} from "reducers/transactionalProfile";
import { flattenArray, removeDuplicateItems } from "util/array";
import * as Yup from "yup";
import * as i18n from "util/i18n";
import { selectors as revelockSelectors } from "reducers/revelock/revelock.reducer";
import useCustomLocationSetter from "hooks/useLocationSetter";
import isEqual from "lodash/isEqual";
import Country from "./Country";
import FieldError from "../fields/FieldError";

const FORM_ID = "transactional.profile";
const ID_ACTIVITY = `${FORM_ID}.send`;

const ListCountriesComponent = (props) => {
    const {
        countriesList,
        isDesktop,
        fetching,
        isAllInChecked,
        isAllOutChecked,
        handlerAllSelect,
        disabledOutColumn,
        selectedCountryId,
        error = null,
    } = props;
    const getColumnsTemplate = () => "1.3fr 1fr 1fr";

    const bodyRef = useRef(null);
    const countryRefs = useRef({});
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredCountries = useMemo(() => {
        if (searchTerm === "") {
            return countriesList;
        }

        return countriesList.filter((country) => country.label.toLowerCase().includes(searchTerm.toLowerCase()));
    }, [countriesList, searchTerm]);

    useEffect(() => {
        if (bodyRef !== null && selectedCountryId && countryRefs.current[selectedCountryId]) {
            const bodyElement = bodyRef.current;
            const targetCountryElement = countryRefs.current[selectedCountryId];

            if (bodyElement && targetCountryElement) {
                const targetPosition = targetCountryElement.offsetTop - targetCountryElement.offsetHeight;
                bodyElement.scrollTo({
                    top: targetPosition,
                });
            }
        }
    }, [selectedCountryId, filteredCountries]);

    return (
        <GridTable>
            <GridTable.Header>
                <GridTable.Container
                    columnsTemplate={getColumnsTemplate()}
                    className={isDesktop ? "gapX-9" : "gapX-3 min-height-9-i"}
                    padding="py-2 px-5 pl-md-7 pr-md-8">
                    <GridTable.Data columnStart={1} alignX="flex-start" size="7" inHeader>
                        {/* <Text size="6" color="text-boton" labelKey="transactional.all.countries" uppercase /> */}
                        <Box className="input-group in-table-header">
                            <Box>
                                <Box display="flex" alignX="center" alignY="center" position="absolute" fullHeight>
                                    <Image src="images/search.svg" />
                                </Box>
                                <input
                                    className="form-control position-relative"
                                    type="text"
                                    name="search"
                                    placeholder={i18n.get("transactional.all.countries")}
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    maxLength={18}
                                />
                            </Box>
                        </Box>
                    </GridTable.Data>
                    <GridTable.Data columnStart={2} alignX="flex-start" size="7" inHeader>
                        <Box display="flex" alignY="center">
                            <Checkbox
                                name="inAll"
                                className="d-flex"
                                formGroup
                                noLabel
                                checked={isAllInChecked}
                                onChange={(e) => handlerAllSelect("in", e)}
                            />
                            <Text size="6" color="text-boton" labelKey="transactional.in.countries" uppercase />
                        </Box>
                    </GridTable.Data>
                    <GridTable.Data columnStart={3} alignX="flex-start" size="7" inHeader>
                        <Checkbox
                            name="outAll"
                            className="d-flex"
                            formGroup
                            noLabel
                            checked={isAllOutChecked}
                            onChange={(e) => handlerAllSelect("out", e)}
                            disabled={disabledOutColumn}
                        />
                        <Text size="6" color="text-boton" labelKey="transactional.out.countries" uppercase />
                    </GridTable.Data>
                </GridTable.Container>
            </GridTable.Header>
            <GridTable.Body ref={bodyRef} loading={fetching} className="scrollable-content">
                {filteredCountries.map((country) => {
                    const assignRef = (el) => {
                        countryRefs.current[country.id] = el;
                    };

                    return (
                        <div ref={assignRef} key={country.id}>
                            <Country
                                data={country}
                                disabledOutColumn={disabledOutColumn}
                                isDesktop={isDesktop}
                                columnsTemplate={getColumnsTemplate()}
                            />
                        </div>
                    );
                })}
            </GridTable.Body>
            {error && (
                <Box className="form-group has-error px-9 py-3">
                    <FieldError error={error} removeFullWidth />
                </Box>
            )}
        </GridTable>
    );
};

ListCountriesComponent.propTypes = {
    countriesList: arrayOf(shape({})).isRequired,
    isDesktop: bool.isRequired,
    fetching: bool,
    isAllInChecked: bool,
    isAllOutChecked: bool,
    handlerAllSelect: func,
    disabledOutColumn: bool,
    selectedCountryId: string,
    error: string,
};

ListCountriesComponent.defaultProps = {
    fetching: false,
    isAllInChecked: false,
    isAllOutChecked: false,
    handlerAllSelect: () => {},
    disabledOutColumn: true,
    selectedCountryId: null,
    error: null,
};

const ListCountriesComponentMemo = React.memo(
    (props) => <ListCountriesComponent {...props} />,
    (prevProps, nextProps) =>
        JSON.stringify(prevProps.countriesList) === JSON.stringify(nextProps.countriesList) &&
        prevProps.fetching === nextProps.fetching &&
        prevProps.disabledOutColumn === nextProps.disabledOutColumn &&
        prevProps.isAllInChecked === nextProps.isAllInChecked &&
        prevProps.isAllOutChecked === nextProps.isAllOutChecked &&
        isEqual(prevProps.error, nextProps.error),
);

const TransactionalProfileModal = ({
    countriesListProp,
    modifiedCountries,
    currentLang,
    fromBackoffice,
    modalProps,
    idTransaction,
    isDesktop,
    mode,
    setFieldValue,
    showModal,
    values,
    fetching,
    account,
    accountList,
    dispatch,
    submitForm,
    credentials,
    resetForm,
    hasTransactionalProfile,
    currentRevelockPosition,
    isAllInChecked,
    isAllOutChecked,
    fromMenu,
    selectedCountryId,
    setErrors,
}) => {
    const { isCreate, accountID, debitAccount, hideSelector } = modalProps;
    const selectRef = useRef(null);

    const [accountListOptions] = useSelectorOptionsAccounts(accountList);
    const [isValidating, setIsValidating] = useState(false);
    const [disabledOutColumn, setDisabledOutColumn] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useCustomLocationSetter(showModal, currentRevelockPosition, FORM_ID);

    const genericProps = {
        fromBackoffice,
        idActivity: ID_ACTIVITY,
        idTransactionTicket: idTransaction,
        isRequired: true,
        lang: currentLang,
        mode,
    };

    const verifyAccountSimplified = (productId) => {
        const accountSelected = accountList.find((accountItem) => accountItem.idProduct === productId);
        const isSimplified = accountSelected?.simplifiedLocal || accountSelected?.simplifiedInternacional;
        if (isSimplified !== undefined) {
            setDisabledOutColumn(isSimplified);
        }
    };

    const clearForm = () => {
        setErrorMessage(null);
        setFieldValue("otp", "");
        setErrors({});
        dispatch(transactionalProfileActions.resetCountrySelected());
    };

    useEffect(() => {
        resetForm();
        if (accountListOptions.options?.length > 0) {
            if (accountID !== null && account?.idProduct !== values?.account) {
                setFieldValue("account", accountID);
                setFieldValue("accountData", debitAccount);
                verifyAccountSimplified(accountID);
            } else if (!values.account) {
                const {
                    countableBalance,
                    currency,
                    idProduct,
                    number,
                    otherLabel,
                    productType,
                    statusDesc,
                } = accountListOptions?.options[0];
                setFieldValue("account", accountListOptions?.options[0]?.idProduct);
                setFieldValue("accountData", {
                    countableBalance,
                    currency,
                    idProduct,
                    number,
                    otherLabel,
                    productType,
                    statusDesc,
                });
                verifyAccountSimplified(accountListOptions?.options[0]?.idProduct);
            }
        }
    }, [accountListOptions]);

    useEffect(() => {
        dispatch(
            transactionalProfileActions.setIsAllInChecked(
                Object.values(modifiedCountries).every((element) => element.in),
            ),
        );
        dispatch(
            transactionalProfileActions.setIsAllOutChecked(
                Object.values(modifiedCountries).every((element) => element.out),
            ),
        );
        setErrorMessage(null);
    }, [modifiedCountries]);

    useEffect(() => {
        if (values?.account && account?.idProduct !== values?.account) {
            verifyAccountSimplified(values?.account);
            dispatch(transactionalProfileActions.transactionalCountriesRequest(values.account, values.accountData));
        }
    }, [values?.account]);

    useEffect(() => {
        setErrorMessage(null);
        if (showModal) {
            dispatch(transactionalProfileActions.credentialsGroupsRequest({ idActivity: ID_ACTIVITY }));
        } else {
            clearForm();
        }
    }, [showModal]);

    const handlerSendForm = () => {
        setIsValidating(true);
    };

    useEffect(() => {
        if (isValidating !== undefined && isValidating === true) {
            const oneCountrySelected = Object.values(modifiedCountries).filter((country) => country.in || country.out);
            setFieldValue("listSelected", oneCountrySelected);
            setFieldValue("isCreate", isCreate);
            setFieldValue("hasTransactionalProfile", hasTransactionalProfile);
            if (oneCountrySelected.length > 0) {
                submitForm();
                setIsValidating(false);
                setErrorMessage(null);
            } else {
                setErrorMessage(i18n.get("transactional.profile.select.oneMore.countries"));
                setIsValidating(false);
            }
        }
    }, [isValidating]);

    const hasChanges = useMemo(
        () =>
            countriesListProp?.some((country) => {
                const modifiedCountry = modifiedCountries[country.id];
                if (!modifiedCountry) {
                    return true;
                }
                return modifiedCountry.in !== country.in || modifiedCountry.out !== country.out;
            }),
        [modifiedCountries, countriesListProp],
    );

    const handlerAllSelect = (_type, value) => {
        dispatch(transactionalProfileActions.setModifiedCountryList(_type, value));

        if (_type === "in") {
            dispatch(transactionalProfileActions.setIsAllInChecked(value));
        } else {
            dispatch(transactionalProfileActions.setIsAllOutChecked(value));
        }
    };

    const handleHideModal = () => {
        dispatch(transactionalProfileActions.modalHide());
    };

    return (
        <>
            <Modal
                aria-labelledby="modalTitleID"
                aria-modal="true"
                show={showModal}
                onHide={handleHideModal}
                className={classNames("drawer transactional-profile-modal")}>
                <div className="modal-container px-0">
                    <Modal.Header closeButton className={isDesktop ? "px-9" : "px-5"}>
                        <Box display="flex" column alignY="center" className="mt-3 mt-md-7 mb-0 mb-md-9">
                            <Text
                                id="modalTitleID"
                                component="h4"
                                labelKey="transactional.profile.modal.title"
                                className={classNames("mt-0", { "mb-2": hideSelector }, { "mb-0": !hideSelector })}
                                color="heading-color"
                                size="3"
                                align="center"
                                bold
                            />
                            {account && isCreate === true && (
                                <Text
                                    id="modalTitleID"
                                    component="h4"
                                    labelKey="transactional.profile.modal.title"
                                    className={classNames("mt-0", { "mb-2": hideSelector }, { "mb-0": !hideSelector })}
                                    color="heading-color"
                                    size="3"
                                    align="center"
                                    regular>
                                    {account?.otherLabel || account?.longLabel}
                                </Text>
                            )}
                        </Box>
                    </Modal.Header>

                    <Form className="full-width full-height min-height-0">
                        <Box display="flex" column fullWidth fullHeight className="px-0 px-md-0">
                            <Box display="flex" column>
                                {accountList.length > 0 && isCreate !== true && (
                                    <Box display="flex" className="mb-5 mb-md-7 px-5 px-md-9">
                                        <Field
                                            {...genericProps}
                                            component={FormFieldsComponents.ProductselectorCustom}
                                            data={accountListOptions}
                                            value={
                                                accountListOptions?.options?.length > 0
                                                    ? accountListOptions?.options[0]?.idProduct
                                                    : ""
                                            }
                                            key="account"
                                            name="account"
                                            idField="account"
                                            renderAs="combo"
                                            labelNoMarginTop
                                            disabled={fetching}
                                            autoFocus
                                            withSingleOption={!fromMenu}
                                            selectRef={selectRef}
                                            onChangeDataCustom={() => clearForm()}
                                        />
                                    </Box>
                                )}
                                <Text
                                    component="p"
                                    size="6"
                                    align="center"
                                    className={classNames("mb-5 mb-md-6 mb-md-9 my-0", { "px-5": !isDesktop })}>
                                    {!fetching && hideSelector && i18n.get("transactional.profile.not.created")}
                                    {!fetching && !hideSelector && i18n.get("transactional.profile.create")}
                                </Text>
                            </Box>
                            <Box display="flex" flex1 className="min-height-7rem">
                                <ListCountriesComponentMemo
                                    countriesList={countriesListProp}
                                    isDesktop={isDesktop}
                                    fetching={fetching}
                                    isAllInChecked={isAllInChecked}
                                    isAllOutChecked={isAllOutChecked}
                                    handlerAllSelect={handlerAllSelect}
                                    disabledOutColumn={disabledOutColumn}
                                    selectedCountryId={selectedCountryId}
                                    error={errorMessage}
                                />
                            </Box>
                            <Box
                                display="flex"
                                column
                                alignY="center"
                                className="mt-auto pt-7 pt-md-8 px-5 px-md-9 pb-12"
                                gap="7">
                                {!fetching && (
                                    <CredentialTokenComponent
                                        credentials={credentials}
                                        onChangeToken={(token) => {
                                            if (setFieldValue) {
                                                setFieldValue("otp", token);
                                            }
                                        }}
                                        disabled={fetching}
                                        relativeStyleError
                                    />
                                )}
                                <Button
                                    bsStyle="primary"
                                    label="global.confirm"
                                    block
                                    onClick={() => handlerSendForm()}
                                    disabled={isValidating || fetching || !account?.idProduct || !hasChanges}
                                />
                            </Box>
                        </Box>
                    </Form>
                </div>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => ({
    fetching: transactionalProfileSelector.getFetching(state),
    countriesListProp: transactionalProfileSelector.getCountriesList(state),
    modifiedCountries: transactionalProfileSelector.getModifiedCountries(state),
    selectedCountryId: transactionalProfileSelector.getSelectedCountryId(state),
    hideSelector: !transactionalProfileSelector.getHasProfile(state),
    account: transactionalProfileSelector.getAccount(state),
    accountList: transactionalProfileSelector.getAccounts(state),
    credentials: compose(
        (array) => array.filter((item) => item !== "accessToken"),
        removeDuplicateItems,
        flattenArray,
        (array) => array.map(({ credentials }) => credentials),
    )(transactionalProfileSelector.getCredentialsGroups(state)),
    hasCompleteCredentialGroups: transactionalProfileSelector.hasCompleteCredentialGroups(state),
    currentRevelockPosition: revelockSelectors.getPosition(state),
    showModal: transactionalProfileSelector.getDisplayModal(state),
    modalProps: transactionalProfileSelector.getModalProps(state),
    hasTransactionalProfile: transactionalProfileSelector.getHasProfile(state),
    isAllInChecked: transactionalProfileSelector.getIsAllInChecked(state),
    isAllOutChecked: transactionalProfileSelector.getIsAllOutChecked(state),
});

TransactionalProfileModal.propTypes = {
    children: func,
    countriesListProp: arrayOf(shape({})).isRequired,
    accountList: arrayOf(shape({})).isRequired,
    modifiedCountries: shape({}).isRequired,
    isAllInChecked: bool.isRequired,
    isAllOutChecked: bool.isRequired,
    currentLang: string,
    fromBackoffice: bool,
    idTransaction: string,
    isDesktop: bool.isRequired,
    mode: string,
    setFieldValue: func.isRequired,
    showModal: bool.isRequired,
    modalProps: shape({}),
    fetching: bool,
    values: shape({}).isRequired,
    account: shape({}),
    dispatch: func.isRequired,
    submitForm: func.isRequired,
    credentials: shape({}),
    resetForm: func.isRequired,
    hasTransactionalProfile: bool,
    currentRevelockPosition: string.isRequired,
    fromMenu: bool,
    selectedCountryId: string,
    setErrors: func.isRequired,
};

TransactionalProfileModal.defaultProps = {
    children: null,
    currentLang: null,
    fromBackoffice: false,
    idTransaction: null,
    mode: "edit",
    account: null,
    modalProps: {},
    fetching: false,
    credentials: {},
    hasTransactionalProfile: false,
    fromMenu: false,
    selectedCountryId: null,
};

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            inAll: false,
            outAll: false,
            otp: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string()
                    .nullable()
                    .required(i18n.get(`${FORM_ID}.otp.required`)),
            }),
        handleSubmit: (values, formikBag) => {
            const { dispatch, account } = formikBag.props;
            const { otp, isCreate, hasTransactionalProfile } = values;

            const newListCountries = values.listSelected;

            dispatch(
                transactionalProfileActions.transactionalCountriesUpdateRequest(
                    account?.idProduct,
                    newListCountries,
                    () => dispatch(transactionalProfileActions.modalHide()),
                    otp,
                    formikBag,
                    isCreate,
                    hasTransactionalProfile,
                ),
            );
        },
    }),
)(resizableRoute(TransactionalProfileModal));
