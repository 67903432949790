import { createReducer, makeActionCreator } from "util/redux";

export const types = {
    ACTIVATE_REQUEST: "wally/ACTIVATE_REQUEST",
    UPDATE_USERWALLY_REQUEST: "wally/UPDATE_USERWALLY_REQUEST",
    DESACTIVATE_USERWALLY_REQUEST: "wally/DESACTIVATE_USERWALLY_REQUEST",
    FAVORITE_REQUEST: "wally/FAVORITE_REQUEST",
    FAVORITE_SUCCESS: "wally/FAVORITE_SUCCESS",
    FAVORITE_FAILURE: "wally/FAVORITE_FAILURE",
    SET_SELECTED_ACCOUNT: "wally/SET_SELECTED_ACCOUNT",
    SET_SELECTED_BENEFICIARY: "wally/SET_SELECTED_BENEFICIARY",
    READ_WALLY_USER_REQUEST: "wally/READ_WALLY_USER_REQUEST",
    READ_WALLY_USER_SUCCESS: "wally/READ_WALLY_USER_SUCCESS",
    READ_WALLY_USER_FAILURE: "wally/READ_WALLY_USER_FAILURE",
    LIST_BENEFICIARIES_COMMERCE_REQUEST: "wally/LIST_BENEFICIARIES_COMMERCE_REQUEST",
    LIST_BENEFICIARIES_COMMERCE_SUCCESS: "wally/LIST_BENEFICIARIES_COMMERCE_SUCCESS",
    LIST_BENEFICIARIES_COMMERCE_FAILURE: "wally/LIST_BENEFICIARIES_COMMERCE_FAILURE",
    LIST_BENEFICIARIES_PEOPLE_REQUEST: "wally/LIST_BENEFICIARIES_PEOPLE_REQUEST",
    LIST_BENEFICIARIES_PEOPLE_SUCCESS: "wally/LIST_BENEFICIARIES_PEOPLE_SUCCESS",
    LIST_BENEFICIARIES_PEOPLE_FAILURE: "wally/LIST_BENEFICIARIES_PEOPLE_FAILURE",
    LIST_MOVEMENTS_REQUEST: "wally/LIST_MOVEMENTS_REQUEST",
    LIST_MOVEMENTS_SUCCESS: "wally/LIST_MOVEMENTS_SUCCESS",
    LIST_MOVEMENTS_FAILURE: "wally/LIST_MOVEMENTS_FAILURE",
    LIST_MOVEMENTS_PENDING_REQUEST: "wally/LIST_MOVEMENTS_PENDING_REQUEST",
    LIST_MOVEMENTS_PENDING_SUCCESS: "wally/LIST_MOVEMENTS_PENDING_SUCCESS",
    LIST_MOVEMENTS_PENDING_FAILURE: "wally/LIST_MOVEMENTS_PENDING_FAILURE",
    DELETE_MOVEMENT_PENDING_REQUEST: "wally/DELETE_MOVEMENT_PENDING_REQUEST",
    REFRESH_PENDING_TRANSACTIONS_WALLY_QUANTITY_SUCCESS: "wally/REFRESH_PENDING_TRANSACTIONS_WALLY_QUANTITY_SUCCESS",
    PATH_REDIRECT_TO_WALLY: "wally/PATH_REDIRECT_TO_WALLY",
    SET_ACCESS_FROM_MENU: "wally/SET_ACCESS_FROM_MENU",
};

export const INITIAL_STATE = {
    selectedAccount: null,
    selectedBeneficiary: null,
    activateWally: null,
    favoriteWally: false,
    userWally: null,
    accountWally: null,
    isActiveWally: null,
    isPhoneValid: null,
    isFetching: false,
    isFetchingPeople: false,
    beneficiariesCommercePA: [],
    beneficiariesCommerceOT: [],
    beneficiariesPeople: [],
    movementsWally: [],
    movementsPendingWally: [],
    isFetchingMovemets: false,
    pendingTransactionsWallyQuantity: 0,
    pathRedirectToWally: "",
    accessFromMenu: false,
};

export default createReducer(INITIAL_STATE, {
    [types.SET_SELECTED_ACCOUNT]: (state, action) => ({
        ...state,
        selectedAccount: action.account,
    }),
    [types.SET_SELECTED_BENEFICIARY]: (state, action) => ({
        ...state,
        selectedBeneficiary: action.beneficiary,
    }),
    [types.FAVORITE_REQUEST]: (state) => ({
        ...state,
        isFetchingPeople: true,
    }),
    [types.FAVORITE_SUCCESS]: (state, action) => ({
        ...state,
        beneficiariesPeople: action.beneficiaries,
        selectedBeneficiary: action.beneficiarySelected,
        isFetchingPeople: false,
    }),
    [types.FAVORITE_FAILURE]: (state) => ({
        ...state,
        isFetchingPeople: false,
    }),
    [types.READ_WALLY_USER_REQUEST]: (state) => ({
        ...state,
        userWally: null,
        accountWally: null,
        isActiveWally: null,
        isPhoneValid: null,
        isFetching: true,
    }),
    [types.READ_WALLY_USER_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.READ_WALLY_USER_SUCCESS]: (state, action) => ({
        ...state,
        userWally: action.beneficiary,
        accountWally: action.account,
        isActiveWally: action.active,
        isPhoneValid: action.phoneValid,
        isFetching: action.fetching,
    }),

    [types.LIST_BENEFICIARIES_COMMERCE_REQUEST]: (state) => ({
        ...state,
        beneficiariesCommercePA: [],
        beneficiariesCommerceOT: [],
        isFetching: true,
    }),
    [types.LIST_BENEFICIARIES_COMMERCE_FAILURE]: (state) => ({
        ...state,
        isFetching: false,
    }),
    [types.LIST_BENEFICIARIES_COMMERCE_SUCCESS]: (state, action) => ({
        ...state,
        beneficiariesCommercePA: action.countryCode === "PA" ? action.beneficiaries : state.beneficiariesCommercePA,
        beneficiariesCommerceOT: action.countryCode !== "PA" ? action.beneficiaries : state.beneficiariesCommerceOT,
        isFetching: false,
    }),

    [types.LIST_BENEFICIARIES_PEOPLE_REQUEST]: (state) => ({
        ...state,
        beneficiariesPeople: [],
        isFetchingPeople: true,
    }),
    [types.LIST_BENEFICIARIES_PEOPLE_FAILURE]: (state) => ({
        ...state,
        isFetchingPeople: false,
    }),
    [types.LIST_BENEFICIARIES_PEOPLE_SUCCESS]: (state, action) => ({
        ...state,
        beneficiariesPeople: action.beneficiaries,
        isFetchingPeople: false,
    }),
    [types.LIST_MOVEMENTS_REQUEST]: (state) => ({
        ...state,
        movementsWally: [],
        isFetchingMovemets: true,
    }),
    [types.LIST_MOVEMENTS_SUCCESS]: (state, action) => ({
        ...state,
        movementsWally: action.movementsWally,
        isFetchingMovemets: false,
    }),
    [types.LIST_MOVEMENTS_FAILURE]: (state) => ({
        ...state,
        movementsWally: [],
        isFetchingMovemets: false,
    }),
    [types.LIST_MOVEMENTS_PENDING_REQUEST]: (state) => ({
        ...state,
        movementsPendingWally: [],
        isFetchingMovemets: true,
    }),
    [types.LIST_MOVEMENTS_PENDING_SUCCESS]: (state, action) => ({
        ...state,
        movementsPendingWally: action.movementsPendingWally,
        isFetchingMovemets: false,
    }),
    [types.LIST_MOVEMENTS_PENDING_FAILURE]: (state) => ({
        ...state,
        movementsPendingWally: [],
        isFetchingMovemets: false,
    }),
    [types.REFRESH_PENDING_TRANSACTIONS_WALLY_QUANTITY_SUCCESS]: (state, action) => ({
        ...state,
        pendingTransactionsWallyQuantity: action.pendingTransactionsWallyQuantity,
    }),
    [types.PATH_REDIRECT_TO_WALLY]: (state, action) => ({
        ...state,
        pathRedirectToWally: action.pathRedirectToWally,
    }),
    [types.SET_ACCESS_FROM_MENU]: (state, action) => ({
        ...state,
        accessFromMenu: action.accessFromMenu,
    }),
});

export const actions = {
    activateRequest: makeActionCreator(types.ACTIVATE_REQUEST, "account", "onFinish"),
    updateRequest: makeActionCreator(types.UPDATE_USERWALLY_REQUEST, "account", "onFinish"),
    desactivateRequest: makeActionCreator(types.DESACTIVATE_USERWALLY_REQUEST, "account", "onFinish"),
    favoriteRequest: makeActionCreator(types.FAVORITE_REQUEST, "beneficiary"),
    favoriteSuccess: makeActionCreator(types.FAVORITE_SUCCESS, "beneficiaries", "beneficiarySelected"),
    setSelectedAccount: makeActionCreator(types.SET_SELECTED_ACCOUNT, "account"),
    setSelectedBeneficiary: makeActionCreator(types.SET_SELECTED_BENEFICIARY, "beneficiary"),
    userWallyRequest: makeActionCreator(types.READ_WALLY_USER_REQUEST),
    userWallyFailure: makeActionCreator(types.READ_WALLY_USER_FAILURE),
    userWallySuccess: makeActionCreator(
        types.READ_WALLY_USER_SUCCESS,
        "beneficiary",
        "account",
        "active",
        "phoneValid",
        "fetching",
    ),

    listBeneficiariesCommerceRequest: makeActionCreator(
        types.LIST_BENEFICIARIES_COMMERCE_REQUEST,
        "countryCode",
        "start",
        "end",
    ),
    listBeneficiariesCommerceFailure: makeActionCreator(types.LIST_BENEFICIARIES_COMMERCE_FAILURE),
    listBeneficiariesCommerceSuccess: makeActionCreator(
        types.LIST_BENEFICIARIES_COMMERCE_SUCCESS,
        "beneficiaries",
        "countryCode",
    ),

    listBeneficiariesPeopleRequest: makeActionCreator(
        types.LIST_BENEFICIARIES_PEOPLE_REQUEST,
        "phoneNumbers",
        "arrContacts",
        "onFinish",
    ),
    listBeneficiariesPeopleFailure: makeActionCreator(types.LIST_BENEFICIARIES_COMMERCE_FAILURE),
    listBeneficiariesPeolpeSuccess: makeActionCreator(types.LIST_BENEFICIARIES_PEOPLE_SUCCESS, "beneficiaries"),
    listMovementsRequest: makeActionCreator(types.LIST_MOVEMENTS_REQUEST, "onFinish"),
    listMovementsSuccess: makeActionCreator(types.LIST_MOVEMENTS_SUCCESS, "movementsWally"),
    listMovementsFailure: makeActionCreator(types.LIST_MOVEMENTS_FAILURE),
    listMovementsPendingRequest: makeActionCreator(types.LIST_MOVEMENTS_PENDING_REQUEST, "onFinish"),
    listMovementsPendingSuccess: makeActionCreator(types.LIST_MOVEMENTS_PENDING_SUCCESS, "movementsPendingWally"),
    listMovementsPendingFailure: makeActionCreator(types.LIST_MOVEMENTS_PENDING_FAILURE),
    deleteMovementPendingRequest: makeActionCreator(types.DELETE_MOVEMENT_PENDING_REQUEST, "movement", "onFinish"),
    setPathRedirectToWally: makeActionCreator(types.PATH_REDIRECT_TO_WALLY, "pathRedirectToWally"),
    setAccessFromMenu: makeActionCreator(types.SET_ACCESS_FROM_MENU, "accessFromMenu"),
};

export const selectors = {
    getSelectedAccount: ({ wally }) => wally.selectedAccount,
    getSelectedBeneficiary: ({ wally }) => wally.selectedBeneficiary,
    getUserWally: ({ wally }) => wally.userWally,
    getAccountWally: ({ wally }) => wally.accountWally,
    getMovementsWally: ({ wally }) => wally.movementsWally,
    getMovementsPendingWally: ({ wally }) => wally.movementsPendingWally,
    getFetchingMovementsWally: ({ wally }) => wally.isFetchingMovemets,
    getIsActiveWally: ({ wally }) => wally.isActiveWally,
    getIsPhoneValid: ({ wally }) => wally.isPhoneValid,
    getBeneficiariesCommercePA: ({ wally }) => wally.beneficiariesCommercePA,
    getBeneficiariesCommerceOT: ({ wally }) => wally.beneficiariesCommerceOT,
    getBeneficiariesPeople: ({ wally }) => wally.beneficiariesPeople,
    getPendingTransactionsWallyQuantity: ({ wally }) => wally.pendingTransactionsWallyQuantity,
    isFetching: ({ wally }) => wally.isFetching,
    isFetchingPeople: ({ wally }) => wally.isFetchingPeople,
    getPathRedirectToWally: ({ wally }) => wally.pathRedirectToWally,
    getAccessFromMenu: ({ wally }) => wally.accessFromMenu,
};
