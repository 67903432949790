import * as API from "middleware/api";

export const fetchCampaign = (environmentSection) =>
    API.executeWithAccessToken("/campaigns.readRandomCampaignBySection", { section: environmentSection });

export const fetchCampaignsAnonymous = (environmentSection) =>
    API.executeAnonymous("/campaigns.readRandomCampaignBySectionAnonymous", { section: environmentSection });

export const fetchCampaignImage = (params) => API.executeAnonymous("/campaigns.readCampaignImage", params);

export const dismissPermanentCampaign = (idCampaign) =>
    API.executeWithAccessToken("/campaigns.userDismissCampaign", idCampaign);
