import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import DocumentField from "pages/_components/fields/Document";
import PhoneInput from "pages/_components/fields/PhoneInput";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from "uuid";
import TextField from "pages/_components/fields/TextField";
import Row from "pages/_components/Row";
import React, { useEffect } from "react";
import Col from "react-bootstrap/lib/Col";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import { DEFAULT_REQUIRED } from "util/validationSchemaUtil";
import { bool, func, shape, string, arrayOf } from "prop-types";
import _ from "lodash";
import FieldLabel from "pages/_components/fields/FieldLabel";

const FORM_ID = "fixed.term.deposit.beneficiary.send";
const PercentageBeneficiaryForm = ({
    isDesktop,
    mode = "edit",
    currentLang,
    saveCallback,
    beneficiaryData,
    documentData,
    beneficiaryList,
    beneficiaryRelationshipList,
    isCorporate,
    isFetching,
    isEditMode,
}) => {
    const preferredCountries = ["pa", "ve"];
    const phoneDefaultCountry = "pa";
    const areaCodes = { ve: ["412", "414", "416", "424", "426"] };
    const mobilizationType = [
        {
            id: i18n.get("fixed.term.deposit.beneficiary.send.mobilization.type.indistinct"),
            label: i18n.get("fixed.term.deposit.beneficiary.send.mobilization.type.indistinct"),
        },
        {
            id: i18n.get("fixed.term.deposit.beneficiary.send.mobilization.type.joint"),
            label: i18n.get("fixed.term.deposit.beneficiary.send.mobilization.type.joint"),
        },
    ];
    const [mobilizationTypeSelected, setMobilizationTypeSelected] = React.useState(
        mobilizationType.filter(
            (item) => item.id === i18n.get("fixed.term.deposit.beneficiary.send.mobilization.type.indistinct"),
        ),
    );
    const genericProps = {
        mode,
        lang: currentLang,
        fromBackoffice: false,
        isRequired: true,
        idActivity: FORM_ID,
        isCorporate: bool.isRequired,
    };

    const [mobilizationTooltip, setMobilizationTooltip] = React.useState(
        "fixed.term.deposit.beneficiary.send.mobilization.type.indistinct.tooltip",
    );
    useEffect(() => {
        if (
            mobilizationTypeSelected[0]?.id ===
            i18n.get("fixed.term.deposit.beneficiary.send.mobilization.type.indistinct")
        ) {
            setMobilizationTooltip("fixed.term.deposit.beneficiary.send.mobilization.type.indistinct.tooltip");
        } else {
            setMobilizationTooltip("fixed.term.deposit.beneficiary.send.mobilization.type.joint.tooltip");
        }
    }, [mobilizationTypeSelected]);
    const initFormValues = {
        name: beneficiaryData?.name || "",
        document: beneficiaryData?.document || {
            documentCountry: "",
            documentType: "",
            documentNumber1: "",
            documentNumber2: "",
            idNumber1: "",
            idNumber2: "",
        },
        phone: beneficiaryData?.phone || "",
        percentage: beneficiaryData?.percentage || "",
        relationship: beneficiaryData?.relationship || "",
        id: beneficiaryData?.id || "",
    };

    const isValidPercentage = (value) => {
        let beneficiaryListUpdate = [...(beneficiaryList || [])];
        if (beneficiaryData?.id) {
            beneficiaryListUpdate = beneficiaryListUpdate.filter(
                (item) => item && item.id && item.id !== beneficiaryData.id,
            );
        }

        const currentPercentage = beneficiaryListUpdate?.reduce((acc, item) => acc + (item?.percentage || 0), 0) || 0;

        return currentPercentage + +value <= 100;
    };

    const validateDuplicated = (document, errors) => {
        beneficiaryList.forEach((beneficiary) => {
            if (_.isEqual(beneficiary?.document, document) && !isEditMode) {
                // eslint-disable-next-line no-param-reassign
                errors.document = {
                    documentNumber1: i18n.get("fixed.term.deposit.beneficiary.send.duplicated.documentnumber"),
                };
            }
        });
        return errors;
    };

    const validateForm = (values) => {
        const errors = {};
        if (!values.name && !isCorporate) {
            errors.name = i18n.get(DEFAULT_REQUIRED);
        }
        if (!values.mobilization && isCorporate) {
            errors.mobilization = { mobilization: i18n.get(DEFAULT_REQUIRED) };
        }
        if (!values.document?.documentCountry) {
            errors.document = { documentCountry: i18n.get(DEFAULT_REQUIRED) };
        }
        if (!values.document?.documentType) {
            errors.document = { documentType: i18n.get(DEFAULT_REQUIRED) };
        }
        if (!values.document?.documentNumber1) {
            errors.document = { documentNumber1: i18n.get(DEFAULT_REQUIRED) };
        }
        if (values.document?.documentCountry === "PA" && values.document?.documentType === "CIP") {
            if (!values.document?.documentNumber2) {
                errors.document = { documentNumber2: i18n.get(DEFAULT_REQUIRED) };
            }
        }
        if (
            (values.document?.documentCountry === "PA" ||
                (values.document?.documentCountry === "VE" && values.document?.documentType === "CED")) &&
            !values.document?.idNumber1
        ) {
            errors.document = { idNumber1: i18n.get(DEFAULT_REQUIRED) };
        }
        if (values.document?.documentCountry === "PA" && !values.document?.idNumber2) {
            errors.document = { idNumber2: i18n.get(DEFAULT_REQUIRED) };
        }
        if (!values.phone?.value && !isCorporate) {
            errors.phone = { value: i18n.get(DEFAULT_REQUIRED) };
        }
        if (!values.percentage && !isCorporate) {
            errors.percentage = i18n.get(DEFAULT_REQUIRED);
        } else if (!isValidPercentage(values.percentage) && !isCorporate) {
            errors.percentage = i18n.get(`${FORM_ID}.invalid.percentage`);
        }
        if (!values.relationship && !isCorporate) {
            errors.relationship = i18n.get(DEFAULT_REQUIRED);
        }
        validateDuplicated(values?.document, errors);
        return errors;
    };

    const renderForm = () => (
        <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initFormValues}
            validate={validateForm}
            baseSchema={() =>
                Yup.object().shape({
                    document: Yup.object().shape({
                        documentCountry: Yup.string().required(i18n.get(DEFAULT_REQUIRED)),
                        documentType: Yup.string().required(i18n.get(DEFAULT_REQUIRED)),
                        documentNumber1: Yup.string().required(i18n.get(DEFAULT_REQUIRED)),
                        documentNumber2: Yup.string().when(["documentCountry", "documentType"], {
                            is: (documentCountry, documentType) => documentCountry === "PA" && documentType === "CIP",
                            then: Yup.string().required(i18n.get(DEFAULT_REQUIRED)),
                        }),
                        idNumber1: Yup.string().when(["documentCountry", "documentType"], {
                            is: (documentCountry, documentType) =>
                                documentCountry === "PA" || (documentCountry === "VE" && documentType === "CED"),
                            then: Yup.string().required(i18n.get(DEFAULT_REQUIRED)),
                        }),
                        idNumber2: Yup.string().when("documentCountry", {
                            is: "PA",
                            then: Yup.string().required(i18n.get(DEFAULT_REQUIRED)),
                        }),
                    }),
                    name: Yup.string().required(i18n.get(DEFAULT_REQUIRED)),
                    phone: Yup.object()
                        .shape({
                            value: Yup.string(),
                        })
                        .when("isCorporate", {
                            is: false,
                            then: Yup.object().shape({
                                value: Yup.string().required(i18n.get(DEFAULT_REQUIRED)),
                            }),
                            otherwise: Yup.object().shape({
                                value: Yup.string().notRequired(),
                            }),
                        }),

                    percentage: Yup.string().when("isCorporate", {
                        is: false,
                        then: Yup.string()
                            .required(i18n.get(DEFAULT_REQUIRED))
                            .test("percentage-is-valid", i18n.get(`${FORM_ID}.invalid.percentage`), isValidPercentage),
                        otherwise: Yup.string().notRequired(),
                    }),

                    relationship: Yup.string().when("isCorporate", {
                        is: true,
                        then: Yup.string().notRequired(),
                        otherwise: Yup.string().required(i18n.get(DEFAULT_REQUIRED)),
                    }),
                })
            }
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);

                if (saveCallback) {
                    saveCallback({ ...values, id: values.id || uuidv4() });
                }
            }}>
            {({ submitForm }) => (
                <Form className="full-width px-5 px-md-0">
                    <Box display="flex" column fullWidth fullHeight>
                        <Row gapX="5" heightAuto className="mb-7 mb-md-12 px-0">
                            <Col xs={12} md={12}>
                                {/* TODO (FIELD NAME) */}
                                {isCorporate ? (
                                    <>
                                        <Box className="form-group form-group--select">
                                            <FieldLabel
                                                idField="mobilizationLabel"
                                                align="right"
                                                isRequired
                                                labelText={i18n.get(
                                                    "fixed.term.deposit.beneficiary.send.mobilization.label",
                                                )}
                                                tooltipText={mobilizationTooltip}
                                                tooltipPosition="bottom-centered"
                                                renderLabel
                                            />
                                        </Box>
                                        <Field
                                            {...genericProps}
                                            component={FormFieldsComponents.Selector}
                                            key="mobilization"
                                            name="mobilization"
                                            idField="mobilization"
                                            defaultValue={mobilizationTypeSelected}
                                            setValue={setMobilizationTypeSelected}
                                            labelIdField="react-select-mobilization-input"
                                            idForm={FORM_ID}
                                            optionList={mobilizationType || []}
                                            renderAs="combo"
                                            onChange={(option) => {
                                                setMobilizationTypeSelected(option);
                                            }}
                                            labelNoMarginTop
                                        />
                                    </>
                                ) : (
                                    <Col xs={12} md={12}>
                                        <Field
                                            autoFocus
                                            component={TextField}
                                            hidePlaceholder
                                            idForm={FORM_ID}
                                            name="name"
                                            type="text"
                                            labelNoMarginTop
                                            // TODO (Tooltip LABEL)
                                            tooltipText="Tooltip de movilización"
                                            tooltipPosition="bottom-centered"
                                        />
                                    </Col>
                                )}
                            </Col>
                            <Col xs={12} md={12}>
                                <Field
                                    countries={documentData?.countryList}
                                    documentCountryMap={documentData?.documentTypeList}
                                    idNumberMap={documentData?.idNumberMap1}
                                    idNumber2Map={documentData?.idNumberMap2}
                                    component={DocumentField}
                                    name="document"
                                    reducedColumnGap
                                    labelNoMarginTop
                                    spacingInputs="mb-5 mb-md-7"
                                    {...(!isDesktop && { idNumberStyle2: true })}
                                />
                            </Col>
                            {!isCorporate && (
                                <>
                                    <Col xs={12} md={12}>
                                        <Field
                                            component={PhoneInput}
                                            idForm={FORM_ID}
                                            idField={`${FORM_ID}.mobilePhone`}
                                            name="phone"
                                            isDesktop={isDesktop}
                                            className="remove-flex"
                                            preferredCountries={preferredCountries}
                                            areaCodes={areaCodes}
                                            enableAreaCodes={preferredCountries}
                                            country={phoneDefaultCountry}
                                            labelNoMarginTop
                                        />
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <Row>
                                            <Col xs={6}>
                                                <Field
                                                    {...genericProps}
                                                    component={FormFieldsComponents.Selector}
                                                    key="relationship"
                                                    name="relationship"
                                                    idField="relationship"
                                                    labelIdField="react-select-relationship-input"
                                                    idForm={FORM_ID}
                                                    optionList={beneficiaryRelationshipList || []}
                                                    renderAs="combo"
                                                    labelNoMarginTop
                                                />
                                            </Col>
                                            <Col xs={6}>
                                                <Field
                                                    {...genericProps}
                                                    component={TextField}
                                                    idForm={FORM_ID}
                                                    key="percentage"
                                                    name="percentage"
                                                    idField="percentage"
                                                    isDesktop={isDesktop}
                                                    placeholder=""
                                                    tooltipText={`${FORM_ID}.percentage.tooltip`}
                                                    tooltipPosition="right-centered"
                                                    type="number"
                                                    maxLength={3}
                                                    labelNoMarginTop
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </>
                            )}
                        </Row>
                        <Box className="pb-9 pb-md-12 mt-auto">
                            <Button
                                loading={isFetching}
                                btn-small
                                bsStyle="primary"
                                className="mt-auto"
                                label={isCorporate ? "beneficiaryPercentageList.add.signers.label" : "global.continue"}
                                onClick={() => {
                                    submitForm();
                                }}
                                block
                            />
                        </Box>
                    </Box>
                </Form>
            )}
        </Formik>
    );

    return <>{renderForm()}</>;
};

PercentageBeneficiaryForm.propTypes = {
    isDesktop: bool,
    mode: string,
    currentLang: string,
    saveCallback: func,
    beneficiaryData: shape({}),
    documentData: shape({}),
    beneficiaryList: arrayOf(shape({})),
    beneficiaryRelationshipList: arrayOf(shape({})),
    isCorporate: bool.isRequired,
    isFetching: bool,
    isEditMode: bool,
};

PercentageBeneficiaryForm.defaultProps = {
    isDesktop: true,
    mode: undefined,
    currentLang: undefined,
    saveCallback: undefined,
    beneficiaryData: undefined,
    documentData: undefined,
    beneficiaryList: {},
    beneficiaryRelationshipList: [],
    isFetching: false,
    isEditMode: false,
};

export default PercentageBeneficiaryForm;
