import * as API from "middleware/api";

export const getPreApprovedProductsList = () => API.executeWithAccessToken("/preApprovedProduct.list");

export const preApprovedProductChangeStatus = (productType, productStatusCode) =>
    API.executeWithAccessToken("/preApprovedProduct.changeStatus", { productType, productStatusCode });

export const getPreApprovedProductsFormList = () => API.executeWithAccessToken("/preApprovedProduct.pre");

export const preApprovedProductsSendRequest = (productType, salary, workPlace, cardType, productStatusCode) => {
    const urlMap = {
        TDC: "/preApprovedProduct.creditCard.send",
        default: "/preApprovedProduct.loan.send",
    };
    const url = urlMap[productType] || urlMap.default;

    return API.executeWithAccessToken(url, {
        productType,
        salary,
        workPlace,
        cardType,
        productStatusCode,
    });
};
