import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import ShortCutBtn from "pages/_components/ShortCutBtn";
import { bool, func } from "prop-types";
import React, { useEffect } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { push } from "react-router-redux/actions";
import { actions as kuaraActions, selectors as kuaraSelectors } from "reducers/kuara";
import { compose } from "redux";
import kuaraVersionNumber from "util/kuaraVersionNumber";

const OneTouchAccessKuara = (props) => {
    const { dispatch, fetching } = props;

    const handleClose = () => {
        dispatch(push("/desktop"));
    };

    useEffect(() => {
        dispatch(kuaraActions.validateUserKuaraRequest());
    }, []);

    const buttonsArrayOptions = {
        wallyOpcions: [
            {
                image: "images/shortCutBtn/SCBsend.svg",
                label: "kuara.home.send",
                href: "/sendKuara",
            },
            {
                image: "images/shortCutBtn/SCBmovement.svg",
                label: "kuara.home.movement",
                href: "/lastMovementsKuara",
            },
            {
                image: "images/shortCutBtn/SCBsettings.svg",
                label: "kuara.home.settings",
                href: "/settingsKuara",
            },
        ],
    };

    let buttonsArray = buttonsArrayOptions.wallyOpcions;

    if (kuaraVersionNumber(2) && !buttonsArray.some((item) => item.label === "kuara.home.commerce")) {
        buttonsArray = [
            ...buttonsArray,
            {
                image: "images/commerce.svg",
                label: "kuara.home.commerce",
                href: "/sendWally",
            },
        ];
    }

    return (
        <PageLoading loading={fetching} classicStyle={false}>
            <Box display="flex" column alignX="flex-start" fullHeight fullWidth>
                <Box
                    component="header"
                    display="flex"
                    alignX="between"
                    background="kuara-secondary"
                    className="pt-2-safe px-2 pb-2">
                    <Box display="flex" alignX="flex-start" alignY="center" className="p-4">
                        <Image height="9" src="images/icons/MbBanescoLogo.svg" />
                    </Box>

                    <Box display="flex" alignX="flex-end" alignY="center" className="p-4">
                        <Button
                            image="images/icons/cross16.svg"
                            className="btn-login py-4"
                            bsStyle="link"
                            onClick={handleClose}
                            inverse
                        />
                    </Box>
                </Box>
                <Box
                    component="main"
                    display="flex"
                    alignY="center"
                    alignX="center"
                    column
                    background="component-background"
                    className="pb-7"
                    fullHeight
                    fullWidth>
                    <Box display="flex" className="mb-10" position="relative" fullWidth>
                        <Box position="absolute" top="0" fullWidth>
                            <Image src="images/logos/kuaraLogoBGDown.svg" color="kuara-secondary" width="100" />
                        </Box>
                        <Box display="flex" alignX="center" zIndex="1" fullWidth className="mt-3">
                            <Image src="images/logos/kuaraHorizontal.svg" />
                        </Box>
                    </Box>

                    <Box display="flex" alignY="center" className="px-8 my-auto" fullWidth>
                        <Row gapX="3" gapY="5" className="height-auto">
                            {buttonsArray.map((el) => (
                                <Col key={`wally-btn-${el.label}`} xs={4} className="height-auto">
                                    <Box display="flex" alignX="center" fullWidth fullHeight>
                                        <ShortCutBtn
                                            key={`${el.label}_${el.image}`}
                                            image={el.image}
                                            label={el.label}
                                            href={el.href}
                                            // onClick={() => {
                                            //     dispatch(replace("/formCustom/transferInternal"));
                                            // }}
                                            className="kuara-primary-color-btn"
                                            showNotification={el.showNotification}
                                            textColor="kuara-primary"
                                        />
                                    </Box>
                                </Col>
                            ))}
                        </Row>
                    </Box>
                </Box>
            </Box>
        </PageLoading>
    );
};

const mapStateToProps = (state) => ({
    fetching: kuaraSelectors.isFetching(state),
});

OneTouchAccessKuara.propTypes = {
    dispatch: func.isRequired,
    fetching: bool,
};

OneTouchAccessKuara.defaultProps = {
    fetching: true,
};

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(OneTouchAccessKuara));
