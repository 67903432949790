import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import RadioButtonGroup from "pages/forms/_components/_fields/_commons/RadioButtonGroup";
import ServiceRemarkableInfo from "pages/servicePayments/_components/ServiceRemarkableInfo";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { push } from "react-router-redux";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { actions as payServiceActions, selectors as payServiceSelectors } from "reducers/payService";
import { actions as creditCardActions, selectors as creditCardSelectors } from "reducers/creditCard";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as i18nSelectors } from "reducers/i18n";
import { compose } from "redux";
import * as i18n from "util/i18n";
import { numberFormat } from "util/number";
import * as Yup from "yup";
import { initialValuesFastRecharge, listViewService } from "./hooks/dinamicFormHooks";
import fieldARP from "./hooks/field";
import { useAccountPaySelectorOptions, useCreditCardPaySelectorOptions } from "./hooks/servicePaymentFormHooks";

const { decimalSeparator, thousandSeparator } = numberFormat();
const FORM_ID = "servicePayment.modify";

const EditServicePayment = (props) => {
    const {
        isDesktop,
        history,
        dispatch,
        fetching,
        payService,
        field,
        creditCardList,
        accountsList,
        fetchingAccounts,
        currentLang,
        isActiveCorporate,
        ...rest
    } = props;
    const [fieldFiltered, setFieldFiltered] = useState([]);
    const creditCardOptions = useCreditCardPaySelectorOptions(creditCardList);
    const accountOptions = useAccountPaySelectorOptions(accountsList);
    const radiButtonOptions = [
        {
            id: "debitAccount",
            label: i18n.get("servicePayment.dinamicForm.label.account"),
        },
        {
            id: "creditCard",
            label: i18n.get("servicePayment.dinamicForm.label.creditCard"),
        },
    ];

    useEffect(() => {
        dispatch(payServiceActions.getPayServiceRequest(rest.match.params?.id));
    }, [rest.match.params?.id]);

    useEffect(() => {
        if (payService && rest.match.params?.id === `${payService?.idPaymentUser}`) {
            // Telered -> consulta de reglas
            if (payService?.extraData?.svcProviderId === "Telered") {
                dispatch(payServiceActions.listRulesBillerRequest(payService?.idBiller));
            } else {
                // ARP -> definicion de reglas por defecto
                dispatch(payServiceActions.listRulesBillerSuccess(fieldARP));
            }
        }
    }, [payService]);

    useEffect(() => {
        if (field.length > 0) {
            setFieldFiltered(field.filter((el) => el.fieldInq === true));
        }
    }, [field]);

    useEffect(() => {
        dispatch(accountsActions.listAccounts(["USD"]));
        dispatch(creditCardActions.listRequest());
    }, []);

    const handleSubmit = (values) => {
        payService.productType = values.typeAccount;
        payService.idProduct = values[values.typeAccount];
        payService.amountQuantity = values.SubTotal.quantity;
        payService.amountCurrency = values.SubTotal.currency;

        dispatch(payServiceActions.updateRequest(payService));
    };

    const handleBack = () => {
        history.goBack();
    };

    const handleCancel = () => {
        dispatch(push("/servicePayments"));
    };

    const onChangeAmount = (value, setFieldValue) => {
        let tax = 0;
        const quantity = typeof value.quantity === "string" ? Number(value.quantity) : value.quantity;
        if (payService?.extraData?.hasTax && quantity > 0) {
            tax = Number(((quantity * payService.extraData.tax) / 100).toFixed(2));
            setFieldValue("TotalTax", { currency: value?.currency, quantity: tax.toFixed(2) });
        }
        setFieldValue("SubTotal", value);
    };

    return (
        <>
            <Head title="servicePayments.new.title" onBack={handleBack} />
            <MainContainer showLoader={fetching || fetchingAccounts}>
                <ServiceRemarkableInfo
                    title={i18n.get(`servicePayment.category.${payService?.category?.idServicePayment}`)}
                    logo={payService?.category?.logo}
                    subtitle={payService?.nameBiller}
                    className="mb-5 mb-md-7"
                    info={listViewService(fieldFiltered, payService)}
                />
                {fieldFiltered.length > 0 && (
                    <Formik
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize
                        initialValues={initialValuesFastRecharge(fieldFiltered, payService)}
                        validationSchema={Yup.object().shape({
                            SubTotal: Yup.string().required(i18n.get(`servicePayment.dinamicForm.validate.required`)),
                        })}
                        onSubmit={(values) => handleSubmit(values)}>
                        {({ values, setFieldValue }) => (
                            <Form className={classNames("display-flex flex-column  ", { "full-height": !isDesktop })}>
                                <Row {...(!isDesktop && { gapY: "0" })} className="mb-5">
                                    <Col xs={12} md={6}>
                                        <Box
                                            component="section"
                                            background="white"
                                            borderRadius={isDesktop ? "default" : "top-default"}
                                            className={
                                                isActiveCorporate
                                                    ? "pt-0 pt-md-2 pb-6 pb-md-8 px-5 px-md-9 mx-n-5 "
                                                    : "pt-5 pt-md-12 pb-5 pb-md-12 px-5 px-md-9 mx-n-5"
                                            }
                                            fullHeight>
                                            {!isActiveCorporate && (
                                                <RadioButtonGroup
                                                    className="d-flex"
                                                    inLineControl={false}
                                                    name="beneficiaryOption"
                                                    selectorId="beneficiaryOption"
                                                    onChange={(value) => setFieldValue("typeAccount", value)}
                                                    options={radiButtonOptions}
                                                    value={values.typeAccount}
                                                    radioButtonGroupFlex
                                                    radioLabelClassName="color-text-boton"
                                                    cControlClassName="mr-3"
                                                />
                                            )}

                                            <Box display="flex" fullWidth className=" pt-2 pt-md-4 pb-2">
                                                <Field
                                                    component={FormFieldsComponents.ProductselectorCustom}
                                                    data={
                                                        values.typeAccount === "debitAccount"
                                                            ? accountOptions
                                                            : creditCardOptions
                                                    }
                                                    key={values.typeAccount}
                                                    name={values.typeAccount}
                                                    renderAs="combo"
                                                    value={values[values.typeAccount]}
                                                    noLabelEditMode={!isActiveCorporate}
                                                    idField={values.typeAccount}
                                                    customPlaceholder={i18n.get(
                                                        `servicePayment.dinamicForm.placeholder.account`,
                                                    )}
                                                    labelMap={{
                                                        [currentLang]: i18n.get(
                                                            "servicePayment.dinamicForm.label.account",
                                                        ),
                                                    }}
                                                    optionClassName="needsclick"
                                                    mode="edit"
                                                    lang={currentLang}
                                                    isRequired
                                                    idActivity={FORM_ID}
                                                />
                                            </Box>
                                        </Box>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Box
                                            component="section"
                                            background="white"
                                            borderRadius={isDesktop ? "default" : "bottom-default"}
                                            className="pt-0 pt-md-8 pb-6 pb-md-8 px-5 px-md-9 mx-n-5 "
                                            alignY="center"
                                            fullHeight
                                            display={payService?.extraData?.hasTax ? "block" : "flex"}>
                                            <Box display="flex" fullWidth className="pt-2 pt-md-4 pb-2">
                                                <Field
                                                    component={FormFieldsComponents.Amount}
                                                    data={{
                                                        decimalSeparator,
                                                        options: [{ id: "USD", label: "USD" }],
                                                        precision: 2,
                                                        thousandSeparator,
                                                    }}
                                                    key="SubTotal"
                                                    name="SubTotal"
                                                    idField="SubTotal"
                                                    value={{ currency: "USD", quantity: values?.amount }}
                                                    onChange={(value) => onChangeAmount(value, setFieldValue)}
                                                    customPlaceholderCurrency=""
                                                    customPlaceholderQuantity="0.00"
                                                    quantityTextAlign="text-left"
                                                    currencyBox={false}
                                                    labelMap={{
                                                        [currentLang]: i18n.get(
                                                            "servicePayment.dinamicForm.label.subTotal",
                                                        ),
                                                    }}
                                                    mode="edit"
                                                    lang={currentLang}
                                                    isRequired
                                                    idActivity={FORM_ID}
                                                />
                                            </Box>
                                            {payService?.extraData?.hasTax && (
                                                <Box display="flex" fullWidth className="pt-2 pt-md-4 pb-2">
                                                    <Field
                                                        component={FormFieldsComponents.Amount}
                                                        data={{
                                                            decimalSeparator,
                                                            options: [{ id: "USD", label: "USD" }],
                                                            precision: 2,
                                                            thousandSeparator,
                                                        }}
                                                        key="TotalTax"
                                                        name="TotalTax"
                                                        idField="TotalTax"
                                                        value={values.TotalTax}
                                                        customPlaceholderCurrency=""
                                                        customPlaceholderQuantity="0.00"
                                                        quantityTextAlign="text-left"
                                                        currencyBox={false}
                                                        disabled
                                                        labelMap={{
                                                            [currentLang]: i18n.get(
                                                                "servicePayment.dinamicForm.label.tax",
                                                            ),
                                                        }}
                                                        className="pt-2 pt-md-4 pb-2"
                                                        mode="edit"
                                                        lang={currentLang}
                                                        isRequired
                                                        idActivity={FORM_ID}
                                                    />
                                                </Box>
                                            )}
                                        </Box>
                                    </Col>
                                </Row>

                                <Box {...(!isDesktop && { className: "mt-auto" })}>
                                    <Row gapY={isDesktop ? "8" : "3"}>
                                        <Col xs={12} md={3} mdOffset={3}>
                                            <Button
                                                label="global.cancel"
                                                type="button"
                                                className="btn-outline"
                                                onClick={handleCancel}
                                                block
                                            />
                                        </Col>
                                        <Col xs={12} md={3} {...(!isDesktop && { className: "grid-reversed" })}>
                                            <Button
                                                bsStyle="primary"
                                                label="global.save.change"
                                                loading={fetching}
                                                type="submit"
                                                block
                                            />
                                        </Col>
                                    </Row>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                )}
            </MainContainer>
        </>
    );
};

const mapStateToProps = (state) => ({
    fetching: payServiceSelectors.isFetching(state),
    payService: payServiceSelectors.getPayService(state),
    field: payServiceSelectors.getField(state),
    creditCardList: creditCardSelectors.getList(state),
    accountsList: accountsSelectors.getAccounts(state),
    fetchingAccounts: accountsSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    isActiveCorporate: sessionSelectors.isActiveCorporate(state),
});

EditServicePayment.propTypes = {
    isDesktop: bool.isRequired,
    dispatch: func.isRequired,
    history: shape({}).isRequired,
    fetching: bool,
    payService: shape({}),
    field: arrayOf(shape({})),
    creditCardList: arrayOf(shape({})),
    accountsList: arrayOf(shape({})),
    fetchingAccounts: bool,
    currentLang: string,
    isActiveCorporate: bool,
};

EditServicePayment.defaultProps = {
    fetching: false,
    payService: {},
    field: [],
    creditCardList: [],
    accountsList: [],
    fetchingAccounts: false,
    currentLang: "",
    isActiveCorporate: false,
};

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(EditServicePayment));
