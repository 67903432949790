import { CORPORATE_GROUP_ENVIRONMENT_TYPE } from "constants.js";
import { bool, func, shape, string, number } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";

import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import GridTable from "pages/_components/GridTable/GridTable";
import I18n from "pages/_components/I18n";
import * as i18n from "util/i18n";
import Image from "pages/_components/Image";
import ChevromRight from "pages/_components/listItem/ChevromRight";
import Text from "pages/_components/Text";
import FormattedDate from "pages/_components/FormattedDate";
import TransactionStatus from "pages/transactions/_components/TransactionStatus";
import BanescoCheckbox from "pages/_components/fields/BanescoCheckbox";

class TransactionsItem extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        isDeletingDraft: bool.isRequired,
        transaction: shape({
            cancelEnabled: bool.isRequired,
            transaction: shape({
                channel: string,
                idTransaction: string,
                creationDateTime: string,
                submitDateTime: string,
                activityName: string,
                idTransactionStatus: string,
            }),
            transactionAmounts: shape(),
        }).isRequired,
        showEnvironment: bool,
        activeEnvironment: shape({ type: string.isRequired }).isRequired,
        hasTicket: bool,
        allDeleteCheckBox: bool,
        idx: number,
        isPending: bool,
        user: shape({}).isRequired,
        canSign: bool,
    };

    static defaultProps = {
        showEnvironment: false,
        hasTicket: true,
        allDeleteCheckBox: false,
        idx: null,
        isPending: false,
        canSign: false,
    };

    // eslint-disable-next-line class-methods-use-this
    getDateTime(data, creationDateTime, idParentTransaction, submitDateTime) {
        if (idParentTransaction && data?.scheduler?.selectedOption !== "TODAY") {
            return submitDateTime;
        }
        return creationDateTime;
    }

    handleDeleteTransactionDraft = (event, idTransaction) => {
        event.stopPropagation();
        event.preventDefault();
        const { dispatch } = this.props;
        dispatch(transactionsActions.deleteDraftRequest(idTransaction));
    };

    handleCheckboxChange = (isChecked, transaction) => {
        const { dispatch } = this.props;
        if (isChecked) {
            dispatch(transactionsActions.addTransactionToProcess(transaction));
        } else {
            dispatch(transactionsActions.removeTransactionToProcess(transaction.idTransaction));
        }
    };

    render() {
        const {
            isDesktop,
            isDeletingDraft,
            transaction: propsTransaction,
            showEnvironment,
            activeEnvironment,
            hasTicket,
            allDeleteCheckBox,
            idx,
            isPending,
            canSign,
            user,
        } = this.props;
        const { transaction, transactionAmounts } = propsTransaction;
        const {
            idTransaction,
            creationDateTime,
            activityName,
            idTransactionStatus,
            environmentName,
            environmentType,
            channel,
            data,
            idParentTransaction,
            submitDateTime,
            programed,
        } = transaction;
        const [currency] = Object.keys(transactionAmounts);
        const amount = transactionAmounts[currency];
        const isEconomicGroup = activeEnvironment.type === CORPORATE_GROUP_ENVIRONMENT_TYPE;
        const extra = showEnvironment ? 1 : 0;
        const quantity =
            data?.amount?.quantity !== null && data?.amount?.quantity !== undefined ? data.amount.quantity : 0;
        const showAmount = amount !== 0 && quantity !== 0;
        let destinatary = null;

        if (data?.beneficiaryDescription !== null && data?.beneficiaryDescription !== undefined) {
            destinatary = data.beneficiaryDescription;
        } else if (data?.beneficiary?.name !== null && data?.beneficiary?.name !== undefined) {
            destinatary = data.beneficiary.name;
        } else if (isDesktop) {
            destinatary = "-";
        }

        const canSignAndNotOwner =
            canSign &&
            user.userId &&
            (user.userId !== transaction.idUserCreator || user.userId !== transaction.idUserEditor) &&
            transaction.signatures?.filter((signature) => signature.idUser === user.userId).length === 0;

        const content = (
            <>
                {!isDesktop && isPending && (
                    <GridTable.Data alignX="flex-start" alignY="center" columnStart={1} rowWidth={3} overflow="hidden">
                        {isPending && (
                            <BanescoCheckbox
                                name={`checkbox-${idx}`}
                                value="testing"
                                defaultChecked={!canSignAndNotOwner ? false : allDeleteCheckBox}
                                onChange={(e) => {
                                    this.handleCheckboxChange(e.target.checked, transaction);
                                }}
                                hideLabel
                                className="m-0"
                                disabled={!canSignAndNotOwner}
                            />
                        )}
                    </GridTable.Data>
                )}
                {isDesktop && (
                    <GridTable.Data
                        alignX={isPending ? "flex-start" : "center"}
                        alignY="center"
                        columnStart={1}
                        overflow="hidden">
                        {isPending && (
                            <BanescoCheckbox
                                name={`checkbox-${idx}`}
                                value="testing"
                                defaultChecked={!canSignAndNotOwner ? false : allDeleteCheckBox}
                                onChange={(e) => {
                                    this.handleCheckboxChange(e.target.checked, transaction);
                                }}
                                hideLabel
                                className="m-0"
                                disabled={!canSignAndNotOwner}
                            />
                        )}
                        <Box {...(isPending && { className: "pl-2" })}>
                            <Image
                                src={channel === "frontend" ? "images/channel-web.svg" : "images/channel-mobile.svg"}
                                className="svg-icon text-center"
                                ariaLabel={`channels.${channel}`}
                            />
                        </Box>
                    </GridTable.Data>
                )}
                <GridTable.Data
                    // eslint-disable-next-line no-nested-ternary
                    columnStart={isDesktop ? 2 : isPending ? 2 : 1}
                    alignX={isDesktop ? "center" : "flex-start"}
                    alignY={isDesktop ? "center" : "flex-end"}>
                    <Text ellipsis {...(!isDesktop && { bold: true })}>
                        {programed ? i18n.get("forms.transaction.ticket.typeOperation.program") : ""}
                        {activityName}
                    </Text>
                </GridTable.Data>
                {showEnvironment && (
                    <GridTable.Data
                        // eslint-disable-next-line no-nested-ternary
                        columnStart={isDesktop ? 3 : isPending ? 2 : 1}
                        columnWidth={isDesktop ? 1 : 2}
                        rowStart={isDesktop ? 1 : 2}
                        alignX={isDesktop ? "center" : "flex-start"}
                        alignY="center">
                        {!isDesktop && <Image src={`images/${environmentType}.svg`} className="svg-icon" />}
                        <Text ellipsis>{environmentName}</Text>
                    </GridTable.Data>
                )}
                <GridTable.Data
                    // eslint-disable-next-line no-nested-ternary
                    columnStart={isDesktop ? 3 + extra : isPending ? 2 : 1}
                    rowStart={isDesktop ? 1 : 3}
                    alignX={isDesktop ? "center" : "flex-start"}
                    alignY={isDesktop ? "center" : "flex-start"}
                    flexDirection="row">
                    <Text ellipsis {...(!isDesktop && { bold: true })} {...(isDesktop && { align: "center" })}>
                        <FormattedDate
                            date={this.getDateTime(data, creationDateTime, idParentTransaction, submitDateTime)}
                            anotherFormat="DD/MM/YYYY HH:mm"
                        />
                    </Text>
                </GridTable.Data>
                {destinatary && (
                    <GridTable.Data
                        // eslint-disable-next-line no-nested-ternary
                        columnStart={isDesktop ? 4 + extra : isPending ? 2 : 1}
                        rowStart={isDesktop ? 1 : 2}
                        alignX={isDesktop ? "center" : "flex-start"}
                        alignY="center">
                        <Text ellipsis {...(isDesktop && { align: "center" })}>
                            {destinatary}
                        </Text>
                    </GridTable.Data>
                )}
                {isDesktop ? (
                    <>
                        <GridTable.Data
                            columnStart={5}
                            columnWidth={1}
                            rowStart={1}
                            rowWidth={1}
                            alignX="flex-end"
                            alignY="center">
                            {showAmount ? (
                                <FormattedAmount size="6" currency={currency} quantity={quantity} />
                            ) : (
                                <Text ellipsis {...(isDesktop && { align: "right" })}>
                                    -
                                </Text>
                            )}
                        </GridTable.Data>
                        <GridTable.Data
                            columnStart={6}
                            columnWidth={1}
                            rowStart={1}
                            rowWidth={2}
                            alignX="center"
                            alignY="center">
                            <TransactionStatus showIcon={false} idTransactionStatus={idTransactionStatus} showLabel />
                        </GridTable.Data>
                    </>
                ) : (
                    <GridTable.Data
                        columnStart={isPending ? 3 : 2}
                        columnWidth={1}
                        rowStart={1}
                        rowWidth={3}
                        alignX="center"
                        alignY="center">
                        <Box display="flex" column alignX="center" alignY="flex-end" fullWidth fullHeight>
                            {showAmount && (
                                <FormattedAmount size="4" className="mb-2" currency={currency} quantity={quantity} />
                            )}
                            <TransactionStatus showIcon={false} idTransactionStatus={idTransactionStatus} showLabel />
                        </Box>
                    </GridTable.Data>
                )}
                <GridTable.Data
                    // eslint-disable-next-line no-nested-ternary
                    columnStart={isDesktop ? 7 + extra : isPending ? 4 : 3}
                    columnWidth={1}
                    rowStart={1}
                    rowWidth={3}
                    alignX="flex-end"
                    alignY="center"
                    flexDirection="row"
                    tabIndex="-1"
                    ariaHiden>
                    {!isDesktop && idTransactionStatus === "DRAFT" && (
                        <Button
                            type="button"
                            className="btn-only-icon"
                            block={false}
                            loading={isDeletingDraft}
                            onClick={(e) => this.handleDeleteTransactionDraft(e, idTransaction)}
                            bsStyle="circle">
                            <I18n
                                id="transactions.list.history.trashIcon.a11y"
                                componentProps={{ className: "visually-hidden" }}
                            />
                            <Image src="images/trash.svg" className="svg-icon" />
                        </Button>
                    )}
                    {!isEconomicGroup && hasTicket && <ChevromRight />}
                </GridTable.Data>
            </>
        );

        return content;
    }
}

const mapStateToProps = (state) => ({
    isDeletingDraft: transactionsSelectors.isDeletingDraft(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    user: sessionSelectors.getUser(state),
});

export default connect(mapStateToProps)(TransactionsItem);
