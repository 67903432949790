import * as API from "middleware/api";

export const preData = () => API.executeAnonymous("/enrollment.wizard.pre");

export const validateDocument = (documentCountry, documentType, documentNumber) =>
    API.executeAnonymous("/enrollment.wizard.validateDocument", {
        documentCountry,
        documentType,
        documentNumber,
    });

export const validateInvitationCode = (_code, _captcha, isSecondary, exchangeToken) =>
    API.executeAnonymous("/enrollment.wizard.validateInvitationCode", {
        _code,
        _captcha,
        isSecondary,
        exchangeToken,
    });

export const finishCreate = (_newUsername, _newPassword, _newPasswordRepeat, _exchangeToken, fingerPrintToken) =>
    API.executeWithExchangeToken(
        "/enrollment.wizard.finishCreate",
        { _newUsername, _newPassword, _newPasswordRepeat, fingerPrintToken },
        _exchangeToken,
    );

export const validateUser = (_usernameToValidate, _password, _captcha, _exchangeToken) =>
    API.executeWithExchangeToken(
        "/enrollment.wizard.validateUser",
        { _usernameToValidate, _password, _captcha },
        _exchangeToken,
    );

export const finishAssociate = (_exchangeToken) =>
    API.executeWithExchangeToken("/enrollment.wizard.finishAssociate", {}, _exchangeToken);

export const validateTokenActivationSelfie = (selfie, callbackType, callbackData, selfieNonTokenized, _exchangeToken) =>
    API.executeWithExchangeToken(
        "/enrollment.token.activation.validate.selfie",
        { _selfie: selfie, callbackType, callbackData, _selfieNonTokenized: selfieNonTokenized },
        _exchangeToken,
    );

export const validateTokenActivationDocument = (
    documentSelected,
    documentScannedList,
    ocrData,
    callbackType,
    callbackData,
    validateSelfieExchangeTokenId,
    _exchangeToken,
) =>
    API.executeWithExchangeToken(
        "/enrollment.token.activation.validate.document",
        {
            documentSelected,
            _documentScannedList: documentScannedList,
            ocrData,
            callbackType,
            callbackData,
            validateSelfieExchangeTokenId,
        },
        _exchangeToken,
    );

/**
 * AUTH Services
 */
export const validateTokenActivationSelfieAuth = (selfie, callbackType, callbackData, selfieNonTokenized) =>
    API.executeWithAccessToken("/enrollment.token.activation.validate.selfie.auth", {
        _selfie: selfie,
        callbackType,
        callbackData,
        _selfieNonTokenized: selfieNonTokenized,
    });

export const validateTokenActivationDocumentAuth = (
    documentSelected,
    documentScannedList,
    ocrData,
    callbackType,
    callbackData,
    validateSelfieExchangeTokenId,
) =>
    API.executeWithAccessToken("/enrollment.token.activation.validate.document.auth", {
        documentSelected,
        _documentScannedList: documentScannedList,
        ocrData,
        callbackType,
        callbackData,
        validateSelfieExchangeTokenId,
    });
