/* eslint-disable*/
import * as kuara from "middleware/kuara";
import { replace } from "react-router-redux";
import { types as loginTypes } from "reducers/login";
import { actions as notificationActions } from "reducers/notification";
import { selectors as sessionSelectors } from "reducers/session";
import globalTypes from "reducers/types/global";
import { selectors, types, actions as kuaraActions } from "reducers/kuara";
import { delay } from "redux-saga";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import * as configUtil from "util/config";
import { isMobileNative } from "util/device";
import * as i18n from "util/i18n";
import Button from "pages/_components/Button";
import React from "react";

const sagas = [
    takeEvery(types.ACTIVATE_REQUEST, activateKuara),
    takeEvery(types.DESACTIVATE_USERKUARA_REQUEST, desactivateKuara),
    takeEvery(types.UPDATE_USERKUARA_REQUEST, updateKuara),
    takeEvery(types.FAVORITE_REQUEST, favoriteKuara),
    takeEvery(types.READ_KUARA_USER_REQUEST, readKuaraUserRequest),
    takeEvery(types.VALIDATE_KUARA_USER_REQUEST, validateKuaraUserRequest),
    takeEvery(types.LIST_BENEFICIARIES_COMMERCE_REQUEST, listbeneficiariesCommerceRequest),
    takeEvery(types.VALIDATE_KUARA_NUMBERS_REQUEST, validateNumbersRequest),
    takeEvery(types.LIST_MOVEMENTS_REQUEST, listMovementsRequest),
    takeEvery(types.LIST_MOVEMENTS_PENDING_REQUEST, listMovementsPendingRequest),
    takeEvery(types.DELETE_MOVEMENT_PENDING_REQUEST, deleteMovementPendingRequest),
    takeEvery(types.LIST_ACCOUNTS_REQUEST, listAccountKuaraRequest),
    takeLatest([globalTypes.INIT, loginTypes.LOGIN_SUCCESS], refreshPendingTransactionsKuaraQuantity),
];

export default sagas;

function* activateKuara({ account, onFinish }) {
    const response = yield call(kuara.activateKuara, account?.idProduct);
    if (response && response.status === 200 && response.data.code === "COR000I") {
        const { enrolledPhoneNumber, enrolledCountryNumber, enrolledAccountNumber } = response.data.data;
        yield put(kuaraActions.setSelectedAccount(account));
        yield put(kuaraActions.activateSuccess(enrolledAccountNumber, enrolledCountryNumber, enrolledAccountNumber));
        yield put(replace("/enrollmentKuaraFinish"));
    } else {
        const { stateAccount, dataError } = response.data?.data;
        if (stateAccount) {
            if (stateAccount === "conjunta") {
                yield put(
                    notificationActions.showNotification(
                        <div>
                            {i18n.get("kuara.validate.IsMancomunate")}
                            <Button
                                label="kuara.validate.NoAccount.link"
                                externalHref={configUtil.get("accessBar.requests.url")}
                                bsStyle="link"
                                className="p-0 btn-link"
                            />
                        </div>,
                        "error",
                        ["enrollmentKuaraSelectAccount"],
                        true,
                    ),
                );
            }
            if (stateAccount === "INACTIVA") {
                yield put(
                    notificationActions.showNotification(
                        <div>
                            <div dangerouslySetInnerHTML={{ __html: i18n.get("kuara.validate.Inactived") }} />
                            <Button
                                label="kuara.validate.Inactived.link"
                                externalHref={configUtil.get("accessBar.chat.url")}
                                bsStyle="link"
                                className="p-0 btn-link"
                            />
                        </div>,
                        "error",
                        ["enrollmentKuaraSelectAccount"],
                        true,
                    ),
                );
            }
            if (stateAccount === "NOACTIVA") {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("kuara.validate.NoActived"),
                        "error",
                        ["enrollmentKuaraSelectAccount"],
                        true,
                    ),
                );
            }
        } else if (dataError) {
            yield put(
                notificationActions.showNotification(
                    i18n.get(dataError),
                    "error",
                    ["enrollmentKuaraSelectAccount"],
                    true,
                ),
            );
        } else {
            yield put(
                notificationActions.showNotification(
                    i18n.get("kuara.change.account.error"),
                    "error",
                    ["enrollmentKuaraSelectAccount"],
                    true,
                ),
            );
        }

        yield put(kuaraActions.activateFailure());
    }
    typeof onFinish === "function" && onFinish();
}

function* desactivateKuara({ onFinish }) {
    const response = yield call(kuara.desactivateKuara);
    if (response && response.status === 200 && response.data.code === "COR000I") {
        yield put(replace("/deactivationKuara"));
    } else {
        yield put(
            notificationActions.showNotification(
                i18n.get("kuara.phone.invalid.reEnrollment"),
                "error",
                ["kuara"],
                true,
            ),
        );
    }
    typeof onFinish === "function" && onFinish();
}

function* updateKuara({ account, onFinish }) {
    const response = yield call(kuara.updateKuara, account?.idProduct);
    if (response && response.status === 200 && response.data.code === "COR000I") {
        yield call(readKuaraUserRequest);
        yield put(replace("/settingsKuara"));
        yield put(
            notificationActions.showNotification(
                i18n.get("kuara.change.account.success"),
                "success",
                ["settingsKuara"],
                true,
            ),
        );
    } else {
        const { stateAccount } = response.data?.data;
        if (stateAccount) {
            if (stateAccount === "conjunta") {
                yield put(
                    notificationActions.showNotification(
                        <div>
                            {i18n.get("kuara.validate.IsMancomunate")}
                            <Button
                                label="kuara.validate.NoAccount.link"
                                externalHref={configUtil.get("accessBar.requests.url")}
                                bsStyle="link"
                                className="p-0 btn-link"
                            />
                        </div>,
                        "error",
                        ["kuaraChangeAccountStep1"],
                        true,
                    ),
                );
            }
            if (stateAccount === "INACTIVA") {
                yield put(
                    notificationActions.showNotification(
                        <div>
                            <div dangerouslySetInnerHTML={{ __html: i18n.get("kuara.validate.Inactived") }} />
                            <Button
                                label="kuara.validate.Inactived.link"
                                externalHref={configUtil.get("accessBar.chat.url")}
                                bsStyle="link"
                                className="p-0 btn-link"
                            />
                        </div>,
                        "error",
                        ["kuaraChangeAccountStep1"],
                        true,
                    ),
                );
            }
            if (stateAccount === "NOACTIVA") {
                yield put(
                    notificationActions.showNotification(
                        i18n.get("kuara.validate.NoActived"),
                        "error",
                        ["kuaraChangeAccountStep1"],
                        true,
                    ),
                );
            }
        } else {
            yield put(
                notificationActions.showNotification(
                    i18n.get("kuara.change.account.error"),
                    "error",
                    ["kuaraChangeAccountStep1"],
                    true,
                ),
            );
        }

        //yield put(kuaraActions.activateFailure());
    }
    typeof onFinish === "function" && onFinish();
}

function* favoriteKuara({ beneficiary }) {
    const response = yield call(
        kuara.favoriteKuara,
        beneficiary?.phoneNumber,
        beneficiary?.type,
        beneficiary?.favorite,
    );
    if (response && response.status === 200 && response.data.code === "COR000I") {
        const beneficiaries = yield select(selectors.getBeneficiariesPeople);
        const beneficiarySelected = yield select(selectors.getSelectedBeneficiary);

        let tmpBeneficiary = null;
        if (!!beneficiarySelected) {
            tmpBeneficiary = { ...beneficiarySelected, favorite: !beneficiary?.favorite };
        }

        const newBeneficiaries = [...beneficiaries];
        newBeneficiaries.map((obj) => {
            obj.phoneNumber === beneficiary.phoneNumber && (obj.favorite = !beneficiary?.favorite);
        });
        newBeneficiaries.sort(function(a, b) {
            if (b.favorite === a.favorite) {
                return 0;
            }
            return b.favorite ? 1 : -1;
        });

        yield put(kuaraActions.favoriteSuccess(newBeneficiaries, tmpBeneficiary));
    } else {
        yield put({ type: types.FAVORITE_FAILURE });
    }
}

function* readKuaraUserRequest() {
    const response = yield call(kuara.readKuaraUser);
    if (response?.data?.code === "API703W") {
        yield put(
            notificationActions.showNotification(
                i18n.get("kuara.phone.invalid.reEnrollment"),
                "error",
                ["desktop"],
                true,
            ),
        );
        yield put(kuaraActions.userKuaraFailure());
        yield put(replace("/desktop"));
    } else if (response && response.status === 200) {
        const {
            enrolledPhoneNumber,
            enrolledCountryNumber,
            enrolledAccountNumber,
            enrolled,
            enrolledAccount,
        } = response.data.data;
        yield put(kuaraActions.userKuaraSuccess(enrolled, enrolledCountryNumber, enrolledPhoneNumber, enrolledAccount));
    }
}

function* validateKuaraUserRequest({ navigate = true }) {
    const response = yield call(kuara.validateKuaraUser);
    if (response?.data?.code === "API703W") {
        yield put(
            notificationActions.showNotification(
                i18n.get("kuara.phone.invalid.reEnrollment"),
                "error",
                ["desktop"],
                true,
            ),
        );
        yield put(kuaraActions.validateUserKuaraFailure());
        yield put(replace("/desktop"));
    } else if (response && response.status === 200) {
        const { enrolled, enrolledOtherUser, enrolledOtherBank } = response.data.data;
        if (enrolledOtherUser) {
            yield put(kuaraActions.validateUserKuaraFailure());
            yield put(replace("/desktop"));
            yield put(
                notificationActions.showNotification(
                    i18n.get("kuara.phone.used.otherUser"),
                    "error",
                    ["desktop"],
                    true,
                ),
            );
            yield;
            return;
        }

        yield put(kuaraActions.validateUserKuaraSuccess(enrolled, enrolledOtherBank));

        /**
         * Redirect enrollment kuara , when user is enrolled in other bank or it is not enrolled
         */
        const enrolledOtherBankRef = enrolledOtherBank !== undefined && (enrolledOtherBank === true || enrolledOtherBank === "true")
        console.log("enrolledOtherBankRef", enrolledOtherBankRef)
        if(!enrolled || enrolledOtherBankRef) {
            if (navigate) {
                yield put(replace("/enrollmentKuara"));
            }
            yield
            return
        }
        
        yield put(kuaraActions.userKuaraRequest());
    }
}

function* listbeneficiariesCommerceRequest({ countryCode, start, end }) {
    const response = yield call(kuara.listBeneficiariesCommerceKuara, countryCode, start, end);
    if (response && response.status === 200) {
        const { beneficiaries } = response.data.data;
        yield put(kuaraActions.listBeneficiariesCommerceSuccess(beneficiaries || [], countryCode));
    }
}

function* validateNumbersRequest({ phoneNumbers, onFinish }) {
    const response = yield call(kuara.validateNumbersKuara, phoneNumbers);
    if (response && response.status === 200) {
        const { numbersValid } = response.data.data;
        if (numbersValid) {
            yield put(kuaraActions.validateNumbersSuccess(numbersValid));
            if (onFinish) {
                onFinish();
            }
        } else {
            yield put(kuaraActions.validateNumbersFailed());
            const {code}=response.data;
            if(code==='API903W'){
                yield put(
                    notificationActions.showNotification(
                        i18n.get("kuara.error.same.phone"),
                        "error",
                        ["sendKuaraAddContact", "sendKuara"],
                        true,
                    ),
                );
                return;
            }
            yield put(
                notificationActions.showNotification(
                    i18n.get("kuara.error.without.contact"),
                    "error",
                    ["sendKuaraAddContact", "sendKuara"],
                    true,
                ),
            );
        }
    }
}

function* listMovementsRequest({ onFinish }) {
    const response = yield call(kuara.listMovementsKuara);
    if (response && response.status === 200 && response.data.code === "COR000I") {
        const { movementsKuara } = response.data.data;
        yield put(kuaraActions.listMovementsSuccess(movementsKuara || []));
    } else {
        yield put(kuaraActions.listMovementsFailure());
    }
    typeof onFinish === "function" && onFinish();
}

function* listMovementsPendingRequest({ onFinish }) {
    const response = yield call(kuara.listMovementsPendingKuara);
    if (response && response.status === 200 && response.data.code === "COR000I") {
        const { movementsKuara } = response.data.data;
        yield put(kuaraActions.listMovementsPendingSuccess(movementsKuara || []));
    } else {
        yield put(kuaraActions.listMovementsPendingFailure());
    }
    typeof onFinish === "function" && onFinish();
}

function* deleteMovementPendingRequest({ movement, onFinish }) {
    const response = yield call(kuara.deleteMovementPendingKuara, movement);
    if (response && response.status === 200 && response.data.code === "COR000I") {
        yield put(kuaraActions.listMovementsPendingRequest(onFinish));
        const response = yield call(kuara.getPendingTransactionsKuaraQuantity);
        if (response.status !== 304 && response.type === "I") {
            yield put({
                type: types.REFRESH_PENDING_TRANSACTIONS_KUARA_QUANTITY_SUCCESS,
                pendingTransactionsKuaraQuantity: response.data.data.pendingTransactionsKuaraQuantity,
            });
        }
    }
}

function* refreshPendingTransactionsKuaraQuantity() {
    if (isMobileNative && configUtil.get("feature.transactionsKuara.refreshPendingQuantity")) {
        while (true) {
            const hasActiveSession = yield select(sessionSelectors.isLoggedIn);
            if (!hasActiveSession) {
                break;
            }

            try {
                const response = yield call(kuara.getPendingTransactionsKuaraQuantity);
                if (response.status !== 304 && response.type === "I") {
                    yield put({
                        type: types.REFRESH_PENDING_TRANSACTIONS_KUARA_QUANTITY_SUCCESS,
                        pendingTransactionsKuaraQuantity: response.data.data.pendingTransactionsKuaraQuantity,
                    });
                }
            } catch (err) {
                if ((err.response && err.response.status === 401) || err.status === 401) {
                    break;
                }
                yield call(delay, 60000);
            }
            yield call(delay, configUtil.get("transactionsKuara.pending.refreshRate", 60) * 1000);
        }
    }
}

function* listAccountKuaraRequest() {
    const response = yield call(kuara.listAccountKuara);
    if (response && response.status === 200 && response.data.code === "COR000I") {
        const { accountList } = response.data.data;
        yield put(kuaraActions.listAccountSuccess(accountList || []));
    } else {
        yield put(kuaraActions.listAccountFailure());
    }
}
