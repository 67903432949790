import Image from "pages/_components/Image";
import CampaignItem from "pages/campaigns/CampaignItem";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { actions, selectors } from "reducers/campaigns";
import { selectors as sessionSelectors } from "reducers/session";
import WidgetLoading from "pages/_components/WidgetLoading";
import Slider from "react-slick";
import * as configUtils from "util/config";
import {
    getCampaignsByCriteria,
    getEnvironmentSection,
    isActive,
    isOngoing,
    isStarted,
    matchEnvironmentSection,
    sortItemsByDateDesc,
} from "util/campaigns";

class Campaigns extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        section: string.isRequired,
        fetchingCampaigns: bool.isRequired,
        items: arrayOf(
            shape({
                image: string,
                idCampaign: string,
                url: string,
                clickable: bool,
            }),
        ).isRequired,
        persistItems: arrayOf(
            shape({
                image: string,
                idCampaign: string,
                url: string,
                clickable: bool,
            }),
        ).isRequired,
        isDesktop: bool,
        className: string,
        isStepZero: bool,
        isActiveCorporate: bool,
    };

    static defaultProps = {
        isDesktop: false,
        className: null,
        isStepZero: false,
        isActiveCorporate: false,
    };

    componentDidMount() {
        const { dispatch, section, isStepZero, isActiveCorporate } = this.props;
        const environmentSection = getEnvironmentSection(section, isActiveCorporate);
        if (isStepZero) {
            dispatch(actions.loadCampaignsAnonymous(environmentSection));
        } else {
            dispatch(actions.loadCampaign(environmentSection));
        }
    }

    render() {
        const {
            items,
            persistItems,
            dispatch,
            isDesktop,
            className,
            fetchingCampaigns,
            isStepZero,
            isActiveCorporate,
            section,
        } = this.props;

        const renderCampaigns = () => {
            let campaignList = [];

            if (isStepZero) {
                campaignList = getCampaignsByCriteria(persistItems, [
                    matchEnvironmentSection(getEnvironmentSection(section, isActiveCorporate)),
                    isActive,
                    isStarted,
                    isOngoing,
                ]);
            } else {
                campaignList = items;
            }

            return {
                showDefault: campaignList.length === 0,
                campaigns: sortItemsByDateDesc(campaignList)?.map((item) => (
                    <CampaignItem
                        key={`campaign-${item.idCampaign}`}
                        className={className}
                        {...item}
                        isDesktop={isDesktop}
                        dispatch={dispatch}
                    />
                )),
            };
        };

        const campaignDefaultLanding = (
            <Image
                wrapperClassName="image-wrapper"
                {...(isActiveCorporate
                    ? {
                          src: "images/campaigns/PersonasJuridicas.png",
                          externalHref: "https://www.banesco.com.pa/empresas/tarjetas/",
                      }
                    : {
                          src: "images/campaigns/connectMilesBanner.png",
                          externalHref:
                              "https://www.banesco.com.pa/tarjetas/visa-infinite-connectmiles/#solicita-tu-tarjeta",
                      })}
            />
        );

        const campaignDefault = isStepZero ? (
            <Image wrapperClassName="image-wrapper" src="images/campaigns/campaignDefault.png" />
        ) : null;

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            swipeToSlide: true,
            autoplay: true,
            autoplaySpeed: configUtils.getInteger("campaigns.autoplaySpeed.milliseconds", 2000),
        };

        const { showDefault, campaigns } = renderCampaigns();

        return isStepZero ? (
            <Slider {...settings}>{showDefault ? campaignDefaultLanding : campaigns}</Slider>
        ) : (
            <WidgetLoading loading={fetchingCampaigns}>
                <Slider {...settings}>{showDefault ? campaignDefault : campaigns}</Slider>
            </WidgetLoading>
        );
    }
}

const mapStateToProps = (state) => ({
    persistItems: selectors.getPersistedCampaigns(state),
    items: selectors.getCampaigns(state),
    fetchingCampaigns: selectors.getFetching(state),
    isActiveCorporate: sessionSelectors.isActiveCorporate(state),
});

export default connect(mapStateToProps)(Campaigns);
