import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { getValidPersistItems } from "util/campaigns";

export const types = {
    LOAD_CAMPAIGN_REQUEST: "campaigns/LOAD_CAMPAIGN_REQUEST",
    LOAD_CAMPAIGNS_ANONYMOUS_REQUEST: "campaigns/LOAD_CAMPAIGNS_ANONYMOUS_REQUEST",
    LOAD_CAMPAIGNS_SUCCESS: "campaigns/LOAD_CAMPAIGNS_SUCCESS",
    LOAD_CAMPAIGN_FAILURE: "campaigns/LOAD_CAMPAIGN_FAILURE",
    UPDATATE_PERSISTED_CAMPAIGNS: "campaigns/UPDATATE_PERSISTED_CAMPAIGNS",
    SET_LOADING: "campaigns/SET_LOADING",
    REMOVE_CAMPAIGNS: "campaigns/REMOVE_CAMPAIGNS",
    DISMISS_CAMPAIGN_PERMANENT_REQUEST: "campaigns/DISMISS_CAMPAIGN_PERMANENT_REQUEST",
    DISMISS_CAMPAIGN_PERMANENT_SUCCESS: "campaigns/DISMISS_CAMPAIGN_PERMANENT_SUCCESS",
    DISMISS_CAMPAIGN_PERMANENT_FAILURE: "campaigns/DISMISS_CAMPAIGN_PERMANENT_FAILURE",
};

export const INITIAL_STATE = {
    fetching: false,
    items: [],
    persistItems: [],
    persistLength: 0,
};

const reducer = (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.LOAD_CAMPAIGN_REQUEST:
        case types.LOAD_CAMPAIGNS_ANONYMOUS_REQUEST:
            return {
                ...state,
                items: [],
            };
        case types.LOAD_CAMPAIGNS_SUCCESS:
            return {
                ...state,
                fetching: false,
                items: [...state.items, action.item],
            };
        case types.UPDATATE_PERSISTED_CAMPAIGNS: {
            const newPersistItems = getValidPersistItems(state);

            return {
                ...state,
                persistItems: newPersistItems,
                persistLength: newPersistItems.length,
                fetching: false,
            };
        }
        case types.SET_LOADING:
            return {
                ...state,
                fetching: action.fetching,
            };

        case types.LOAD_CAMPAIGN_FAILURE:
            return { ...state, fetching: false };

        case types.REMOVE_CAMPAIGNS:
            return INITIAL_STATE;

        case types.DISMISS_CAMPAIGN_PERMANENT_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.DISMISS_CAMPAIGN_PERMANENT_SUCCESS:
        case types.DISMISS_CAMPAIGN_PERMANENT_FAILURE:
            return {
                ...INITIAL_STATE,
            };
        default:
            return state;
    }
};

export default persistReducer(
    {
        storage,
        key: "campaigns",
        whitelist: ["persistItems", "persistLength"],
    },
    reducer,
);

export const actions = {
    loadCampaign: (environmentSection) => ({
        type: types.LOAD_CAMPAIGN_REQUEST,
        environmentSection,
    }),
    loadCampaignsAnonymous: (environmentSection) => ({
        type: types.LOAD_CAMPAIGNS_ANONYMOUS_REQUEST,
        environmentSection,
    }),
    removeCampaigns: () => ({
        type: types.REMOVE_CAMPAIGNS,
    }),
    dismissPermanentCampaign: (idCampaign) => ({
        type: types.DISMISS_CAMPAIGN_PERMANENT_REQUEST,
        idCampaign,
    }),
};

export const selectors = {
    getCampaigns: ({ campaigns }) => campaigns.items,
    getPersistedCampaigns: ({ campaigns }) => campaigns.persistItems,
    getFetching: ({ campaigns }) => campaigns.fetching,
};
