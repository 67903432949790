import Step0Content from "pages/login/_components/Step0Content";
import ConfirmDialog from "pages/_components/modal/ConfirmDialog";
import { func, number, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { selectors as momentDaySelectors } from "reducers/momentDay";
import { actions as sessionActions } from "reducers/session";
import * as config from "util/config";
import { isIOSPlatform, isMobileNativeFunc } from "util/device";
import * as i18n from "util/i18n";
import {
    REVELOCK_CUSTOMER_ID_DEFAULT,
    setAutoLogoutActionRevelock,
    setCustomerIdRevelock,
} from "util/revelockMobile/revelockMobile.util";
import { validatePermissionMobile } from "util/permissions/permission.util";
import { actions as revelockActions, selectors as revelockSelectors } from "reducers/revelock/revelock.reducer";
import { selectors as fingerprintSelectors } from "reducers/fingerprint";
import { getMobileTagManagetId } from "util/tagManager/tagManager.util";
import TagManager from "react-gtm-module";

const Login = (props) => {
    const { momentDay, showSuspiciousActivityModal, dispatch } = props;

    const [initialized, setInitialized] = useState(false);
    const [showSuspiciousModal, setShowSuspiciousModal] = useState(undefined);

    const checkInitialization = () => {
        if (!initialized && momentDay) {
            dispatch(loginActions.initLoginFlow());
            setInitialized(true);
            window.common.hideSplashScreen();
        }
    };

    const callbackLogoutRevelock = () => {
        dispatch(revelockActions.showSuspiciousActivityModal());
        dispatch(sessionActions.logout());
    };

    const initRevelockConfiguration = () => {
        if (!isMobileNativeFunc()) {
            return;
        }
        const customerId = config.get("revelock.mobile.customerId", REVELOCK_CUSTOMER_ID_DEFAULT);
        setCustomerIdRevelock(customerId);
        setAutoLogoutActionRevelock(callbackLogoutRevelock);
    };

    const initMobileGoogleTagManager = () => {
        if (!isMobileNativeFunc()) {
            return;
        }

        const gtmId = getMobileTagManagetId(config);
        if (!gtmId || gtmId === "") {
            // eslint-disable-next-line no-console
            console.log("Google tag manager id, not found");
            return;
        }

        TagManager.initialize({
            gtmId,
            events: {
                sendUserInfo: "userInfo",
            },
        });
    };

    const init = () => {
        dispatch(loginActions.cancelLoginStep1());
        dispatch(loginActions.cancelLoginStep3());
        validatePermissionMobile();
        checkInitialization();
        initRevelockConfiguration();
        initMobileGoogleTagManager();
    };
    const validateProminentDisclosurePlugin = () => {
        try {
            const titleContact = config.get("prominent.disclosure.request.permission.title") ?? "Aviso de seguridad";
            const descriptionContact =
                config.get("prominent.disclosure.request.permission.desc") ??
                "Comprometidos con la seguridad y confidencialidad de su información, Banesco (Panamá) S.A. recopila información sobre aplicaciones instaladas en tu dispositivo móvil, lo que permite la protección de sus datos ante posibles eventos de fraude o actividades sospechosas. Esta acción es completamente con fines de seguridad y no es compartida con terceros";

            const body = [
                {
                    imageType: "RESOURCE",
                    imageResId: "ic_contact",
                    title: titleContact,
                    description: descriptionContact,
                },
            ];

            window.cordova.plugins.ProminentDisclosurePlugin.showPrompt(
                JSON.stringify(body),
                () => {
                    init();
                },
                () => {
                    navigator.app.exitApp();
                },
            );
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
    };

    const validatePlatform = () => {
        if (!isMobileNativeFunc()) {
            init();
            return;
        }

        if (isIOSPlatform()) {
            init();
            return;
        }

        validateProminentDisclosurePlugin();
    };

    useEffect(() => {
        validatePlatform();
    }, []);

    useEffect(() => {
        if (showSuspiciousActivityModal && showSuspiciousActivityModal > 0) {
            setShowSuspiciousModal(true);
        }
    }, [showSuspiciousActivityModal]);

    return (
        <>
            <Step0Content />
            <ConfirmDialog
                showDialog={showSuspiciousModal}
                title={undefined}
                description={i18n.get("revelock.suspicious.activity.text") || ""}
                onConfirm={() => {
                    setShowSuspiciousModal(undefined);
                    dispatch(revelockActions.clearSuspiciousActivityModal());
                }}
                confirmLabel="global.accept"
                textClassName="mx-md-5"
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    momentDay: momentDaySelectors.getMomentDay(state) || "",
    rememberedUser: loginSelectors.getRememberedUser(state),
    showSuspiciousActivityModal: revelockSelectors.getShowSuspiciousActivityModal(state),
    device: fingerprintSelectors.getDevice(state),
});

Login.propTypes = {
    momentDay: string.isRequired,
    showSuspiciousActivityModal: number,
    dispatch: func,
};

Login.defaultProps = {
    showSuspiciousActivityModal: 0,
    dispatch: () => {},
};

export default withRouter(connect(mapStateToProps)(Login));
