import classNames from "classnames";
import { Field } from "formik";
import moment from "moment";
import Box from "pages/_components/Box";
import FormattedAmount from "pages/_components/FormattedAmount";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { push } from "react-router-redux";
import { actions as creditCardActions, selectors as creditCardSelectors } from "reducers/creditCard";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as sessionSelectors } from "reducers/session";
import { selectors as templateSelectors } from "reducers/template";
import { compose } from "redux";
import * as i18n from "util/i18n";
import * as schedulerUtils from "util/scheduler";
import { getMessageRequired, validationAccount } from "util/validationSchemaUtil";
import * as Yup from "yup";
import * as creditCardsUtil from "util/creditCards";
import PageLoading from "pages/_components/PageLoading";
import { statusMap } from "util/general";
import FormTransition from "../_components/FormTransition";
import { PDFAmountField, PDFAmountFieldError, PDFProductSelectorField, PDFTextField } from "./PDFTicket";
import { useCreditCardPayment, useSelectorOptions, useCreditCardPaySelectorOptions } from "./hooks/PayCreditCardHooks";
import { useDataAmount, useGenericProps, useMetaData } from "./hooks/TransferInternalHooks";

const ID_FORM = "creditCard.payment.financingPlan.creditCardLocal";
const ID_ACTIVITY = `${ID_FORM}.send`;
const ID_ACTIVITY_PRE = `${ID_FORM}.pre`;
const TITLE_FORM = "forms.creditCard.payment.formName";

const FinancingPlanForm = (props) => {
    const {
        mode,
        dispatch,
        location,
        preDataForm,
        transaction,
        currentLang,
        financingPlans,
        isDesktop,
        creditCardList,
        fetchingList,
        usesJointAccount,
    } = props;
    const [metadata] = useMetaData(preDataForm, ID_ACTIVITY);

    const [genericProps] = useGenericProps(props, ID_ACTIVITY);
    const [dataAmount] = useDataAmount(preDataForm);
    let accountOptions = useSelectorOptions(preDataForm);
    let creditCardOptions = useCreditCardPaySelectorOptions(creditCardList);
    if (usesJointAccount) {
        const ccData = transaction.data.creditCardData;
        if (ccData) {
            creditCardOptions = { options: [{ ...ccData, id: ccData.idProduct, label: ccData.otherLabel }] };
        }
        const daData = transaction.data.debitAccountData;
        if (daData) {
            accountOptions = { options: [{ ...daData, id: daData.idProduct, label: daData.otherLabel }] };
        }
    }
    const [creditCardPayment] = useCreditCardPayment(location);
    const [amountDebitAccount, setAmountDebitAccount] = useState({ currency: "", quantity: 0 });
    const [loading, setLoading] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const titleForm = TITLE_FORM;

    useEffect(() => {
        if (mode === "edit") {
            dispatch(creditCardActions.listRequest());

            const formData = { debitAccount: null };
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, formData }));
        }
    }, [dispatch, mode, location]);

    useEffect(() => {
        setSubmitDisabled(financingPlans.length <= 0 || loading);
    }, [financingPlans, loading]);

    const validationSchema = () =>
        Yup.object().shape({
            debitAccount: validationAccount(`forms.${ID_FORM}.debitAccount.invalid`, [
                ...(preDataForm?.debitAccountList || []),
            ]),
            creditCard: validationAccount(
                `forms.${ID_FORM}.creditCard.invalid`,
                creditCardsUtil.getNonPrepaidCreditCardsWithAdditionals(creditCardList),
            ),
        });

    const validateCustom = (values) => {
        const currentDue = financingPlans?.map((financing) => financing.balanceDue).reduce((a, b) => a + b, 0) || 0;

        if (values.amount?.quantity > (currentDue || 0) * 1.1) {
            return { NO_FIELD: getMessageRequired(`forms.${ID_FORM}.amount.exceeded`) };
        }
        if (!values.amount?.quantity || values.amount?.quantity <= 0 || !values.amount?.currency) {
            return { NO_FIELD: getMessageRequired(`forms.${ID_FORM}.amount.required`) };
        }

        return {};
    };

    const getFinancingPlansCreditCard = (idCreditCard) => {
        setLoading(true);
        dispatch(creditCardActions.financingPlansRequest(idCreditCard, () => setLoading(false)));
    };

    useEffect(() => {
        if (creditCardOptions?.options && creditCardOptions.options.length > 0 && creditCardPayment) {
            getFinancingPlansCreditCard(creditCardPayment);
        }
    }, [creditCardOptions]);

    const cancelAction = () => {
        if (creditCardPayment) {
            dispatch(push(`/creditCards/${creditCardPayment}`));
        } else {
            dispatch(push("/desktop"));
        }
    };

    const handleCancelPreview = () => {
        dispatch(push("/formCustom/financingPlan"));
    };

    const getAmountNumberFormat = (amount) => {
        if (!amount) {
            return "";
        }

        const amontNumber = (Math.round(amount.quantity * 100) / 100).toFixed(2);
        return `${amount.currency} ${amontNumber}`;
    };

    const renderTicket = (values) => {
        const { idTransactionStatus } = transaction;
        const idStatus = statusMap.get(idTransactionStatus) ?? idTransactionStatus;
        return (
            <>
                {idStatus === "FINISHED" ? (
                    <PDFAmountField
                        idForm={ID_FORM}
                        name="amount"
                        values={values}
                        label={i18n.get(`forms.${ID_FORM}.amount.label_view`)}
                    />
                ) : (
                    <PDFAmountFieldError
                        idForm={ID_FORM}
                        name="amount"
                        values={values}
                        label={i18n.get(`forms.${ID_FORM}.amount.label_preview`)}
                    />
                )}

                {idStatus === "FINISHED" && (
                    <PDFTextField
                        idForm={ID_FORM}
                        name="backendReference"
                        values={values}
                        label={i18n.get(`forms.creditCard.payment.voucher.label`)}
                    />
                )}
                <PDFTextField
                    idForm={ID_FORM}
                    value={moment(transaction?.creationDateTime).format("DD/MM/YYYY")}
                    label={i18n.get("forms.creditCard.payment.date.label_preview")}
                />

                <PDFTextField
                    idForm={ID_FORM}
                    value={i18n.get("forms.creditCard.payment.typePayment.financingPlan.label")}
                    label={i18n.get("transactions.ticket.typeOperation.label")}
                />

                <PDFProductSelectorField idForm={ID_FORM} name="debitAccount" values={values} />
                <PDFProductSelectorField idForm={ID_FORM} name="creditCard" values={values} />

                {values?.amountPlans?.length === 1 && (
                    <PDFTextField
                        idForm={ID_FORM}
                        value={values?.amountPlans[0]?.id}
                        label={i18n.get("creditCard.financing.plans.header.table.reference")}
                    />
                )}
            </>
        );
    };

    const renderFields = (setFieldValue, values) => {
        if (mode !== "view" && fetchingList) {
            return <PageLoading loading classicStyle={false} />;
        }

        if (!values?.scheduler && metadata?.firstWorkingDate) {
            setFieldValue("scheduler", {
                selectedOption: schedulerUtils.TODAY,
                valueDate: moment(metadata?.firstWorkingDate, "YYYY-MM-DD"),
            });
        }

        if (values?.debitAccount && mode === "edit") {
            const debitAccount = preDataForm?.debitAccountList?.find((acc) => acc.idProduct === values?.debitAccount);
            const currency = debitAccount?.currency;
            const balance = debitAccount?.balance;

            if (amountDebitAccount?.currency !== currency || amountDebitAccount?.quantity !== balance) {
                setAmountDebitAccount({ currency: debitAccount?.currency, quantity: debitAccount?.balance });
            }
        }

        const { idTransactionStatus = "" } = transaction;
        const idStatus = statusMap.get(idTransactionStatus) ?? idTransactionStatus;

        return (
            <>
                {mode === "edit" && (
                    <>
                        <Box display="none">
                            <Field
                                {...genericProps}
                                isFocused={false}
                                mode={mode}
                                component={FormFieldsComponents.Coordinates}
                                key="localization"
                                isRequired
                                name="localization"
                                idField="localization"
                            />
                        </Box>
                        <Row gapY={isDesktop ? "5" : "3"}>
                            <Col xs={12} md={6}>
                                <Box
                                    background="white"
                                    borderRadius="default"
                                    className="form-creditCardPay__debitAccount
                                                pl-5 pl-lg-10 pr-5 pr-lg-10 pb-8 pb-md-10 mx-n-5">
                                    <Field
                                        {...genericProps}
                                        component={FormFieldsComponents.ProductselectorCustom}
                                        data={accountOptions}
                                        key="debitAccount"
                                        name="debitAccount"
                                        renderAs="combo"
                                        value={values?.debitAccount}
                                        idField="debitAccount"
                                    />
                                </Box>
                            </Col>
                            <Col xs={12} md={6}>
                                <Box
                                    background="white"
                                    borderRadius="default"
                                    className="form-creditCardPay__creditCard
                                                pl-5 pl-lg-10 pr-5 pr-lg-10 pb-8 pb-md-10 mx-n-5">
                                    <Field
                                        {...genericProps}
                                        component={FormFieldsComponents.ProductselectorCustom}
                                        data={creditCardOptions}
                                        key="creditCard"
                                        name="creditCard"
                                        renderAs="combo"
                                        value={creditCardPayment || values?.creditCard}
                                        idField="creditCard"
                                        onChangeDataCustom={(creditCard) => {
                                            getFinancingPlansCreditCard(creditCard);
                                            setFieldValue("amountPlans", []);
                                            setFieldValue("amount", { quantity: 0, currency: "USD" });
                                        }}
                                    />
                                </Box>
                            </Col>
                            <Col xs={12}>
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.AmountPaymentFinancingPlans}
                                    data={{ ...dataAmount, financingPlans, loading }}
                                    key="amount"
                                    name="amount"
                                    value={values?.amount}
                                    idField="amount"
                                    renderLabel={false}
                                    relativeStyleError
                                />
                            </Col>
                            <Col xs={12}>
                                <Box
                                    display="flex"
                                    alignX={isDesktop ? "flex-end" : "between"}
                                    alignY="center"
                                    gap="7"
                                    background="heading-color"
                                    borderRadius="default"
                                    className="px-5 px-md-8 py-7">
                                    <Text
                                        size={isDesktop ? "5" : "6"}
                                        color="inverse"
                                        labelKey={`forms.${ID_FORM}.amount.total`}
                                    />
                                    <FormattedAmount
                                        size={isDesktop ? "big" : "2"}
                                        color="inverse"
                                        bold
                                        quantity={values?.amount?.quantity || 0}
                                        currency="USD"
                                    />
                                </Box>
                            </Col>
                        </Row>
                    </>
                )}
                {mode === "preview" || mode === "view" ? (
                    <>
                        <Box display="flex" alignX="center" fullWidth className="amount-wrapper">
                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Amount}
                                data={dataAmount}
                                key="amount"
                                name="amount"
                                value={values.amount}
                                idField="amount"
                                bigDataAmount
                                label_previewMap={{
                                    [currentLang]: i18n.get(`forms.${ID_FORM}.amount.label_preview`),
                                }}
                                label_viewMap={
                                    idStatus === "FINISHED"
                                        ? {
                                              [currentLang]: i18n.get(`forms.${ID_FORM}.amount.label_view`),
                                          }
                                        : {
                                              [currentLang]: i18n.get(`forms.${ID_FORM}.amount.label_preview`),
                                          }
                                }
                            />
                        </Box>

                        <Box className={classNames("mt-3", { "mt-9 mx-7": mode === "view" })}>
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={values?.backendReference}
                                label="forms.creditCard.payment.voucher.label"
                                shouldRender={
                                    mode === "view" &&
                                    transaction &&
                                    idStatus === "FINISHED" &&
                                    values?.typeTransaction !== "PARENT"
                                }
                            />
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={values?.scheduler?.valueDate?.format("DD/MM/YYYY")}
                                label="forms.creditCard.payment.date.label_preview"
                            />
                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={i18n.get("forms.creditCard.payment.typePayment.financingPlan.label")}
                                label="transactions.ticket.typeOperation.label"
                                shouldRender={mode === "view"}
                            />

                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.ProductselectorCustom}
                                data={accountOptions}
                                key="debitAccount"
                                name="debitAccount"
                                renderAs="combo"
                                value={values?.debitAccount}
                                idField="debitAccount"
                            />
                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.ProductselectorCustom}
                                data={creditCardOptions}
                                key="creditCard"
                                name="creditCard"
                                renderAs="combo"
                                value={creditCardPayment || values?.creditCard}
                                idField="creditCard"
                                onChange={(creditCard) => getFinancingPlansCreditCard(creditCard)}
                            />

                            {values?.amountPlans?.length === 1 && (
                                <FormFieldsComponents.ReadTextCustom
                                    {...genericProps}
                                    value={values?.amountPlans[0]?.id}
                                    label="creditCard.financing.plans.header.table.reference"
                                    shouldRender={mode === "view"}
                                />
                            )}

                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={i18n.get("forms.creditCard.payment.typePayment.financingPlan.label")}
                                label="forms.creditCard.payment.typePayment.label"
                                shouldRender={mode === "preview"}
                            />

                            {values?.amountPlans?.length > 1 && (
                                <FormFieldsComponents.PaymentPlans
                                    shouldRender={mode === "view"}
                                    amountPlans={values?.amountPlans}
                                />
                            )}
                            <FormFieldsComponents.GlobalPay
                                date={transaction?.creationDateTime}
                                parentTransactionId={values?.parentTransactionId}
                                shouldRender={!!values?.parentTransactionId}
                            />

                            {mode === "preview" &&
                                values?.amountPlans?.map((plan) => (
                                    <FormFieldsComponents.ReadTextCustom
                                        {...genericProps}
                                        value={getAmountNumberFormat(plan?.amount)}
                                        labelCustom={`${i18n.get(
                                            "creditCard.financing.plans.header.table.reference",
                                        )} ${plan?.id}`}
                                        shouldRender={mode === "preview"}
                                    />
                                ))}
                        </Box>
                    </>
                ) : null}
            </>
        );
    };

    const formProps = {
        title: titleForm,
        metadata,
        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        preData: preDataForm,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        titleFormConfirmation: "OTP CODE",
        validationSchema,
        validate: validateCustom,
        cancelAction,
        handleCancelPreview,
        showSchedulerMessage: false,
        submitDisabled,
    };
    return <FormTransition {...props} {...formProps} />;
};

FinancingPlanForm.propTypes = {
    dispatch: func,
    mode: string,
    fromBackoffice: bool,
    previewData: shape({}),
    currentLang: string,
    preDataForm: shape({}),
    transaction: shape({}),
    location: shape({}),
    fromTransaction: bool,
    isDesktop: bool,
    postData: shape({}),
    payCreditCardPermission: bool.isRequired,
    financingPlans: arrayOf(shape({})),
    fetchingDetail: bool,
    fetchingForm: bool.isRequired,
    fetchingList: bool,
    creditCardList: arrayOf(shape({})),
    usesJointAccount: bool,
};
FinancingPlanForm.defaultProps = {
    dispatch: () => {},
    fromBackoffice: false,
    mode: "",
    currentLang: "",
    preDataForm: shape({}),
    previewData: null,
    transaction: null,
    location: {},
    fromTransaction: false,
    isDesktop: false,
    postData: {},
    financingPlans: [],
    fetchingDetail: false,
    fetchingList: false,
    creditCardList: [],
    usesJointAccount: false,
};
const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    postData: formSelectors.getData(state),
    payCreditCardPermission: sessionSelectors.hasPermissions(state, ["payCreditCard"]),
    fetchingDetail: creditCardSelectors.getFetching(state),
    financingPlans: creditCardSelectors.getFinancingPlans(state),
    fetchingList: creditCardSelectors.isFetching(state),
    creditCardList: creditCardSelectors.getList(state),
    usesJointAccount: formSelectors.getUsesJointAccount(state),
});
export default compose(connect(mapStateToProps), withRouter)(resizableRoute(FinancingPlanForm));
