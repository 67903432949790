import AccountOpeningRequestForm from "pages/forms/customForms/AccountOpeningRequestForm";
import FormRender from "pages/forms/_components/FormRender";
import ServicePaymentForm from "pages/servicePayments/ServicePaymentForm";
import SendWallyTransaction from "pages/wally/SendWallyTransaction";
import React from "react";
import { connect } from "react-redux";
import { selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import * as i18nUtils from "util/i18n";
import SendKuaraTransaction from "pages/kuara/SendKuaraTransaction";
import { getLocationBasePath } from "util/revelock";
import withRevelockPositionContext from "hoc/withRevelockPositionContext";
import { func, shape } from "prop-types";
import ExchangePointsTicketForm from "../_components/TransactionTicket/ExchangePointsTicketForm";
import TicketHistoricTransaction from "../_components/TransactionTicket/TicketHistoricTransaction";
import TicketNoTransactional from "../_components/TransactionTicket/TicketNoTransactional";
import CashAdvanceForm from "./CashAdvanceForm";
import LoanPaymentForm from "./LoanPaymentForm";
import PayCreditCardLocal from "./PayCreditCardLocal";
import RechargeCreditCardLocal from "./RechargeCreditCardLocal";
import RequestLoanForm from "./RequestLoanForm";
import RolePaymentForm from "./RolePaymentForm";
import TransferForeignForm from "./TransferForeignForm";
import TransferInternalForm from "./TransferInternalForm";
import TransferLocalForm from "./TransferLocalForm";
import TicketBeneficiary from "../_components/TransactionTicket/TicketBeneficiary";
import FinancingPlanForm from "./FinancingPlanForm";
import CreateFixedTermDepositForm from "./fixedTermDeposit/CreateFixedTermDepositForm";
import StopChecksForm from "./StopChecksForm";
import ReportReplaceCard from "./ReportReplaceCard";
import InstallmentPurchaseForm from "./InstallmentPurchaseForm";
import RequestsCheckBooks from "./RequestsCheckBooks";
import RedeemMilesCash from "./RedeemMilesCash";
import Pay2x3Form from "./Pay2x3Form";

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    metadata: formSelectors.getMetadata(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    fromTransaction: true,
    usesJointAccount: formSelectors.getUsesJointAccount(state),
});

const PAGE_IDENTIFIER = "transaction.read";

class TransactionCustom extends React.Component {
    static propTypes = {
        match: shape({}).isRequired,
        setLocationCustomPath: func.isRequired,
    };

    componentDidMount() {
        this.setRevelockLocation();
    }

    setRevelockLocation = () => {
        const { setLocationCustomPath, match } = this.props;

        if (setLocationCustomPath) {
            setLocationCustomPath(getLocationBasePath(match), PAGE_IDENTIFIER);
        }
    };

    formMap = (props) => {
        let accountFound = null;
        if (
            props.transaction !== null &&
            props.transaction &&
            props.transaction.data !== null &&
            props.transaction.data &&
            props.transaction.data.accounts !== null &&
            props.transaction.data.accounts
        ) {
            accountFound = props.transaction.data.accounts.options.filter(
                (item) => item.idProduct === props.transaction.data.debitAccount,
            );
        }

        let labelReferenceLetterAll = null;
        if (props.transaction.data.debitAccount === "all") {
            labelReferenceLetterAll = i18nUtils.get("reference.letter.all.acccounts");
        } else if (accountFound !== null && accountFound && accountFound[0] !== null && accountFound[0]) {
            labelReferenceLetterAll = accountFound[0].shortLabel;
        }

        const currencyReferenceLetterAll =
            props.transaction.data.currency !== null && props.transaction.data.currency
                ? props.transaction.data.currency[0].label
                : null;
        return {
            "transfers.internal.send": <TransferInternalForm {...this.props} />,
            "transfers.local.send": <TransferLocalForm {...this.props} />,
            "transfers.thirdParties.send": <TransferLocalForm {...this.props} />,
            "transfers.foreign.send": <TransferForeignForm {...this.props} />,
            "transfers.wally.send": <SendWallyTransaction {...this.props} />,
            "transfers.kuara.send": <SendKuaraTransaction {...this.props} />,
            "transfers.approve.wally.send": <SendWallyTransaction {...this.props} />,
            "loan.payment.send": <LoanPaymentForm {...this.props} />,
            "creditCard.payment.creditCardLocal.send": <PayCreditCardLocal {...this.props} />,
            "creditCard.payment.creditCardThird.send": <TransferLocalForm {...this.props} />,
            "creditCard.recharge.creditCardThird.send": <TransferLocalForm {...this.props} />,
            "creditCard.recharge.creditCardLocal.send": <RechargeCreditCardLocal {...this.props} />,
            "creditCard.cashAdvance.send": <CashAdvanceForm {...this.props} />,
            "product.exchange.points.send": <ExchangePointsTicketForm {...this.props} />,
            "request.newloan.send": <RequestLoanForm {...this.props} />,
            "role.payment.send": <RolePaymentForm {...this.props} />,
            "suppliers.payment.send": <RolePaymentForm {...this.props} />,
            "transfers.payService.send": <ServicePaymentForm {...this.props} />,
            "accounts.openingRequest.send": <AccountOpeningRequestForm {...this.props} />,
            "creditCards.changeStatus.active.send": (
                <TicketNoTransactional
                    {...this.props}
                    highlightedLabel="Card.label.active"
                    highlightedValue={`${
                        props.transaction?.data?.numberMask
                    } ${props.transaction?.data?.cardBrand?.toUpperCase()}`}
                />
            ),
            "creditCards.changeStatus.block.send": (
                <TicketNoTransactional
                    {...this.props}
                    highlightedLabel="Card.label.block"
                    highlightedValue={`${
                        props.transaction?.data?.numberMask
                    } ${props.transaction?.data?.cardBrand?.toUpperCase()}`}
                />
            ),
            "creditCards.changeStatus.unlock.send": (
                <TicketNoTransactional
                    {...this.props}
                    highlightedLabel="Card.label.unlock"
                    highlightedValue={`${
                        props.transaction?.data?.numberMask
                    } ${props.transaction?.data?.cardBrand?.toUpperCase()}`}
                />
            ),
            "debitCards.changeStatus.active.send": (
                <TicketNoTransactional
                    {...this.props}
                    highlightedLabel="Card.label.active"
                    highlightedValue={`${
                        props.transaction?.data?.debitCard?.numberMask
                    } ${props.transaction?.data?.debitCard?.franchise?.toUpperCase()}`}
                />
            ),
            "debitCards.changeStatus.block.send": (
                <TicketNoTransactional
                    {...this.props}
                    highlightedLabel="Card.label.block"
                    highlightedValue={`${
                        props.transaction?.data?.debitCard?.numberMask
                    } ${props.transaction?.data?.debitCard?.franchise?.toUpperCase()}`}
                />
            ),
            "debitCards.changeStatus.unlock.send": (
                <TicketNoTransactional
                    {...this.props}
                    highlightedLabel="Card.label.unlock"
                    highlightedValue={`${
                        props.transaction?.data?.debitCard?.numberMask
                    } ${props.transaction?.data?.debitCard?.franchise?.toUpperCase()}`}
                />
            ),
            "historic.transaction": <TicketHistoricTransaction {...this.props} />,
            "activate.wally.user": <TicketNoTransactional {...this.props} wally />,
            "deactivate.wally.user": <TicketNoTransactional {...this.props} wally />,
            "update.wally.user": <TicketNoTransactional {...this.props} wally />,
            "accounts.changeStatus.active.send": <TicketNoTransactional {...this.props} />,
            "transactions.migration.reSchedule.send": <TicketNoTransactional {...this.props} />,
            "reference.letter.send": (
                <TicketNoTransactional
                    {...this.props}
                    extraData={[
                        {
                            label: i18nUtils.get("forms.transfers.foreign.accountType.option.account_number"),
                            labelCustom: i18nUtils.get("forms.transfers.foreign.accountType.option.account_number"),
                            value: labelReferenceLetterAll,
                        },
                        {
                            label: i18nUtils.get("currency.modal.th.currency"),
                            labelCustom: i18nUtils.get("currency.modal.th.currency"),
                            value: currencyReferenceLetterAll,
                        },
                        {
                            label: i18nUtils.get("transactions.list.header.receiver"),
                            labelCustom: i18nUtils.get("transactions.list.header.receiver"),
                            value: props.transaction?.data?.destinatary,
                        },
                    ]}
                />
            ),
            "creditCards.assignPin.send": (
                <TicketNoTransactional
                    {...this.props}
                    highlightedLabel="creditCard.label"
                    highlightedValue={`${
                        props.transaction?.data?.idCreditCardMask ? props.transaction.data.idCreditCardMask : ""
                    }`}
                />
            ),
            "debitCards.requestPin": (
                <TicketNoTransactional
                    {...this.props}
                    extraData={[
                        {
                            label: i18nUtils.get("creditCard.label"),
                            value: props.transaction?.data?.cardNumber,
                        },
                    ]}
                />
            ),
            "beneficiary.create.send": <TicketBeneficiary {...this.props} />,
            "beneficiary.update.send": <TicketBeneficiary {...this.props} />,
            "beneficiary.create": <TicketBeneficiary {...this.props} />,
            "beneficiary.delete": <TicketBeneficiary {...this.props} />,
            "fixed.term.deposit.create.send": <CreateFixedTermDepositForm {...this.props} />,
            "creditCard.payment.financingPlan.creditCardLocal.send": <FinancingPlanForm {...this.props} />,
            "checks.suspend.send": <StopChecksForm {...this.props} />,
            "report.replace.card.send": <ReportReplaceCard {...this.props} />,
            "report.renew.card.send": <ReportReplaceCard {...this.props} />,
            "installment.purchase.send": <InstallmentPurchaseForm {...this.props} />,
            "requests.checkBooks.send": <RequestsCheckBooks {...this.props} />,
            "administration.corporateData.update.step4.send": <TicketNoTransactional {...this.props} />,
            "transactional.profile.send": (
                <TicketNoTransactional
                    {...this.props}
                    highlightedLabel="transactional.profile.label"
                    highlightedValue=" "
                />
            ),
            "administration.users.deactivate.permission.send": (
                <TicketNoTransactional
                    {...this.props}
                    extraData={[
                        {
                            label: "administration.permission.ticket.action.label",
                            value: i18nUtils.get(`global.${props.transaction?.data?.permission}`),
                        },
                        {
                            label: "administration.channels.user",
                            value: props.transaction?.data?.userName,
                        },
                    ]}
                />
            ),
            "administration.users.activate.permission.send": (
                <TicketNoTransactional
                    {...this.props}
                    extraData={[
                        {
                            label: "administration.permission.ticket.action.label",
                            value: i18nUtils.get(`global.${props.transaction?.data?.permission}`),
                        },
                        {
                            label: "administration.channels.user",
                            value: props.transaction?.data?.userName,
                        },
                    ]}
                />
            ),
            "token.entrust.activeEnvironmentsToken": (
                <TicketNoTransactional
                    {...this.props}
                    extraData={[
                        {
                            label: "administration.channels.user",
                            value: props.transaction?.data?.fullName,
                        },
                    ]}
                />
            ),
            "token.entrust.rejectUserToken": (
                <TicketNoTransactional
                    {...this.props}
                    extraData={[
                        {
                            label: "administration.channels.user",
                            value: props.transaction?.data?.fullName,
                        },
                    ]}
                />
            ),
            "redeem.miles.connect.send": <RedeemMilesCash {...this.props} />,
            "redeem.miles.cash.send": <RedeemMilesCash {...this.props} />,
            "settings.userData.update.step6.send": <TicketNoTransactional {...this.props} />,
            "pay.2x3.send": <Pay2x3Form {...this.props} />,
        };
    };

    render() {
        /* eslint-disable react/prop-types */
        const { transaction, fetching } = this.props;
        const { idActivity } = transaction;
        /* eslint-enable react/prop-types */

        if (!fetching && transaction && this.formMap(this.props)[idActivity] !== undefined) {
            return this.formMap(this.props)[idActivity];
        }
        return <FormRender {...this.props} />;
    }
}

export default connect(mapStateToProps)(withRevelockPositionContext(TransactionCustom));
