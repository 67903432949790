import classNames from "classnames";
import { USD_CURRENCY } from "constants.js";
import moment from "moment";
import { PDFStickerList, PDFTextField, PDFTextFieldFirst } from "pages/forms/customForms/PDFTicket";
import FormTransition from "pages/forms/_components/FormTransition";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import Box from "pages/_components/Box";
import { resizableRoute } from "pages/_components/Resizable";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { push } from "react-router-redux";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as templateSelectors } from "reducers/template";
import { selectors as wallySelectors } from "reducers/wally";
import { compose } from "redux";
import * as i18n from "util/i18n";
import Sticker from "pages/_components/Sticker";

const ID_FORM = "checks.suspend";
const ID_ACTIVITY = `${ID_FORM}.send`;
const TITLE_FORM_CHECK = "checks.title.sidebar.suspend";
const TITLE_FORM_CHECKBOOK = "checkbooks.title.sidebar.suspend";

const StopChecksForm = (props) => {
    const { mode, currentLang, preDataForm, fromBackoffice, dispatch, location, transaction, data, isDesktop } = props;

    const isFormCheckbook = location.pathname === "/formCustom/stopCheckbooks";

    useEffect(() => {
        if (mode === "edit") {
            dispatch(formActions.confirmSuspendCheck(ID_ACTIVITY));
        }
    }, []);

    const genericProps = {
        mode,
        lang: currentLang,
        fromBackoffice,
        isRequired: true,
        idActivity: ID_ACTIVITY,
    };

    const renderTicket = (values) => (
        <>
            <PDFTextFieldFirst
                idForm={ID_FORM}
                value={moment(transaction?.creationDateTime).format("DD/MM/YYYY")}
                label={i18n.get("forms.checks.suspend.date.label")}
            />
            <PDFTextField
                idForm={ID_FORM}
                value={
                    values?.type === "checks"
                        ? i18n.get("checks.suspend.typeOperation.label")
                        : i18n.get("checkbooks.suspend.typeOperation.label")
                }
                label={i18n.get("forms.checks.suspend.typeOperation.label")}
            />

            <PDFTextField
                idForm={ID_FORM}
                label={i18n.get("forms.checks.suspend.reason.label")}
                value={i18n.get(`checks.reason.suspend.${values?.reasonSuspend?.value}`)}
            />
            <PDFTextField
                idForm={ID_FORM}
                label={i18n.get("forms.checks.suspend.commission.label")}
                value={`${USD_CURRENCY} ${values?.commissionTotalAmount}`}
            />
            <PDFTextField
                idForm={ID_FORM}
                label={i18n.get("forms.checks.suspend.quantityChecks.label")}
                value={values?.quantityChecks}
            />
            {values?.type === "checks" && (
                <PDFStickerList
                    idForm={ID_FORM}
                    label={i18n.get("forms.checks.suspend.numberChecks.label")}
                    list={values?.checksSuspend}
                />
            )}
            {values?.type === "checkbooks" && (
                <PDFTextField
                    idForm={ID_FORM}
                    label={i18n.get("forms.checks.suspend.numberChecks.label")}
                    value={`${values?.checksSuspend[0]} a ${values?.checksSuspend[1]}`}
                />
            )}
        </>
    );

    const validationSchema = () => {};

    const cancelAction = () => {};

    const handleCancelPreview = () => {
        dispatch(push(`/checkbooks/${data.idAccount}`));
    };

    const renderFields = (setFieldValue, values) => (
        <>
            {mode === "preview" || mode === "view" ? (
                <Box className={classNames("mt-3", { "mt-6 mx-7": mode === "view" })}>
                    {values?.backendReference && (
                        <FormFieldsComponents.ReadTextCustom
                            {...genericProps}
                            value={values?.backendReference}
                            label="forms.checks.suspend.vaucher.label"
                            shouldRender={mode === "view"}
                        />
                    )}
                    <FormFieldsComponents.ReadTextCustom
                        {...genericProps}
                        value={
                            moment(transaction?.creationDateTime).format("DD/MM/YYYY") ||
                            values?.scheduler?.transactionDate?.format("DD/MM/YYYY")
                        }
                        label="forms.checks.suspend.date.label"
                    />
                    <FormFieldsComponents.ReadTextCustom
                        {...genericProps}
                        label="forms.checks.suspend.typeOperation.label"
                        value={
                            values?.type === "checks"
                                ? i18n.get("checks.suspend.typeOperation.label")
                                : i18n.get("checkbooks.suspend.typeOperation.label")
                        }
                    />
                    <FormFieldsComponents.ReadTextCustom
                        {...genericProps}
                        label="forms.checks.suspend.reason.label"
                        value={i18n.get(`checks.reason.suspend.${values?.reasonSuspend?.value}`)}
                    />
                    <FormFieldsComponents.ReadTextCustom
                        {...genericProps}
                        type="amount_type"
                        label="forms.checks.suspend.commission.label"
                        amount={{
                            currency: USD_CURRENCY,
                            quantity: values?.commissionTotalAmount,
                        }}
                    />

                    <FormFieldsComponents.ReadTextCustom
                        {...genericProps}
                        label="forms.checks.suspend.quantityChecks.label"
                        value={values?.quantityChecks}
                    />

                    {values?.type === "checks" && (
                        <FormFieldsComponents.ReadTextCustom
                            {...genericProps}
                            label="forms.checks.suspend.numberChecks.label"
                            value={
                                <div className={(!isDesktop || mode === "view") && "py-4 align-left"}>
                                    {values?.checksSuspend?.map((check) => (
                                        <Sticker
                                            component="span"
                                            className="m-1"
                                            status="background-secondary"
                                            display="inherit"
                                            textSize="normal"
                                            addPaddingY>
                                            {check}
                                        </Sticker>
                                    ))}
                                </div>
                            }
                        />
                    )}
                    {values?.type === "checkbooks" && (
                        <FormFieldsComponents.ReadTextCustom
                            {...genericProps}
                            label="forms.checks.suspend.numberChecks.label"
                            value={`${values?.checksSuspend[0]} a ${values?.checksSuspend[1]}`}
                        />
                    )}
                </Box>
            ) : null}
        </>
    );

    const formProps = {
        title: isFormCheckbook ? TITLE_FORM_CHECKBOOK : TITLE_FORM_CHECK,
        metadata: { idActivity: ID_ACTIVITY },
        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        preData: preDataForm,
        showFilterChips: false,
        cancelAction,
        handleCancelPreview,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        showSchedulerMessage: false,
        validationSchema,
    };
    return <FormTransition {...props} {...formProps} />;
};

StopChecksForm.propTypes = {
    history: shape({}).isRequired,
    mode: string.isRequired,
    currentLang: string,
    preDataForm: shape({}),
    fromBackoffice: bool,
    location: shape({}),
    dispatch: func.isRequired,
    transaction: shape({}),
    data: shape({}),
    isDesktop: bool.isRequired,
};

StopChecksForm.defaultProps = {
    fromBackoffice: false,
    currentLang: "",
    preDataForm: {},
    location: {},
    transaction: {},
    data: {},
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    beneficiaryWally: wallySelectors.getSelectedBeneficiary(state),
    accountWally: wallySelectors.getAccountWally(state),
    userWally: wallySelectors.getUserWally(state),
    isFetchingPeople: wallySelectors.isFetchingPeople(state),
});

export default compose(connect(mapStateToProps), withRouter)(resizableRoute(StopChecksForm));
