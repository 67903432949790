import { pdf } from "@react-pdf/renderer";
import classNames from "classnames";
import { Field, withFormik } from "formik";
import set from "lodash/set";
import moment from "moment";
import { PDFTicket } from "pages/forms/customForms/PDFTicket";
import { PDFTicketWally } from "pages/forms/customForms/PDFTicketWally";
import { PDFTicketKuara } from "pages/forms/customForms/PDFTicketKuara";
import CreateTemplateModal from "pages/forms/_components/CreateTemplateModal";
import FormActions from "pages/forms/_components/FormActions";
import ListTemplateModal from "pages/forms/_components/ListTemplateModal";
import Templates from "pages/forms/_components/Templates";
import TransactionTicket from "pages/forms/_components/TransactionTicket";
import Scheduler from "pages/forms/_components/_fields/Scheduler";
import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import mapProps from "pages/_components/mapProps";
import ConfirmDialog from "pages/_components/modal/ConfirmDialog";
import Notification from "pages/_components/Notification";
import { arrayOf, bool, func, node, oneOf, shape, string, oneOfType } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { goBack, replace, routerActions } from "react-router-redux";
import { actions as bankActions } from "reducers/bankSelector";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { actions as notificationActions } from "reducers/notification";
import { actions as templateActions, selectors as templateSelectors } from "reducers/template";
import { actions as transactionActions } from "reducers/transactions";
import { selectors as payServiceSelectors } from "reducers/payService";
import { compose } from "redux";
import { getDisplay, getMobileOS, isMobileNative, isMobileNativeFunc, isAndroidPlatform } from "util/device";
import { downloadPdf } from "util/download";
import * as i18n from "util/i18n";
import * as stringUtils from "util/string";
import withRevelockPositionContext from "hoc/withRevelockPositionContext";
import { parse } from "query-string";
import * as config from "util/config";
import FormsFilterChips from "../customForms/FormsFilterChips";
import FormConfirmation from "./FormConfirmation";
import OpeningAccountStep2 from "./_regulations/OpeningAccountStep2";
import { VIEW_MODE } from "../customForms/fixedTermDeposit/CreateFixedTermDepositForm";

const getTicketComponent = (wally, kuara) => {
    if (wally) {
        return PDFTicketWally;
    }
    if (kuara) {
        return PDFTicketKuara;
    }
    return PDFTicket;
};

const generateTicketBlob = async (title, transaction, values, renderTicket, wally, usesJointAccount, kuara) => {
    const TicketComponent = getTicketComponent(wally, kuara);
    const Ticket = (
        <TicketComponent title={title} transaction={transaction} values={values} renderTicket={renderTicket} />
    );
    return pdf(Ticket).toBlob();
};

const generateUrlFromBlob = async (blob, title, transaction) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            let dataUrl = reader.result;
            const chunks = dataUrl.split(",");

            if (getMobileOS(getDisplay()) === "iOS") {
                dataUrl = `data:text/pdf:${i18n.get(title)?.replace("/", "-")}_${
                    transaction.idTransaction
                }'.pdf;base64,${chunks[1]}`;
            }
            resolve(dataUrl);
        };
        reader.onerror = (e) => {
            reject(e);
        };
        reader.readAsDataURL(blob);
    });

class FormRenderCustom extends Component {
    static propTypes = {
        alertConfirmation: shape({}),
        bigDataAmount: bool,
        cancelAction: func,
        childrenTransactions: shape({}),
        closeLinkTo: string,
        credentialsGroupsConfirmation: shape({}).isRequired,
        currentLang: string.isRequired,
        dispatch: func.isRequired,
        errors: shape({}).isRequired,
        extraContent: node,
        fetching: bool,
        formFlex: bool,
        formikBagConfirmation: shape({}).isRequired,
        // tells if the form is being rendered from Backoffice
        fromBackoffice: bool,
        handleCancelPreview: func,
        handleSubmit: func.isRequired,
        history: shape({ location: shape({ pathname: string }) }).isRequired,
        id: string,
        idActivity: string.isRequired,
        isCreateModalVisible: bool,
        isDesktop: bool.isRequired,
        isMobile: bool,
        isSubmitting: bool.isRequired,
        location: shape({ pathname: string }).isRequired,
        match: shape({ path: string, url: string, isExact: bool }).isRequired,
        metadata: shape({}).isRequired,
        mode: oneOf(["view", "edit", "edit-step2", "preview", ""]),
        parentTransaction: shape({}),
        renderAdditionalActions: func,
        renderFields: func.isRequired,
        renderFieldsTermsAndConditions: func,
        renderFootNote: node,
        renderTicket: func,
        returnBack: bool,
        setErrors: func.isRequired,
        setFieldValue: func.isRequired,
        setValues: func.isRequired,
        showFilterChips: bool,
        showLocalRecharge: bool,
        showSchedulerMessage: bool,
        showSubmit: bool,
        submitAction: func.isRequired,
        submitButtonLabel: string,
        submitDisabled: bool,
        templates: arrayOf(shape()),
        // tells if the form is being rendered to confirm a recently made transaction
        ticketConfirmation: bool,
        title: string,
        titlePreviewMode: string,
        titleViewMode: string,
        topDisclaimer: string,
        topDisclaimerAlignY: string,
        topDisclaimerTextColor: string,
        topDisclaimerTextSize: string,
        transaction: shape({ idTransaction: string }).isRequired,
        values: shape({}).isRequired,
        showFormAcions: bool,
        submitActionParams: shape({
            idActivity: string.isRequired,
            values: shape({ scheduler: oneOfType([string, shape({})]) }),
        }).isRequired,
        submitDirectly: bool.isRequired,
        subtitle: string,
        subtitlePrefix: string,
        usesJointAccount: bool,
        onlyBackToDesktopBtn: bool,
        wally: bool,
        kuara: bool,
        confirmationFetching: bool.isRequired,
        pathBackEditMode: string,
        setLocationPathWithParameters: func,
        isLinkFastRecharge: bool,
        hideDownloadButton: bool,
        resetForm: func.isRequired,
    };

    static defaultProps = {
        alertConfirmation: undefined,
        bigDataAmount: false,
        cancelAction: null,
        childrenTransactions: null,
        closeLinkTo: null,
        extraContent: undefined,
        fetching: false,
        formFlex: false,
        fromBackoffice: false,
        handleCancelPreview: null,
        id: null,
        isCreateModalVisible: false,
        isMobile: false,
        mode: "edit",
        parentTransaction: null,
        renderAdditionalActions: null,
        renderFieldsTermsAndConditions: null,
        renderFootNote: null,
        renderTicket: null,
        returnBack: false,
        showFilterChips: true,
        showLocalRecharge: true,
        showFormAcions: true,
        showSchedulerMessage: true,
        showSubmit: true,
        submitButtonLabel: null,
        submitDisabled: false,
        subtitle: null,
        subtitlePrefix: null,
        templates: [],
        ticketConfirmation: false,
        title: "",
        titlePreviewMode: undefined,
        titleViewMode: undefined,
        topDisclaimer: null,
        topDisclaimerAlignY: null,
        topDisclaimerTextColor: null,
        topDisclaimerTextSize: null,
        usesJointAccount: false,
        onlyBackToDesktopBtn: false,
        wally: false,
        kuara: false,
        pathBackEditMode: undefined,
        setLocationPathWithParameters: undefined,
        isLinkFastRecharge: false,
        hideDownloadButton: false,
    };

    state = {
        showTemplateMobile: false,
        showAlertConfirm: false,
        alertConfirmation: undefined,
        // eslint-disable-next-line react/destructuring-assignment
        titleMode: this.props.title || "",
    };

    static getDerivedStateFromProps(props, state) {
        return {
            ...state,
            titleMode: props.title || "",
        };
    }

    componentDidMount() {
        this.loadTemplateList();
    }

    componentDidUpdate(prevProps) {
        const { idActivity, location, setErrors, alertConfirmation, mode, transaction } = this.props;
        if (idActivity !== prevProps.idActivity) {
            this.loadTemplateList();
            setErrors({});
        }
        if (location !== prevProps.location) {
            this.loadTemplateList();
            this.clearRoleAndSuppliersPaymentForm(prevProps.location?.pathname, location?.pathname);
            setErrors({});
        }

        if (alertConfirmation !== prevProps.alertConfirmation) {
            this.showAlertConfirmation(alertConfirmation);
        }
        if (mode !== prevProps.mode) {
            this.setTitleMode(mode);
        }

        if (mode !== prevProps.mode || transaction !== prevProps.transaction) {
            this.setRevelockPositionForm(mode, transaction);
        }
    }

    componentWillUnmount() {
        const { dispatch, history, match } = this.props;
        const { pathname } = history.location;
        const { url } = match.url;

        if (!pathname.includes(url)) {
            dispatch(formActions.formClosed());
        }
        const data = {};
        if (!pathname.includes("bankSearch")) {
            dispatch(formActions.setData(data));
            dispatch(bankActions.resetSelectedBanks());
        }
    }

    clearRoleAndSuppliersPaymentForm = (prevLocation, newLocation) => {
        if (
            (prevLocation === "/salaryPayment/request" && newLocation === "/suppliersPayment/request") ||
            (prevLocation === "/suppliersPayment/request" && newLocation === "/salaryPayment/request")
        ) {
            const { resetForm, setFieldValue } = this.props;
            resetForm();
            setFieldValue("file", []);
        }
    };

    setRevelockPositionForm = (mode, transaction) => {
        const { setLocationPathWithParameters, match } = this.props;

        if (!setLocationPathWithParameters) {
            return;
        }

        /**
         * When the user finished the transaction and is viewing the ticket
         */
        if (mode && mode === VIEW_MODE && transaction?.idTransactionStatus) {
            setLocationPathWithParameters([mode, transaction.idTransactionStatus.toLowerCase()], match);
            return;
        }

        /**
         * When user navigate between form steps
         */
        if (mode) {
            setLocationPathWithParameters([mode], match);
        }
    };

    setTitleMode = (mode) => {
        const { title, titlePreviewMode, titleViewMode } = this.props;

        let titleMode = title;
        if (mode === "preview" && titlePreviewMode) {
            titleMode = titlePreviewMode;
        } else if (mode === "view" && titleViewMode) {
            titleMode = titleViewMode;
        }

        this.setState({
            titleMode,
        });
    };

    showAlertConfirmation = (alertConfirmation) => {
        this.setState({ showAlertConfirm: true, alertConfirmation });
    };

    loadTemplateList = () => {
        const {
            metadata: { templatesEnabled, loadTemplatesOnPreview = false },
            mode,
            dispatch,
            idActivity,
            fromBackoffice,
        } = this.props;
        const isTemplatesVisibile =
            (mode === "edit" || (mode === "preview" && loadTemplatesOnPreview)) && templatesEnabled && !fromBackoffice;
        if (isTemplatesVisibile) {
            const idActivityTemplate = idActivity.replace(".pre", ".send");
            dispatch(templateActions.loadTemplateList(null, idActivityTemplate));
        }
    };

    handleClick = async (action) => {
        const {
            dispatch,
            metadata: { idForm, idActivity },
            transaction,
            values,
            renderTicket,
            title,
            wally,
            usesJointAccount,
            kuara,
        } = this.props;

        const { idTransaction } = transaction;
        switch (action) {
            case "listTemplate":
                this.loadTemplateList();
                dispatch(templateActions.listTemplate());
                break;
            case "createTemplate":
                dispatch(templateActions.createTemplate());
                this.loadTemplateList();
                break;
            case "saveDraft":
                dispatch(
                    formActions.saveDraft({
                        idActivityDraft: idActivity,
                        data: values,
                        idTransaction: transaction ? idTransaction : null,
                    }),
                );
                break;
            case "cancelTransaction":
                dispatch(formActions.cancelTransactionPre({ idActivity, idForm }));
                break;
            case "modifyTransaction":
                dispatch(formActions.changeCancelTransaction(false));

                dispatch(formActions.modifyTransaction(idTransaction));
                break;
            case "signTransaction":
                dispatch(
                    formActions.signTransactionPreview({
                        idForm,
                        idActivity,
                        idTransaction: transaction.idTransaction,
                        ticketData: {}, // Requerido en administrationTicket.signAdministrativeTransaction
                        values,
                    }),
                );
                break;
            case "downloadTicketPdf":
                if (renderTicket) {
                    try {
                        const ticketBlob = await generateTicketBlob(
                            title,
                            transaction,
                            values,
                            renderTicket,
                            wally,
                            usesJointAccount,
                            kuara,
                        );

                        downloadPdf(`${i18n.get(title)}_${transaction.idTransaction}.pdf`, ticketBlob, false);
                    } catch (error) {
                        // eslint-disable-next-line no-console
                        console.error(error);
                        dispatch(
                            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["form"]),
                        );
                    }
                }

                break;
            case "shareTicket":
                if (renderTicket) {
                    try {
                        const ticketBlob = await generateTicketBlob(
                            title,
                            transaction,
                            values,
                            renderTicket,
                            wally,
                            usesJointAccount,
                            kuara,
                        );
                        const url = await generateUrlFromBlob(ticketBlob, title, transaction);

                        let options = {
                            files: [url],
                        };

                        if (isMobileNativeFunc() && isAndroidPlatform()) {
                            options = { ...options, message: i18n.get(title), subject: i18n.get(title) };
                        }

                        window.plugins.socialsharing.shareWithOptions(options, null, null);
                    } catch {
                        dispatch(
                            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", [
                                "transaction/details",
                            ]),
                        );
                    }
                }

                break;
            default:
                break;
        }
    };

    renderScheduler = (schedulerDisabled, disabledToday = false, disabledScheduled = false) => {
        const {
            currentLang,
            metadata: {
                enabledWeekDays,
                firstWorkingDate,
                maxDaysToSchedule,
                nonWorkingDays,
                programable,
                schedulable,
                allowRecurrent,
            },
            mode,
            idActivity,
        } = this.props;

        const data = {
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            nonWorkingDays,
            lang: currentLang,
            mode,
            programable,
            schedulable,
            allowRecurrent,
        };

        return (
            (programable || schedulable) && (
                <Field
                    component={Scheduler}
                    data={data}
                    renderRadioButtons
                    radioInline
                    name="scheduler"
                    disabled={schedulerDisabled}
                    disabledToday={disabledToday}
                    disabledScheduled={disabledScheduled}
                    idActivity={idActivity}
                />
            )
        );
    };

    handleClose = () => {
        const { dispatch, location, closeLinkTo } = this.props;
        let pathname = closeLinkTo || "/desktop";
        if (location.pathname.match("/transaction/")) {
            pathname = "/transactions/list";
            dispatch(transactionActions.setFilters(null));
        }
        dispatch(replace({ pathname, state: { transition: "transition-flow-close" } }));
    };

    handleBackEditMode = (pathBackEditMode) => {
        const { dispatch } = this.props;
        if (pathBackEditMode) {
            dispatch(routerActions.replace(pathBackEditMode));
            return;
        }
        dispatch(goBack());
    };

    handleBack = (buttonType) => {
        const { dispatch, mode, location, transaction, pathBackEditMode, isLinkFastRecharge } = this.props;
        let isTicketRendered = location.pathname.match("/transaction/") != null;
        const isTicketWallyRendered = location.pathname.match("/transactionWally") != null;
        const isTransactionPendingWally = location.pathname.match("/transactionPendingWally") != null;
        const isTransactionStopCheck =
            location.pathname.match("/formCustom/stopChecks") != null ||
            location.pathname.match("/formCustom/stopCheckbooks") != null;

        const { isTransactionFromMovementsKuara } = parse(location.search);

        const isPendingProcessing =
            transaction?.idTransactionStatus === "PROCESSING" || transaction?.idTransactionStatus === "PENDING";

        isTicketRendered = isPendingProcessing ? !isTicketRendered : isTicketRendered;

        let pathname = null;
        if (
            isTransactionFromMovementsKuara &&
            (isTransactionFromMovementsKuara === true || isTransactionFromMovementsKuara === "true")
        ) {
            dispatch(replace({ pathname: "/lastMovementsKuara", state: { transition: "transition-flow-close" } }));
            return;
        }

        if (isTicketRendered) {
            pathname = "/transactions/list";
        } else if (isPendingProcessing) {
            pathname = "/pendingTransaction/list";
        } else if (isTicketWallyRendered) {
            pathname = "/lastMovementsWally";
        } else {
            pathname = "/desktop";
        }
        if (mode === "preview" && buttonType === "cancel") {
            dispatch(replace({ pathname: "/desktop", state: { transition: "transition-flow-close" } }));
        } else if (mode === "preview" && !isTransactionPendingWally && !isTransactionStopCheck && !isLinkFastRecharge) {
            dispatch(formActions.goBackCleanTransaction());
        } else if (isTicketRendered || isPendingProcessing || isTicketWallyRendered) {
            dispatch(replace({ pathname, state: { transition: "transition-flow-close" } }));
        } else {
            this.handleBackEditMode(pathBackEditMode);
        }
    };

    handleTemplateSelect = (template) => {
        let blackListKey = config.getArray("core.listTransactionTemplates.blackList");
        blackListKey = blackListKey !== undefined && blackListKey.length > 0 ? blackListKey : ["balance"];
        const { setValues, setErrors, metadata, isDesktop } = this.props;
        const templateToBeLoaded = template;
        blackListKey.forEach((key) => {
            delete templateToBeLoaded[key];
        });
        const { beforeTemplateLoad, onTemplateLoad } = metadata;
        if (templateToBeLoaded?.scheduler?.valueDate) {
            const valueDate = moment(templateToBeLoaded.scheduler.valueDate);
            templateToBeLoaded.scheduler.valueDate = valueDate;
        }

        if (beforeTemplateLoad) {
            beforeTemplateLoad(templateToBeLoaded);
        }

        setValues(templateToBeLoaded);
        setErrors({});

        if (onTemplateLoad) {
            onTemplateLoad(templateToBeLoaded);
        }
        if (!isDesktop) {
            this.setState({ showTemplateMobile: false });
        }
    };

    renderFieldsSection = () => {
        const {
            mode,
            renderFields,
            setFieldValue,
            values,
            setValues,
            isDesktop,
            errors,
            formFlex,
            setErrors,
        } = this.props;

        return (
            <Box
                {...(formFlex && { display: "flex", fullHeight: true })}
                className={classNames("form-content", {
                    "mb-8": mode === "edit" && isDesktop,
                    "mb-5": mode === "edit" && !isDesktop,
                })}>
                {renderFields(setFieldValue, values, setValues, this.renderScheduler, errors, setErrors)}
            </Box>
        );
    };

    getRenderContentEdit = (renderFormContent, values, id, idActivity, handleSubmit) => {
        const { isDesktop } = this.props;
        const { showTemplateMobile } = this.state;

        if (!isDesktop && showTemplateMobile) {
            return (
                <Templates
                    customSelect={this.handleTemplateSelect}
                    customBack={() => {
                        this.setState({ showTemplateMobile: false });
                    }}
                />
            );
        }
        return (
            <>
                <form
                    onSubmit={handleSubmit}
                    {...(!isDesktop && { className: "display-flex flex-column justify-content-between flex" })}>
                    {renderFormContent()}
                </form>
                <CreateTemplateModal values={values} idForm={id} idActivityTemplate={idActivity} />
                <ListTemplateModal onSelect={this.handleTemplateSelect} className="navigational-list" />
            </>
        );
    };

    renderFormContent = () => {
        const {
            cancelAction,
            childrenTransactions,
            extraContent,
            fromBackoffice,
            handleCancelPreview,
            isDesktop,
            isSubmitting,
            metadata: { draftsEnabled, templatesEnabled, idForm, showSubmit, templatesShowDisabled },
            mode,
            parentTransaction,
            renderAdditionalActions,
            renderFootNote,
            showFormAcions,
            showSchedulerMessage,
            submitButtonLabel,
            submitDisabled,
            templates,
            ticketConfirmation,
            transaction,
            usesJointAccount,
            onlyBackToDesktopBtn,
            wally,
            kuara,
            hideDownloadButton,
        } = this.props;

        const isTemplatesVisibile = mode === "edit" && templatesEnabled && templates.length > 0;

        const isEditable =
            typeof childrenTransactions === "undefined" ||
            childrenTransactions === null ||
            childrenTransactions.length === 0;

        const actionButtons = !fromBackoffice && showFormAcions && (
            <FormActions
                onClick={this.handleClick}
                fetching={isSubmitting}
                mode={mode}
                draftsEnabled={draftsEnabled}
                templatesEnabled={templatesEnabled}
                templatesShowDisabled={templatesShowDisabled}
                transaction={transaction}
                parentTransaction={parentTransaction}
                isEditable={isEditable}
                idForm={idForm}
                isDesktop={isDesktop}
                additionalActions={renderAdditionalActions}
                showSubmit={showSubmit}
                renderFootNote={renderFootNote}
                isTemplatesVisibile={isTemplatesVisibile}
                submitDisabled={submitDisabled}
                cancelAction={cancelAction}
                submitButtonLabel={submitButtonLabel}
                wally={wally}
                kuara={kuara}
                usesJointAccount={usesJointAccount}
                onlyBackToDesktopBtn={onlyBackToDesktopBtn}
            />
        );

        if (mode === "edit") {
            return (
                <>
                    {this.renderFieldsSection()}
                    {actionButtons}
                </>
            );
        }

        return (
            <TransactionTicket
                childrenTransactions={childrenTransactions}
                formActions={actionButtons}
                wally={wally}
                kuara={kuara}
                fromBackoffice={fromBackoffice}
                parentTransaction={parentTransaction}
                ticketConfirmation={ticketConfirmation}
                transaction={transaction}
                generalHandleClick={this.handleClick}
                showSchedulerMessage={showSchedulerMessage}
                usesJointAccount={usesJointAccount}
                handleCancel={() => (handleCancelPreview ? handleCancelPreview() : this.handleBack("cancel"))}
                extraContent={extraContent}
                hideDownloadButton={hideDownloadButton}>
                {this.renderFieldsSection()}
            </TransactionTicket>
        );
    };

    renderContent = () => {
        const {
            bigDataAmount,
            currentLang,
            handleCancelPreview,
            handleSubmit,
            id,
            metadata,
            mode,
            renderFields,
            renderFieldsTermsAndConditions,
            setFieldValue,
            showSchedulerMessage,
            topDisclaimer,
            topDisclaimerAlignY,
            topDisclaimerTextColor,
            topDisclaimerTextSize,
            values,
            wally,
            kuara,
        } = this.props;

        const { idActivity } = metadata;
        const isOverdraftFlow = false;
        switch (mode) {
            case "edit":
                return this.getRenderContentEdit(this.renderFormContent, values, id, idActivity, handleSubmit);
            case "view":
                return <div className="above-the-fold">{this.renderFormContent()}</div>;
            case "edit-step2":
                if (isOverdraftFlow) {
                    return (
                        <OpeningAccountStep2
                            overdraft={values.overdraft}
                            accountType={values.accountType}
                            setFieldValue={setFieldValue}
                        />
                    );
                }
                return <></>;
            case "preview":
                return (
                    <FormConfirmation
                        idForm={id}
                        metadata={metadata}
                        currentLang={currentLang}
                        renderFields={(submitValues) =>
                            renderFields(
                                () => {},
                                submitValues,
                                () => {},
                            )
                        }
                        renderFieldsTermsAndConditions={renderFieldsTermsAndConditions}
                        showSchedulerMessage={showSchedulerMessage}
                        topDisclaimer={topDisclaimer}
                        topDisclaimerAlignY={topDisclaimerAlignY}
                        topDisclaimerTextColor={topDisclaimerTextColor}
                        topDisclaimerTextSize={topDisclaimerTextSize}
                        bigDataAmount={bigDataAmount}
                        handleCancel={() => (handleCancelPreview ? handleCancelPreview() : this.handleBack("cancel"))}
                        idActivity={idActivity}
                        wally={wally}
                        kuara={kuara}
                    />
                );

            default:
                return <></>;
        }
    };

    renderHeader = () => {
        const {
            fetching,
            fromBackoffice,
            isMobile,
            location,
            metadata: { templatesEnabled },
            mode,
            subtitle,
            subtitlePrefix,
            templates,
            transaction,
            wally,
            kuara,
        } = this.props;
        const isTemplatesVisibile = mode === "edit" && templatesEnabled && templates.length > 0;
        const isTicket = location.pathname.match("/transaction/") != null;
        const { titleMode } = this.state;

        if (!fromBackoffice) {
            if (mode !== "view" && mode !== "edit") {
                return (
                    <>
                        {isMobile ? (
                            <Head
                                hideMenu
                                accessibilityTextId="administration.forms.confirm.credentials"
                                titleText={i18n.get(titleMode)}
                                onBack={this.handleBack}
                                arrowWhite
                                disableBackButton={fetching}
                                {...(wally && {
                                    titleImage: "images/wally-head.svg",
                                    subtitlePrefix,
                                    subtitle,
                                })}
                                {...(kuara && {
                                    subtitlePrefix,
                                    subtitle,
                                    // additionalClassName: "kuara-header",
                                })}
                            />
                        ) : (
                            <Head
                                hideMenu
                                accessibilityTextId="administration.forms.confirm.credentials"
                                titleText={i18n.get(titleMode)}
                                onBack={this.handleBack}
                                disableBackButton={fetching}
                            />
                        )}
                    </>
                );
            }

            if (isTicket || (isMobile && mode === "view")) {
                return (
                    <Head
                        {...(wally && {
                            titleImage: "images/wally-head.svg",
                            subtitlePrefix,
                            subtitle,
                        })}
                        {...(kuara && {
                            subtitlePrefix,
                            subtitle,
                        })}
                        {...(isTicket && { onBack: this.handleBack })}
                        hideNavbarInMobile
                        accessibilityTextId="administration.forms.transaction.details"
                        titleText={i18n.get(
                            transaction.idTransactionStatus === "PENDING" ||
                                transaction.idTransactionStatus === "PROCESSING"
                                ? "pendingDispatch.list.title"
                                : titleMode,
                        )}
                        {...(transaction.idTransactionStatus !== "PROCESSING" &&
                            transaction.idTransactionStatus !== "PENDING" &&
                            transaction?.idTransactionStatus !== "SCHEDULED" &&
                            transaction?.data?.typeTransaction !== "PARENT" &&
                            isMobileNative && {
                                actionButton: () => this.handleClick("shareTicket"),
                                actionButtonImage: "images/icons/share.svg",
                                actionButtonBsStyle: !isMobile ? "link" : "only-icon",
                                actionButtonImageMd: true,
                            })}
                    />
                );
            }
            const actionButtonProps = isTemplatesVisibile && {
                actionButtonImage: "images/icons/template.svg",
                actionButtonLabel: !isMobile ? "forms.template.list.button" : null,
                actionButtonBsStyle: !isMobile ? "link" : "only-icon",
                actionButton: () => this.handleClick("listTemplate"),
            };

            return (
                <Head
                    {...(mode !== "view" && { onBack: this.handleBack })}
                    {...(wally && {
                        disableBackButton: fetching,
                        titleImage: "images/wally-head.svg",
                        subtitlePrefix,
                        subtitle,
                    })}
                    {...(kuara && {
                        disableBackButton: fetching,
                        subtitlePrefix,
                        subtitle,
                        additionalClassName: "kuara-header",
                    })}
                    titleText={i18n.get(titleMode)}
                    hideMenu
                    accessibilityTextId={
                        !isTicket && mode === "view" ? "administration.forms.transaction.details" : null
                    }
                    {...actionButtonProps}
                />
            );
        }
        return null;
    };

    onAcceptAlertConfirm = () => {
        const {
            credentialsGroupsConfirmation,
            dispatch,
            formikBagConfirmation,
            submitAction,
            submitActionParams,
            submitDirectly,
        } = this.props;
        if (submitDirectly) {
            dispatch(
                submitAction({
                    ...submitActionParams,
                    credentials: credentialsGroupsConfirmation,
                    formikBag: formikBagConfirmation,
                }),
            );
        } else {
            dispatch(formActions.setMode("preview", "edit"));
            this.setState({ showAlertConfirm: false, alertConfirmation: undefined });
        }
    };

    onCancelAlertConfirm = () => {
        const { dispatch } = this.props;
        this.setState({ showAlertConfirm: false, alertConfirmation: undefined });
        dispatch(formActions.setMode("edit", ""));
    };

    render() {
        const {
            fetching,
            currentLang,
            errors,
            mode,
            idActivity,
            showFilterChips,
            confirmationFetching,
            showLocalRecharge,
        } = this.props;
        const { alertConfirmation, showAlertConfirm } = this.state;

        return (
            <>
                <Notification scopeToShow="form" errors={errors} currentLang={currentLang} />
                {!fetching && this.renderHeader()}

                <MainContainer showLoader={fetching}>
                    {mode === "edit" && showFilterChips && (
                        <FormsFilterChips idActivity={idActivity} showLocalRecharge={showLocalRecharge} />
                    )}
                    {!fetching && this.renderContent()}
                </MainContainer>

                {alertConfirmation && (
                    <ConfirmDialog
                        showDialog={showAlertConfirm}
                        title={alertConfirmation.title || ""}
                        description={alertConfirmation.message || ""}
                        onConfirm={this.onAcceptAlertConfirm}
                        onCancel={this.onCancelAlertConfirm}
                        confirmLabel="global.confirm"
                        loading={confirmationFetching}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    isCreateModalVisible: templateSelectors.isCreateModalVisible(state),
    alertConfirmation: formSelectors.getAlertConfirmation(state),
    submitDirectly: formSelectors.isSubmitDirectly(state),
    submitAction: formSelectors.getSubmitAction(state),
    submitActionParams: formSelectors.getSubmitActionParams(state),
    formikBagConfirmation: formSelectors.getFormikBagConfirmation(state),
    credentialsGroupsConfirmation: formSelectors.getCredentialsGroupsConfirmation(state),
    confirmationFetching: formSelectors.isConfirmationFetching(state),
    isLinkFastRecharge: payServiceSelectors.isLinkFastRecharge(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: ({ data }) => ({
            ...data,
            scheduler: data?.scheduler
                ? {
                      ...data.scheduler,
                      valueDate: data.scheduler.valueDate ? moment(data.scheduler.valueDate) : null,
                  }
                : null,
        }),
        validate: (values, props) => {
            const { validationSchema, validate, dispatch } = props;
            let errors = {};

            if (validationSchema) {
                try {
                    validationSchema(props).validateSync(values, { abortEarly: false });
                } catch (error) {
                    if (error.name !== "ValidationError") {
                        throw error;
                    }

                    errors = error.inner.reduce(
                        (e, currentError) => set(e, currentError.path, currentError.message),
                        {},
                    );
                }
            }

            if (validate) {
                errors = { ...errors, ...validate(values, props) };
            }

            if (Object.keys(errors).length > 0) {
                // despacho notificacion de errores en el form
                if (errors.NO_FIELD) {
                    dispatch(notificationActions.showNotification(errors.NO_FIELD, "error", ["form"], true));
                } else {
                    dispatch(
                        notificationActions.showNotification(i18n.get("forms.fieldsErrors"), "error", ["form"], true),
                    );
                }
            }

            return errors;
        },
        handleSubmit: (values, formikBag) => {
            const focusedField = document.activeElement;
            if (focusedField) {
                focusedField.blur();
            }

            const filteredValues = Object.entries(values).reduce((accumulator, [key, value]) => {
                if (value === "") {
                    return accumulator;
                }
                if (Array.isArray(value) && value.length > 0 && value[0].text !== undefined) {
                    const arrayValues = [];
                    value.forEach((item) => {
                        arrayValues.push(item.text || item);
                    });
                    return {
                        ...accumulator,
                        [key]: arrayValues,
                    };
                }
                return {
                    ...accumulator,
                    [key]: stringUtils.trim(value),
                };
            }, {});

            if (formikBag.props.useDefaultSubmit) {
                let funcToDispatch = formActions.previewForm({
                    formikBag,
                    idForm: formikBag.props.id,
                    idActivity: formikBag.props.metadata.idActivity,
                    idTransaction: formikBag.props.transaction ? formikBag.props.transaction.idTransaction : null,
                    values: filteredValues,
                    gtmKeys: formikBag?.props?.gtmKeys
                });
                if (formikBag.props.metadata.idActivity === "token.pin.send") {
                    const deviceUuid = window?.app?.getDeviceUUID() || "";
                    const deviceData = {
                        deviceUuid,
                    };

                    funcToDispatch = formActions.sendForm({
                        formikBag,
                        idForm: formikBag.props.id,
                        idActivity: formikBag.props.metadata.idActivity,
                        idTransaction: formikBag.props.transaction ? formikBag.props.transaction.idTransaction : null,
                        values: Object.assign({}, filteredValues, deviceData),
                    });
                }

                formikBag.props.dispatch(funcToDispatch);
            } else {
                formikBag.props.dispatch(formikBag.props.alternateSubmitFunc({ formikBag, values: filteredValues }));
            }
        },
    }),
    mapProps(({ metadata, ...props }) => ({
        ...props,
        metadata,
    })),
)(withRevelockPositionContext(FormRenderCustom));
