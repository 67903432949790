import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FormattedAmount from "pages/_components/FormattedAmount";
import GridTable from "pages/_components/GridTable/GridTable";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import FieldError from "pages/_components/fields/FieldError";
import SideBarModal from "pages/_components/modal/SideBarModal";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import * as utilIdentification from "util/recoveryUser";

import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { selectors as fixedTermDepositSelectors } from "reducers/fixedTermDeposit/fixedTermDeposit.reducer";
import { connect } from "react-redux";
import {
    EDIT_MODE,
    PREVIEW_MODE,
    VIEW_MODE,
} from "pages/forms/customForms/fixedTermDeposit/CreateFixedTermDepositForm";
import { arrayOf, bool, shape, string, func } from "prop-types";
import React, { useEffect, useState } from "react";
import * as i18n from "util/i18n";
import PercentageBeneficiaryForm from "./PercentageBeneficiaryForm";

const PercentageBeneficiaryField = (props) => {
    const {
        labelKey,
        form,
        field,
        isDesktop,
        className,
        documentData,
        infoLabel,
        defaultValue,
        mode,
        currentLang,
        beneficiaryRelationshipList,
        activeEnvironment,
        getSignerName,
        isFetchingBeneficiaryByDocumentType,
        beneficiaryByDocumentType,
    } = props;
    const [showAddBeneficiary, setShowAddBeneficiary] = useState(false);
    const [beneficiaryList, setBeneficiaryList] = useState(defaultValue || []);
    const [beneficiarySelected, setBeneficiarySelected] = useState(undefined);
    const [singleBeneficiary, setSingleBeneficiary] = useState();

    const isCorporate = activeEnvironment.type === "corporate";
    const fieldHasError = () => {
        const { errors } = form;
        return errors[`${field.name}`];
    };

    const renderError = (absolute) => {
        const { errors } = form;

        if (fieldHasError()) {
            return (
                <Box
                    display="flex"
                    alignY="center"
                    {...(absolute
                        ? { position: "absolute", className: "pl-5 pt-5" }
                        : { className: "pl-5 translate-error-center" })}>
                    <FieldError relativeStyleError noEllipsis error={errors[`${field.name}`]} />
                </Box>
            );
        }
        return null;
    };

    const deleteError = () => {
        // borro el error del campo
        const { errors, setErrors } = form;
        const { [field.name]: error, ...rest } = errors;
        setErrors(rest);
    };

    const saveBeneficiary = (beneficiary) => {
        if (!beneficiary?.id) {
            return;
        }
        setSingleBeneficiary(beneficiary);
        if (isCorporate) {
            getSignerName(beneficiary?.document);
        }
        if (!isCorporate && beneficiary?.id) {
            const updateBeneficiaryList = [...(beneficiaryList || [])].filter(
                (item) => item && item.id && item.id !== beneficiary?.id,
            );
            setBeneficiaryList([...updateBeneficiaryList, beneficiary]);
            form.setFieldValue(field.name, [...updateBeneficiaryList, beneficiary]);
            setShowAddBeneficiary(false);
            deleteError();
        }
    };

    const deleteBeneficiary = (idBeneficiary) => {
        if (!idBeneficiary) {
            return;
        }
        setBeneficiaryList([...(beneficiaryList?.filter((ben) => ben && ben.id !== idBeneficiary) || [])]);

        deleteError();
    };

    const shouldShowAddButton = () => {
        const percentage = beneficiaryList.reduce((acc, item) => acc + (item?.percentage ? +item.percentage : 0), 0);
        return percentage < 100;
    };

    useEffect(() => {
        if (beneficiaryByDocumentType?.data?.isValidate && singleBeneficiary?.id) {
            const document = utilIdentification.generateIdentificationToSend(singleBeneficiary?.document, true);

            const newBeneficiary = {
                ...singleBeneficiary,
                document,
                name: beneficiaryByDocumentType?.data?.name,
            };
            const updateBeneficiaryList = [...(beneficiaryList || [])].filter(
                (item) => item && item.id && item.id !== singleBeneficiary?.id,
            );
            setBeneficiaryList([...updateBeneficiaryList, newBeneficiary]);
            form.setFieldValue(field.name, [...updateBeneficiaryList, newBeneficiary]);
            setShowAddBeneficiary(false);
            deleteError();
        }
        setShowAddBeneficiary(false);
    }, [beneficiaryByDocumentType]);

    const getColumnsTemplate = () => {
        if (isCorporate) {
            return isDesktop ? "1fr 1fr 1fr 8rem" : "1fr 2fr";
        }
        return isDesktop ? "1.1fr 1.1fr 1.8fr" : "1.3fr 1fr 5rem";
    };

    const renderItemListBeneficiary = () =>
        beneficiaryList?.map(
            (beneficiary) =>
                beneficiary.id && (
                    <GridTable.Container
                        className={classNames({ "gapX-3": isDesktop })}
                        padding={classNames("px-5 py-2", { "py-4": !isDesktop && mode !== EDIT_MODE })}
                        columnsTemplate={mode === EDIT_MODE ? getColumnsTemplate() : "2fr 1fr"}
                        background="transparent">
                        {/* TODO (LOGICA DE FIRMANTES) */}
                        {isCorporate ? (
                            <>
                                <GridTable.Data columnStart={1} columnWidth={1} alignX="flex-start">
                                    <Text size={isDesktop ? "6" : "5"} ellipsis>
                                        {beneficiary?.name || ""}
                                    </Text>
                                </GridTable.Data>
                                {mode === EDIT_MODE && (
                                    <GridTable.Data
                                        columnStart={2}
                                        columnWidth={1}
                                        {...(isDesktop ? { alignX: "center" } : { alignX: "flex-end" })}>
                                        <Box display="flex" column alignX="center" alignY="center" className="mx-auto">
                                            {/* TODO (LABELS DATA) */}
                                            <Text size={isDesktop ? "6" : "5"}>
                                                {i18n.get(
                                                    `documentType.label.${beneficiary?.document?.documentType}`,
                                                ) || ""}
                                            </Text>
                                            {!isDesktop && (
                                                <Text size={isDesktop ? "6" : "5"}>
                                                    {beneficiary?.document?.documentCountry
                                                        ? `${beneficiary?.document?.documentCountry}-`
                                                        : ""}
                                                    {beneficiary?.document?.idNumber2
                                                        ? `${beneficiary?.document?.idNumber2}${
                                                              beneficiary?.document?.documentNumber1 ||
                                                              beneficiary?.document?.documentNumber2
                                                                  ? "-"
                                                                  : ""
                                                          }`
                                                        : ""}
                                                    {beneficiary?.document?.documentNumber1
                                                        ? `${beneficiary?.document?.documentNumber1}${
                                                              beneficiary?.document?.documentNumber2 ? "-" : ""
                                                          }`
                                                        : ""}
                                                    {beneficiary?.document?.documentNumber2
                                                        ? `${beneficiary?.document?.documentNumber2}`
                                                        : ""}
                                                </Text>
                                            )}
                                        </Box>
                                        {!isDesktop && (
                                            <Box display="flex" className="ml-auto">
                                                <Button
                                                    ariaLabel="edit_beneficiary"
                                                    bsStyle="only-icon"
                                                    image="images/icons/editPen16.svg"
                                                    onClick={() => {
                                                        setBeneficiarySelected(beneficiary);
                                                        setShowAddBeneficiary(true);
                                                    }}
                                                />

                                                <Button
                                                    ariaLabel="delete_beneficiary"
                                                    bsStyle="only-icon"
                                                    image="images/icons/circleCross16.svg"
                                                    onClick={() => {
                                                        deleteBeneficiary(beneficiary?.id);
                                                    }}
                                                />
                                            </Box>
                                        )}
                                    </GridTable.Data>
                                )}
                                {isDesktop && mode === EDIT_MODE && (
                                    <GridTable.Data columnStart={3} columnWidth={1} alignX="flex-end">
                                        {/* TODO (LABELS DATA) */}
                                        <Text size={isDesktop ? "6" : "5"}>
                                            {beneficiary?.document?.documentCountry
                                                ? `${beneficiary?.document?.documentCountry}-`
                                                : ""}
                                            {beneficiary?.document?.idNumber2
                                                ? `${beneficiary?.document?.idNumber2}${
                                                      beneficiary?.document?.documentNumber1 ||
                                                      beneficiary?.document?.documentNumber2
                                                          ? "-"
                                                          : ""
                                                  }`
                                                : ""}
                                            {beneficiary?.document?.documentNumber1
                                                ? `${beneficiary?.document?.documentNumber1}${
                                                      beneficiary?.document?.documentNumber2 ? "-" : ""
                                                  }`
                                                : ""}
                                            {beneficiary?.document?.documentNumber2
                                                ? `${beneficiary?.document?.documentNumber2}`
                                                : ""}
                                        </Text>
                                    </GridTable.Data>
                                )}
                                {mode === EDIT_MODE && isDesktop && (
                                    <GridTable.Data className="p-0" columnStart={4} alignX="flex-end">
                                        <Button
                                            ariaLabel="edit_beneficiary"
                                            bsStyle="only-icon"
                                            image="images/icons/editPen16.svg"
                                            onClick={() => {
                                                setBeneficiarySelected(beneficiary);
                                                setShowAddBeneficiary(true);
                                            }}
                                        />

                                        <Box display="flex" className="table-data table-data-icon">
                                            <Button
                                                ariaLabel="delete_beneficiary"
                                                bsStyle="only-icon"
                                                image="images/icons/circleCross16.svg"
                                                onClick={() => {
                                                    deleteBeneficiary(beneficiary?.id);
                                                }}
                                            />
                                        </Box>
                                    </GridTable.Data>
                                )}
                            </>
                        ) : (
                            <>
                                <GridTable.Data columnStart={1} columnWidth={1} alignX="flex-start">
                                    <Text size={isDesktop ? "6" : "5"}>{beneficiary?.name || ""}</Text>
                                </GridTable.Data>
                                <GridTable.Data
                                    columnStart={2}
                                    columnWidth={1}
                                    // eslint-disable-next-line no-nested-ternary
                                    {...(mode === EDIT_MODE
                                        ? isCorporate
                                            ? {
                                                  alignX: isDesktop ? "center" : "flex-end",
                                              }
                                            : { alignX: isDesktop ? "flex-end" : "center" }
                                        : { alignX: "flex-end" })}>
                                    <FormattedAmount
                                        quantity={beneficiary?.percentage || ""}
                                        noCurrency
                                        className="mr-2"
                                        frequencyInSpan={false}
                                        frequency="%"
                                        size={isDesktop ? "6" : "5"}
                                    />
                                </GridTable.Data>
                                {mode === EDIT_MODE && (
                                    <GridTable.Data className="p-0" columnStart={3} alignX="flex-end">
                                        <Button
                                            ariaLabel="edit_beneficiary"
                                            bsStyle="only-icon"
                                            image="images/icons/editPen16.svg"
                                            onClick={() => {
                                                setBeneficiarySelected(beneficiary);
                                                setShowAddBeneficiary(true);
                                            }}
                                        />

                                        <Box display="flex" className="table-data table-data-icon">
                                            <Button
                                                ariaLabel="delete_beneficiary"
                                                bsStyle="only-icon"
                                                image="images/icons/circleCross16.svg"
                                                onClick={() => {
                                                    deleteBeneficiary(beneficiary?.id);
                                                }}
                                            />
                                        </Box>
                                    </GridTable.Data>
                                )}
                            </>
                        )}
                    </GridTable.Container>
                ),
        ) || <div />;

    const renderTableBeneficiary = () => (
        <GridTable>
            <GridTable.Header>
                <GridTable.Container
                    className={classNames({ "gapX-3": isDesktop, "py-4 px-5": !isDesktop })}
                    columnsTemplate={mode === EDIT_MODE ? getColumnsTemplate() : "2fr 1fr"}>
                    {isCorporate ? (
                        <>
                            <GridTable.Data columnStart={1} columnWidth={1} alignX="flex-start" inHeader>
                                {i18n.get("administration.signatures.list.signers")}
                            </GridTable.Data>
                            {mode === EDIT_MODE && (
                                <GridTable.Data
                                    columnStart={2}
                                    columnWidth={1}
                                    {...(mode === EDIT_MODE
                                        ? {
                                              alignX: isDesktop ? "center" : "flex-start",
                                          }
                                        : { alignX: "flex-end" })}
                                    inHeader>
                                    {/* TODO (LABEL) */}
                                    {i18n.get(
                                        isDesktop
                                            ? "document.group.id.type.document.label"
                                            : "TIPO Y NRO. DE IDENTIFICACIÓN",
                                    )}
                                </GridTable.Data>
                            )}
                            {isDesktop && mode === EDIT_MODE && (
                                <GridTable.Data
                                    columnStart={3}
                                    columnWidth={1}
                                    {...(isDesktop ? { alignX: "flex-end" } : { alignX: "center" })}
                                    inHeader>
                                    {i18n.get("document.group.id.number.label")}
                                </GridTable.Data>
                            )}
                        </>
                    ) : (
                        <>
                            <GridTable.Data columnStart={1} columnWidth={1} alignX="flex-start" inHeader>
                                {i18n.get(`${field?.name || ""}.beneficiary.name.label`)}
                            </GridTable.Data>

                            <GridTable.Data
                                columnStart={2}
                                columnWidth={1}
                                {...(mode === EDIT_MODE
                                    ? {
                                          alignX: isDesktop ? "flex-end" : "center",
                                      }
                                    : { alignX: "flex-end" })}
                                inHeader>
                                {i18n.get(`${field?.name || ""}.beneficiary.percentage.label`)}
                            </GridTable.Data>

                            {mode === EDIT_MODE && <GridTable.Data columnStart={3} alignX="flex-end" inHeader />}
                        </>
                    )}
                </GridTable.Container>
            </GridTable.Header>
            <GridTable.Body {...(mode === EDIT_MODE && beneficiaryList.length === 0 && { className: "pb-10" })}>
                {renderItemListBeneficiary()}
                {renderError(beneficiaryList.length === 0)}
            </GridTable.Body>
        </GridTable>
    );

    const renderViewModeResume = () =>
        beneficiaryList?.map((beneficiary) => (
            <>
                {activeEnvironment.type === "corporate" && (
                    <div>
                        <Text style={{}} size={isDesktop ? "8" : "5"}>
                            {beneficiary?.name || ""}
                        </Text>
                    </div>
                )}
                {activeEnvironment.type !== "corporate" && (
                    <>
                        <FormFieldsComponents.ReadTextCustom
                            {...props}
                            value={beneficiary?.mobilization || beneficiary?.name || ""}
                            labelCustom={i18n.get(`${field?.name || ""}.beneficiary.name.label`)}
                        />
                        <FormFieldsComponents.ReadTextCustom
                            {...props}
                            value={`${beneficiary?.percentage || ""}%`}
                            labelCustom={i18n.get(`${field?.name || ""}.beneficiary.percentage.label`)}
                        />
                    </>
                )}
            </>
        )) || <div />;

    return (
        <>
            {mode === EDIT_MODE && (
                <SideBarModal
                    show={showAddBeneficiary}
                    onClose={() => {
                        setShowAddBeneficiary(false);
                    }}
                    title={
                        isCorporate
                            ? `${field?.name || ""}.signer.new.title`
                            : `${field?.name || ""}.beneficiary.new.title`
                    }
                    scrollable
                    modalContainerClassName="px-0"
                    modalBodyClassName="px-0 px-md-0"
                    modalHeaderClassName="px-5 px-md-0">
                    <PercentageBeneficiaryForm
                        isDesktop={isDesktop}
                        saveCallback={saveBeneficiary}
                        beneficiaryData={beneficiarySelected}
                        documentData={documentData}
                        beneficiaryList={beneficiaryList}
                        currentLang={currentLang}
                        beneficiaryRelationshipList={beneficiaryRelationshipList}
                        isCorporate={isCorporate}
                        isFetching={isFetchingBeneficiaryByDocumentType}
                        isEditMode={beneficiarySelected !== undefined}
                    />
                </SideBarModal>
            )}
            <Box
                className={classNames("form-group", className, {
                    "has-error": fieldHasError(),
                })}>
                <Box
                    display="flex"
                    alignX="between"
                    alignY="center"
                    className={classNames("mb-3", {
                        "mt-3  mt-md-0": mode === EDIT_MODE,
                        "mt-7  mb-md-6": mode === PREVIEW_MODE,
                        "mt-4": mode === VIEW_MODE,
                    })}
                    gap="3">
                    {labelKey && (
                        <Box display="flex" column alignX="center">
                            <Text
                                component="label"
                                className="m-0"
                                color="primary"
                                labelKey={labelKey}
                                {...(mode === EDIT_MODE ? { regular: true } : { bold: true })}
                                addColon
                            />
                            {/* TODO (LABEL) */}
                            {isCorporate && mode === EDIT_MODE && (
                                <Text
                                    size={isDesktop ? "6" : "small"}
                                    labelKey="fixed.term.deposit.create.corporate.beneficiarySelector.description"
                                />
                            )}
                        </Box>
                    )}
                    {/* TODO (LOGICA DE MOSTRAR BOTON) */}
                    {mode === EDIT_MODE && shouldShowAddButton() && (
                        <Button
                            ariaLabel="add_beneficiary"
                            label={
                                isCorporate
                                    ? `${field?.name || ""}.add.signers.label`
                                    : `${field?.name || ""}.add.beneficiary.label`
                            }
                            bsStyle="link"
                            image="images/plusCircle.svg"
                            onClick={() => {
                                setShowAddBeneficiary(true);
                                setBeneficiarySelected(undefined);
                            }}
                            style={{ backgroundColor: "#ebf0f5" }}
                        />
                    )}
                </Box>

                {(mode === EDIT_MODE || mode === PREVIEW_MODE) && beneficiaryList && (
                    <Box
                        borderRadius="default"
                        border="background-divider-outset"
                        background="component-background"
                        overflow="hidden"
                        {...(mode === EDIT_MODE && { toBorderInMobile: true })}>
                        {renderTableBeneficiary()}
                    </Box>
                )}

                {mode === VIEW_MODE && beneficiaryList && renderViewModeResume()}
                {/* {renderError()} */}
                {infoLabel && mode === EDIT_MODE && (
                    <Box display="flex" alignX="flex-start" alignY="center" gapX={3} className="mb-4 mt-4">
                        <Box display="flex">
                            <Image className={classNames("tooltip-img")} src="images/tooltip-icon.svg" />
                        </Box>
                        <Box display="flex">
                            <Text
                                size={isDesktop ? "7" : "7"}
                                {...(!isDesktop && { color: "heading" })}
                                isCorporate={isCorporate}>
                                <div dangerouslySetInnerHTML={{ __html: infoLabel }} />
                            </Text>
                        </Box>
                    </Box>
                )}
            </Box>
        </>
    );
};

PercentageBeneficiaryField.propTypes = {
    labelKey: string,
    name: string.isRequired,
    tooltipText: string,
    form: shape({}).isRequired,
    field: shape({}).isRequired,
    className: bool,
    isDesktop: bool,
    documentData: shape({}),
    infoLabel: string,
    defaultValue: arrayOf(shape({})),
    mode: string,
    currentLang: string,
    beneficiaryRelationshipList: arrayOf(shape({})),
    activeEnvironment: shape({}).isRequired,
    getSignerName: func,
    isFetchingBeneficiaryByDocumentType: bool,
    beneficiaryByDocumentType: shape({}),
    saveCallback: func,
};

PercentageBeneficiaryField.defaultProps = {
    labelKey: undefined,
    tooltipText: null,
    className: false,
    isDesktop: false,
    documentData: undefined,
    infoLabel: undefined,
    defaultValue: [],
    mode: undefined,
    currentLang: undefined,
    beneficiaryRelationshipList: [],
    saveCallback: () => {},
    getSignerName: () => {},
    isFetchingBeneficiaryByDocumentType: false,
    beneficiaryByDocumentType: undefined,
};

const mapStateToProps = (state) => ({
    isFetchingBeneficiaryByDocumentType: fixedTermDepositSelectors.isFetchingBeneficiaryByDocumentType(state),
    beneficiaryByDocumentType: fixedTermDepositSelectors.getBeneficiaryByDocumentType(state),
});

export default compose(connect(mapStateToProps), withRouter)(PercentageBeneficiaryField);
