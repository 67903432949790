import * as campaigns from "middleware/campaigns";

import { all, call, put, takeLatest } from "redux-saga/effects";

import { types } from "reducers/campaigns";
import { getDisplay } from "util/device";

const sagas = [
    takeLatest(types.LOAD_CAMPAIGN_REQUEST, loadCampaign),
    takeLatest(types.LOAD_CAMPAIGNS_ANONYMOUS_REQUEST, loadCampaignsAnonymous),
    takeLatest(types.DISMISS_CAMPAIGN_PERMANENT_REQUEST, dismissPermanentCampaign),
];

export default sagas;

function* loadCampaign({ environmentSection }) {
    try {
        const response = yield call(campaigns.fetchCampaign, environmentSection);

        if (response.status === 304 || !response?.data?.data?.campaigns) {
            yield put({ type: types.LOAD_CAMPAIGN_FAILURE });
            yield;
            return;
        }

        if (response.data.data.campaigns.length === 0) {
            return;
        }

        yield put({
            type: types.SET_LOADING,
            fetching: true,
        });

        const loadCampaignTasks = response.data.data.campaigns.map((campaign) => call(handleCampaignLoading, campaign));

        yield all(loadCampaignTasks);
    } catch (err) {
        yield put({ type: types.LOAD_CAMPAIGN_FAILURE });
    }
}

function* loadCampaignsAnonymous({ environmentSection }) {
    try {
        const response = yield call(campaigns.fetchCampaignsAnonymous, environmentSection);
        if (response.status === 304 || !response?.data?.data?.campaigns) {
            yield put({ type: types.LOAD_CAMPAIGN_FAILURE });
            yield;
            return;
        }

        const loadCampaignTasks = response.data.data.campaigns.map((campaign) => call(handleCampaignLoading, campaign));

        yield all(loadCampaignTasks);
        yield put({ type: types.UPDATATE_PERSISTED_CAMPAIGNS });
    } catch (err) {
        yield put({ type: types.LOAD_CAMPAIGN_FAILURE });
    }
}

function getFittingImage([mobileImage, tabletImage, desktopImage]) {
    if (!tabletImage && !desktopImage) {
        return mobileImage;
    }
    switch (getDisplay()) {
        case "mobile":
            return mobileImage;
        case "tablet":
            return tabletImage;
        default:
            return desktopImage;
    }
}

function* handleCampaignLoading(campaign) {
    try {
        yield call(loadCampaignsImages, { item: campaign });
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error("Error campaign: ", campaign.idCampaign, err);
    }
}

function* loadCampaignsImages({ item }) {
    try {
        const image = getFittingImage(item.imageList);
        if (!image) {
            yield put({ type: types.LOAD_CAMPAIGN_FAILURE });
        } else {
            const { idImage, idCampaign } = image;
            const response = yield call(campaigns.fetchCampaignImage, { idImage, idCampaign });

            if (response.status !== 304) {
                const { data } = response.data;
                yield put({
                    type: types.LOAD_CAMPAIGNS_SUCCESS,
                    item: { ...item, image: data.image.content },
                });
            }
        }
    } catch (err) {
        yield put({ type: types.LOAD_CAMPAIGN_FAILURE });
    }
}

function* dismissPermanentCampaign(idCampaign) {
    try {
        const response = yield call(campaigns.dismissPermanentCampaign, idCampaign);

        if (response.data.code !== "COR000I") {
            yield put({ type: types.DISMISS_CAMPAIGN_PERMANENT_SUCCESS });
        } else {
            yield put({ type: types.DISMISS_CAMPAIGN_PERMANENT_FAILURE });
        }
    } catch (err) {
        yield put({ type: types.DISMISS_CAMPAIGN_PERMANENT_FAILURE });
    }
}
