import moment from "moment";
import { CORPORATE_ENVIRONMENT_TYPE, RETAIL_ENVIRONMENT_TYPE } from "constants.js";

export const matchEnvironmentSection = (environmentSection) => (item) =>
    item.sectionList.some((sectionItem) => sectionItem.idSection === environmentSection);

export const isActive = (item) => !item?.suspended;

export const isStarted = (item) => item?.startDate && moment(item.startDate).isBefore(moment());

export const isOngoing = (item) => (item.endDate ? moment(item.endDate).isAfter(moment()) : true);

export const getCampaignsByCriteria = (campaigns, criteriaList) =>
    campaigns.filter((campaign) => criteriaList.every((criteria) => criteria(campaign)));

export const getEnvironmentSection = (section, isActiveCorporate) =>
    `${section}-${isActiveCorporate ? CORPORATE_ENVIRONMENT_TYPE : RETAIL_ENVIRONMENT_TYPE}`;

export const sortItemsByDateDesc = (items) => {
    const today = moment().startOf("day");

    return items.sort((a, b) => {
        const dateA = moment(a.startDate);
        const dateB = moment(b.startDate);

        if (dateA.isSame(today, "day")) {
            return -1;
        }
        if (dateB.isSame(today, "day")) {
            return 1;
        }

        return dateB.isAfter(dateA) ? 1 : -1;
    });
};

export const getValidPersistItems = (state) => {
    const itemsMap = new Map(
        [...state.persistItems.filter(isOngoing), ...state.items].map((item) => [item.idCampaign, item]),
    );
    return Array.from(itemsMap.values());
};
