import classNames from "classnames";
import flowRight from "lodash/flowRight";
import Box from "pages/_components/Box";
import FormattedAmount from "pages/_components/FormattedAmount";
import Image from "pages/_components/Image";
import ProductTag from "pages/_components/ProductTag";
import { resizableRoute } from "pages/_components/Resizable";
import withFocus from "pages/_components/withFocus";
import Select from "pages/forms/_components/_fields/Select";
import formField from "pages/forms/_components/_fields/_commons/formField";
import propTypes, { arrayOf, bool, func, oneOfType, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { calculateShortLabel } from "util/account";
import * as i18nUtils from "util/i18n";

const ProductSelectorCustom = (props) => {
    const {
        accountSelected,
        autoFocus,
        customPlaceholder = null,
        data: dataProp,
        delayAutoFocus,
        disabled,
        field,
        idField,
        initialChange = true,
        isDesktop,
        isRequired,
        isWallyAccount,
        mode,
        onChange,
        selectRef,
        onChangeDataCustom,
        setValue,
        value,
        withSingleOption,
        loadBalance,
        form,
        showCreditCardBalance,
    } = props;

    const { options } = dataProp;
    const [initChange, setInitChange] = useState(initialChange);

    const generateLabelTc = (description) => {
        if (description) {
            const splitProductDesc = description?.split(" ");
            if (showCreditCardBalance) {
                const creditCardEmisor = description.length > 10 ? `${description.substring(0, 9)}...` : description;
                return `${creditCardEmisor}`;
            }
            return `${splitProductDesc[0]} ${splitProductDesc[1]}`;
        }
        return "";
    };

    const setAccountData = (AccountData) => {
        const {
            form: { setFieldValue },
            field: { name },
        } = props;
        let otherLabel = "";
        const { idProduct, productType, number, numberMask, currency, label, countableBalance, statusDesc } =
            AccountData || {};
        if (productType === "TC") {
            const { description } = AccountData || {};
            otherLabel = generateLabelTc(description);
        }
        if (productType === "CA" || productType === "CC") {
            otherLabel = label;
        }
        setFieldValue(
            `${name}Data`,
            {
                idProduct,
                productType,
                number: number || numberMask,
                currency,
                otherLabel,
                countableBalance,
                statusDesc,
            },
            false,
        );
    };

    const handleChange = (e) => {
        const { id } = e;
        if (id) {
            const valueSelect = options?.find((option) => option.id === id);
            const isAccountType = valueSelect?.productType === "CC" || valueSelect?.productType === "CA";

            if ((isAccountType && valueSelect?.status === "ACTIVA") || !isAccountType) {
                setAccountData(valueSelect);
                setValue(id);
            }
            if (onChangeDataCustom) {
                onChangeDataCustom(id);
            }
        }
    };

    useEffect(() => {
        if (value) {
            setValue(value);

            if (onChange && mode === "edit" && initChange) {
                onChange(value);
            } else if (!initChange) {
                setInitChange(true);
            }
        }
        // eslint-disable-next-line
    }, [value]);

    useEffect(() => {
        if (value && loadBalance !== undefined && loadBalance === true) {
            const balanceArray = options.filter((item) => item?.id && item.id === value);
            if (balanceArray.length > 0 && balanceArray[0]?.balance) {
                form.setFieldValue("balance", balanceArray[0].balance);
            }
        }
        // eslint-disable-next-line
    }, [value, options?.length]);

    useEffect(() => {
        const {
            form: { values },
            field: { name, value: valueField },
        } = props;

        if (
            options &&
            values &&
            name === "debitAccount" &&
            values.debitAccountData &&
            !values?.debitAccountData?.idProduct
        ) {
            const valueSelect = options?.find((option) => option.id === valueField);
            if (valueSelect) {
                setAccountData(valueSelect);
            }
        }
        if (
            options &&
            values &&
            name === "creditAccount" &&
            values.creditAccountData &&
            !values?.creditAccountData?.idProduct
        ) {
            const valueSelect = options?.find((option) => option.id === valueField);
            if (valueSelect) {
                setAccountData(valueSelect);
            }
        }
        if (!!valueField && name === "creditCard" && !values?.creditCardData?.idProduct && !!options) {
            const valueSelect = options?.find((option) => option.id === valueField);
            if (valueSelect) {
                setAccountData(valueSelect);
            }
        }
    }, [options, props, value]);

    const dataLength = dataProp?.options?.length;

    useEffect(() => {
        let v = value;

        if (mode === "edit") {
            if (!v && !isRequired) {
                v = "";
            }
            if (!v && dataLength) {
                if (accountSelected) {
                    handleChange({ id: accountSelected });
                }
            } else if (v) {
                // es value.value si es un campo cargado con setFieldValue (dado que el valor es
                // {value: xxx, isFrequestDestination: bool}) si es un valor que viene cargado por url,
                // el value es directamente el id de la cuenta es así hasta que eliminemos el
                // isFrequestDestination y esto se resuelva de otra forma (con value solito :P)
                handleChange({ id: v.value || v });
            }
        }
        if (autoFocus && delayAutoFocus) {
            setTimeout(() => {
                if (selectRef) {
                    selectRef.current.focus();
                }
            }, delayAutoFocus);
        }
        // eslint-disable-next-line
    }, []);

    const buildProductOptions = () => {
        // \u00A0 es un character especial para forzar un espacio en blanco
        const optionsRes = isRequired
            ? options
            : [{ id: "_empty", isFrequentDestination: false, label: "\u00A0" }, ...options];

        return optionsRes
            ?.filter((elem) => {
                if (elem === null || elem === undefined) {
                    return false; // skip
                }
                return true;
            })
            .map((elem) => {
                let labelPresentation = elem.longLabel || elem.label;
                if (elem.productType === "TC") {
                    const otherLabel = generateLabelTc(elem.description);
                    labelPresentation = calculateShortLabel(elem.productType, elem.numberMask, otherLabel);
                    if (elem?.relationType && elem.relationType === "N") {
                        labelPresentation = `${labelPresentation} - ${i18nUtils.get(
                            "creditCard.type.additional.label",
                        )}`;
                    }
                }

                if (elem.balance) {
                    return {
                        id: elem.id,
                        label: (
                            <Box
                                display="flex"
                                alignY="center"
                                className={classNames({ "combined-option": !isDesktop })}
                                fullWidth>
                                <span
                                    className={classNames("control-label", {
                                        "is-disabled": disabled,
                                    })}>
                                    {labelPresentation}
                                </span>

                                {(elem.productType !== "TC" || (elem.productType === "TC" && showCreditCardBalance)) && (
                                    <div>
                                        <FormattedAmount
                                            quantity={elem.balance.quantity}
                                            currency={elem.balance.currency}
                                        />
                                    </div>
                                )}
                                {isWallyAccount && (
                                    <Image
                                        height="5"
                                        wrapperWidth="8"
                                        wrapperHeight="5"
                                        className="ml-3"
                                        src="wally-head.svg"
                                    />
                                )}
                            </Box>
                        ),
                        // TODO pass disabled bool
                        disabled: elem.disabled,
                        data: elem,
                    };
                }
                return {
                    id: elem.id,
                    label: elem.longLabel || elem.label,
                    // TODO pass disabled bool
                    disabled: elem.disabled,
                    data: elem,
                };
            });
    };

    const renderEditMode = () => (
        <div className="input-group selector-custom">
            <div className="flex full-width">
                <Select
                    {...(selectRef && { selectRef })}
                    id={idField}
                    value={value}
                    options={buildProductOptions()}
                    labelKey="label"
                    valueKey="id"
                    clearable={false}
                    searchable={false}
                    withSingleOption={withSingleOption}
                    onChange={handleChange}
                    isOptionDisabled={(option) => option.disabled}
                    {...(customPlaceholder && { placeholder: customPlaceholder })}
                    disabled={disabled}
                    {...(!delayAutoFocus && { autoFocus })}
                />
            </div>
        </div>
    );

    const renderViewMode = () => {
        const { labelBackoffice } = props;
        let labelPresentation = labelBackoffice || field.value;
        const {
            form: { values: formValue },
            field: { name },
        } = props;

        if (formValue[`${name}Data`]) {
            const { productType, number, otherLabel } = formValue ? formValue[`${name}Data`] : {};
            const shortLabel = calculateShortLabel(productType, number, otherLabel);
            labelPresentation = shortLabel || labelPresentation;
        }

        return <ProductTag alias={labelPresentation} />;
    };

    const renderPreviewMode = () => {
        const productId = value || accountSelected;
        const { labelBackoffice } = props;
        let labelPresentation = labelBackoffice || field.value;
        let option = {};
        if (options) {
            option = options.find(({ id }) => id === productId) || {};
        }
        labelPresentation = option?.label || labelPresentation;
        if (option?.productType === "TC") {
            const otherLabel = generateLabelTc(option?.description) || option.otherLabel;
            labelPresentation = calculateShortLabel(
                option?.productType,
                option?.numberMask || option?.number,
                otherLabel,
            );
        }

        return <ProductTag alias={labelPresentation} />;
    };

    const renderContent = () => {
        if (mode === "edit") {
            return renderEditMode();
        }

        if (mode === "preview") {
            return renderPreviewMode();
        }

        if (mode === "view") {
            return renderViewMode();
        }

        return <div />;
    };

    return <>{renderContent()}</>;
};

ProductSelectorCustom.propTypes = {
    value: oneOfType([
        shape({
            isFrequentDestination: bool,
            value: string,
        }),
        string,
    ]),
    mode: string.isRequired,
    isRequired: bool.isRequired,
    onChange: func,
    idField: string.isRequired,
    field: oneOfType([string, shape({})]).isRequired,
    options: oneOfType([arrayOf(shape({})), shape({})]),
    setValue: func,
    accountSelected: shape({}),
    data: shape({}),
    initialChange: bool,
    customPlaceholder: string,
    labelBackoffice: string,
    form: shape({}),
    disabled: bool,
    isWallyAccount: bool,
    isDesktop: bool.isRequired,
    autoFocus: bool,
    delayAutoFocus: propTypes.number,
    selectRef: shape({}),
    withSingleOption: bool,
    onChangeDataCustom: func,
    loadBalance: bool,
    showCreditCardBalance: bool,
};

ProductSelectorCustom.defaultProps = {
    value: null,
    options: {},
    onChange: () => {},
    setValue: () => {},
    accountSelected: null,
    data: {},
    initialChange: true,
    customPlaceholder: "",
    labelBackoffice: null,
    form: null,
    disabled: false,
    isWallyAccount: false,
    autoFocus: false,
    delayAutoFocus: null,
    selectRef: null,
    withSingleOption: true,
    onChangeDataCustom: () => {},
    loadBalance: false,
    showCreditCardBalance: false,
};

export default flowRight(withFocus, formField())(resizableRoute(ProductSelectorCustom));
